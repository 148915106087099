<template>
  <div class="full-bleed banner-container" role="banner">
    <img v-if="user?.bannerIpfsPath" :src="fileUrl(user.bannerIpfsPath)" alt="banner image">
  </div>

  <div v-if="user" class="content-container">
    <div v-if="currentUserId === userId" class="profile-options">
      <router-link :to="{ name: 'edit-user', params: { userNickname }}">
        Edit profile
        <img src="@/assets/images/common/edit.svg" alt="edit icon" class="icon">
      </router-link>

      <button @click="logout">
        Log out
        <img src="@/assets/images/common/exit.svg" alt="log out icon" class="icon">
      </button>
    </div>

    <div class="left-column">
      <div class="user-card">
        <UserImage
          :userName="user.name"
          :userImagePath="user.photoIpfsPath"
          :isUserVerified="user.isVerified"
          :isUserRegistered="user.isRegistered"
          imageSize="160px"
          iconSize="42px"
          class="user-image-container"
        />
        <h2 v-if="user.name" class="name">
          {{ user.name }}
        </h2>
        <span class="nickname">
          @{{ user.nickname }}
        </span>
        <!-- <template v-if="user.isRegistered">
          <span v-if="isAddressCopied" class="copied-notification">
            copied!
          </span>
          <button v-else class="copy-address-button" @click="copyToClipboard(user.publicAddress)">
            <span class="address">{{ user.publicAddress }}</span>
            <img src="@/assets/images/common/copy.svg" alt="copy icon">
          </button>
        </template> -->
        <Markdown
          v-if="user.description"
          :data="user.description"
          :isMarked="user.isDescriptionMarked"
          class="description"
        />
        <div v-if="isAuthenticated && user.isFollowing !== null" class="subscription-container">
          <div class="followers-container">
            <span class="followers" :class="{ 'left': currentUserId !== userId }">
              {{ user.followersCounter }}
            </span>
            <span>followers</span>
          </div>
          <button
            v-if="currentUserId !== userId"
            class="subscribe-button"
            :disabled="disableFollowing"
            @click="user.isFollowing ? unfollowUser() : followUser()"
          >
            {{ user.isFollowing ? 'Unfollow' : 'Follow' }}
          </button>
        </div>

        <!-- social followers -->

        <div class="social-stats">
          <hr>
          <div v-if="user.tiktokFollowersCount" class="social-followers">
            <p class="social-followers-count">{{ formatNumber(user.tiktokFollowersCount) }}</p>
            <p class="social-followers-platform">TikTok followers</p>
          </div>
          <div v-if="user.instagramFollowersCount" class="social-followers">
            <p class="social-followers-count">{{ formatNumber(user.instagramFollowersCount) }}</p>
            <p class="social-followers-platform">Instagram followers</p>
          </div>
          <div v-if="user.twitchFollowersCount" class="social-followers">
            <p class="social-followers-count">{{ formatNumber(user.twitchFollowersCount) }}</p>
            <p class="social-followers-platform">Twitch followers</p>
          </div>
          <div v-if="user.twitterFollowersCount" class="social-followers">
            <p class="social-followers-count">{{ formatNumber(user.twitterFollowersCount) }}</p>
            <p class="social-followers-platform">Twitter followers</p>
          </div>
          <div v-if="user.youtubeFollowersCount" class="social-followers">
            <p class="social-followers-count">{{ formatNumber(user.youtubeFollowersCount) }}</p>
            <p class="social-followers-platform">YouTube followers</p>
          </div>
          <hr>
        </div>

        <!-- social followers end -->

        <div class="socials">
          <a v-if="user.twitterLink" :href="user.twitterLink" target="_blank">
            <img src="@/assets/images/socials/twitter.svg" alt="twitter icon">
          </a>
          <a v-if="user.instagramLink" :href="user.instagramLink" target="_blank">
            <img src="@/assets/images/socials/instagram.svg" alt="instagram icon">
          </a>
          <a v-if="user.twitchLink" :href="user.twitchLink" target="_blank">
            <img src="@/assets/images/socials/twitch.svg" alt="twitch icon">
          </a>
          <a v-if="user.youtubeLink" :href="user.youtubeLink" target="_blank">
            <img src="@/assets/images/socials/youtube.svg" alt="youtube icon">
          </a>
          <a v-if="user.tiktokLink" :href="user.tiktokLink" target="_blank">
            <img src="@/assets/images/socials/tiktok.svg" alt="tiktok icon">
          </a>
        </div>
      </div>
    </div>

    <div class="user-content">
      <template v-if="!user.isVerified && !user.isRegistered">
        <UserProfileOffChainBanner
          class="info-card"
          :userNickname="user.name || user.nickname"
        />
      </template>

      <div class="tabs-header">
        <label class="tab-button">
          <input
            v-model="filter"
            name="user-profile-item-type-tab-options"
            :value="UserItemFilter.Created"
            type="radio"
            :disabled="isSelectingModeOn"
            hidden
          >
          <span class="label">Created</span>
        </label>
        <label class="tab-button">
          <input
            v-model="filter"
            name="user-profile-item-type-tab-options"
            :value="UserItemFilter.Collectibles"
            type="radio"
            :disabled="isSelectingModeOn"
            hidden
          >
          <span class="label">Collected</span>
        </label>
      </div>

      <template v-if="currentUserId === userId">
        <div v-if="isSelectingModeOn" class="selected-options">
          <button @click="isSelectingModeOn = false" class="outline-button">
            Cancel
          </button>
          <button
            @click="isPutOnSaleModalOpen = true"
            class="primary-button"
            :disabled="!selected.length || !isMainNet"
          >
            Put selected on sale
          </button>
        </div>
        <div v-else class="items-options">
          <router-link
            v-if="user.isSelfMint && userProfileInfo.isSelfMint"
            :to="{ name: 'create-item' }"
          >
            Create NFT
          </router-link>
          <button
            v-if="isSelectingModeAvailable"
            :disabled="!isMainNet"
            @click="turnSelectingModeOn"
          >
            Put NFTs on sale
          </button>
        </div>
      </template>

      <UserItems
        :userId="userId"
        :filter="filter"
        :selecting="isSelectingModeOn"
        v-model="selected"
      />
    </div>
  </div>

  <ContentLoader :loading="isPageLoading"/>

  <SaleModal
    key="user-profile-sale-form"
    :isOpen="isPutOnSaleModalOpen"
    formId="user-profile-sale-form"
    :title="'Put selected NFTs on sale'"
    submitButtonLabel="Put on sale"
    @setOnSale="putItemsOnSale"
    @setOnEnglishAuction="putItemsOnEnglishAuction"
    @setOnDutchAuction="putItemsOnDutchAuction"
    @close="isPutOnSaleModalOpen = false"
  />

  <InfoModal :isOpen="error.isOpen" title="Error" @close="error.isOpen = false">
    <p>{{ error.text }}</p>
  </InfoModal>

  <InfoModal :isOpen="success.isOpen" title="Success" @close="success.isOpen = false">
    <p>{{ success.text }}</p>
  </InfoModal>

  <EmailSubscribeModal
    :isOpen="showEmailSubscribeModal"
    @updated-email="showEmailSubscribeModal = false"
    @close="showEmailSubscribeModal = false"
  ></EmailSubscribeModal>

  <Loading
    v-model:active="isLoading"
    loader="dots"
    background-color="#161414"
    color="#d92b3a"
    :opacity="0.9"
  />
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Loading from 'vue-loading-overlay';
import copy from 'copy-to-clipboard';

import { fmKey } from '@/instances/fortmatic';

import HttpStatusCode from '@/shared/models/http-status-code.enum';
import fileUrl from '@/shared/helpers/file-url';
import formatNumber from '@/shared/helpers/format-number';
import {
  ACTION_SUCCESS_DEFAULT,
  ERROR_UNKNOWN,
  ITEMS_SELLING_SET_SUCCESS,
  USER_FOLLOW_FAILURE,
  USER_UNFOLLOW_FAILURE,
} from '@/shared/constants/messages';

import { useStore } from '@/store';
import { Actions as GlobalActions, Modules, Mutations as GlobalMutations } from '@/store/props';
import { Actions } from '@/store/modules/user-profile/props';
import { Actions as ItemProfileActions } from '@/store/modules/item-profile/props';

import ItemSaleData from '@/store/modules/item-profile/models/item-sale-data';
import UserItemFilter from '@/store/modules/user-list/models/user-item-filter.enum';

import ContentLoader from '@/components/ContentLoader.vue';
import InfoModal from '@/components/InfoModal.vue';
import Markdown from '@/components/Markdown.vue';
import SaleModal from '@/components/SaleModal.vue';
import UserImage from '@/components/UserImage.vue';
import EmailSubscribeModal from '@/components/EmailSubscribeModal.vue';
import UserProfileOffChainBanner from '@/components/UserProfileOffChainBanner.vue';

import UserItems from './UserItems.vue';

export default defineComponent({
  name: 'UserProfile',

  components: {
    Loading,
    UserItems,
    ContentLoader,
    InfoModal,
    Markdown,
    SaleModal,
    UserImage,
    EmailSubscribeModal,
    UserProfileOffChainBanner,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const fmProvider = inject(fmKey);

    const isPageLoading = ref<boolean>(false);

    const isPutOnSaleModalOpen = ref<boolean>(false);
    const isAddressCopied = ref<boolean>(false);
    const showEmailSubscribeModal = ref<boolean>(false);

    const isLoading = ref<boolean>(false);
    const disableFollowing = ref<boolean>(false);
    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });
    const success = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });

    const filter = ref<UserItemFilter>(UserItemFilter.Created);
    const isSelectingModeOn = ref<boolean>(false);
    const selected = ref<number[]>([]);

    const userNickname = computed(() => route.params.userNickname);
    const user = computed(() => store.state.UserProfile.user);
    const userId = computed(() => store.state.UserProfile.user?.userId);
    const userProfileInfo = computed(() => store.state.user.personalInfo);
    const currentUserId = computed(() => store.state.user.id);
    const isMainNet = computed(() => store.state.user.isMainNet);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const isSelectingModeAvailable = computed(
      () => (
        filter.value === UserItemFilter.Created
          ? store.state.UserProfile.items.Created.data.length > 0
          : store.state.UserProfile.items.Collectibles.data.length > 0
      ),
    );

    const loadUser = async (nickname: string) => {
      isPageLoading.value = true;

      try {
        await store.dispatch(`${Modules.UserProfile}/${Actions.fetchUser}`, nickname);
      } catch (e) {
        if (e.isAxiosError && e.response.status === HttpStatusCode.NotFound) {
          router.push({ name: 'user-list' });
        }
      } finally {
        isPageLoading.value = false;
      }
    };

    const updateFollowingStatus = async (nickname: string) => {
      isPageLoading.value = true;

      try {
        await store.dispatch(`${Modules.UserProfile}/${Actions.fetchIsFollowing}`, nickname);
      } catch (e) {
        console.error(e);
      } finally {
        isPageLoading.value = false;
      }
    };

    const clearSelectingState = () => {
      isSelectingModeOn.value = false;
      selected.value = [];
    };

    const clearUser = () => {
      store.dispatch(`${Modules.UserProfile}/${Actions.clearUser}`);
    };

    const copyToClipboard = (address: string) => {
      if (copy(address)) {
        isAddressCopied.value = true;

        setTimeout(() => {
          isAddressCopied.value = false;
        }, 1000);
      }
    };

    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    function buildSuccessModal(text: string): void {
      success.value = {
        isOpen: true,
        text: text || ACTION_SUCCESS_DEFAULT,
      };
    }

    async function putItemsOnSale(data: ItemSaleData) {
      isPutOnSaleModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.UserProfile}/${Actions.setItemsForSale}`, { ...data, ids: [...selected.value] });
        buildSuccessModal(ITEMS_SELLING_SET_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function putItemsOnEnglishAuction(data: ItemSaleData) {
      isPutOnSaleModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${ItemProfileActions.setItemsForEnglishAuction}`, { ...data, ids: [...selected.value] });
        buildSuccessModal(ITEMS_SELLING_SET_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function putItemsOnDutchAuction(data: ItemSaleData) {
      isPutOnSaleModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${ItemProfileActions.setItemsForDutchAuction}`, { ...data, ids: [...selected.value] });
        buildSuccessModal(ITEMS_SELLING_SET_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function followUser() {
      showEmailSubscribeModal.value = false;
      if (!userProfileInfo.value?.email || !userProfileInfo.value?.isNotificationAvailable) {
        showEmailSubscribeModal.value = true;
      }

      disableFollowing.value = true;

      try {
        await store.dispatch(`${Modules.UserProfile}/${Actions.followUser}`);
      } catch (e) {
        buildErrorModal(e.message || USER_FOLLOW_FAILURE);
      } finally {
        disableFollowing.value = false;
      }
    }

    async function unfollowUser() {
      disableFollowing.value = true;

      try {
        await store.dispatch(`${Modules.UserProfile}/${Actions.unfollowUser}`);
      } catch (e) {
        buildErrorModal(e.message || USER_UNFOLLOW_FAILURE);
      } finally {
        disableFollowing.value = false;
      }
    }

    const turnSelectingModeOn = () => {
      selected.value = [];
      isSelectingModeOn.value = true;
    };

    const logout = () => {
      clearSelectingState();

      store.commit(GlobalMutations.setLoading, true);
      store
        .dispatch(GlobalActions.logout)
        .catch((err) => console.error(err))
        .finally(() => {
          if (fmProvider) {
            fmProvider.user.logout();
          }
          store.commit(GlobalMutations.setLoading, false);
          router.push({ name: 'home' });
        });
    };

    watch(userNickname, (nickname) => {
      clearUser();
      clearSelectingState();
      if (nickname) {
        loadUser(nickname as string);
      }
    });

    watch(currentUserId, () => {
      clearSelectingState();
      if (userId.value) {
        updateFollowingStatus(userId.value as string);
      }
    });

    // As for now metamask cancel unapproved transactions on network change,
    // but do not send any response to that, so we reload forcefully, though,
    // there may be ongoing transaction, too.
    watch(isMainNet, () => {
      if (isLoading.value) {
        router.go(0);
      }
    });

    watch(() => success.value.isOpen, (open) => {
      if (!open) {
        router.go(0);
      }
    });

    onMounted(() => {
      clearSelectingState();
      loadUser(userNickname.value as string).finally(() => {
        // reload user page if logged user got isSelfMint field updated
        if (userProfileInfo.value.id === userId.value
          && userProfileInfo.value.isSelfMint !== user.value?.isSelfMint) {
          router.go(0);
        }
      });
    });

    onUnmounted(() => {
      clearUser();
      clearSelectingState();
    });

    return {
      isPageLoading,
      user,
      userProfileInfo,
      userId,
      userNickname,
      currentUserId,
      isMainNet,
      isAuthenticated,
      filter,
      selected,
      isSelectingModeAvailable,
      isSelectingModeOn,
      isPutOnSaleModalOpen,
      isAddressCopied,
      isLoading,
      disableFollowing,
      error,
      success,
      UserItemFilter,
      copyToClipboard,
      turnSelectingModeOn,
      putItemsOnSale,
      putItemsOnEnglishAuction,
      putItemsOnDutchAuction,
      followUser,
      unfollowUser,
      logout,
      fileUrl,
      showEmailSubscribeModal,
      formatNumber,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

@mixin primary-button {
  @include typo-button-2;
  @include button-primary-filled;

  display: inline-flex;
  justify-content: center;
  padding: 10px 1rem;
}

.banner-container {
  @include background-gradient-default;

  height: 326px;
  margin-top: -4rem;

  @include for-xs-sm-md-width {
    height: 230px;
  }

  img {
    @include image-fallback;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content-container {
  @include items-wrapper;

  position: relative;
  margin-top: -9rem;

  @include for-md-width {
    justify-content: center;
  }

  @include for-xs-sm-md-width {
    margin-top: -4rem;
  }

  .left-column {
    width: 25%;

    @include for-lg-width {
      width: 33.33%;
    }

    @include for-md-width {
      width: 100%;
      max-width: 366px * 2;
    }

    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }
  }

  .user-content {
    width: 75%;

    .info-card {
      margin-bottom: 3rem;
    }

    @include for-lg-xl-width {
      padding-top: 10.25rem;
    }

    @include for-lg-width {
      width: 66.66%;
    }

    @include for-md-width {
      width: 100%;
      max-width: 366px * 2;
    }

    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }
  }
}

.profile-options {
  position: absolute;
  top: 4.5rem;
  right: 1rem;
  padding: 0;

  @include for-xs-sm-md-width {
    top: 0.5rem;
    transform: translateY(-100%);
  }

  & > * {
    @include primary-button;

    margin-left: 1rem;

    .icon {
      width: 16px;
      margin-left: 0.75rem;
    }
  }
}

.items-options {
  margin: 1rem -0.5rem 0;

  @include for-xs-sm-width {
    display: flex;
    flex-direction: column;
  }

  & > * {
    @include primary-button;

    margin: 1rem 0.5rem 0;
  }
}

.selected-options {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  @include for-xs-sm-width {
    flex-direction: column-reverse;
  }

  .outline-button {
    @include typo-button-2;
    @include button-outline($neutrals-1, $control-border-color);

    margin-top: 1rem;
    padding: 10px 1rem;
  }

  .primary-button {
    @include primary-button;

    margin-top: 1rem;
  }
}

.user-card {
  @include user-card-common;

  max-width: 256px;
  margin: 0 auto;
  padding: 2rem 1rem;
  overflow: visible;
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);

  @include for-xs-sm-width {
    max-width: 310px;
  }

  .user-image-container {
    margin-bottom: 1.5rem;
  }

  .name {
    @include typo-body-1-bold;
    color: $neutrals-2;
  }

  .nickname {
    @include typo-body-2-bold;
    @include text-ellipsis;

    max-width: 90%;
    color: $neutrals-2;
  }

  .copy-address-button {
    @include typo-button-2;

    display: inline-flex;
    max-width: 160px;
    margin-top: 1rem;
    color: $neutrals-2;

    .address {
      @include text-ellipsis;

      max-width: calc(100% - 1.5rem);
    }

    img {
      width: 1rem;
      margin-left: 0.5rem;
    }
  }

  .copied-notification {
    @include typo-button-2;

    margin-top: 1rem;
    color: $neutrals-2;
  }

  .description {
    @include typo-caption-2;
    margin-top: 1.25rem;
    color: $neutrals-4;
  }

  .subscription-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1.5rem 1rem 0;

    .followers-container {
      @include typo-caption-2;

      display: flex;
      flex-direction: column;
      color: $neutrals-1;

      .followers {
        @include typo-caption-bold;

        &.left {
          text-align: left;
        }
      }
    }

    .subscribe-button {
      @include typo-button-2;
      @include button-outline($neutrals-1, $control-border-color);

      display: inline-flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      margin-left: 1rem;
      padding: 13px 1rem;
      text-align: center;
    }
  }

  .social-stats {
    width: 100%;
    padding: 0 0.75rem;

    hr {
      border: none;
      border-bottom: 1px solid $neutrals-6;

      &:nth-of-type(1) {
        margin: 24px 0;
      }

      &:nth-of-type(2) {
        margin-top: 24px;
        margin-bottom: 0;
      }
    }

    .social-followers {
      margin-bottom: 10px;
      text-align: left;

      .social-followers-count {
        @include typo-caption;

        color: $neutrals-2;
        font-weight: 500;
      }

      .social-followers-platform {
        @include typo-caption-2;

        color: $neutrals-3;
      }
    }
  }

  .socials {
    & > * {
      display: inline-flex;
      width: 20px;
      margin: 2rem 0.75rem 0;
    }
  }
}

.tabs-header {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;

  .tab-button {
    display: inline-flex;
    margin-right: 0.75rem;

    .label {
      @include transition-default;
      @include typo-button-2;
      padding: 8px 14px;
      color: $neutrals-3;
      border-radius: 100px;
      cursor: pointer;
    }

    input:checked + .label {
      color: $neutrals-8;
      background: $neutrals-3;
    }

    input:disabled + .label {
      cursor: not-allowed;
      opacity: 0.6;
    }

    input:not(:disabled) + .label:hover {
      color: $neutrals-8;
      background: $neutrals-3;
    }
  }
}
</style>
