
import { defineComponent } from 'vue';

// components
import FeaturedCreators from './Home/FeaturedCreators.vue';
import FeaturedItems from './Home/FeaturedItems.vue';

export default defineComponent({
  name: 'Home',
  components: {
    FeaturedCreators,
    FeaturedItems,
  },
});
