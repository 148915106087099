
import { computed, defineComponent } from 'vue';

import { useStore } from '@/store';
import fileUrl from '@/shared/helpers/file-url';

export default defineComponent({
  setup() {
    const store = useStore();
    const banner = computed(() => store.state.featuredBanner);

    return {
      banner,
      fileUrl,
    };
  },
});
