import { USER_AUTH_CANCELED, USER_AUTH_PENDING } from '../constants/messages';

export default function getAuthError(e: any): string {
  if (e.code === 4001) {
    return USER_AUTH_CANCELED;
  }

  if (e.code === -32002) {
    return USER_AUTH_PENDING;
  }

  return e.message;
}
