<template>
  <div class="feed-item-card">
    <button class="creator-container" @click="openCreator(data.user.nickname, $event)">
      <UserImage
        :userName="data.user.name"
        :userImagePath="data.user.photoIpfsPath"
        :isUserVerified="data.user.isVerified"
        :isUserRegistered="data.user.isRegistered"
        imageSize="36px"
        iconSize="12px"
      />
      <span class="creator-name">{{ data.user.nickname }}</span>
    </button>
    <div class="event-container">
      <span class="event-name">{{ data.eventText }}</span>
      <span class="event-date">{{ getDate(data.createdAt) }}</span>
    </div>
    <ItemCard :data="data.marketItem" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import FeedListItem from '@/store/modules/feed/models/feed-list-item';

import ItemCard from '@/components/ItemCard.vue';
import UserImage from '@/components/UserImage.vue';

export default defineComponent({
  name: 'FeedItemCard',

  components: {
    ItemCard,
    UserImage,
  },

  props: {
    data: {
      type: Object as () => FeedListItem,
      required: true,
    },
  },

  setup() {
    const router = useRouter();

    const openCreator = (userNickname: string, e: MouseEvent) => {
      e.preventDefault();

      router.push({ name: 'user-profile', params: { userNickname } });
    };

    function getDate(date: string): string {
      return new Date(date).toDateString();
    }

    return {
      openCreator,
      getDate,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.feed-item-card {
  @include card;
  @include card-interactive;

  @include for-xs-sm-md-lg-width {
    @include box-shadow-mobile;
  }
}

.creator-container {
  display: flex;
  align-items: center;
  max-width: calc(100% - 1rem);
  padding: 0.5rem;

  .creator-name {
    @include typo-body-2;
    @include text-ellipsis;

    max-width: calc(100% - 42px);
    margin-left: 6px;
    color: $neutrals-2;
    font-weight: 500;
  }
}

.event-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  .event-name {
    @include typo-body-2;
    color: $neutrals-1;
  }

  .event-date {
    @include typo-caption-2;
    color: $neutrals-4;
  }
}
</style>
