import TimeLeftData from '@/shared/models/time-left-data';

function calcTimeDiffSeparately(endDateStr: string): TimeLeftData {
  const curDate = new Date();
  const endDate = new Date(endDateStr);

  const diff = +endDate - +curDate;
  const diffDate = new Date(diff);

  return {
    days: Math.floor(diff / (1000 * 60 * 60 * 24)),
    hours: diffDate.getUTCHours(),
    minutes: diffDate.getUTCMinutes(),
    seconds: diffDate.getUTCSeconds(),
    totalLeft: diff,
  };
}

export default calcTimeDiffSeparately;
