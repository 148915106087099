<template>
  <table class="table">
    <thead>
      <th class="table-header" colspan="1"></th>
      <th class="table-header" colspan="3">
        Creator
      </th>
      <th class="table-header" colspan="6">
        <div class="table-header-content">
          <span>Followers of verified creators</span>
          <button @click="sortFollowers = !sortFollowers">
            <img
              v-if="sortFollowers"
              class="table-header-sort"
              src="@/assets/images/common/arrow-down.svg"
            />
            <img
              v-else
              src="@/assets/images/common/arrow-down.svg"
              class="table-header-sort rotate-180"
            />
          </button>
        </div>
      </th>
      <!-- <th class="table-header" colspan="1">
        <div class="table-header-content">
          <span>Likes</span>
          <button @click="sortLikes = !sortLikes">
            <img
              v-if="sortLikes"
              class="table-header-sort"
              src="@/assets/images/common/arrow-down.svg"
            />
            <img
              v-else
              src="@/assets/images/common/arrow-down.svg"
              class="table-header-sort rotate-180"
            />
          </button>
        </div>
      </th>
      <th class="table-header" colspan="1">
        <div class="table-header-content">
          <span>Views</span>
          <button @click="sortViews = !sortViews">
            <img
              v-if="sortViews"
              class="table-header-sort"
              src="@/assets/images/common/arrow-down.svg"
            />
            <img
              v-else
              src="@/assets/images/common/arrow-down.svg"
              class="table-header-sort rotate-180"
            />
          </button>
        </div>
      </th> -->
      <th class="table-header" colspan="2">NFT Items</th>
      <th class="table-header" colspan="1"></th>
    </thead>
    <tbody class="table-body">
      <CreatorsTableRow
        v-for="(item, index) in data"
        :key="index"
        :data="item"
        :index="index + 1"
      />
    </tbody>
  </table>
</template>

<script lang="ts">
import {
  defineComponent, computed, ref, watch,
} from 'vue';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-list/props';

import CreatorsTableRow from '@/components/CreatorsTableRow.vue';
import UserStatsFilter from '@/store/modules/user-list/models/user-stats-filter.enum';

interface loadOptions {
  sortBy?: string,
}

export default defineComponent({
  components: {
    CreatorsTableRow,
  },
  setup() {
    const store = useStore();

    const data = computed(() => store.state.UserList.stats.data);
    const filters = computed(() => store.state.UserList.stats.filters);

    const sortFollowers = ref<boolean>(true);
    const sortLikes = ref<boolean>(false);
    const sortViews = ref<boolean>(false);

    const clearRankedUsers = () => {
      store.dispatch(`${Modules.UserList}/${Actions.clearRankedUsers}`);
    };

    const loadUsers = (options: loadOptions) => {
      store.dispatch(`${Modules.UserList}/${Actions.fetchRankedUsers}`, {
        offset: filters.value.offset,
        followersFilter: filters.value.followersFilter,
        creatorsFilter: filters.value.creatorsFilter,
        creatorItemsFilters: filters.value.creatorItemsFilters,
        sortBy: options.sortBy,
        pageSize: filters.value.pageSize,
      });
    };

    watch(
      [sortFollowers, sortLikes, sortViews],
      // eslint-disable-next-line consistent-return
      ([sf, sl, sv], [prevSf, prevSl, prevSv]) => {
        clearRankedUsers();
        if (sf !== prevSf) {
          return loadUsers({
            sortBy: sf ? UserStatsFilter.FollowersDesc : UserStatsFilter.Followers,
          });
        }
        if (sl !== prevSl) {
          return loadUsers({
            sortBy: sl ? UserStatsFilter.ViewsDesc : UserStatsFilter.Views,
          });
        }
        if (sv !== prevSv) {
          return loadUsers({
            sortBy: sv ? UserStatsFilter.ViewsDesc : UserStatsFilter.Views,
          });
        }
      },
    );

    return {
      data,
      sortFollowers,
      sortLikes,
      sortViews,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.table {
  width: 100%;
  margin-top: 20px;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 1em;

  .table-header {
    font-weight: 500;
    font-size: 14px;

    .table-header-content {
      display: flex;
      align-items: center;
    }

    .table-header-sort {
      margin-right: 20px;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      width: 35%;
    }

    &:nth-of-type(4) {
      width: 15%;
    }

    @include for-xs-sm-width {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  }

  @include for-xs-sm-width {
    display: block;
    margin-top: -40px;

    .table-body {
      display: block;
    }
  }
}

.rotate-180 {
  transform: rotate(180deg);
}
</style>
