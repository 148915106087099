
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import Loading from 'vue-loading-overlay';
import Toggle from '@vueform/toggle';
import isEqual from 'lodash/isEqual';

import { getUrlRegExpByTypeId, isExternalType, isInstagramType } from '@/shared/helpers/get-item-file-type';
import CryptoCurrency from '@/shared/models/crypto-currency.enum';
import HttpStatusCode from '@/shared/models/http-status-code.enum';
import { ACTION_SUCCESS_DEFAULT, ERROR_UNKNOWN, ITEM_MINT_SUCCESS } from '@/shared/constants/messages';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-profile/props';
import { Actions as ItemListActions } from '@/store/modules/item-list/props';
import { Actions as UserProfileActions } from '@/store/modules/user-profile/props';

import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';
import ItemListItem from '@/store/modules/item-list/models/item-list-item';

import ItemSellingType from '@/store/modules/item-profile/models/item-selling-type';
import ItemSaleData from '@/store/modules/item-profile/models/item-sale-data';
import ItemCreateRequest from '@/store/modules/item-profile/models/item-create-request';
import ItemCreateResponse from '@/store/modules/item-profile/models/item-create-response';

import InfoModal from '@/components/InfoModal.vue';
import ItemCard from '@/components/ItemCard.vue';
import FileUploadButton from '@/components/FileUploadButton.vue';
import Select from '@/components/Select.vue';
import ItemFilterSelect from '@/components/ItemFilterSelect.vue';
import ItemTypeSelect from '@/components/ItemTypeSelect.vue';
import SaleForm from '@/components/SaleForm.vue';
import RewardStatus from '@/shared/models/reward-status.enum';

export default defineComponent({
  name: 'CreateItem',

  components: {
    ItemTypeSelect,
    Loading,
    Toggle,
    InfoModal,
    ItemCard,
    FileUploadButton,
    Select,
    ItemFilterSelect,
    SaleForm,
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const isLoading = ref<boolean>(false);
    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });
    const success = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });

    const token = computed(() => store.state.session.token);
    const currentUserNickname = computed(() => store.state.user.personalInfo.nickname);
    const isMainNet = computed(() => store.state.user.isMainNet);
    const user = computed(() => store.state.UserProfile.user);

    const originalLinkRef = ref<HTMLInputElement | null>(null);

    const isOnSale = ref<boolean>(false);
    const itemData = reactive<ItemCreateRequest>({
      files: [],
      title: '',
      description: '',
      isDescriptionMarked: false,
      originalLink: '',
      extra: null,
      isExtraMarked: false,
      categoryIds: [],
      typeId: null,
      filters: [],
      saleType: null,
      totalCopies: 1,
    });

    const maxFilesCount = computed(() => (isInstagramType(itemData.typeId) ? 10 : 1));

    const pristine = ref<Record<string, boolean>>({
      title: true,
      originalLink: true,
    });

    const createItemData = ref<{
      prevData: ItemCreateRequest,
      prevSaleData: ItemSaleData,
      res: ItemCreateResponse,
    } | null>(null);

    const formRef = ref<HTMLFormElement | null>(null);

    const sellingType = ref<ItemSellingType>(ItemSellingType.OnSale);
    const saleData = ref<ItemSaleData>({
      currency: CryptoCurrency.MATIC,
      amount: 1,
      amountTo: 2,
      duration: 60 * 60 * 24,
      numberOfIntervals: 1,
    });

    const previewItem = ref<Partial<ItemListItem>>({
      title: itemData.title ?? '',
      ipfsPaths: [],
      mimeTypes: [],
      edition: `1 of ${itemData.totalCopies}`,

      price: saleData.value.amount,
      cryptoCurrency: saleData.value.currency,
      usdPrice: '',
      salesStatus: isOnSale.value ? sellingType.value : ItemSalesStatus.Minted,

      creatorNickname: '',
      creatorName: '',
      creatorPhotoIpfsPath: '',
      isCreatorVerified: false,
      isCreatorRegistered: true,

      rewardStatus: RewardStatus.None,

      ownerId: '',
    });

    const categories = computed(() => store.state.ItemList.categories);

    const errors = ref<Record<string, string | null>>({
      file: null,
    });

    function onFileRejected(e: Error): void {
      errors.value.file = e.message;
    }

    function validateFilesCount(): void {
      const filesCount = itemData.files.length;

      if (maxFilesCount.value < filesCount) {
        errors.value.file = `Selected number of files is invalid. Max possible file count: ${maxFilesCount.value}`;
      } else {
        errors.value.file = null;
      }
    }

    function clearAllFiles(): void {
      itemData.files = [];
    }

    function clearSelectedFile(index: number): void {
      itemData.files = itemData.files.filter((_, i) => i !== index);
    }

    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    function buildSuccessModal(text: string): void {
      success.value = {
        isOpen: true,
        text: text || ACTION_SUCCESS_DEFAULT,
      };
    }

    async function createItem() {
      if (!isMainNet.value) {
        return;
      }

      pristine.value.title = false;

      isLoading.value = true;

      const data = { ...itemData };

      if (isOnSale.value) {
        data.saleType = sellingType.value;
      }

      if (!isEqual(data, createItemData.value?.prevData)
        || !isEqual(saleData.value, createItemData.value?.prevSaleData)) {
        createItemData.value = null;
      }

      if (!createItemData.value) {
        try {
          const res = await store.dispatch(`${Modules.ItemProfile}/${Actions.createItem}`, data);
          createItemData.value = { prevData: data, prevSaleData: { ...saleData.value }, res };
        } catch (e) {
          if (e.response?.status === HttpStatusCode.BadRequest
            && e.response?.data?.TypeId) {
            if (originalLinkRef.value) {
              originalLinkRef.value.setCustomValidity(e.response?.data?.TypeId[0]);
              originalLinkRef.value.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          } else {
            buildErrorModal(e.message);
          }
        }
      }

      if (createItemData.value) {
        try {
          const action = `mintItem${sellingType.value}` as Actions.mintItemOnSale | Actions.mintItemOnEnglishAuction | Actions.mintItemOnDutchAuction;

          await store.dispatch(`${Modules.ItemProfile}/${action}`, {
            data,
            saleData: { ...saleData.value },
            res: { ...createItemData.value.res },
          });
          buildSuccessModal(ITEM_MINT_SUCCESS);
        } catch (e) {
          buildErrorModal(e.message);
        }
      }

      isLoading.value = false;
    }

    function setUserData() {
      store.dispatch(`${Modules.UserProfile}/${UserProfileActions.fetchUser}`, currentUserNickname.value as string).finally(() => {
        previewItem.value.creatorNickname = currentUserNickname.value ?? '';
        previewItem.value.creatorName = user.value?.name ?? '';
        previewItem.value.creatorPhotoIpfsPath = user.value?.photoIpfsPath ?? '';
        previewItem.value.isCreatorVerified = user.value?.isVerified;
        previewItem.value.isCreatorRegistered = user.value?.isRegistered;

        previewItem.value.ownerId = user.value?.userId ?? '';
      });
    }

    function setFiat(usdPrice: string | null) {
      previewItem.value.usdPrice = usdPrice;
    }

    watch(() => itemData.files, (value: File[]) => {
      validateFilesCount();

      if (value) {
        previewItem.value.ipfsPaths = value.map((file: File) => URL.createObjectURL(file));
        previewItem.value.mimeTypes = value.map((file: File) => file.type);
      }
    });

    watch(() => itemData.title, (value) => {
      pristine.value.title = false;
      previewItem.value.title = value ?? '';
    });

    watch(() => itemData.originalLink, () => {
      if (originalLinkRef.value) {
        originalLinkRef.value.setCustomValidity('');
      }
    });

    watch(() => itemData.typeId, () => {
      validateFilesCount();
    });

    watch(() => itemData.extra, (value) => {
      previewItem.value.rewardStatus = value ? RewardStatus.Available : RewardStatus.None;
    });

    watch(() => itemData.totalCopies, (value) => {
      previewItem.value.edition = `1 of ${value}`;
    });

    watch(() => saleData.value.amount, (value) => {
      previewItem.value.price = value || 0;
    });

    watch(() => saleData.value.currency, (value) => {
      previewItem.value.cryptoCurrency = value;
    });

    watch(sellingType, (value) => {
      previewItem.value.salesStatus = isOnSale.value ? value : ItemSalesStatus.Minted;
    });

    watch(isOnSale, (value) => {
      previewItem.value.salesStatus = value ? sellingType.value : ItemSalesStatus.Minted;

      if (!value) {
        sellingType.value = ItemSalesStatus.OnSale;
        saleData.value = {
          currency: CryptoCurrency.MATIC,
          amount: 1,
          amountTo: 2,
          duration: 60 * 60 * 24,
          numberOfIntervals: 1,
        };
      }
    });

    watch(token, (value) => {
      if (value) {
        setUserData();
      }
    });

    // As for now metamask cancel unapproved transactions on network change,
    // but do not send any response to that, so we reload forcefully, though,
    // there may be ongoing transaction, too.
    watch(isMainNet, () => {
      if (isLoading.value) {
        router.go(0);
      }
    });

    watch(() => success.value.isOpen, (open) => {
      // Changed to closed
      if (!open) {
        const id = createItemData.value?.res.ids[0];
        createItemData.value = null;
        router.push(id ? { name: 'item-profile', params: { id } } : { name: 'item-list' });
      }
    });

    onMounted(() => {
      createItemData.value = null;

      store.dispatch(`${Modules.ItemList}/${ItemListActions.fetchCategories}`);

      setUserData();
    });

    onUnmounted(() => {
      createItemData.value = null;

      store.dispatch(`${Modules.UserProfile}/${UserProfileActions.clearUser}`);
    });

    return {
      ...toRefs(itemData),
      maxFilesCount,
      isOnSale,
      previewItem,
      formRef,
      originalLinkRef,
      sellingType,
      saleData,
      isMainNet,
      categories,
      isLoading,
      error,
      pristine,
      success,
      errors,
      CryptoCurrency,
      isExternalType,
      getUrlRegExpByTypeId,
      onFileRejected,
      clearAllFiles,
      clearSelectedFile,
      setFiat,
      createItem,
    };
  },
});
