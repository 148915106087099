<template>
  <div class="heading-container">
    <h3>Featured NFT Items 🔥</h3>
    <div class="options">
      <router-link
        :to="{ name: 'item-list' }"
        class="more-button"
      >See all</router-link>
    </div>
  </div>
  <div v-if="data.length" ref="scrollComponent" class="items-container">
    <div v-for="item in data" :key="item.id">
      <ItemCard :data="item" />
    </div>
  </div>
  <div v-if="!isItemsLoading && !data.length" class="empty-container">
    <p>Coming soon.</p>
  </div>
  <ContentLoader :loading="isItemsLoading"/>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import throttle from 'lodash/throttle';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-list/props';
import PaginationOptions from '@/shared/models/pagination-options';

import ItemListFilters from '@/store/modules/item-list/models/item-list-filters';

import ContentLoader from '@/components/ContentLoader.vue';
import ItemCard from '@/components/ItemCard.vue';

export default defineComponent({
  name: 'FeaturedItems',

  components: {
    ItemCard,
    ContentLoader,
  },

  setup() {
    const store = useStore();

    const isItemsLoading = ref<boolean>(false);

    const data = computed(() => store.state.ItemList.items.data);
    const pagination = computed(() => store.state.ItemList.items.pagination);
    const isLastPage = computed(() => store.state.ItemList.items.isLastPage);

    const scrollComponent = ref<HTMLElement | null>(null);

    const loadItems = (options: Partial<PaginationOptions> & Partial<ItemListFilters>) => {
      isItemsLoading.value = true;
      store.dispatch(`${Modules.ItemList}/${Actions.fetchItems}`, {
        isFeatured: true,
        isNSFW: true,
        ...options,
      }).finally(() => {
        isItemsLoading.value = false;
      });
    };

    const clearItems = () => {
      store.dispatch(`${Modules.ItemList}/${Actions.clearItems}`);
    };

    const handleScroll = () => {
      const element = scrollComponent.value;
      if (element && !isLastPage.value && !isItemsLoading.value
        && element.getBoundingClientRect().bottom < window.innerHeight
      ) {
        loadItems({ page: pagination.value.page + 1 });
      }
    };

    onMounted(() => {
      loadItems({ page: 1 });

      window.addEventListener('scroll', throttle(handleScroll, 800, { leading: false }));
    });

    onUnmounted(() => {
      clearItems();

      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isItemsLoading,
      data,
      pagination,
      scrollComponent,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.heading-container {
  @include items-wrapper;

  margin-top: 2rem;

  @include for-md-lg-xl-width {
    justify-content: space-between;
  }

  & > * {
    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }
  }

  h3 {
    color: $neutrals-2;

    @include typo-headline-5;
  }

  .options {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include for-xs-sm-width {
      padding-top: 1rem;
    }
  }

  .nsfw-toggle {
    display: inline-flex;
    align-items: center;
    margin-right: 2rem;

    .label {
      @include typo-body-2-bold;

      margin-right: 0.5rem;
      color: $neutrals-2;
    }
  }

  .more-button {
    @include typo-button-2;
    @include button-primary-filled;

    display: inline-flex;
    padding: 10px 1rem;
  }
}

.items-container {
  @include items-container;
}

.empty-container {
  @include empty-container;
}
</style>
