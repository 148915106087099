<template>
  <span class="info-tooltip" @click="preventDefault">
    <Tooltip
      :label="label"
      :position="position"
      :style="{ 'vertical-align': 'middle' }"
      multiline
    >
      <img
        src="@/assets/images/common/info-circle.svg"
        alt="info circle icon"
        class="icon"
      >
    </Tooltip>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InfoTooltip',

  props: {
    label: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: 'is-top',
    },
  },

  setup() {
    const preventDefault = (e: MouseEvent) => {
      e.preventDefault();
    };

    return {
      preventDefault,
    };
  },
});
</script>

<style lang="scss" scoped>
.info-tooltip {
  display: inline-flex;
  margin: -10px -10px -10px 0;
  padding: 10px;

  .icon {
    display: block;
    width: 24px;
  }
}
</style>
