enum Mutations {
  setItemDetails = 'setItemDetails',
  setItemOffChainOffers = 'setItemOffChainOffers',
  setItemOffers = 'setItemOffers',
  setItemBids = 'setItemBids',
  setItemUserOffChainOffer = 'setItemUserOffChainOffer',
  setItemUserOffer = 'setItemUserOffer',
  setItemsStats = 'setItemsStats',
}

export default Mutations;
