
import {
  defineComponent, computed, ref, watch,
} from 'vue';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-list/props';

import CreatorsTableRow from '@/components/CreatorsTableRow.vue';
import UserStatsFilter from '@/store/modules/user-list/models/user-stats-filter.enum';

interface loadOptions {
  sortBy?: string,
}

export default defineComponent({
  components: {
    CreatorsTableRow,
  },
  setup() {
    const store = useStore();

    const data = computed(() => store.state.UserList.stats.data);
    const filters = computed(() => store.state.UserList.stats.filters);

    const sortFollowers = ref<boolean>(true);
    const sortLikes = ref<boolean>(false);
    const sortViews = ref<boolean>(false);

    const clearRankedUsers = () => {
      store.dispatch(`${Modules.UserList}/${Actions.clearRankedUsers}`);
    };

    const loadUsers = (options: loadOptions) => {
      store.dispatch(`${Modules.UserList}/${Actions.fetchRankedUsers}`, {
        offset: filters.value.offset,
        followersFilter: filters.value.followersFilter,
        creatorsFilter: filters.value.creatorsFilter,
        creatorItemsFilters: filters.value.creatorItemsFilters,
        sortBy: options.sortBy,
        pageSize: filters.value.pageSize,
      });
    };

    watch(
      [sortFollowers, sortLikes, sortViews],
      // eslint-disable-next-line consistent-return
      ([sf, sl, sv], [prevSf, prevSl, prevSv]) => {
        clearRankedUsers();
        if (sf !== prevSf) {
          return loadUsers({
            sortBy: sf ? UserStatsFilter.FollowersDesc : UserStatsFilter.Followers,
          });
        }
        if (sl !== prevSl) {
          return loadUsers({
            sortBy: sl ? UserStatsFilter.ViewsDesc : UserStatsFilter.Views,
          });
        }
        if (sv !== prevSv) {
          return loadUsers({
            sortBy: sv ? UserStatsFilter.ViewsDesc : UserStatsFilter.Views,
          });
        }
      },
    );

    return {
      data,
      sortFollowers,
      sortLikes,
      sortViews,
    };
  },
});
