<template>
  <transition :name="hasInitialBackdrop ? 'fade-with-initial-backdrop' : 'fade'">
    <div class="modal-backdrop">
      <div
        class="modal-container"
        :style="{
          maxWidth: maxWidth,
          borderRadius,
          height,
          width,
        }
      ">
        <div
          class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
        >
          <header class="modal-header" id="modalTitle">
            <slot name="header"></slot>
            <button
              v-if="hasCloseButton"
              type="button"
              class="close-button"
              @click="closeModal"
              aria-label="Close modal"
            >
              <img src="@/assets/images/common/close.svg" alt="Close icon">
            </button>
          </header>

          <section v-if="hasBody" class="modal-body">
            <slot name="body"></slot>
          </section>

          <footer v-if="hasFooter" class="modal-footer">
            <slot name="footer"></slot>
            <button
              v-if="hasCancelButton"
              type="button"
              class="cancel-button"
              @click="closeModal"
              aria-label="Close modal"
            >
              {{ cancelButtonLabel }}
            </button>
          </footer>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed, defineComponent, watch } from 'vue';

import { useStore } from '@/store';

export default defineComponent({
  name: 'Modal',

  props: {
    hasInitialBackdrop: {
      type: Boolean,
      default: false,
    },
    hasCloseButton: {
      type: Boolean,
      default: true,
    },
    hasBody: {
      type: Boolean,
      default: true,
    },
    hasCancelButton: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: '450px',
    },
    height: {
      type: String,
      default: 'auto',
    },
    width: {
      type: String,
      default: '',
    },
    borderRadius: {
      type: String,
      default: '20px',
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    cancelButtonLabel: {
      type: String,
      default: 'Cancel',
    },
  },

  emits: [
    'close',
  ],

  setup(props, { emit }) {
    const store = useStore();

    const currentUserId = computed(() => store.state.user.id);

    const closeModal = () => {
      emit('close');
    };

    watch(currentUserId, () => {
      closeModal();
    });

    return {
      closeModal,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($neutrals-1, 0.9);
}

.modal-container {
  z-index: 1000;
  width: calc(100% - 2rem);
  overflow: hidden;
  background: $neutrals-8;
  border-radius: 20px;
  box-shadow: $depth-4;
}

.modal {
  @include scrollbar;

  display: flex;
  flex-direction: column;

  max-height: calc(100vh - 2rem);
  padding: 2rem;
  overflow: auto;
}

.modal-header {
  @include typo-headline-4;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: $neutrals-2;

  .close-button {
    @include button-outline($neutrals-2, $neutrals-6, $neutrals-6);

    display: inline-flex;
    margin-left: 1rem;
    padding: 11px;
    line-height: 14px;

    img {
      width: 14px;
      height: 14px;
    }
  }
}

.modal-body {
  @include typo-body-2;

  margin-top: 2rem;
}

.modal-footer {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  .cancel-button {
    @include typo-button-1;
    @include button-outline($neutrals-1, $control-border-color);

    margin-top: 0.5rem;
    padding: 13px;
  }
}

.fade-enter-active,
.fade-leave-active,
.fade-with-initial-backdrop-enter-active,
.fade-with-initial-backdrop-leave-active {
  transition: opacity .4s ease;

  & > .modal {
    transform: translateY(0);
    opacity: 1;
    transition: all .4s ease;
  }
}

.fade-enter-from,
.fade-leave-to,
.fade-with-initial-backdrop-leave-to {
  opacity: 0;

  & > .modal {
    transform: translateY(-20px);
    opacity: 0;
  }
}

.fade-with-initial-backdrop-enter-from {
  opacity: 1;

  & > .modal {
    transform: translateY(-20px);
    opacity: 0;
  }
}
</style>
