
import { defineComponent, ref } from 'vue';

import ExpansionCard from '@/components/ExpansionCard.vue';

export default defineComponent({
  components: {
    ExpansionCard,
  },
  props: {
    userNickname: {
      type: String,
      default: '',
    },
  },
  setup() {
    const expanded = ref<boolean>(true);

    const toggleExpanded = (newVal: boolean) => {
      expanded.value = newVal;
    };

    return {
      expanded,
      toggleExpanded,
    };
  },
});
