<template>
  <modal
    v-show="isOpen"
    :hasBody="false"
    :hasCancelButton="false"
    @close="$emit('close')"
  >
    <template v-slot:header>
      <h2>Connect wallet</h2>
    </template>

    <template v-slot:footer>
      <button
        @click="connectWithMetamask"
        class="outline-button"
      >
        Connect via MetaMask
        <img src="@/assets/images/wallets/metamask.svg" alt="MetaMask logo" class="icon">
      </button>
      <button
        @click="connectWithFortmatic"
        class="outline-button"
      >
        Connect via Fortmatic
        <img src="@/assets/images/wallets/fortmatic.svg" alt="Fortmatic logo" class="icon">
      </button>
    </template>
  </modal>

  <InfoModal :isOpen="error.isOpen" title="Error" @close="error.isOpen = false">
    <p>{{ error.text }}</p>
  </InfoModal>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from 'vue';
import { fmKey } from '@/instances/fortmatic';

import { ERROR_UNKNOWN } from '@/shared/constants/messages';

// store
import { useStore } from '@/store';
import { Actions, Mutations } from '@/store/props';

// components
import Modal from '@/components/Modal.vue';
import InfoModal from '@/components/InfoModal.vue';

export default defineComponent({
  name: 'ConnectionModal',
  components: {
    Modal,
    InfoModal,
  },
  props: ['isOpen'],
  emits: ['close', 'endConnection'],
  setup(_, { emit }) {
    // data
    const fmProvider = inject(fmKey);
    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });
    // store
    const store = useStore();

    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    async function connect(isMetamask: boolean) {
      emit('close');

      store.commit(Mutations.setLoading, true);
      try {
        await store.dispatch(Actions.reqUserAuth, { forceConnection: true, isMetamask });
        if (isMetamask) {
          await store.dispatch(Actions.switchEthNetwork);
        }
        emit('endConnection');
      } catch (err) {
        buildErrorModal(err.message);
        if (!isMetamask && fmProvider) {
          fmProvider.user.logout();
        }
      } finally {
        store.commit(Mutations.setLoading, false);
      }
    }

    async function connectWithMetamask() {
      await connect(true);
    }

    async function connectWithFortmatic() {
      await connect(false);
    }

    return {
      error,
      connectWithMetamask,
      connectWithFortmatic,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.outline-button {
  @include typo-button-1;
  @include button-outline($neutrals-1, $control-border-color);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 1rem;
  text-align: center;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  .icon {
    height: 24px;
    margin-left: 0.75rem;
  }
}
</style>
