<template>
  <ExpansionCard id="item-profile-offers" title="Offers">
    <div v-if="data.length" ref="scrollComponent" class="table-container">
      <table class="table">
        <thead>
          <tr class="table-header-row">
            <th class="table-header-cell">Price</th>
            <th class="table-header-cell">USD Price</th>
            <th class="table-header-cell">From</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :ref="setRowRef"
            v-for="item in data"
            :key="item.id"
            class="table-row"
            @click="$emit('acceptOffer', item)"
            title="Accept this offer"
            tabindex="0"
            role="button"
            aria-pressed="false"
          >
            <td class="table-cell">
              <CryptoPrice :amount="item.price" :currency="item.cryptoCurrency"/>
            </td>
            <td class="table-cell">
              ${{ item.usdPrice }}
            </td>
            <td class="table-cell">
              <span class="overlay-cell">
                <router-link
                  :to="{ name: 'user-profile', params: { userNickname: item.userNickname }}"
                  class="link"
                >
                  {{ item.userName }}
                </router-link>
                <span class="accept">Accept</span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-if="!isOffersLoading && !data.length" class="empty-list">
      No offers yet.
    </p>
    <ContentLoader :loading="isOffersLoading"/>
  </ExpansionCard>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUpdate,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
} from 'vue';
import throttle from 'lodash/throttle';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-profile/props';

import PaginationOptions from '@/shared/models/pagination-options';

import CryptoPrice from '@/components/CryptoPrice.vue';
import ExpansionCard from '@/components/ExpansionCard.vue';
import ContentLoader from '@/components/ContentLoader.vue';

export default defineComponent({
  name: 'ItemProfileOfferList',

  components: {
    CryptoPrice,
    ExpansionCard,
    ContentLoader,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  emits: ['acceptOffer'],

  setup(props) {
    const { id } = toRefs(props);
    const scrollComponent = ref<HTMLElement | null>(null);
    const isOffersLoading = ref<boolean>(false);

    const store = useStore();
    const data = computed(() => store.state.ItemProfile.offers.data);
    const pagination = computed(() => store.state.ItemProfile.offers.pagination);
    const isLastPage = computed(() => store.state.ItemProfile.offers.isLastPage);

    let rowRefs: HTMLTableRowElement[] = [];

    const loadOffers = (options: Partial<PaginationOptions> & { id: string }) => {
      isOffersLoading.value = true;
      store.dispatch(`${Modules.ItemProfile}/${Actions.fetchOffers}`, { ...options })
        .finally(() => {
          isOffersLoading.value = false;
        });
    };

    const setRowRef = (el: HTMLTableRowElement) => {
      if (el) {
        rowRefs.push(el);
      }
    };

    const checkElementsBounds = () => {
      if (!isLastPage.value && !isOffersLoading.value && scrollComponent.value) {
        const element = rowRefs[rowRefs.length - 1];
        if (element && (element.getBoundingClientRect().top
          < scrollComponent.value.getBoundingClientRect().bottom)
        ) {
          loadOffers({ id: id.value, page: pagination.value.page + 1 });
        }
      }
    };

    onBeforeUpdate(() => {
      rowRefs = [];
    });

    onMounted(() => {
      loadOffers({ id: id.value, page: 1 });

      if (scrollComponent.value) {
        scrollComponent.value.addEventListener('scroll', throttle(checkElementsBounds, 800, { leading: false }));
      }
    });

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener('scroll', throttle(checkElementsBounds, 800, { leading: false }));
      }
    });

    return {
      isOffersLoading,
      data,
      scrollComponent,
      setRowRef,
      loadOffers,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.table-container {
  @include table-container-common;
  max-height: 300px;
  overflow: visible;
}

.table-row {
  outline: none;
  cursor: pointer;

  .overlay-cell {
    position: relative;
    display: inline-flex;
    width: 100%;

    .accept {
      @include typo-button-1;

      position: absolute;
      top: 50%;
      left: 50%;
      display: none;
      transform: translate(-50%, -50%);
    }
  }

  .table-cell:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .table-cell:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &:hover, &:focus {
    .table-cell {
      color: $neutrals-8;
      background-color: $neutrals-4;

      .link {
        color: $neutrals-4;
        pointer-events: none;
      }
    }

    .overlay-cell {
      .accept {
        display: inline-flex;
      }
    }
  }
}

.empty-list {
  @include typo-caption;
}
</style>
