enum Actions {
  fetchUser = 'fetchUser',
  fetchIsFollowing = 'fetchIsFollowing',
  fetchItems = 'fetchItems',
  setItemsForSale = 'setItemsForSale',
  updateUser = 'updateUser',
  followUser = 'followUser',
  unfollowUser = 'unfollowUser',
  clearUser = 'clearUser',
  clearItems = 'clearItems',
  fetchSocialFollowersStats = 'fetchSocialFollowersStats',
  fetchGlobalUsersStats = 'fetchGlobalUsersStats',
}

export default Actions;
