<template>
  <template v-if="enableEmbedded && typeId && isExternalType(typeId) && url && !error">
    <ContentLoader v-if="!isLoaded" :loading="!isLoaded" :overlap="false" />

    <!-- INSTAGRAM -->
    <div v-if="isInstagramType(typeId)" class="instagram-container">
      <div
        ref="containerRef"
        class="iframe-container"
        :style="{
          'padding-bottom': getInstagramOffset(oembed?.thumbnail_width, oembed?.thumbnail_height),
        }"
      >
        <iframe
          v-if="oembed"
          :sandbox="IFRAME_SANDBOX"
          :srcdoc="oembed.html"
          frameborder="0"
          :allow="IFRAME_ALLOW"
          @load="isLoaded = true"
          :style="{ visibility: isLoaded ? 'visible' : 'hidden' }"
        ></iframe>
        <a
          v-if="isLoaded"
          :href="url"
          target="_blank"
          class="overlay-link"
          aria-label="link to original source"
        ></a>
      </div>
    </div>

    <!-- TWITTER -->
    <div
      v-else-if="isTwitterType(typeId)"
      ref="containerRef"
      class="twitter-container"
    ></div>

    <!-- TIKTOK, YOUTUBE, TWITCH -->
    <div
      v-else
      :class="{
        'external-container': isLoaded,
        'tiktok-container': isTikTokType(typeId),
      }"
    >
      <div
        ref="containerRef"
        class="iframe-container"
        :class="{
          'standard-video': isYouTubeType(typeId) || isTwitchType(typeId),
          'tiktok': isTikTokType(typeId),
        }"
      >
        <iframe
          v-if="mediaEmbed"
          :sandbox="IFRAME_SANDBOX"
          :src="mediaEmbed.url"
          frameborder="0"
          :allow="IFRAME_ALLOW"
          allowfullscreen
          @load="isLoaded = true"
          :style="{ visibility: isLoaded ? 'visible' : 'hidden' }"
          :class="{
            'tiktok': isTikTokType(typeId),
          }"
        ></iframe>
      </div>
    </div>
  </template>

  <slot v-else name="fallback" />
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs,
  watch,
} from 'vue';

import {
  isOEmbedType,
  isTikTokType,
  isInstagramType,
  isTwitterType,
  isYouTubeType,
  isTwitchType,
  isExternalType,
} from '@/shared/helpers/get-item-file-type';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-profile/props';

import { VideoTypeOEmbedData, PhotoTypeOEmbedData } from '@/shared/models/oembed-data';
import ItemTypeSimplified from '@/store/modules/item-list/models/item-type-simplified';

import ContentLoader from '@/components/ContentLoader.vue';

const IFRAME_SANDBOX = 'allow-same-origin allow-scripts allow-forms allow-popups allow-presentation allow-orientation-lock allow-pointer-lock';
const IFRAME_ALLOW = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';

interface MediaEmbedData {
  url: string,
}

export default defineComponent({
  name: 'ItemEmbedFilePreview',

  components: {
    ContentLoader,
  },

  props: {
    itemId: {
      type: Number,
      required: true,
    },
    typeId: {
      type: String as PropType<ItemTypeSimplified['id']>,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    enableEmbedded: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const { enableEmbedded } = toRefs(props);
    const store = useStore();

    const containerRef = ref<HTMLDivElement | null>(null);

    const oembed = ref<VideoTypeOEmbedData | PhotoTypeOEmbedData | null>(null);
    const mediaEmbed = ref<MediaEmbedData | null>(null);

    const isLoaded = ref<boolean>(false);
    const error = ref<string | null>(null);

    function setError(message: string): void {
      error.value = message;
    }

    function getInstagramOffset(mediaWidth?: number, mediaHeight?: number): string {
      const minContainerOffset = mediaHeight ? mediaHeight * 2.5 : 680;
      const ratio = mediaHeight && mediaWidth ? mediaHeight / mediaWidth : 1;
      const containerWidth = containerRef.value?.getBoundingClientRect().width;
      const wrapperWidth = Math.max(Math.floor(containerWidth ?? 0), 326);

      const mediaOffset = Math.ceil(wrapperWidth * ratio); // image height
      const nonMediaOffset = 54 + 44 + 41 + 24 + 44; // other elements heigtht (caption is hidden)
      const wrapperOffset = 8 + 12 + 1 * 2; // iframe margins and borders
      const scrollbarOffset = 20; // overflow scrollbar maximum height

      const totalOffset = mediaOffset + nonMediaOffset + wrapperOffset + scrollbarOffset;

      return `${minContainerOffset > totalOffset ? minContainerOffset : totalOffset}px`;
    }

    async function loadOEmbed(itemId: number, maxwidth: number, maxheight: number) {
      try {
        const data = await store.dispatch(
          `${Modules.ItemProfile}/${Actions.getItemOEmbedData}`,
          { itemId, maxwidth, maxheight },
        );

        if (data.type) {
          oembed.value = { ...data };
        } else {
          setError(data);
        }
      } catch (e) {
        setError(e.message);
      }
    }

    function getSlug(url: string) {
      return url.trim().split('?')[0].split('/').pop();
    }

    function setTikTokEmbed(url: string) {
      const slug = getSlug(url);
      mediaEmbed.value = {
        url: `https://tiktok.com/embed/${slug}`,
      };
    }

    function setYouTubeEmbed(oembedHtml: string) {
      // youtube html is stringified iframe element
      const src = oembedHtml.split('"').find((v) => v.startsWith('http'));
      mediaEmbed.value = {
        url: `${src}&autoplay=1`,
      };
    }

    function setTwitterEmbed(url: string) {
      // eslint-disable-next-line
      if (!(window as any).twttr) {
        setError('Twitter publisher doesn\'t exist.');
        return;
      }

      const slug = getSlug(url);
      // eslint-disable-next-line
      (window as any).twttr.widgets.createTweet(slug, containerRef.value, {
        conversation: 'none',
        dnt: true,
      })// eslint-disable-next-line
        .then((data: any) => {
          if (!data) {
            setError('Tweet is not available');
          }
        })
        .catch(() => {
          setError('Tweet can not be embedded');
        })
        .finally(() => {
          isLoaded.value = true;
        });
    }

    function setTwitchEmbed(url: string) {
      // https://dev.twitch.tv/docs/embed/video-and-clips#non-interactive-iframes-for-clips
      const slug = getSlug(url);
      const origin = window.location.hostname;
      mediaEmbed.value = {
        url: `https://clips.twitch.tv/embed?clip=${slug}&parent=${origin}&autoplay=true`,
      };
    }

    function setEmbed(itemId: number, typeId: string, url: string) {
      let width = Math.floor(containerRef.value?.getBoundingClientRect().width ?? 640);
      let height = Math.floor(containerRef.value?.getBoundingClientRect().height ?? 360);

      if (isInstagramType(typeId)) {
        width = Math.max(width, 320);
        height = Math.min(height, 600);
      } else if (isTwitterType(typeId)) {
        width = Math.max(width, 180);
        height = Math.max(height, 200);
      }

      if (isTikTokType(typeId)) {
        setTikTokEmbed(url);
      } else if (isOEmbedType(typeId)) {
        loadOEmbed(itemId, width, height).finally(() => {
          if (!error.value) {
            if (isYouTubeType(typeId)) {
              setYouTubeEmbed((oembed.value as VideoTypeOEmbedData).html);
            } else if (isTwitterType(typeId)) {
              setTwitterEmbed(url);
            }
          }
        });
      } else if (isTwitchType(typeId)) {
        setTwitchEmbed(url);
      }
    }

    function trySetEmbedded(): void {
      if (props.typeId && props.url) {
        setEmbed(props.itemId, props.typeId, props.url);
      }
    }

    watch(enableEmbedded, (value) => {
      if (value) {
        trySetEmbedded();
      }
    });

    onMounted(() => {
      trySetEmbedded();
    });

    return {
      containerRef,
      oembed,
      mediaEmbed,
      isLoaded,
      error,
      IFRAME_SANDBOX,
      IFRAME_ALLOW,
      getInstagramOffset,
      isTikTokType,
      isInstagramType,
      isTwitterType,
      isYouTubeType,
      isTwitchType,
      isExternalType,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.external-container {
  box-shadow: $depth-item;
}

.tiktok-container {
  max-width: 325px;
  margin-right: auto;
  margin-left: auto;
}

.instagram-container {
  max-width: 326px + 8px * 2 + 16px * 2;
  margin-right: auto;
  margin-left: auto;
  overflow-x: auto;

  @include for-xs-sm-width {
    margin-right: -16px;
    margin-left: -16px;
  }

  .overlay-link {
    position: absolute;
    z-index: 5;
    display: inline-flex;
    width: 100%;
    height: 100%;
  }
}

.twitter-container {
  overflow: auto;
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;

  &.tiktok {
    padding-bottom: calc(177.78% + 4px); // ~9:16 + offset
  }

  &.standard-video {
    padding-bottom: 56.25%; // 16:9
  }
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;

  &.tiktok {
    width: 103%;
    height: 117%;
    margin-top: -4px;
    margin-left: -4px;
  }
}

img {
  @include image-fallback;
}

video {
  @include background-gradient-default;

  display: block;
}
</style>
