<template>
  <article>
    <h2>Privacy Policy</h2>

    <h4>Introduction</h4>

    <p>Welcome to the Melon's privacy policy.</p>

    <p>
      Melon respects your privacy and is committed to protecting your personal
      data. This privacy policy will inform you as to how we look after your
      personal data when you visit our website (regardless of where you visit it
      from) and tell you about your privacy rights and how the law protects you.
    </p>

    <ol class="index">
      <li>
        <router-link to="#pp-about">
          Important information and who we are
        </router-link>
      </li>
      <li>
        <router-link to="#pp-personal-data-kinds">
          The data we collect about you
        </router-link>
      </li>
      <li>
        <router-link to="#pp-personal-data-collection">
          How is your personal data collected?
        </router-link>
      </li>
      <li>
        <router-link to="#pp-personal-data-usage">
          How we use your personal data
        </router-link>
      </li>
      <li>
        <router-link to="#pp-personal-data-disclosures">
          Disclosures of your personal data
        </router-link>
      </li>
      <li>
        <router-link to="#pp-personal-data-transfers">
          International transfers
        </router-link>
      </li>
      <li>
        <router-link to="#pp-personal-data-security">
          Data security
        </router-link>
      </li>
      <li>
        <router-link to="#pp-personal-data-retention">
          Data retention
        </router-link>
      </li>
      <li>
        <router-link to="#pp-personal-data-protection">
          Your legal rights
        </router-link>
      </li>
      <li>
        <router-link to="#pp-glossary">
          Glossary
        </router-link>
      </li>
    </ol>

    <h3 id="pp-about">Important information and who we are</h3>

    <h4>Purpose of this privacy policy</h4>

    <p>
      This privacy policy aims to give you information on how Melon collects and
      processes your personal data through your use of this website, including
      any data you may provide through this website when you sign up to our
      newsletter
      <strong>OR</strong> purchase a product or service <strong>OR</strong> take
      part in a competition <strong>OR</strong> sign a contract to sell an NFT
      with us <strong>OR</strong> sign a referral agreement.
    </p>

    <p>
      This website is not intended for children and we do not knowingly collect
      data relating to children.
    </p>

    <p>
      It is important that you read this privacy policy together with any other
      privacy policy or fair processing policy we may provide on specific
      occasions when we are collecting or processing personal data about you so
      that you are fully aware of how and why we are using your data. This
      privacy policy supplements other notices and privacy policies and is not
      intended to override them.
    </p>

    <h4>Controller</h4>

    <p>
      Melon Technology Inc is the controller and responsible for your personal
      data (collectively referred to as &quot;Melon&quot;, &quot;we&quot;,
      &quot;us&quot; or &quot;our&quot; in this privacy policy).
    </p>

    <h4>Contact details</h4>

    <p>
      If you have any questions about this privacy policy or our privacy
      practices, please contact us in the following ways:
    </p>

    <p>Full name of legal entity: Melon Technology Inc</p>

    <p>Email address: privacy@melon.ooo</p>

    <p>
      Postal address: Intershore Chambers, Road Town, Tortola, British Virgin
      Islands, VG1110
    </p>

    <p>
      You have the right to make a complaint at any time to the Information
      Commissioner's Office (ICO), the UK regulator for data protection issues
      (<a href="https://ico.org.uk/" target="_blank">www.ico.org.uk</a>). We
      would, however, appreciate the chance to deal with your concerns before
      you approach the ICO so please contact us in the first instance.
    </p>

    <h4>Changes to the privacy policy and your duty to inform us of changes</h4>

    <p>
      We keep our privacy policy under regular review. It is important that the
      personal data we hold about you is accurate and current. Please keep us
      informed if your personal data changes during your relationship with us.
    </p>

    <h4>Third-party links</h4>

    <p>
      This website may include links to third-party websites, plug-ins and
      applications. Clicking on those links or enabling those connections may
      allow third parties to collect or share data about you. We do not control
      these third-party websites and are not responsible for their privacy
      statements. When you leave our website, we encourage you to read the
      privacy policy of every website you visit.
    </p>

    <h3 id="pp-personal-data-kinds">The data we collect about you</h3>

    <p>
      Personal data, or personal information, means any information about an
      individual from which that person can be identified. It does not include
      data where the identity has been removed (anonymous data).
    </p>

    <p>
      We may collect, use, store and transfer different kinds of personal data
      about you which we have grouped together as follows:
    </p>

    <ul>
      <li>
        <strong>Identity Data</strong> includes first name, maiden name, last
        name, username or similar identifier, social media profile handles,
        title, date of birth and gender.
      </li>
      <li>
        <strong>Contact Data</strong> includes billing address, delivery
        address, email address and telephone numbers.
      </li>
      <li>
        <strong>Financial Data</strong> includes cryptocurrency wallet
        addresses.
      </li>
      <li>
        <strong>Transaction Data</strong> includes details about payments to and
        from you and other details of products and services you have purchased
        from us.
      </li>
      <li>
        <strong>Technical Data</strong> includes internet protocol (IP) address,
        your login data, browser type and version, time zone setting and
        location, browser plug-in types and versions, operating system and
        platform, and other technology on the devices you use to access this
        website.
      </li>
      <li>
        <strong>Profile Data</strong> includes your username and password,
        profile content, purchases or orders made by you, your interests,
        preferences, feedback and survey responses.
      </li>
      <li>
        <strong>Usage Data</strong> includes information about how you use our
        website, products and services.
      </li>
      <li>
        <strong>Marketing and Communications Data</strong> includes your
        preferences in receiving marketing from us and our third parties and
        your communication preferences.
      </li>
    </ul>

    <p>
      We also collect, use and share <strong>Aggregated Data</strong> such as
      statistical or demographic data for any purpose. Aggregated Data could be
      derived from your personal data but is not considered personal data in law
      as this data will <strong>not</strong> directly or indirectly reveal your
      identity. For example, we may aggregate your Usage Data to calculate the
      percentage of users accessing a specific website feature. However, if we
      combine or connect Aggregated Data with your personal data so that it can
      directly or indirectly identify you, we treat the combined data as
      personal data which will be used in accordance with this privacy policy.
    </p>

    <p>
      We do not collect any
      <strong>Special Categories of Personal Data</strong> about you (this
      includes details about your race or ethnicity, religious or philosophical
      beliefs, sex life, sexual orientation, political opinions, trade union
      membership, information about your health, and genetic and biometric
      data). Nor do we collect any information about criminal convictions and
      offences.
    </p>

    <h4>If you fail to provide personal data</h4>

    <p>
      Where we need to collect personal data by law, or under the terms of a
      contract we have with you, and you fail to provide that data when
      requested, we may not be able to perform the contract we have or are
      trying to enter into with you (for example, to provide you with goods or
      services). In this case, we may have to cancel a product or service you
      have with us but we will notify you if this is the case at the time.
    </p>

    <h3 id="pp-personal-data-collection">
      How is your personal data collected?
    </h3>

    <p>
      We use different methods to collect data from and about you including
      through:
    </p>

    <ul>
      <li>
        <strong>Direct interactions.</strong> You may give us your Identity,
        Contact and Financial Data by filling in forms or by corresponding with
        us by post, phone, email or otherwise. This includes personal data you
        provide when you:

        <ul>
          <li>apply to be a creator on our website;</li>
          <li>create an account on our website;</li>
          <li>subscribe to our service or publications;</li>
          <li>request marketing to be sent to you;</li>

          <li>enter a competition, promotion or survey; or</li>
          <li>send us content to mint as an NFT; or</li>
          <li>give us feedback or contact us.</li>
        </ul>
      </li>
      <li>
        <strong>Automated technologies or interactions.</strong> As you interact
        with our website, we will automatically collect Technical Data about
        your equipment, browsing actions and patterns. We collect this personal
        data by using cookies and other similar technologies. [We may also
        receive Technical Data about you if you visit other websites employing
        our cookies.]
      </li>
      <li>
        <strong>Third parties or publicly available sources.</strong> We will
        receive personal data about you from various third parties [and public
        sources] as set out below

        <ul>
          <li>Facebook based out side the UK;</li>
          <li>
            Technical Data from analytics providers such as Google based outside
            the UK;
          </li>
          <li>
            Identity and Contact Data from data brokers or aggregators such as
            Tokfluence based outside the UK;
          </li>
          <li>
            Identity and Contact Data from publicly available sources such as
            Companies House based inside the UK.
          </li>
        </ul>
      </li>
    </ul>

    <h3 id="pp-personal-data-usage">How we use your personal data</h3>

    <p>
      We will only use your personal data when the law allows us to. Most
      commonly, we will use your personal data in the following circumstances:
    </p>

    <ul>
      <li>
        Where we need to perform the contract we are about to enter into or have
        entered into with you.
      </li>
      <li>
        Where it is necessary for our legitimate interests (or those of a third
        party) and your interests and fundamental rights do not override those
        interests.
      </li>
      <li>Where we need to comply with a legal obligation.</li>
    </ul>

    <p>
      Click <router-link to="#pp-glossary-lawful-basis">here</router-link> to
      find out more about the types of lawful basis that we will rely on to
      process your personal data.
    </p>

    <p>
      Generally, we do not rely on consent as a legal basis for processing your
      personal data although we will get your consent before sending third party
      direct marketing communications to you via email or text message. You have
      the right to withdraw consent to marketing at any time by contacting us.
    </p>

    <h4 id="pp-personal-data-usage-purposes">
      Purposes for which we will use your personal data
    </h4>

    <p>
      We have set out below, in a table format, a description of all the ways we
      plan to use your personal data, and which of the legal bases we rely on to
      do so. We have also identified what our legitimate interests are where
      appropriate.
    </p>

    <p>
      Note that we may process your personal data for more than one lawful
      ground depending on the specific purpose for which we are using your data.
      Please contact us if you need details about the specific legal ground we
      are relying on to process your personal data where more than one ground
      has been set out in the table below.
    </p>

    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>Purpose/Activity</th>
            <th>Type of data</th>
            <th>
              Lawful basis for processing including basis of legitimate interest
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>To register you as a new customer</td>
            <td>
              <ol class="letter">
                <li>Identity</li>
                <li>Contact</li>
              </ol>
            </td>
            <td>Performance of a contract with you</td>
          </tr>
          <tr>
            <td>
              To process and deliver your order including:
              <ol class="letter">
                <li>Manage payments, fees and charges</li>
                <li>Collect and recover money owed to us</li>
              </ol>
            </td>
            <td>
              <ol class="letter">
                <li>Identity</li>
                <li>Contact</li>
                <li>Financial</li>
                <li>Transaction</li>
                <li>Marketing and Communications</li>
              </ol>
            </td>
            <td>
              <ol class="letter">
                <li>Performance of a contract with you</li>
                <li>
                  Necessary for our legitimate interests (to recover debts due
                  to us)
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              To manage our relationship with you which will include:
              <ol class="letter">
                <li>
                  Notifying you about changes to our terms or privacy policy
                </li>
                <li>Asking you to leave a review or take a survey</li>
              </ol>
            </td>
            <td>
              <ol class="letter">
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Marketing and Communications</li>
              </ol>
            </td>
            <td>
              <ol class="letter">
                <li>Performance of a contract with you</li>
                <li>Necessary to comply with a legal obligation</li>
                <li>
                  Necessary for our legitimate interests (to keep our records
                  updated and to study how customers use our products/services)
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              To enable you to partake in a prize draw, competition or complete
              a survey
            </td>
            <td>
              <ol class="letter">
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Usage</li>
                <li>Marketing and Communications</li>
              </ol>
            </td>
            <td>
              <ol class="letter">
                <li>Performance of a contract with you</li>
                <li>
                  Necessary for our legitimate interests (to study how customers
                  use our products/services, to develop them and grow our
                  business
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              To administer and protect our business and this website (including
              troubleshooting, data analysis, testing, system maintenance,
              support, reporting and hosting of data)
            </td>
            <td>
              <ol class="letter">
                <li>Identity</li>
                <li>Contact</li>
                <li>Technical</li>
              </ol>
            </td>
            <td>
              <ol class="letter">
                <li>
                  Necessary for our legitimate interests (for running our
                  business, provision of administration and IT services, network
                  security, to prevent fraud and in the context of a business
                  reorganisation or group restructuring exercise)
                </li>
                <li>Necessary to comply with a legal obligation</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              To deliver relevant website content and advertisements to you and
              measure or understand the effectiveness of the advertising we
              serve to you
            </td>
            <td>
              <ol class="letter">
                <li>Identity</li>
                <li>Contact</li>
                <li>Profile</li>
                <li>Usage</li>
                <li>Marketing and Communications</li>
                <li>Technical</li>
              </ol>
            </td>
            <td>
              Necessary for our legitimate interests (to study how customers use
              our products/services, to develop them, to grow our business and
              to inform our marketing strategy)
            </td>
          </tr>
          <tr>
            <td>
              To use data analytics to improve our website, products/services,
              marketing, customer relationships and experiences
            </td>
            <td>
              <ol class="letter">
                <li>Technical</li>
                <li>Usage</li>
              </ol>
            </td>
            <td>
              Necessary for our legitimate interests (to define types of
              customers for our products and services, to keep our website
              updated and relevant, to develop our business and to inform our
              marketing strategy)
            </td>
          </tr>
          <tr>
            <td>
              To make suggestions and recommendations to you about goods or
              services that may be of interest to you
            </td>
            <td>
              <ol class="letter">
                <li>Identity</li>
                <li>Contact</li>
                <li>Technical</li>
                <li>Usage</li>
                <li>Profile</li>
                <li>Marketing and Communications</li>
              </ol>
            </td>
            <td>
              Necessary for our legitimate interests (to develop our
              products/services and grow our business)
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h4>Marketing</h4>

    <p>
      We strive to provide you with choices regarding certain personal data
      uses, particularly around marketing and advertising.
    </p>

    <h4>Promotional offers from us</h4>

    <p>
      We may use your Identity, Contact, Technical, Usage and Profile Data to
      form a view on what we think you may want or need, or what may be of
      interest to you. This is how we decide which products, services and offers
      may be relevant for you (we call this marketing).
    </p>

    <p>
      You will receive marketing communications from us if you have requested
      information from us or used our NFT marketplace and you have not opted out
      of receiving that marketing.
    </p>

    <h4>Opting out</h4>

    <p>
      You can ask us or third parties to stop sending you marketing messages at
      any time by contacting us at any time at privacy@melon.ooo.
    </p>

    <p>
      Where you opt out of receiving these marketing messages, this will not
      apply to personal data provided to us as a result of a transaction.
    </p>

    <h4>Cookies</h4>

    <p>
      You can set your browser to refuse all or some browser cookies, or to
      alert you when websites set or access cookies. If you disable or refuse
      cookies, please note that some parts of this website may become
      inaccessible or not function properly. Below is the list of cookies we
      use:
    </p>

    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>Cookie</th>
            <th>Domain</th>
            <th>Type</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>_fbp</td>
            <td>.melon.ooo</td>
            <td>Advertisement</td>
            <td>
              This cookie is set by Facebook to display advertisements when
              either on Facebook or on a digital platform powered by Facebook
              advertising, after visiting the website.
            </td>
          </tr>
          <tr>
            <td>fr</td>
            <td>.facebook.com</td>
            <td>Advertisement</td>
            <td>
              Facebook sets this cookie to show relevant advertisements to users
              by tracking user behaviour across the web, on sites that have
              Facebook pixel or Facebook social plugin.
            </td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>.melon.ooo</td>
            <td>Analytics</td>
            <td>
              The _ga cookie, installed by Google Analytics, calculates visitor,
              session and campaign data and also keeps track of site usage for
              the site's analytics report. The cookie stores information
              anonymously and assigns a randomly generated number to recognize
              unique visitors.
            </td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>.melon.ooo</td>
            <td>Analytics</td>
            <td>
              Installed by Google Analytics, _gid cookie stores information on
              how visitors use a website, while also creating an analytics
              report of the website's performance. Some of the data that are
              collected include the number of visitors, their source, and the
              pages they visit anonymously.
            </td>
          </tr>
          <tr>
            <td class="break-word">_gat_gtag_UA_199203243_1</td>
            <td>.melon.ooo</td>
            <td>Analytics</td>
            <td>
              This cookie is set by Google and is used to distinguish users.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h4>Change of purpose</h4>

    <p>
      We will only use your personal data for the purposes for which we
      collected it, unless we reasonably consider that we need to use it for
      another reason and that reason is compatible with the original purpose. If
      you wish to get an explanation as to how the processing for the new
      purpose is compatible with the original purpose, please contact us.
    </p>

    <p>
      If we need to use your personal data for an unrelated purpose, we will
      notify you and we will explain the legal basis which allows us to do so.
    </p>

    <p>
      Please note that we may process your personal data without your knowledge
      or consent, in compliance with the above rules, where this is required or
      permitted by law.
    </p>

    <h3 id="pp-personal-data-disclosures">Disclosures of your personal data</h3>

    <p>
      We may share your personal data with the parties set out below for the
      purposes set out in the table
      <router-link to="#pp-personal-data-usage-purposes">
        <em>Purposes for which we will use your personal data</em>
      </router-link> above.
    </p>

    <ul>
      <li>
        Internal Third Parties as set out in the
        <router-link to="#pp-glossary"><em>Glossary</em></router-link>.
      </li>
      <li>
        External Third Parties as set out in the
        <router-link to="#pp-glossary"><em>Glossary</em></router-link>.
      </li>
      <li>
        Third parties to whom we may choose to sell, transfer or merge parts of
        our business or our assets. Alternatively, we may seek to acquire other
        businesses or merge with them. If a change happens to our business, then
        the new owners may use your personal data in the same way as set out in
        this privacy policy.
      </li>
    </ul>

    <p>
      We require all third parties to respect the security of your personal data
      and to treat it in accordance with the law. We do not allow our
      third-party service providers to use your personal data for their own
      purposes and only permit them to process your personal data for specified
      purposes and in accordance with our instructions.
    </p>

    <h3 id="pp-personal-data-transfers">International transfers</h3>

    <p>
      We share your personal data within Melon and across our global workforce.
      This may involve transferring your data outside the UK or the country from
      which your data originated.
    </p>

    <p>
      Many of our external third parties are based around the world so their
      processing of your personal data may involve a transfer of data outside of
      the UK or the country from which your data originated.
    </p>

    <p>
      Whenever we transfer your personal data out of the UK, we ensure a similar
      degree of protection is afforded to it by ensuring at least one of the
      following safeguards is implemented:
    </p>

    <ul>
      <li>
        We will only transfer your personal data to countries that have been
        deemed to provide an adequate level of protection for personal data.
      </li>
      <li>
        Where we use certain service providers, we may use specific contracts
        (the &quot;Standard Contractual Clauses&quot;) approved for use in the
        UK which give personal data the same protection it has in the UK.
      </li>
    </ul>

    <p>
      Please contact us if you want further information on the specific
      mechanism used by us when transferring your personal data out of the UK.
    </p>

    <h3 id="pp-personal-data-security">Data security</h3>

    <p>
      We have put in place appropriate security measures to prevent your
      personal data from being accidentally lost, used or accessed in an
      unauthorised way, altered or disclosed. In addition, we limit access to
      your personal data to those employees, agents, contractors and other third
      parties who have a business need to know. They will only process your
      personal data on our instructions and they are subject to a duty of
      confidentiality.
    </p>

    <p>
      We have put in place procedures to deal with any suspected personal data
      breach and will notify you and any applicable regulator of a breach where
      we are legally required to do so.
    </p>

    <h3 id="pp-personal-data-retention">Data retention</h3>

    <h4>How long will you use my personal data for?</h4>

    <p>
      We will only retain your personal data for as long as reasonably necessary
      to fulfil the purposes we collected it for, including for the purposes of
      satisfying any legal, regulatory, tax, accounting or reporting
      requirements. We may retain your personal data for a longer period in the
      event of a complaint or if we reasonably believe there is a prospect of
      litigation in respect to our relationship with you.
    </p>

    <p>
      To determine the appropriate retention period for personal data, we
      consider the amount, nature and sensitivity of the personal data, the
      potential risk of harm from unauthorised use or disclosure of your
      personal data, the purposes for which we process your personal data and
      whether we can achieve those purposes through other means, and the
      applicable legal, regulatory, tax, accounting or other requirements.
    </p>

    <h3 id="pp-personal-data-protection">Your legal rights</h3>

    <p>
      Under certain circumstances, you have rights under data protection laws in
      relation to your personal data. Please refer to the
      <a
        href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"
        target="_blank"
      >
        <em>ICO website</em>
      </a>
      to find out more about these rights. If you wish to exercise any of the
      rights set out above, please contact us.
    </p>

    <h4>No fee usually required</h4>

    <p>
      You will not have to pay a fee to access your personal data (or to
      exercise any of the other rights). However, we may charge a reasonable fee
      if your request is clearly unfounded, repetitive or excessive.
      Alternatively, we could refuse to comply with your request in these
      circumstances.
    </p>

    <h4>What we may need from you</h4>

    <p>
      We may need to request specific information from you to help us confirm
      your identity and ensure your right to access your personal data (or to
      exercise any of your other rights). This is a security measure to ensure
      that personal data is not disclosed to any person who has no right to
      receive it. We may also contact you to ask you for further information in
      relation to your request to speed up our response.
    </p>

    <h4>Time limit to respond</h4>

    <p>
      We try to respond to all legitimate requests within one month.
      Occasionally it could take us longer than a month if your request is
      particularly complex or you have made a number of requests. In this case,
      we will notify you and keep you updated.
    </p>

    <h3 id="pp-glossary">Glossary</h3>

    <h4 id="pp-glossary-lawful-basis">LAWFUL BASIS</h4>

    <p>
      <strong>Legitimate Interest</strong> means the interest of our business in
      conducting and managing our business to enable us to give you the best
      service/product and the best and most secure experience. We make sure we
      consider and balance any potential impact on you (both positive and
      negative) and your rights before we process your personal data for our
      legitimate interests. We do not use your personal data for activities
      where our interests are overridden by the impact on you (unless we have
      your consent or are otherwise required or permitted to by law). You can
      obtain further information about how we assess our legitimate interests
      against any potential impact on you in respect of specific activities by
      contacting us.
    </p>

    <p>
      <strong>Performance of Contract</strong> means processing your data where
      it is necessary for the performance of a contract to which you are a party
      or to take steps at your request before entering into such a contract.
    </p>

    <p>
      <strong>Comply with a legal obligation</strong> means processing your
      personal data where it is necessary for compliance with a legal obligation
      that we are subject to.
    </p>

    <h4 id="pp-glossary">THIRD PARTIES</h4>

    <h5>External Third Parties</h5>

    <ul>
      <li>
        Service providers who provide IT and system administration services,
        such as Google (Google Analytics, Google Workspace), Slack (internal
        office communications), Airtable (customer relationship management).
      </li>
      <li>
        Professional advisers which may include marketing agencies, lawyers,
        bankers, auditors and insurers who provide consultancy, banking, legal,
        insurance and accounting services.
      </li>
    </ul>

    <h4>YOUR LEGAL RIGHTS</h4>

    <p>You have the right to:</p>

    <p>
      <strong>Request access</strong> to your personal data (commonly known as a
      &quot;data subject access request&quot;). This enables you to receive a
      copy of the personal data we hold about you and to check that we are
      lawfully processing it.
    </p>

    <p>
      <strong>Request correction</strong> of the personal data that we hold
      about you. This enables you to have any incomplete or inaccurate data we
      hold about you corrected, though we may need to verify the accuracy of the
      new data you provide to us.
    </p>

    <p>
      <strong>Request erasure</strong> of your personal data. This enables you
      to ask us to delete or remove personal data where there is no good reason
      for us continuing to process it. You also have the right to ask us to
      delete or remove your personal data where you have successfully exercised
      your right to object to processing (see below), where we may have
      processed your information unlawfully or where we are required to erase
      your personal data to comply with local law. Note, however, that we may
      not always be able to comply with your request of erasure for specific
      legal reasons which will be notified to you, if applicable, at the time of
      your request.
    </p>

    <p>
      <strong>Object to processing</strong>
      of your personal data where we are relying on a legitimate interest (or
      those of a third party) and there is something about your particular
      situation which makes you want to object to processing on this ground as
      you feel it impacts on your fundamental rights and freedoms. You also have
      the right to object where we are processing your personal data for direct
      marketing purposes. In some cases, we may demonstrate that we have
      compelling legitimate grounds to process your information which override
      your rights and freedoms.
    </p>

    <p>
      <strong>Request restriction of processing</strong> of your personal data.
      This enables you to ask us to suspend the processing of your personal data
      in the following scenarios:
    </p>

    <ul>
      <li>If you want us to establish the data's accuracy.</li>
      <li>
        Where our use of the data is unlawful but you do not want us to erase
        it.
      </li>
      <li>
        Where you need us to hold the data even if we no longer require it as
        you need it to establish, exercise or defend legal claims.
      </li>
      <li>
        You have objected to our use of your data but we need to verify whether
        we have overriding legitimate grounds to use it.
      </li>
    </ul>

    <p>
      <strong>Request the transfer</strong> of your personal data to you or to a
      third party. We will provide to you, or a third party you have chosen,
      your personal data in a structured, commonly used, machine-readable
      format. Note that this right only applies to automated information which
      you initially provided consent for us to use or where we used the
      information to perform a contract with you.
    </p>

    <p>
      <strong>Withdraw consent at any time</strong> where we are relying on
      consent to process your personal data. However, this will not affect the
      lawfulness of any processing carried out before you withdraw your consent.
      If you withdraw your consent, we may not be able to provide certain
      products or services to you. We will advise you if this is the case at the
      time you withdraw your consent.
    </p>
  </article>
</template>

<style lang="scss" scoped>
@import 'src/styles/mixins';

article {
  @include policy-typo;
}
</style>
