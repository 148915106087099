export default function getTransactionError(e: any, regex: RegExp, fallback: string): string {
  let message;

  try {
    const match = e.message.match(regex);
    if (match) {
      const errMessage = JSON.parse(match[0])?.message;
      if (errMessage) {
        message = errMessage;
      }
    } else if (e.message) {
      message = e.message;
    }
  } catch (err) {
    console.error(err);
  }

  return message || fallback;
}
