<template>
  <template v-if="isMarked">
    <div v-html="markdown" class="markdown"></div>
  </template>
  <div v-else class="parsed">
    <p
      v-for="(paragraph, index) in data.split('\n')"
      :key="index"
    >{{ paragraph }}</p>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import marked from 'marked';
import DOMPurify from 'dompurify';

export default defineComponent({
  name: 'markdown',

  props: {
    data: {
      type: String,
      required: true,
    },
    isMarked: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const markdown = computed(() => (props.isMarked ? DOMPurify.sanitize(marked(props.data)) : ''));

    return {
      markdown,
    };
  },
});
</script>

<style lang="scss">
@import 'src/styles/variables';

.markdown {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.6em;
  }

  h3 {
    font-size: 1.2em;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }

  a {
    color: $primary-1;
  }

  ul {
    padding-left: 1.5rem;
    list-style-type: disc;
  }

  ol {
    padding-left: 1.5rem;
    list-style-type: numeric;
  }
}

.markdown, .parsed {
  & > *:not(:first-child) {
    margin-top: 0.75rem;
  }
}
</style>
