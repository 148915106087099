// eslint-disable-next-line import/no-extraneous-dependencies
import { initializeApp, FirebaseApp } from 'firebase/app';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getAnalytics, Analytics } from 'firebase/analytics';

const isExist = !!process.env.VUE_APP_FIREBASE_API_KEY;

// eslint-disable-next-line import/no-mutable-exports
let app: null | FirebaseApp = null;
// eslint-disable-next-line import/no-mutable-exports
let analytics: null | Analytics = null;

if (isExist) {
  const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: `G-${process.env.VUE_APP_FIREBASE_MEASUREMENT_ID}`,
  };

  // Initialize Firebase
  app = initializeApp(firebaseConfig);

  app.automaticDataCollectionEnabled = true;

  analytics = getAnalytics(app);
}

export {
  app,
  analytics,
};
