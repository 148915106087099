import qs from 'qs';

// models
import PaginationOptions from '@/shared/models/pagination-options';
import ListWithTotalResponse from '@/shared/models/list-with-total-response';

import CategorySimplified from '@/store/modules/item-list/models/category-simplified';
import TextFilterSimplified from '@/store/modules/item-list/models/text-filter-simplified';
import UserSimplified from '@/store/modules/item-list/models/user-simplified';
import ItemListTextFiltersFilters from '@/store/modules/item-list/models/item-list-text-filters-filters';
import ItemListCreatorsFilters from '@/store/modules/item-list/models/item-list-creators-filters';
import ItemTypeSimplified from '@/store/modules/item-list/models/item-type-simplified';

import ItemListFilters from '@/store/modules/item-list/models/item-list-filters';
import ItemListItem from '@/store/modules/item-list/models/item-list-item';

import http from '../instances/http';

const ItemListApi = {
  async getCategories(): Promise<CategorySimplified[]> {
    const res = await http.get('/categories/simplified');
    return res.data;
  },

  async getTypes(): Promise<ItemTypeSimplified[]> {
    const res = await http.get('/types/simplified');
    return res.data;
  },

  async getTextFilters(
    { page, pageSize }: PaginationOptions,
    filters: ItemListTextFiltersFilters,
  ): Promise<TextFilterSimplified[]> {
    const res = await http.get('/filters', {
      params: {
        offset: (page - 1) * pageSize,
        pageSize,
        ...filters,
      },
    });

    return (res.data as ListWithTotalResponse<TextFilterSimplified>).data;
  },

  async getCreators(
    { page, pageSize }: PaginationOptions,
    filters: ItemListCreatorsFilters,
  ): Promise<UserSimplified[]> {
    const res = await http.get('/users/simplified', {
      params: {
        offset: (page - 1) * pageSize,
        pageSize,
        ...filters,
      },
    });
    return res.data;
  },

  async getItems(
    { page, pageSize }: PaginationOptions,
    { textFilters, ...filters }: ItemListFilters,
  ): Promise<ItemListItem[]> {
    const res = await http.get('/items/storefront', {
      params: {
        offset: (page - 1) * pageSize,
        pageSize,
        filters: textFilters,
        ...filters,
      },
      paramsSerializer: (params) => qs.stringify(params),
    });
    return res.data;
  },
};

export default ItemListApi;
