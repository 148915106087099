import { Module } from 'vuex';

// apis
import ItemProfileApi from '@/api/item-profile.api';
import ContractApi from '@/api/contract.api';
import NotifyApi from '@/api/notify.api';

// global
import GlobalState from '@/store/models/global-state';

// shared
import CryptoCurrency, { currencyToNumberMap } from '@/shared/models/crypto-currency.enum';
import PaginationOptions from '@/shared/models/pagination-options';
import ListState from '@/shared/models/list-state';
import OEmbedData from '@/shared/models/oembed-data';
import getTransactionError from '@/shared/helpers/get-transaction-error';
import NotifyType from '@/shared/constants/notify-type';
import FAILURE_TIMEOUT from '@/shared/constants/failure-timeout';
import {
  USER_NOT_AUTHENTICATED,
  ITEM_NOT_EXIST,
  ITEM_MINT_FAILURE,
  ITEM_BUY_FAILURE,
  ITEM_SALE_STATUS_INVALID,
  ITEM_SELLING_SET_FAILURE,
  ITEMS_SELLING_SET_FAILURE,
  ITEM_SELLING_WITHDRAW_FAILURE,
  ITEM_SELLING_FINALIZE_FAILURE,
  ITEM_PRICE_UPDATE_FAILURE,
  ITEM_OFFER_SET_FAILURE,
  ITEM_OFFER_WITHDRAW_FAILURE,
  ITEM_OFFER_APPROVE_FAILURE,
} from '@/shared/constants/messages';

// module types
import { Actions, Mutations } from './props';

// models
import Item from './models/item';
import ItemEnglishAuction from './models/item-english-auction';
import ItemDutchAuction from './models/item-dutch-auction';
import ItemSalesStatus from './models/item-sales-status.enum';
import ItemSaleData from './models/item-sale-data';
import ItemCreateRequest from './models/item-create-request';
import ItemCreateResponse from './models/item-create-response';
import ItemUpdateRequest from './models/item-update-request';
import { buildNftMetadata } from './models/nft-metadata';

import OffChainOffer from './models/off-chain-offer';
import OffChainOfferCreateRequest from './models/off-chain-offer-create-request';
import Offer from './models/offer';
import Bid from './models/bid';
import ItemStats from './models/item-stats';

import ItemProfileState from './models/item-profile-state';

// constants
const OFF_CHAIN_OFFERS_PER_PAGE = 10;
const OFFERS_PER_PAGE = 10;
const BIDS_PER_PAGE = 10;

const MarketItemProfile: Module<ItemProfileState, GlobalState> = {
  namespaced: true,

  state: () => ({
    offChainOffers: {
      data: [],
      pagination: {
        page: 1,
        pageSize: OFF_CHAIN_OFFERS_PER_PAGE,
      },
      isLastPage: false,
      total: 0,
    },
    offers: {
      data: [],
      pagination: {
        page: 1,
        pageSize: OFFERS_PER_PAGE,
      },
      isLastPage: false,
    },
    bids: {
      data: [],
      pagination: {
        page: 1,
        pageSize: BIDS_PER_PAGE,
      },
      isLastPage: false,
    },
    stats: {
      itemStats: {
        minted: 0,
        onAuction: 0,
        onOffChainAuction: 0,
        onSale: 0,
        total: 0,
      },
    },
    userOffChainOffer: null,
    userOffer: null,
    item: null,
  }),

  mutations: {
    [Mutations.setItemDetails](state: ItemProfileState, item?: Item): void {
      state.item = item ?? null;
    },
    [Mutations.setItemOffChainOffers](
      state: ItemProfileState,
      {
        data, pagination, isLastPage, total,
      }: Partial<ListState<OffChainOffer>>,
    ): void {
      state.offChainOffers.data = data ?? [];
      state.offChainOffers.pagination.page = pagination?.page ?? 1;
      state.offChainOffers.pagination.pageSize = pagination?.pageSize ?? OFF_CHAIN_OFFERS_PER_PAGE;
      state.offChainOffers.isLastPage = isLastPage ?? false;
      state.offChainOffers.total = total ?? 0;
    },
    [Mutations.setItemOffers](
      state: ItemProfileState,
      { data, pagination, isLastPage }: Partial<ListState<Offer>>,
    ): void {
      state.offers.data = data ?? [];
      state.offers.pagination.page = pagination?.page ?? 1;
      state.offers.pagination.pageSize = pagination?.pageSize ?? OFFERS_PER_PAGE;
      state.offers.isLastPage = isLastPage ?? false;
    },
    [Mutations.setItemBids](
      state: ItemProfileState,
      { data, pagination, isLastPage }: Partial<ListState<Bid>>,
    ): void {
      state.bids.data = data ?? [];
      state.bids.pagination.page = pagination?.page ?? 1;
      state.bids.pagination.pageSize = pagination?.pageSize ?? BIDS_PER_PAGE;
      state.bids.isLastPage = isLastPage ?? false;
    },
    [Mutations.setItemUserOffChainOffer](state: ItemProfileState, offer?: OffChainOffer): void {
      state.userOffChainOffer = offer ?? null;
    },
    [Mutations.setItemUserOffer](state: ItemProfileState, offer?: Offer): void {
      state.userOffer = offer ?? null;
    },
    [Mutations.setItemsStats](state: ItemProfileState, stats?: ItemStats): void {
      state.stats.itemStats = stats ?? <ItemStats>{};
    },
  },

  actions: {
    async [Actions.fetchOffChainOffers](
      { state, commit },
      { page, pageSize, id }: Partial<PaginationOptions> & { id: string },
    ): Promise<void> {
      let {
        data, pagination, isLastPage, total,
      } = state.offChainOffers;

      const isPaginationSchemeChanged = pageSize !== undefined && pageSize !== pagination.pageSize;

      pagination = {
        page: isPaginationSchemeChanged ? 1 : (page ?? pagination.page),
        pageSize: pageSize ?? pagination.pageSize,
      };

      if (isPaginationSchemeChanged) {
        data = [];
        isLastPage = false;
      }

      if (!isLastPage) {
        const res = await ItemProfileApi.getOffChainOffers(id, pagination);

        data.push(...res.data);
        isLastPage = res.data.length < pagination.pageSize;
        total = res.total;
      }

      commit(Mutations.setItemOffChainOffers, {
        data, pagination, isLastPage, total,
      });
    },

    async [Actions.fetchOffers](
      { state, commit },
      { page, pageSize, id }: Partial<PaginationOptions> & { id: string },
    ): Promise<void> {
      let { data, pagination, isLastPage } = state.offers;

      const isPaginationSchemeChanged = pageSize !== undefined && pageSize !== pagination.pageSize;

      pagination = {
        page: isPaginationSchemeChanged ? 1 : (page ?? pagination.page),
        pageSize: pageSize ?? pagination.pageSize,
      };

      if (isPaginationSchemeChanged) {
        data = [];
        isLastPage = false;
      }

      if (!isLastPage) {
        const dataPerPage = await ItemProfileApi.getOffers(id, pagination);

        data.push(...dataPerPage);
        isLastPage = dataPerPage.length < pagination.pageSize;
      }

      commit(Mutations.setItemOffers, { data, pagination, isLastPage });
    },

    async [Actions.fetchBids](
      { state, commit },
      { page, pageSize, id }: Partial<PaginationOptions> & { id: string },
    ): Promise<void> {
      let { data, pagination, isLastPage } = state.bids;

      const isPaginationSchemeChanged = pageSize !== undefined && pageSize !== pagination.pageSize;

      pagination = {
        page: isPaginationSchemeChanged ? 1 : (page ?? pagination.page),
        pageSize: pageSize ?? pagination.pageSize,
      };

      if (isPaginationSchemeChanged) {
        data = [];
        isLastPage = false;
      }

      if (!isLastPage) {
        const dataPerPage = await ItemProfileApi.getBids(id, pagination);

        data.push(...dataPerPage);
        isLastPage = dataPerPage.length < pagination.pageSize;
      }

      commit(Mutations.setItemBids, { data, pagination, isLastPage });
    },

    async [Actions.fetchItemOfferOnOffChainOfferAuction](
      { rootState, commit },
      id: string,
    ): Promise<void> {
      const { token } = rootState.session;

      if (!token) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      try {
        const offer = await ItemProfileApi.getOffChainOffer(id, token);
        commit(Mutations.setItemUserOffChainOffer, offer);
      } catch (e) {
        commit(Mutations.setItemUserOffChainOffer);
      }
    },

    async [Actions.setItemOfferOnOffChainOfferAuction](
      { rootState, state },
      offer: OffChainOfferCreateRequest,
    ): Promise<void> {
      const { item } = state;
      const { token } = rootState.session;

      if (!token) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      await ItemProfileApi.setOffChainOffer(String(item.id), offer, token);
    },

    async [Actions.withdrawItemOfferFromOffChainOfferAuction](
      { rootState, state },
    ): Promise<void> {
      const { item } = state;
      const { token } = rootState.session;

      if (!token) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      await ItemProfileApi.withdrawOffChainOffer(String(item.id), token);
    },

    async [Actions.fetchOffer]({ rootState, commit }, id: string): Promise<void> {
      const { token } = rootState.session;

      if (!token) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      try {
        const offer = await ItemProfileApi.getOffer(id, token);
        commit(Mutations.setItemUserOffer, offer);
      } catch (e) {
        commit(Mutations.setItemUserOffer);
      }
    },

    async [Actions.createItem](
      { rootState },
      data: ItemCreateRequest,
    ): Promise<ItemCreateResponse> {
      const { token } = rootState.session;

      if (!token) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      return ItemProfileApi.createItem(data, token);
    },

    async [Actions.getItem]({ rootState, commit, dispatch }, itemId: string): Promise<void> {
      const item = await ItemProfileApi.getItem(itemId);

      if (rootState.user.isMainNet) {
        switch (item.salesStatus) {
          case ItemSalesStatus.OnDutchAuction:
            item.dutchAuction = await dispatch(Actions.getItemDutchAuction, +itemId);
            break;
          case ItemSalesStatus.OnEnglishAuction:
            item.englishAuction = await dispatch(Actions.getItemEnglishAuction, +itemId);
            if (item.englishAuction) {
              item.price = item.englishAuction.currentPrice || item.englishAuction.minBid;
              item.cryptoCurrency = item.englishAuction.cryptoCurrency;
              item.usdPrice = await dispatch(Actions.getUsdPrice, {
                amount: item.price,
                currency: item.cryptoCurrency,
              });
            }
            break;
          default:
            break;
        }
      }

      commit(Mutations.setItemDetails, item);
    },

    async [Actions.getItemOEmbedData](
      _,
      { itemId, maxwidth, maxheight } : { itemId: number; maxwidth: number; maxheight: number },
    ): Promise<OEmbedData> {
      const data = await ItemProfileApi.getItemOEmbedData(itemId, maxwidth, maxheight);
      return data;
    },

    async [Actions.updateItem]({ state, rootState }, data: ItemUpdateRequest): Promise<void> {
      const { item } = state;
      const { token } = rootState.session;

      if (!token) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      await ItemProfileApi.updateItem(data, item.id, token);
    },

    async [Actions.mintItemOnSale](
      { rootState },
      { data, saleData, res }: {
        data: ItemCreateRequest, saleData: ItemSaleData, res: ItemCreateResponse,
      },
    ): Promise<void> {
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      const nftMetadata = buildNftMetadata(
        !!data.saleType, saleData.amount, saleData.currency, address,
      );

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi.mintToken(
          nftMetadata,
          res.ids,
          res.sign,
          address,
          isMetamask as boolean,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess({ blockNum, marketItemIds: res.ids }, NotifyType.Mint, token);
      } catch (e) {
        clearTimeout(timerId);
        const message = getTransactionError(e, /{[\s\w\d"'!?:,\\()-]*}/, ITEM_MINT_FAILURE);
        throw new Error(message);
      }
    },

    async [Actions.mintItemOnEnglishAuction](
      { rootState },
      { saleData, res }: {
        saleData: ItemSaleData, res: ItemCreateResponse,
      },
    ): Promise<void> {
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      const nftMetadata = buildNftMetadata(false, saleData.amount, saleData.currency, address);

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi.mintTokenOnEnglishAuction(
          nftMetadata,
          res.ids,
          currencyToNumberMap[saleData.currency],
          saleData.amount,
          saleData.amountTo as number,
          saleData.duration as number,
          res.sign,
          address,
          isMetamask as boolean,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess({ blockNum, marketItemIds: res.ids }, NotifyType.Mint, token);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: res.ids },
          NotifyType.SetOnEnglishAuctionMultiple,
          token,
        );
      } catch (e) {
        clearTimeout(timerId);
        const message = getTransactionError(e, /{[\s\w\d"'!?:,\\()-]*}/, ITEM_MINT_FAILURE);
        throw new Error(message);
      }
    },

    async [Actions.mintItemOnDutchAuction](
      { rootState },
      { saleData, res }: {
        saleData: ItemSaleData, res: ItemCreateResponse,
      },
    ): Promise<void> {
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      const nftMetadata = buildNftMetadata(false, saleData.amount, saleData.currency, address);

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi.mintTokenOnDutchAuction(
          nftMetadata,
          res.ids,
          currencyToNumberMap[saleData.currency],
          saleData.amountTo as number,
          saleData.amount,
          saleData.duration as number,
          saleData.numberOfIntervals as number,
          res.sign,
          address,
          isMetamask as boolean,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess({ blockNum, marketItemIds: res.ids }, NotifyType.Mint, token);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: res.ids },
          NotifyType.SetOnDutchAuctionMultiple,
          token,
        );
      } catch (e) {
        clearTimeout(timerId);
        const message = getTransactionError(e, /{[\s\w\d"'!?:,\\()-]*}/, ITEM_MINT_FAILURE);
        throw new Error(message);
      }
    },

    async [Actions.setItemForSale](
      { state, rootState },
      { amount, currency }: ItemSaleData,
    ): Promise<void> {
      const { item } = state;
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      if (
        item.salesStatus !== ItemSalesStatus.Minted
        && item.salesStatus !== ItemSalesStatus.Sold
      ) {
        throw new Error(ITEM_SALE_STATUS_INVALID(item.salesStatus));
      }

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi.setOnSale(
          item.id, amount, currencyToNumberMap[currency], address, isMetamask as boolean,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: [item.id] },
          NotifyType.SetOnSale,
          token,
        );
      } catch (e) {
        clearTimeout(timerId);
        throw new Error(ITEM_SELLING_SET_FAILURE('sale'));
      }
    },

    async [Actions.updateItemPrice]({ state, rootState }, amount: number): Promise<void> {
      const { item } = state;
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      if (item.salesStatus !== ItemSalesStatus.OnSale) {
        throw new Error(ITEM_SALE_STATUS_INVALID(item.salesStatus));
      }

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi.setPrice(
          item.id, amount, address, isMetamask as boolean,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: [item.id] },
          NotifyType.SetPrice,
          token,
        );
      } catch (e) {
        clearTimeout(timerId);
        throw new Error(ITEM_PRICE_UPDATE_FAILURE);
      }
    },

    async [Actions.setItemsForEnglishAuction](
      { rootState },
      {
        ids, amount, amountTo, currency, duration,
      }: { ids: number[] } & ItemSaleData,
    ): Promise<void> {
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi.setOnEnglishAuctionMultiple(
          ids,
          currencyToNumberMap[currency],
          amount,
          amountTo as number,
          duration as number,
          address,
          isMetamask as boolean,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: [...ids] },
          NotifyType.SetOnEnglishAuctionMultiple,
          token,
        );
      } catch (e) {
        clearTimeout(timerId);
        throw new Error(ITEMS_SELLING_SET_FAILURE('auction'));
      }
    },

    async [Actions.setItemsForDutchAuction](
      { state, rootState },
      {
        ids, amount, amountTo, currency, duration, numberOfIntervals,
      }: { ids: number[] } & ItemSaleData,
    ): Promise<void> {
      const { item } = state;
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      if (item.salesStatus !== ItemSalesStatus.Minted) {
        throw new Error(ITEM_SALE_STATUS_INVALID(item.salesStatus));
      }

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi.setOnDutchAuctionMultiple(
          ids,
          currencyToNumberMap[currency],
          amountTo as number,
          amount,
          duration as number,
          numberOfIntervals as number,
          address,
          isMetamask as boolean,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: [...ids] },
          NotifyType.SetOnDutchAuctionMultiple,
          token,
        );
      } catch (e) {
        clearTimeout(timerId);
        throw new Error(ITEMS_SELLING_SET_FAILURE('auction'));
      }
    },

    async [Actions.getItemEnglishAuction](_, itemId: number): Promise<ItemEnglishAuction> {
      return ContractApi.getEnglishAuction(itemId);
    },

    async [Actions.getItemDutchAuction](_, itemId: number): Promise<ItemDutchAuction> {
      return ContractApi.getDutchAuction(itemId);
    },

    async [Actions.withdrawItemFromSelling]({ state, rootState }): Promise<void> {
      const { item } = state;
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      if (item.salesStatus !== ItemSalesStatus.OnSale
        && item.salesStatus !== ItemSalesStatus.OnEnglishAuction
        && item.salesStatus !== ItemSalesStatus.OnDutchAuction) {
        throw new Error(ITEM_SALE_STATUS_INVALID(item.salesStatus));
      }

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi[`withdraw${item.salesStatus}`](item.id, address, isMetamask as boolean);
        clearTimeout(timerId);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: [item.id] },
          NotifyType[`Withdraw${item.salesStatus}`],
          token,
        );
      } catch (e) {
        clearTimeout(timerId);
        throw new Error(ITEM_SELLING_WITHDRAW_FAILURE(item.salesStatus === ItemSalesStatus.OnSale ? 'sale' : 'auction'));
      }
    },

    async [Actions.finalizeItemOnEnglishAuction]({ state, rootState }): Promise<void> {
      const { item } = state;
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      if (item.salesStatus !== ItemSalesStatus.OnEnglishAuction) {
        throw new Error(ITEM_SALE_STATUS_INVALID(item.salesStatus));
      }

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi.finalizeOnEnglishAuction(
          item.id, address, isMetamask as boolean,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: [item.id] },
          NotifyType.FinalizeOnEnglishAuction,
          token,
        );
      } catch (e) {
        clearTimeout(timerId);
        throw new Error(ITEM_SELLING_FINALIZE_FAILURE('English auction'));
      }
    },

    async [Actions.subscribeForSale]({ state }, email: string): Promise<void> {
      const { item } = state;

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      await ItemProfileApi.subscribeOnSale(item.id, email);
    },

    async [Actions.buyItem]({ state, rootState, dispatch }, bid = 0): Promise<void> {
      const { item } = state;
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      if (item.salesStatus !== ItemSalesStatus.OnSale
        && item.salesStatus !== ItemSalesStatus.OnEnglishAuction
        && item.salesStatus !== ItemSalesStatus.OnDutchAuction) {
        throw new Error(ITEM_SALE_STATUS_INVALID(item.salesStatus));
      }

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        // TODO get current price for dutch auction
        const price = item.salesStatus === ItemSalesStatus.OnEnglishAuction ? bid : item.price;
        const blockNum = await ContractApi[`buyWith${item.cryptoCurrency}${item.salesStatus}`](
          item.id, price, address, isMetamask,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: [item.id] },
          NotifyType[`Buy${item.salesStatus}`],
          token,
        );
        await dispatch(Actions.getItem, String(item.id));
      } catch (e) {
        clearTimeout(timerId);
        const message = getTransactionError(e, /{[\s\w\d":,()-]*}/, ITEM_BUY_FAILURE);
        throw new Error(message);
      }
    },

    async [Actions.setItemOfferOnEnglishAuction](
      { state, rootState },
      amount: number,
    ): Promise<void> {
      const { item } = state;
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      if (item.salesStatus !== ItemSalesStatus.OnEnglishAuction) {
        throw new Error(ITEM_SALE_STATUS_INVALID(item.salesStatus));
      }

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi[`setOfferWith${item.cryptoCurrency}`](
          item.id, amount, address, isMetamask as boolean,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: [item.id] },
          NotifyType.SetOfferOnEnglishAuction,
          token,
        );
      } catch (e) {
        clearTimeout(timerId);
        const message = getTransactionError(e, /{[\s\w\d":,()-]*}/, ITEM_OFFER_SET_FAILURE);
        throw new Error(message);
      }
    },

    async [Actions.withdrawItemOfferFromEnglishAuction](
      { state, rootState },
      offerId: number,
    ): Promise<void> {
      const { item } = state;
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      if (item.salesStatus !== ItemSalesStatus.OnEnglishAuction) {
        throw new Error(ITEM_SALE_STATUS_INVALID(item.salesStatus));
      }

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi.withdrawOffer(
          item.id, offerId, address, isMetamask as boolean,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: [item.id] },
          NotifyType.WithdrawOfferOnEnglishAuction,
          token,
        );
      } catch (e) {
        clearTimeout(timerId);
        throw new Error(ITEM_OFFER_WITHDRAW_FAILURE);
      }
    },

    async [Actions.approveItemOfferOnEnglishAuction](
      { state, rootState },
      offerId: number,
    ): Promise<void> {
      const { item } = state;
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      if (item.salesStatus !== ItemSalesStatus.OnEnglishAuction) {
        throw new Error(ITEM_SALE_STATUS_INVALID(item.salesStatus));
      }

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi.approveOffer(
          item.id, offerId, address, isMetamask as boolean,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: [item.id] },
          NotifyType.ApproveOfferOnEnglishAuction,
          token,
        );
      } catch (e) {
        clearTimeout(timerId);
        throw new Error(ITEM_OFFER_APPROVE_FAILURE);
      }
    },

    async [Actions.clearItem]({ commit }): Promise<void> {
      commit(Mutations.setItemDetails);
      commit(Mutations.setItemOffers, {});
      commit(Mutations.setItemBids, {});
      commit(Mutations.setItemUserOffer);
      commit(Mutations.setItemUserOffChainOffer);
    },

    async [Actions.getUsdPrice](
      _,
      { amount, currency }: { amount: number; currency: CryptoCurrency },
    ): Promise<string | null> {
      const price = await ContractApi.getUsdPrice(amount, currency);
      return price.usdValue !== null ? price.usdValue.toLocaleString() : price.usdValue;
    },

    async [Actions.getSecretCode]({ state, rootState }): Promise<string> {
      const { item } = state;
      const { token } = rootState.session;

      if (!token) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      return ItemProfileApi.getSecretCode(item.id, token);
    },

    async [Actions.setRewardAsRedeemed]({ state, rootState }): Promise<void> {
      const { item } = state;
      const { token } = rootState.session;

      if (!token) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!item) {
        throw new Error(ITEM_NOT_EXIST);
      }

      await ItemProfileApi.setRewardAsRedeemed(item.id, token);
    },

    async [Actions.fetchItemsStats]({ commit }): Promise<void> {
      const data = await ItemProfileApi.getMelonItemsStats();
      commit(Mutations.setItemsStats, data);
    },
  },
};

export default MarketItemProfile;
