import { anyWeb3 } from '@/instances/web3';

export const requiredChainId = process.env[`VUE_APP_${process.env.VUE_APP_NETWORK_NAME}_CHAIN_ID`];

export default async function isRequiredNet(isMetamask: boolean): Promise<boolean> {
  // Metamask and Fortmatic are currently used. We check required net for metamask
  // as appropriate settings have been already set in Fortmatic instance.
  const chainId: number = await anyWeb3.eth.getChainId();

  return isMetamask
    ? chainId === Number(requiredChainId as string)
    : true;
}
