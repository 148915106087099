import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import { fm } from '@/instances/fortmatic';
import {
  anyWeb3,
  eth,
  ethWeb3,
  fmWeb3,
} from '@/instances/web3';

// apis
import AuthApi from '@/api/auth.api';
import UserProfileApi from '@/api/user-profile.api';
import BannerApi from '@/api/banner.api';
import ContractApi from '@/api/contract.api';

// shared
import isRequiredNet, { requiredChainId } from '@/shared/helpers/is-required-net';
import unpackToken from '@/shared/helpers/unpack-token';
import getTokenExpDate from '@/shared/helpers/get-token-exp-date';
import getAuthError from '@/shared/helpers/get-auth-error';
import calcTimeDiff from '@/shared/helpers/calc-time-diff';
import TokenFieldAlias from '@/shared/models/token-field-alias.enum';
import CryptoCurrency from '@/shared/models/crypto-currency.enum';
import { METAMASK_NOT_SUPPORTED, USER_NOT_AUTHENTICATED } from '@/shared/constants/messages';

// modules
import CommonService from './modules/common-service';

import ItemList from './modules/item-list';
import ItemListState from './modules/item-list/models/item-list-state';

import ItemProfile from './modules/item-profile';
import ItemProfileState from './modules/item-profile/models/item-profile-state';

import UserList from './modules/user-list';
import UserListState from './modules/user-list/models/user-list-state';

import UserProfile from './modules/user-profile';
import UserProfileState from './modules/user-profile/models/user-profile-state';

import Feed from './modules/feed';
import FeedState from './modules/feed/models/feed-state';

// module types
import { Actions, Modules, Mutations } from './props';

// models
import GlobalState from './models/global-state';
import UserAuthDataRequest from './models/user-auth-data-request';
import TokenBundle from './models/token-bundle';
import TokenBundleExtended from './models/token-bundle-extended';
import UserInfo from './models/user-info';
import CurrentUserInfo from './models/current-user-info';
import Banner from './models/banner';
import FeaturedBanner from './models/featured-banner';

export interface AppState extends GlobalState {
  [Modules.CommonService]: null,
  [Modules.ItemList]: ItemListState,
  [Modules.ItemProfile]: ItemProfileState,
  [Modules.UserList]: UserListState,
  [Modules.UserProfile]: UserProfileState,
  [Modules.Feed]: FeedState,
}

export const key: InjectionKey<Store<AppState>> = Symbol('app-store-key');

export function useStore(): Store<AppState> {
  return baseUseStore(key);
}

export default createStore<GlobalState>({
  state: () => ({
    session: {
      token: null,
      refreshToken: null,
      expDate: null,
      refreshTimerId: null,
      isMetamask: null,
    },
    user: {
      id: null,
      address: null,
      isMainNet: true,
      personalInfo: {
        id: null,
        name: null,
        nickname: null,
        photoPath: null,
        isSelfMint: false,
        email: null,
        isNotificationAvailable: false,
      },
    },
    isLoading: false,
    banner: null,
    featuredBanner: {
      title: '',
      description: '',
      ctaLink: '',
      ctaButtonTitle: '',
      image: null,
      displayed: false,
    },
  }),

  getters: {
    isAuthenticated: (state): boolean => !!state.session.token && !!state.session.refreshTimerId,
  },

  mutations: {
    [Mutations.setUserData](state: GlobalState, user?: UserInfo): void {
      state.user.id = user?.id || null;
      state.user.address = user?.address || null;
      state.user.isMainNet = user ? user.isMainNet : true;
    },
    [Mutations.setUserPersonalInfo](state: GlobalState, user?: CurrentUserInfo): void {
      state.user.personalInfo.id = user?.id ?? null;
      state.user.personalInfo.name = user?.name ?? null;
      state.user.personalInfo.nickname = user?.nickname ?? null;
      state.user.personalInfo.photoPath = user?.photoPath ?? null;
      state.user.personalInfo.isSelfMint = user?.isSelfMint ?? false;
      state.user.personalInfo.email = user?.email ?? null;
      state.user.personalInfo.isNotificationAvailable = user?.isNotificationAvailable ?? false;
    },
    [Mutations.setTokenBundle](state: GlobalState, tokenBundle?: TokenBundleExtended): void {
      state.session.token = tokenBundle?.token || null;
      state.session.refreshToken = tokenBundle?.refreshToken || null;
      state.session.expDate = tokenBundle?.expDate || null;
      state.session.isMetamask = tokenBundle ? tokenBundle.isMetamask : null;
    },
    [Mutations.setRefreshTimerId](state: GlobalState, refreshId: number | null): void {
      state.session.refreshTimerId = refreshId;
    },
    [Mutations.setLoading](state: GlobalState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },
    [Mutations.setBanner](state: GlobalState, banner?: Banner): void {
      state.banner = banner ?? null;
    },
    [Mutations.setFeaturedBanner](state: GlobalState, banner: FeaturedBanner): void {
      state.featuredBanner = banner;
    },
  },

  actions: {
    async [Actions.getFromLocalStorage](_, address: string): Promise<TokenBundleExtended | null> {
      const tokenBundle = window.localStorage.getItem(address.toLowerCase());

      if (tokenBundle) {
        return JSON.parse(tokenBundle);
      }

      return null;
    },
    async [Actions.initializeContractAddresses]({ commit }): Promise<void> {
      commit(Mutations.setLoading, true);
      await ContractApi.initializeContractAddresses();
      commit(Mutations.setLoading, false);
    },
    async [Actions.getLastSelectedUser](): Promise<string | null> {
      return window.localStorage.getItem('last_selected_user');
    },
    async [Actions.setToLocalStorage]({ state }): Promise<void> {
      const { user: { address }, session } = state;

      if (address) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { refreshTimerId, ...tokenBundleExtended } = session;
        const tokenBundleRow = session.token ? JSON.stringify(tokenBundleExtended) : '';
        window.localStorage.setItem(address.toLowerCase(), tokenBundleRow);
        window.localStorage.setItem('last_selected_user', address);
      }
    },
    async [Actions.clearLocalStorage](_, address: string): Promise<void> {
      window.localStorage.removeItem(address.toLowerCase());
      window.localStorage.removeItem('last_selected_user');
    },
    async [Actions.updateFromLocalStorage](
      { commit, dispatch },
      providedAddress?: string,
    ): Promise<void> {
      let address = providedAddress || await dispatch(Actions.getLastSelectedUser);

      if (address) {
        address = anyWeb3.utils.toChecksumAddress(address);

        const tokenBundle: TokenBundleExtended = await dispatch(
          Actions.getFromLocalStorage,
          address,
        );

        if (tokenBundle && tokenBundle.expDate) {
          const isTokenExpired = calcTimeDiff(tokenBundle.expDate) <= 0;

          if (isTokenExpired) {
            dispatch(Actions.reqRefreshToken, { ...tokenBundle, address });
          } else {
            const isMainNet = await isRequiredNet(tokenBundle.isMetamask as boolean);
            const id = unpackToken(tokenBundle.token)[TokenFieldAlias.id];

            commit(Mutations.setTokenBundle, tokenBundle);
            commit(Mutations.setUserData, { id, address, isMainNet });
            dispatch(Actions.setToLocalStorage);
            dispatch(Actions.waitTokenExpiration);
          }

          let fortmaticAddress: string | null = null;
          if (await fm.user.isLoggedIn()) {
            [fortmaticAddress] = await fmWeb3.eth.getAccounts();
            if (fortmaticAddress) {
              fortmaticAddress = anyWeb3.utils.toChecksumAddress(fortmaticAddress);
            }
          }

          let metamaskAddress: string | null = null;
          if (eth) {
            metamaskAddress = eth.selectedAddress;
            if (metamaskAddress) {
              metamaskAddress = anyWeb3.utils.toChecksumAddress(metamaskAddress);
            }
          }

          if (address !== metamaskAddress && address !== fortmaticAddress) {
            dispatch(Actions.logout);
          }
        }
      }
    },

    async [Actions.getUserPersonalInfo]({ state, commit }): Promise<void> {
      const { token } = state.session;

      if (!token) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      try {
        const user = await UserProfileApi.getCurrentUser(token as string);
        commit(Mutations.setUserPersonalInfo, user);
      } catch (e) {
        console.error(e);
      }
    },

    async [Actions.fetchBanner]({ commit }): Promise<void> {
      try {
        const banner = await BannerApi.getBanner();
        commit(Mutations.setBanner, banner);
      } catch (e) {
        console.error(e);
      }
    },

    async [Actions.fetchFeaturedBanner]({ commit }): Promise<void> {
      try {
        const banner = await BannerApi.getFeaturedBanner();
        commit(Mutations.setFeaturedBanner, banner);
      } catch (e) {
        console.error(e);
      }
    },

    async [Actions.switchEthNetwork](): Promise<void> {
      const chainId = anyWeb3.utils.numberToHex(requiredChainId as string);

      try {
        await eth.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
      } catch (e) {
        if (e.code === 4902) {
          const rpcUrl = process.env[`VUE_APP_${process.env.VUE_APP_NETWORK_NAME}_RPC_URL`];
          const blockExplorerUrl = process.env[`VUE_APP_${process.env.VUE_APP_NETWORK_NAME}_BLOCK_EXPLORER_URL`];
          const network = {
            chainId,
            chainName: process.env.VUE_APP_NETWORK_NAME,
            nativeCurrency: {
              name: CryptoCurrency.MATIC,
              symbol: CryptoCurrency.MATIC,
              decimals: 18,
            },
            rpcUrls: [rpcUrl as string],
            blockExplorerUrls: [blockExplorerUrl as string],
          };

          try {
            await eth.request({
              method: 'wallet_addEthereumChain',
              params: [network],
            });
          } catch (err) {
            throw new Error(err.message);
          }
        } else {
          throw new Error(e.message);
        }
      }
    },

    async [Actions.reqUserAuth](
      { commit, state, dispatch },
      payload: UserAuthDataRequest,
    ): Promise<void> {
      const web3 = payload.isMetamask ? ethWeb3 : fmWeb3;
      if (!web3) {
        throw Error(METAMASK_NOT_SUPPORTED);
      }

      let { address } = payload;

      if (state.session.isMetamask === false) {
        await fm.user.logout();
      }

      if (!address && payload.forceConnection) {
        try {
          if (payload.isMetamask) {
            [address] = await web3.eth.requestAccounts();
          } else {
            [address] = await web3.eth.getAccounts();
          }
        } catch (e) {
          console.error(e);
          throw new Error(getAuthError(e));
        }
      }

      if (!address) {
        if (state.user.address) {
          await dispatch(Actions.clearLocalStorage, state.user.address);
        }
        commit(Mutations.setTokenBundle);
        commit(Mutations.setUserData);
        dispatch(Actions.waitTokenExpiration);
        return;
      }

      address = anyWeb3.utils.toChecksumAddress(address) as string;

      const isMainNet = await isRequiredNet(payload.isMetamask);

      if (address === state.user.address) {
        const { user: userInfo } = state;

        commit(Mutations.setUserData, { ...userInfo, isMainNet });
        return;
      }

      const localTokenBundle = await dispatch(Actions.getFromLocalStorage, address);
      if (localTokenBundle) {
        await dispatch(Actions.updateFromLocalStorage, address);
        return;
      }

      const nonce = await AuthApi.getNonce(address);
      let signature: string;

      try {
        signature = await web3.eth.personal.sign(
          `I am signing my one-time nonce: ${nonce}`,
          address,
          '',
        );
      } catch (e) {
        throw new Error(getAuthError(e));
      }

      const rawTokenBundle = await AuthApi.authUser(address, signature);
      const tokenBundle: TokenBundleExtended = {
        ...rawTokenBundle,
        expDate: getTokenExpDate(rawTokenBundle.token as string),
        isMetamask: payload.isMetamask,
      };
      const id = unpackToken(tokenBundle.token)[TokenFieldAlias.id];

      commit(Mutations.setTokenBundle, tokenBundle);
      commit(Mutations.setUserData, { id, address, isMainNet });
      dispatch(Actions.setToLocalStorage);
      dispatch(Actions.waitTokenExpiration);
    },

    async [Actions.reqRefreshToken](
      { commit, dispatch },
      {
        token,
        refreshToken,
        isMetamask,
        address,
      }: TokenBundleExtended & { address: string },
    ): Promise<void> {
      if (token && refreshToken) {
        try {
          const rawTokenBundle: TokenBundle = await AuthApi.refreshToken({ token, refreshToken });
          const tokenBundle: TokenBundleExtended = {
            ...rawTokenBundle,
            expDate: getTokenExpDate(rawTokenBundle.token as string),
            isMetamask,
          };

          const isMainNet = await isRequiredNet(tokenBundle.isMetamask as boolean);
          const id = unpackToken(tokenBundle.token)[TokenFieldAlias.id];

          commit(Mutations.setTokenBundle, tokenBundle);
          commit(Mutations.setUserData, { address, isMainNet, id });
          dispatch(Actions.setToLocalStorage);
          dispatch(Actions.waitTokenExpiration);
        } catch (e) {
          console.error(e);
          commit(Mutations.setTokenBundle);
          commit(Mutations.setUserData);
          dispatch(Actions.clearLocalStorage, address);
        }
      } else {
        commit(Mutations.setTokenBundle);
        commit(Mutations.setUserData);
      }
    },

    async [Actions.waitTokenExpiration]({ state, commit, dispatch }): Promise<void> {
      const { user: { address }, session: { expDate, refreshTimerId, ...bundle } } = state;

      if (refreshTimerId !== null) {
        clearTimeout(refreshTimerId);
        commit(Mutations.setRefreshTimerId, null);
      }

      if (expDate) {
        const diff = calcTimeDiff(expDate);

        if (diff > 0) {
          const timerId = setTimeout(
            () => dispatch(Actions.reqRefreshToken, { address, ...bundle }),
            diff,
          );
          commit(Mutations.setRefreshTimerId, timerId);
        } else {
          dispatch(Actions.reqRefreshToken, { address, ...bundle });
        }
      }
    },

    async [Actions.logout]({ state, commit, dispatch }): Promise<void> {
      const { user: { address } } = state;

      commit(Mutations.setTokenBundle);
      commit(Mutations.setUserData);
      dispatch(Actions.clearLocalStorage, address);
      dispatch(Actions.waitTokenExpiration);
    },
  },

  modules: {
    [Modules.CommonService]: CommonService,
    [Modules.ItemList]: ItemList,
    [Modules.ItemProfile]: ItemProfile,
    [Modules.UserList]: UserList,
    [Modules.UserProfile]: UserProfile,
    [Modules.Feed]: Feed,
  },
});
