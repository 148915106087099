
import { computed, defineComponent, onUnmounted } from 'vue';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-list/props';
import PaginationOptions from '@/shared/models/pagination-options';

import ItemListCreatorsFilters from '@/store/modules/item-list/models/item-list-creators-filters';

import Select from '@/components/Select.vue';

export default defineComponent({
  name: 'CreatorSelect',

  props: {
    modelValue: {
      default: null,
    },
    id: {
      type: String,
    },
  },

  components: {
    Select,
  },

  emits: [
    'update:modelValue',
  ],

  setup(props, { emit }) {
    const store = useStore();

    const selectValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const data = computed(() => store.state.ItemList.creators.data);
    const pagination = computed(() => store.state.ItemList.creators.pagination);
    const isLastPage = computed(() => store.state.ItemList.creators.isLastPage);

    const loadCreators = (
      options: Partial<PaginationOptions> & Partial<ItemListCreatorsFilters>,
    ) => {
      store.dispatch(`${Modules.ItemList}/${Actions.fetchCreators}`, options);
    };

    onUnmounted(() => {
      store.dispatch(`${Modules.ItemList}/${Actions.clearCreators}`);
    });

    return {
      selectValue,
      data,
      pagination,
      isLastPage,
      loadCreators,
    };
  },
});
