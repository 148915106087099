<template>
  <div class="sale-container">
    <div v-if="!isEditing" class="input-container">
      <label :for="formId + '-sale-type'">Selling type</label>
      <Select
        :model-value="type"
        @update:model-value="$emit('update:type', $event)"
        :id="formId + '-sale-type'"
        :data="sellingTypeOptions"
        :isFormElement="true"
      />
    </div>

    <div v-if="type !== ItemSellingType.OnSale" class="input-container">
      <label :for="formId + '-duration'">Auction duration</label>
      <Select
        :model-value="duration"
        @update:model-value="$emit('update:duration', $event)"
        :id="formId + '-duration'"
        placeholder="Auction duration"
        :data="durationOptions"
        :isFormElement="true"
      />
    </div>

    <div v-if="type === ItemSellingType.OnDutchAuction" class="input-container">
      <label :for="formId + '-number-of-intervals'">Number of intervals</label>
      <input
        ref="numberOfIntervalsRef"
        :model-value="numberOfIntervals"
        @update:model-value="$emit('update:numberOfIntervals', $event)"
        :id="formId + '-number-of-intervals'"
        step="1"
        min="1"
        :max="duration ? duration / 300 : null"
        type="number"
        placeholder="Number of intervals"
        :required="required"
      />
      <p v-if="!numberOfIntervalsRef?.validity.valid" class="error-text">
        Invalid number of intervals (minimal dip should be 5 minutes)
      </p>
    </div>

    <div class="input-container">
      <label :for="formId + '-currency'">Currency</label>
      <Select
        :model-value="currency"
        @update:model-value="$emit('update:currency', $event)"
        :id="formId + '-currency'"
        :data="currencyOptions"
        :read-only="isCurrencyReadonly"
        :isFormElement="true"
      />
    </div>

    <div class="input-container">
      <label :for="formId + '-crypto-amount'">
        {{
          type === ItemSellingType.OnSale
            ? 'Amount'
            : (type === ItemSellingType.OnDutchAuction ? 'Final price' : 'Minimum bid')
        }}
      </label>
      <input
        ref="cryptoAmountRef"
        v-model="cryptoAmount"
        :id="formId + '-crypto-amount'"
        step="0.000001"
        :min="minAmount !== null && minAmount !== undefined ? minAmount : 0.000001"
        :max="type !== ItemSellingType.OnSale && !!cryptoAmountToRef?.validity.valid && !!amountTo
            ? Number(amountTo)
            : 9999999999"
        type="number"
        placeholder="Amount"
        :required="required"
      />
      <p v-if="!cryptoAmountRef?.validity.valid" class="error-text">Invalid amount</p>

      <div v-if="fiatAmount !== null" class="fiat-input-container">
        <label :for="formId + '-fiat-amount'">≈ $</label>
        <input
          v-model="fiatAmount"
          :id="formId + '-fiat-amount'"
          type="text"
          class="fiat-amount"
          placeholder="Amount"
          readonly
        />
      </div>
    </div>

    <div v-if="type !== ItemSellingType.OnSale" class="input-container">
      <label :for="formId + '-crypto-amount-to'">
        {{ type === ItemSellingType.OnDutchAuction ? 'Start price' : 'Reserve price' }}
      </label>
      <input
        ref="cryptoAmountToRef"
        v-model="cryptoAmountTo"
        :id="formId + '-crypto-amount-to'"
        step="0.000001"
        :min="!!cryptoAmountRef?.validity.valid && !!amount ? Number(amount) : 0.000001"
        max="9999999999"
        type="number"
        placeholder="Amount"
        :required="required"
      />
      <p v-if="!cryptoAmountToRef?.validity.valid" class="error-text">Invalid amount</p>

      <div v-if="fiatAmountTo !== null" class="fiat-input-container">
        <label :for="formId + '-fiat-amount-to'">≈ $</label>
        <input
          v-model="fiatAmountTo"
          :id="formId + '-fiat-amount-to'"
          type="text"
          class="fiat-amount"
          placeholder="Amount"
          readonly
        />
      </div>

      <div v-if="type === ItemSellingType.OnDutchAuction" class="toggle-container">
        <label :for="formId + '-has-amount-to-visible'">
          Show final price to buyer
        </label>
        <Toggle
          :model-value="hasAmountToVisible"
          @update:model-value="$emit('update:hasAmountToVisible', $event)"
          :id="formId + '-has-amount-to-visible'"
        />
      </div>
    </div>
  </div>

  <p v-if="!isEditing" class="hint">
    You will be charged with
    {{ currency === CryptoCurrency.MELON ? '0%' : '2.5%' }}
    commission.
  </p>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  toRefs,
  watch,
} from 'vue';
import Toggle from '@vueform/toggle';
import debounce from 'lodash/debounce';

import CryptoCurrency from '@/shared/models/crypto-currency.enum';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-profile/props';
import ItemSellingType from '@/store/modules/item-profile/models/item-selling-type';

import Select from './Select.vue';

export default defineComponent({
  name: 'SaleForm',

  components: {
    Toggle,
    Select,
  },

  props: {
    formId: {
      type: String,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      default: ItemSellingType.OnSale,
    },
    currency: {
      default: CryptoCurrency.MATIC,
    },
    isCurrencyReadonly: {
      type: Boolean,
      default: false,
    },
    amount: {
      default: 1,
    },
    minAmount: {
      type: Number,
      default: null,
    },
    amountTo: {
      default: 2,
    },
    duration: {
      type: Number,
      default: null,
    },
    numberOfIntervals: {
      default: null,
    },
    hasAmountToVisible: {
      default: false,
    },
  },

  emits: [
    'update:type',
    'update:currency',
    'update:amount',
    'update:fiat',
    'update:amountTo',
    'update:duration',
    'update:numberOfIntervals',
    'update:hasAmountToVisible',
  ],

  setup(props, { emit }) {
    const store = useStore();

    const {
      currency: cryptoCurrency,
    } = toRefs(props);

    const cryptoAmount = computed({
      get: () => props.amount,
      set: debounce((value) => {
        emit('update:amount', value);
      }, 400),
    });

    const cryptoAmountTo = computed({
      get: () => props.amountTo,
      set: debounce((value) => {
        emit('update:amountTo', value);
      }, 400),
    });

    const numberOfIntervalsRef = ref<HTMLInputElement | null>(null);
    const cryptoAmountRef = ref<HTMLInputElement | null>(null);
    const cryptoAmountToRef = ref<HTMLInputElement | null>(null);
    const fiatAmount = ref<string | null>('');
    const fiatAmountTo = ref<string | null>('');

    async function getUsdPrice(amount: number, currency: CryptoCurrency): Promise<string | null> {
      return store.dispatch(`${Modules.ItemProfile}/${Actions.getUsdPrice}`, { amount, currency });
    }

    async function getFiatAmount(
      fromEl: HTMLInputElement | null,
      amount: number,
      currency: CryptoCurrency,
    ) {
      return fromEl?.validity.valid ? getUsdPrice(amount, currency) : '0';
    }

    async function setFiatAmount(amount: number, currency: CryptoCurrency) {
      fiatAmount.value = await getFiatAmount(cryptoAmountRef.value, amount, currency);
      emit('update:fiat', fiatAmount.value);
    }

    async function setFiatAmountTo(amount: number, currency: CryptoCurrency) {
      fiatAmountTo.value = await getFiatAmount(cryptoAmountToRef.value, amount, currency);
    }

    watch(cryptoCurrency, (value) => {
      setFiatAmount(cryptoAmount.value, value);
      setFiatAmountTo(cryptoAmountTo.value, value);
    });

    watch(cryptoAmount, (value) => {
      setFiatAmount(value, cryptoCurrency.value);
    });

    watch(cryptoAmountTo, (value) => {
      setFiatAmountTo(value, cryptoCurrency.value);
    });

    watch(cryptoAmountToRef, (value) => {
      if (value) {
        setFiatAmountTo(cryptoAmountTo.value, cryptoCurrency.value);
      }
    });

    onMounted(() => {
      setFiatAmount(cryptoAmount.value, cryptoCurrency.value);
      setFiatAmountTo(cryptoAmountTo.value, cryptoCurrency.value);
    });

    const sellingTypeOptions = [
      {
        id: ItemSellingType.OnSale,
        label: 'Fixed price',
      },
      {
        id: ItemSellingType.OnEnglishAuction,
        label: 'English auction',
      },
      // {
      //   id: ItemSellingType.OnDutchAuction,
      //   label: 'Dutch auction',
      // },
    ];

    const currencyOptions = [
      {
        id: CryptoCurrency.MATIC,
        label: 'MATIC',
      },
      {
        id: CryptoCurrency.WETH,
        label: 'ETH on Polygon',
      },
    ];

    const durationOptions = [
      { label: '1 day', id: 60 * 60 * 24 },
      { label: '5 days', id: 60 * 60 * 24 * 5 },
      { label: '1 week', id: 60 * 60 * 24 * 7 },
      { label: '2 weeks', id: 60 * 60 * 24 * 7 * 2 },
      { label: '3 weeks', id: 60 * 60 * 24 * 7 * 3 },
      { label: '4 weeks', id: 60 * 60 * 24 * 7 * 4 },
    ];

    return {
      cryptoAmount,
      cryptoAmountTo,
      fiatAmount,
      fiatAmountTo,
      numberOfIntervalsRef,
      cryptoAmountRef,
      cryptoAmountToRef,
      sellingTypeOptions,
      currencyOptions,
      durationOptions,
      CryptoCurrency,
      ItemSellingType,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

@mixin input-wrapper($padding-left: 0) {
  position: relative;

  label {
    @include typo-caption-bold;

    position: absolute;
    top: 50%;
    left: 1rem;
    color: $neutrals-2;
    transform: translateY(-50%);
  }

  input {
    padding-left: $padding-left;
  }
}

input {
  @include input-common;
  @include typo-caption-bold;

  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
}

.input-container {
  @include input-container;

  &:not(:first-child) {
    margin-top: 2rem;
  }
}

.fiat-input-container {
  @include input-wrapper(2.25rem);
  margin-top: 1rem;
}

.toggle-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  /* stylelint-disable-next-line no-descending-specificity */
  & > *:not(:first-child) {
    margin-top: 0.5rem;
  }
}

.error-text {
  @include typo-caption-2;
  margin-top: 0.25rem;
  color: $primary-1;
}

.sale-container {
  display: flex;
  flex-direction: column;
}

.hint {
  @include typo-caption;

  margin-top: 1.5rem;
  color: $neutrals-4;
}
</style>
