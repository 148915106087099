import { TransactionConfig } from 'web3-core';
import { anyWeb3 } from '@/instances/web3';

export default function createTx(
  encodedTransaction: string,
  transactionCreator: string,
  price: number,
  contractAddress: string,
  gas = 200000,
): TransactionConfig {
  return {
    from: transactionCreator,
    to: contractAddress,
    gas: anyWeb3.utils.toHex(gas),
    value: anyWeb3.utils.toWei(price.toString(), 'ether'),
    data: encodedTransaction,
  };
}
