<template>
  <div class="socials">
    <a href="https://discord.com/invite/3cPFUZU5nw" target="_blank">
      <img src="@/assets/images/socials/discord.svg" alt="discord icon">
    </a>
    <a href="https://www.instagram.com/melon_ooo" target="_blank">
      <img src="@/assets/images/socials/instagram.svg" alt="instagram icon">
    </a>
    <a href="https://twitter.com/melon_ooo_app" target="_blank">
      <img src="@/assets/images/socials/twitter.svg" alt="twitter icon">
    </a>
    <a href="https://t.me/joinchat/-OG0b6U0Qwk3NzE0" target="_blank">
      <img src="@/assets/images/socials/telegram.svg" alt="telegram icon">
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Socials',
});
</script>

<style lang="scss" scoped>
.socials {
  display: inline-flex;
  align-items: center;
  margin-right: -0.25rem;
  margin-left: -0.25rem;

  & > * {
    display: inline-flex;
    margin: 0 0.25rem;
    padding: 0.5rem;

    img {
      width: 20px;
    }
  }
}
</style>
