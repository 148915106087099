<template>
  <div v-if="item" class="content-container">
    <div class="preview-container">
      <div v-if="isExternalType(item.typeId)" class="embedded-switcher-container">
        <div class="embedded-switcher-header">
          <h4>Embedded view </h4>
          <InfoTooltip
            position="is-bottom"
            :label="'Embedded view is item social media post review.' +
              ' You can switch between the social media view and' +
              ' the backing IPFS file by toggling embedded view.'"
          />
        </div>
        <Toggle v-model="enableEmbedded" id="item-profile-is-embedded-view" />
      </div>
      <div class="file-wrapper">
        <ItemEmbedFilePreview
          :itemId="item.id"
          :typeId="item.typeId"
          :url="item.originalLink"
          :enable-embedded="enableEmbedded"
        >
          <template v-slot:fallback>
            <ItemFilePreview
              :filePath="item.ipfsPaths[0]"
              :mimeType="item.mimeTypes[0]"
              :hasControls="true"
              :isInitiallyMuted="isVideoInitiallyMuted"
              class="file-preview"
            />
          </template>
        </ItemEmbedFilePreview>
      </div>
    </div>

    <div class="info-container">
      <template v-if="!item.creatorVerifiedStatus && !item.isCreatorRegistered">
        <UserProfileOffChainBanner
          class="info-card"
          :userNickname="item.creatorName || item.creatorNickname"
        />
      </template>

      <ItemProfileMainInfo :item="item" class="main-info" />

      <ItemProfileSaleTimer
        v-if="
          item.saleDate
           && (
             item.salesStatus !== SaleStatus.OnSale
              && item.salesStatus !== SaleStatus.OnEnglishAuction
           )
        "
        :sale-date="item.saleDate"
        @subscribe="subscribeOnSale"
      />

      <div v-if="item.rewardStatus !== RewardStatus.None" class="info-card">
        <div class="heading-container">
          <img
            v-if="item.rewardStatus === RewardStatus.Available"
            src="@/assets/images/common/reward.svg"
            alt="reward"
            title="reward"
            class="icon with-shadow"
          >
          <img
            v-else
            src="@/assets/images/common/reward-redeemed.svg"
            alt="reward"
            title="reward"
            class="icon"
          >
          <h2 class="small-title">Exclusive reward</h2>
          <div class="spacer"></div>
          <div class="text-right">
            <InfoTooltip
              :position="width < 590 ? 'is-left' : 'is-top'"
              label="The reward(s) are only redeemable once unless otherwise stated."
            />
          </div>
        </div>
        <div class="extra-content">
          <Markdown
            v-if="item.rewardStatus === RewardStatus.Available"
            :data="item.extra"
            :isMarked="item.isExtraMarked"
          />
          <p v-else>Reward has been already claimed</p>
        </div>

        <template
          v-if="item.salesStatus === SaleStatus.Sold
            && item.rewardStatus === RewardStatus.Available"
        >
          <button v-if="isJustOwner" class="primary-button" @click="openRewardModal">
            Redeem
          </button>

          <button v-if="isJustCreator" class="primary-button" @click="markAsRedeemed">
            Mark as redeemed
          </button>
        </template>
      </div>

      <div v-if="item.salesStatus === SaleStatus.OnOffChainOfferAuction" class="info-card">
        <div v-if="itemBids.length" class="offchain-highest-bid">
          <p class="price-label">Highest offer</p>
          <ItemPrice
            class="price"
            :cryptoAmount="itemBids[0].price"
            :cryptoCurrency="itemBids[0].cryptoCurrency"
            :usdAmount="itemBids[0].usdPrice"
          />
        </div>
        <div class="offchain-offer-description" :class="{ 'has-padding': !!userOffChainOffer }">
          <p>
            Name a price you are willing to pay for this NFT!
          </p>
          <InfoTooltip
            :label="'The offer takes place off-chain, so making this offer does ' +
              'not require commitment of your funds. We will contact the creator ' +
              'and when they decide to put the NFT on sale, we will ' +
              'notify you via email.'
            "
          />
        </div>

        <template v-if="userOffChainOffer">
          <span class="price-label">Your current offered price</span>
          <ItemPrice
            class="price"
            :cryptoAmount="userOffChainOffer.price"
            :cryptoCurrency="userOffChainOffer.cryptoCurrency"
            :usdAmount="userOffChainOffer.usdPrice"
          />
          <button
            class="outline-button"
            @click="withdrawOffChainOfferOnItem"
          >
            Cancel offer
          </button>
        </template>
        <template v-else>
          <button class="primary-button" @click="callModalOnOffChainOffer">
            Name Your Price
          </button>
        </template>
      </div>

      <div v-if="isItemSelling && !isMainNet" class="info-card">
        <p>Change network to see sale info!</p>
      </div>

      <div v-if="isItemSelling && isMainNet" class="info-card with-overflow">
        <!-- TODO add the same for dutch auction -->
        <div
          v-if="item.salesStatus === SaleStatus.OnEnglishAuction && !!item.englishAuction"
          class="auction-ending"
        >
          <div>
            <template v-if="item.englishAuction.hasEnded">
              <p v-if="item.englishAuction.isPending">Auction is pending</p>
              <p v-else>Sale has ended</p>
            </template>
            <template v-else>
              <p>
                Sale ends
                <span class="em">
                  <vue-countdown
                    :time="item.englishAuction.timeToEndDate"
                    v-slot="{ days }"
                    @end="reloadPage"
                  >
                    {{ days > 1 ? `in ${days} days` : (days === 1 ? 'tomorrow' : 'today')}}
                  </vue-countdown>
                </span>
                in
                <span class="em">
                  <vue-countdown
                    :time="item.englishAuction.timeToEndDate"
                    :emit-events="false"
                    :transform="transformSlotProps"
                    v-slot="{ days, hours, minutes, seconds }"
                  >
                    {{ days }}:{{ hours }}:{{ minutes }}:{{ seconds }}
                  </vue-countdown>
                </span>
              </p>
            </template>
          </div>

          <InfoTooltip
            :position="width < 590 ? 'is-left' : 'is-top'"
            :label="getDate(item.englishAuction.endDate)"
          />
        </div>

        <span
          v-if="item.salesStatus === SaleStatus.OnEnglishAuction && !!item.englishAuction"
          class="price-label"
        >
          {{ item.englishAuction.isPending
            ? 'Reserve price'
            : item.englishAuction.currentPrice ? 'Top bid' : 'Minimum bid' }}
          <span
            v-if="!item.englishAuction.hasEnded
              && !!bids.length
              && item.englishAuction.reservePrice <= item.englishAuction.currentPrice"
            class="reserve-price"
          >Reserve price met!</span>
        </span>
        <span v-else class="price-label">Current price</span>

        <ItemPrice
          class="price"
          :cryptoAmount="item.price"
          :cryptoCurrency="item.cryptoCurrency"
          :usdAmount="item.usdPrice"
        />

        <div v-if="item.salesStatus === SaleStatus.OnDutchAuction" class="dutch-auction-info">
          <!-- TODO change test values for dutch auction -->
          <p>Next price dip in <span class="em">{{ 'test' }}</span></p>
          <p>Final price: <CryptoPrice class="em" amount="test" currency="test" /></p>
        </div>

        <template v-if="isOwner">
          <button
            v-if="item.salesStatus === SaleStatus.OnEnglishAuction && !!item.englishAuction"
            class="primary-button"
            :disabled="!validateAcceptBid(item.englishAuction)"
            @click="isAcceptBidModalOpen = true"
          >
            Accept Bid
          </button>

          <button
            v-if="item.salesStatus === SaleStatus.OnSale"
            class="outline-button"
            @click="isSetPriceModalOpen = true"
          >
            Edit price
          </button>

          <button
            class="outline-button"
            @click="withdrawItemFromSelling"
          >
            Withdraw from {{ item.salesStatus === SaleStatus.OnSale ? 'sale' : 'auction' }}
          </button>
        </template>

        <template v-if="currentUserId !== item.ownerId">
          <template v-if="item.salesStatus === SaleStatus.OnEnglishAuction">
            <button
              v-if="!item.englishAuction?.hasEnded || item.englishAuction?.isPending"
              class="primary-button"
              @click="callModalOnBuy(true)"
            >
              Place Bid
            </button>
          </template>
          <button
            v-else
            class="primary-button"
            @click="callModalOnBuy(false)"
          >
            Buy
          </button>

          <template v-if="item.salesStatus === SaleStatus.OnEnglishAuction">
            <div v-if="userOffer" class="user-offer">
              <span class="price-label">Your offer is:</span>
              <ItemPrice
                class="price"
                :cryptoAmount="userOffer.price"
                :cryptoCurrency="userOffer.cryptoCurrency"
                :usdAmount="userOffer.usdPrice"
              />
              <button
                class="text-button"
                @click="withdrawOfferOnItem(userOffer.offerId)"
              >
                Cancel offer
              </button>
            </div>

            <button
              v-if="!userOffer && !item.englishAuction?.hasEnded"
              class="text-button"
              @click="callModalOnOffer"
            >
              Place offer
            </button>

            <p class="helper-text">
              Learn more about
              <Tooltip
                :label="bidsHelperText"
                position="is-top"
                :style="{ 'vertical-align': 'middle' }"
                multiline
              >
                <span class="helper-tooltip-link">
                  bids
                </span>
              </Tooltip> and
              <Tooltip
                :label="offersHelperText"
                :position="width >= 590 || width <= 370 ? 'is-top' : 'is-left'"
                :style="{ 'vertical-align': 'middle' }"
                multiline
              >
                <span class="helper-tooltip-link">
                  offers
                </span>
              </Tooltip>
            </p>
          </template>
        </template>
      </div>
    </div>
  </div>

  <ContentLoader :loading="isPageLoading"/>

  <!-- modals -->
  <ConnectionModal :is-open="isConnectionModalOpen" @close="isConnectionModalOpen = false" />

  <modal
    v-show="isCheckoutModalOpen"
    @close="isCheckoutModalOpen = false"
  >
    <template v-slot:header>
      <h2>Checkout</h2>
    </template>

    <template v-slot:body>
      <p>
        You are about to purchase {{ item?.title }} from {{ item?.ownerName }}
      </p>
    </template>

    <template v-slot:footer>
      <button
        @click="buyItem"
        class="modal-primary-button"
      >I understand, continue</button>
    </template>
  </modal>

  <SaleModal
    key="item-profile-price-form"
    :isOpen="isSetPriceModalOpen"
    formId="item-profile-price-form"
    :title="`Edit price for “${item?.title}”`"
    :initialCurrency="item?.cryptoCurrency"
    :initialAmount="item?.price"
    :isEditing="true"
    :isCurrencyReadonly="true"
    submitButtonLabel="Set price"
    @setOnSale="updateItemPrice"
    @close="isSetPriceModalOpen = false"
  />

  <SaleModal
    key="item-profile-off-chain-offer-form"
    :isOpen="isMakeOffChainOfferModalOpen"
    formId="item-profile-off-chain-offer-form"
    title="Place offer"
    :isEditing="true"
    submitButtonLabel="Place Offer"
    @setOnSale="makeOffChainOfferOnItem"
    @close="isMakeOffChainOfferModalOpen = false"
  >
    <template v-slot:body>
      <p v-if="errors.amount" class="error-text">
        {{ errors.amount }}
      </p>
    </template>
  </SaleModal>

  <SaleModal
    key="item-profile-offer-form"
    :isOpen="isMakeOfferModalOpen"
    formId="item-profile-offer-form"
    title="Place offer"
    :initialCurrency="item?.cryptoCurrency"
    :initialAmount="item?.englishAuction?.reservePrice"
    :minAmount="item?.englishAuction?.reservePrice"
    :isEditing="true"
    :isCurrencyReadonly="true"
    submitButtonLabel="Place Offer"
    @setOnSale="makeOfferOnItem"
    @close="isMakeOfferModalOpen = false"
  >
    <template v-slot:body>
      <p class="hint">
        Reserve price for this item is
        <CryptoPrice
          :amount="item?.englishAuction?.reservePrice"
          :currency="item?.cryptoCurrency"
        />.
        Your offer must be higher than or equal to the reserve price
      </p>
    </template>
  </SaleModal>

  <SaleModal
    key="item-profile-bid-form"
    :isOpen="isMakeBidModalOpen"
    formId="item-profile-bid-form"
    title="Place a bid"
    :initialCurrency="item?.cryptoCurrency"
    :initialAmount="item?.englishAuction?.currentMinBid"
    :minAmount="item?.englishAuction?.currentMinBid"
    :isEditing="true"
    :isCurrencyReadonly="true"
    submitButtonLabel="Place a bid"
    @setOnSale="checkBidOnItem"
    @close="isMakeBidModalOpen = false"
  >
    <template v-slot:body>
      <p class="hint">
        Reserve price for this item is
        <CryptoPrice
          :amount="item?.englishAuction?.reservePrice"
          :currency="item?.cryptoCurrency"
        />.
        Your bid must be higher than or equal to
        <CryptoPrice
          :amount="item?.englishAuction?.currentMinBid"
          :currency="item?.cryptoCurrency"
        />
      </p>
      <p v-if="hasPriceChanged" class="error-text">
        The highest price has changed, bid has been updated.
      </p>
    </template>
  </SaleModal>

  <InfoModal :isOpen="error.isOpen" title="Error" @close="error.isOpen = false">
    <p>{{ error.text }}</p>
  </InfoModal>

  <InfoModal :isOpen="success.isOpen" title="Success" @close="success.isOpen = false">
    <p>{{ success.text }}</p>
  </InfoModal>

  <modal
    v-show="selectedOffer"
    @close="selectedOffer = null"
  >
    <template v-slot:header>
      <h2>Accept offer</h2>
    </template>

    <template v-slot:body>
      <p>
        You are about to accept
        <CryptoPrice :amount="selectedOffer?.price" :currency="selectedOffer?.cryptoCurrency"/>
        offer from {{ selectedOffer?.userName }}
      </p>
      <p style="margin-top: 0.5rem">
        Accepting the offer will end the Auction sale. The item will be transferred to the offer
        creator. All of the other pending offers and the highest bid for the item will be returned
        to the offer creators and the bidder correspondingly.
      </p>
    </template>

    <template v-slot:footer>
      <button
        @click="acceptOfferOnItem(selectedOffer.offerId)"
        class="modal-primary-button"
      >I understand, continue</button>
    </template>
  </modal>

  <modal
    v-show="isAcceptBidModalOpen"
    @close="isAcceptBidModalOpen = false"
  >
    <template v-slot:header>
      <h2>Accept current bid</h2>
    </template>

    <template v-slot:body>
      <p>
        You are about to accept
        <CryptoPrice :amount="item?.price" :currency="item?.cryptoCurrency"/>
        bid
      </p>
      <p style="margin-top: 0.5rem">
        Accepting the bid will end the Auction sale. The item will be transferred to the highest
        bidder. All of the pending offers for the item will be returned to the offer creators.
      </p>
    </template>

    <template v-slot:footer>
      <button
        @click="acceptBidOnItem"
        class="modal-primary-button"
      >I understand, continue</button>
    </template>
  </modal>

  <modal
    v-show="secretCodeModal.isOpen"
    :has-footer="false"
    max-width="737px"
    @close="secretCodeModal.isOpen = false"
  >
    <template v-slot:header>
      <h2>Redeem your reward</h2>
    </template>

    <template v-slot:body>
      <div class="input-container">
        <label for="item-profile-secret-code">Secret code</label>
        <div class="textarea-container">
          <input
            id="item-profile-secret-code"
            v-model="secretCodeModal.secretCode"
            type="text"
            placeholder="Reward secret code"
            class="input"
            readonly
          >
          <img
            v-if="!secretCodeModal.isCodeCopied"
            src="@/assets/images/common/copy-text.svg"
            alt="copy-text icon"
            class="icon"
            @click="copyCode(secretCodeModal.secretCode)"
          >
          <span v-else class="copy-text">Copied!</span>
        </div>
      </div>
      <div class="input-container">
        <label for="item-profile-contact-email">Contact email</label>
        <div class="textarea-container">
          <input
            id="item-profile-contact-email"
            v-model="secretCodeModal.email"
            type="text"
            placeholder="Contact email"
            class="input"
            readonly
          >
          <img
            v-if="!secretCodeModal.isEmailCopied"
            src="@/assets/images/common/copy-text.svg"
            alt="copy-text icon"
            class="icon"
            @click="copyEmail(secretCodeModal.email)"
          >
          <span v-else class="copy-text">Copied!</span>
        </div>
      </div>
      <p class="info-text">
        Contact this email for further information about how you can get your unique reward
      </p>
    </template>
  </modal>

  <Loading
    v-model:active="isLoading"
    loader="dots"
    background-color="#161414"
    color="#d92b3a"
    :opacity="0.9"
  />
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { START_LOCATION, useRoute, useRouter } from 'vue-router';
import Loading from 'vue-loading-overlay';
import copy from 'copy-to-clipboard';
import Toggle from '@vueform/toggle';
import VueCountdown from '@chenfengyuan/vue-countdown';

import HttpStatusCode from '@/shared/models/http-status-code.enum';
import CryptoCurrency from '@/shared/models/crypto-currency.enum';
import RewardStatus from '@/shared/models/reward-status.enum';
import {
  ACTION_SUCCESS_DEFAULT,
  ERROR_UNKNOWN,
  ITEM_BID_SET_SUCCESS,
  ITEM_BUY_SUCCESS,
  ITEM_OFFER_APPROVE_SUCCESS,
  ITEM_OFFER_SET_SUCCESS,
  ITEM_OFFER_WITHDRAW_SUCCESS,
  ITEM_OFF_CHAIN_OFFER_SET_FAILURE,
  ITEM_OFF_CHAIN_OFFER_SET_SUCCESS,
  ITEM_OFF_CHAIN_OFFER_WITHDRAW_FAILURE,
  ITEM_OFF_CHAIN_OFFER_WITHDRAW_SUCCESS,
  ITEM_PRICE_UPDATE_SUCCESS,
  ITEM_SELLING_FINALIZE_SUCCESS,
  ITEM_SELLING_WITHDRAW_SUCCESS,
  ITEM_SET_REWARD_AS_REDEEMED_SUCCESS,
  ITEM_SALE_SUBSCRIBE_SUCCESS,
} from '@/shared/constants/messages';

import { isExternalType } from '@/shared/helpers/get-item-file-type';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions, Mutations } from '@/store/modules/item-profile/props';

import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';
import ItemSaleData from '@/store/modules/item-profile/models/item-sale-data';
import Offer from '@/store/modules/item-profile/models/offer';
import ItemEnglishAuction from '@/store/modules/item-profile/models/item-english-auction';

import ContentLoader from '@/components/ContentLoader.vue';
import Modal from '@/components/Modal.vue';
import Markdown from '@/components/Markdown.vue';
import InfoModal from '@/components/InfoModal.vue';
import SaleModal from '@/components/SaleModal.vue';
import ConnectionModal from '@/components/ConnectionModal.vue';
import ItemFilePreview from '@/components/ItemFilePreview.vue';
import ItemEmbedFilePreview from '@/components/ItemEmbedFilePreview.vue';
import CryptoPrice from '@/components/CryptoPrice.vue';
import ItemPrice from '@/components/ItemPrice.vue';
import InfoTooltip from '@/components/InfoTooltip.vue';

import ItemProfileMainInfo from './ItemProfile/ItemProfileMainInfo.vue';
import ItemProfileSaleTimer from './ItemProfile/ItemProfileSaleTimer.vue';
import UserProfileOffChainBanner from '@/components/UserProfileOffChainBanner.vue';
import useWindowResize from '@/shared/hooks/use-window-resize';

interface SecretCodeModal {
  isOpen: boolean,
  secretCode: string,
  email: string,
  isCodeCopied: boolean,
  isEmailCopied: boolean,
}

const bidsHelperText = 'When you place a bid, we store your bid on our escrow. If somebody outbids you, the owner cancels the auction or accepts an offer, your bid is returned to you. You can not cancel your bid.';
const offersHelperText = 'You can place an offer for the item and the owner can accept it independently on the highest bid. We store your offer on our escrow. If the owner accepts the highest bid, another offer or cancels the auction, your offer is returned to you. You also can cancel your offer at any time.';

export default defineComponent({
  name: 'ItemProfile',

  components: {
    ItemProfileSaleTimer,
    VueCountdown,
    Loading,
    ConnectionModal,
    ItemFilePreview,
    ItemEmbedFilePreview,
    Modal,
    Markdown,
    InfoModal,
    SaleModal,
    ContentLoader,
    CryptoPrice,
    ItemPrice,
    ItemProfileMainInfo,
    InfoTooltip,
    Toggle,
    UserProfileOffChainBanner,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const { width } = useWindowResize();

    const isPageLoading = ref<boolean>(false);

    const isVideoInitiallyMuted = ref<boolean | null>(null);
    const isCheckoutModalOpen = ref<boolean>(false);
    const isConnectionModalOpen = ref<boolean>(false);
    const isMakeOffChainOfferModalOpen = ref<boolean>(false);
    const isPutOnSaleModalOpen = ref<boolean>(false);
    const isSetPriceModalOpen = ref<boolean>(false);
    const isMakeOfferModalOpen = ref<boolean>(false);
    const isMakeBidModalOpen = ref<boolean>(false);
    const hasPriceChanged = ref<boolean>(false);
    const isAcceptBidModalOpen = ref<boolean>(false);
    const selectedOffer = ref<Offer | null>(null);

    const enableEmbedded = ref<boolean>(true);

    const isLoading = ref<boolean>(false);
    const secretCodeModal = ref<SecretCodeModal>({
      isOpen: false,
      secretCode: '',
      email: 'redeem@melon.ooo',
      isCodeCopied: false,
      isEmailCopied: false,
    });
    const errors = ref<Record<string, string | null>>({ // inline errors
      amount: null,
    });
    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });
    const success = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });

    const isOwner = ref<boolean>(false);
    const isJustOwner = ref<boolean>(false);
    const isJustCreator = ref<boolean>(false);

    const item = computed(() => store.state.ItemProfile.item);
    const itemBids = computed(() => store.state.ItemProfile.offChainOffers.data);
    const userOffChainOffer = computed(() => store.state.ItemProfile.userOffChainOffer);
    const userOffer = computed(() => store.state.ItemProfile.userOffer);
    const isItemSelling = computed(() => (
      store.state.ItemProfile.item?.salesStatus === ItemSalesStatus.OnSale
      || store.state.ItemProfile.item?.salesStatus === ItemSalesStatus.OnEnglishAuction
      || store.state.ItemProfile.item?.salesStatus === ItemSalesStatus.OnDutchAuction
    ));
    const bids = computed(() => store.state.ItemProfile.bids.data);

    const currentUserId = computed(() => store.state.user.id);
    const isMainNet = computed(() => store.state.user.isMainNet);

    function getDate(date: number) {
      const d = new Date(date);
      return d.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZoneName: 'short',
      });
    }

    function transformSlotProps(props: { [s: string]: number }) {
      const formattedProps: { [s: string]: string } = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    }

    function validateAcceptBid(data: ItemEnglishAuction): boolean {
      const curDate = +new Date();
      return (
        curDate >= data.startDate
        && curDate <= data.endDate
        && (data.minBid === data.reservePrice
          ? data.currentPrice >= data.minBid
          : data.currentPrice > data.minBid)
      ) || (
        curDate > data.endDate
        && data.endDate !== 0
        && data.currentPrice >= data.reservePrice
      );
    }

    const loadItem = async (id: string, withGlobalLoading = false) => {
      if (withGlobalLoading) {
        isLoading.value = true;
      } else {
        isPageLoading.value = true;
      }

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.getItem}`, id);

        const titlePostfix = item.value?.title;

        if (titlePostfix) {
          window.document.title = `${window.document.title} - ${titlePostfix}`;
        }

        if (currentUserId.value && item.value
          && item.value.salesStatus === ItemSalesStatus.OnEnglishAuction
          && currentUserId.value !== item.value.ownerId) {
          await store.dispatch(`${Modules.ItemProfile}/${Actions.fetchOffer}`, id);
        }
        if (currentUserId.value && item.value
          && item.value.salesStatus === ItemSalesStatus.OnOffChainOfferAuction
          && currentUserId.value !== item.value.creatorId) {
          await store.dispatch(`${Modules.ItemProfile}/${Actions.fetchItemOfferOnOffChainOfferAuction}`, id);
        }
      } catch (e) {
        console.error(e);

        if (e.isAxiosError
          // Bad Request is present due to item id type - Number.
          // In case item id type is changed to string or backend will handle it in another way,
          // HttpStatusCode.BadRequest may be removed
          && [HttpStatusCode.BadRequest, HttpStatusCode.NotFound].includes(e.response.status)) {
          router.push({ name: 'item-list' });
        }
      } finally {
        if (withGlobalLoading) {
          isLoading.value = false;
        } else {
          isPageLoading.value = false;
        }
      }
    };

    const isAuthenticated = (callback: () => void) => {
      if (currentUserId.value) {
        callback();
      } else {
        isConnectionModalOpen.value = true;
      }
    };

    const callModalOnBuy = (isEnglishAuction: boolean): void => {
      isAuthenticated(() => {
        if (isEnglishAuction) {
          isMakeBidModalOpen.value = true;
        } else {
          isCheckoutModalOpen.value = true;
        }
      });
    };

    const callModalOnOffer = (): void => {
      isAuthenticated(() => {
        isMakeOfferModalOpen.value = true;
      });
    };

    const callModalOnOffChainOffer = (): void => {
      isAuthenticated(() => {
        isMakeOffChainOfferModalOpen.value = true;
      });
    };

    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    function buildSuccessModal(text: string): void {
      success.value = {
        isOpen: true,
        text: text || ACTION_SUCCESS_DEFAULT,
      };
    }

    function reloadPage(): void {
      router.go(0);
    }

    async function subscribeOnSale(email: string) {
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.subscribeForSale}`, email);
        buildSuccessModal(ITEM_SALE_SUBSCRIBE_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function buyItem() {
      isCheckoutModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.buyItem}`);
        buildSuccessModal(ITEM_BUY_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function makeOffChainOfferOnItem(data: ItemSaleData) {
      isMakeOffChainOfferModalOpen.value = false;
      errors.value.amount = null;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.setItemOfferOnOffChainOfferAuction}`, {
          price: data.amount,
          cryptoCurrency: data.currency,
        });
        buildSuccessModal(ITEM_OFF_CHAIN_OFFER_SET_SUCCESS);
      } catch (e) {
        if (e.response?.data?.Price) {
          isMakeOffChainOfferModalOpen.value = true;
          errors.value.amount = e.response?.data?.Price[0];
        } else {
          buildErrorModal(ITEM_OFF_CHAIN_OFFER_SET_FAILURE);
        }
      } finally {
        isLoading.value = false;
      }
    }

    async function withdrawOffChainOfferOnItem() {
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.withdrawItemOfferFromOffChainOfferAuction}`);
        buildSuccessModal(ITEM_OFF_CHAIN_OFFER_WITHDRAW_SUCCESS);
      } catch (e) {
        buildErrorModal(ITEM_OFF_CHAIN_OFFER_WITHDRAW_FAILURE);
      } finally {
        isLoading.value = false;
      }
    }

    async function makeOfferOnItem(data: ItemSaleData) {
      isMakeOfferModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.setItemOfferOnEnglishAuction}`, data.amount);
        buildSuccessModal(ITEM_OFFER_SET_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function withdrawOfferOnItem(offerId: number) {
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.withdrawItemOfferFromEnglishAuction}`, offerId);
        buildSuccessModal(ITEM_OFFER_WITHDRAW_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function makeBidOnItem(data: ItemSaleData) {
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.buyItem}`, data.amount);
        buildSuccessModal(ITEM_BID_SET_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function checkBidOnItem(data: ItemSaleData) {
      const { amount } = data;

      isMakeBidModalOpen.value = false;
      hasPriceChanged.value = false;

      await loadItem(route.params.id as string, true);

      if (!!item.value?.englishAuction && amount < item.value.englishAuction.currentMinBid) {
        isMakeBidModalOpen.value = true;
        hasPriceChanged.value = true;
      } else {
        await makeBidOnItem(data);
      }
    }

    async function updateItemPrice(data: ItemSaleData) {
      isSetPriceModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.updateItemPrice}`, data.amount);
        buildSuccessModal(ITEM_PRICE_UPDATE_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function acceptBidOnItem() {
      isAcceptBidModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.finalizeItemOnEnglishAuction}`);
        buildSuccessModal(ITEM_SELLING_FINALIZE_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function acceptOfferOnItem(offerId: number) {
      selectedOffer.value = null;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.approveItemOfferOnEnglishAuction}`, offerId);
        buildSuccessModal(ITEM_OFFER_APPROVE_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function withdrawItemFromSelling(): Promise<void> {
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.withdrawItemFromSelling}`);
        buildSuccessModal(ITEM_SELLING_WITHDRAW_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function openRewardModal(): Promise<void> {
      isLoading.value = true;

      try {
        secretCodeModal.value.secretCode = await store.dispatch(`${Modules.ItemProfile}/${Actions.getSecretCode}`);
        secretCodeModal.value.isOpen = true;
      } catch (e) {
        buildErrorModal(e.response?.data?.itemId?.[0] || e.message);
      } finally {
        isLoading.value = false;
      }
    }

    function copyCode(code: string) {
      if (copy(code)) {
        secretCodeModal.value.isCodeCopied = true;

        setTimeout(() => {
          secretCodeModal.value.isCodeCopied = false;
        }, 1000);
      }
    }

    function copyEmail(email: string) {
      if (copy(email)) {
        secretCodeModal.value.isEmailCopied = true;

        setTimeout(() => {
          secretCodeModal.value.isEmailCopied = false;
        }, 1000);
      }
    }

    async function markAsRedeemed(): Promise<void> {
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.setRewardAsRedeemed}`);
        buildSuccessModal(ITEM_SET_REWARD_AS_REDEEMED_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    watch(currentUserId, (userId) => {
      if (userId && item.value
        && item.value.salesStatus === ItemSalesStatus.OnEnglishAuction
        && userId !== item.value.ownerId) {
        store.dispatch(`${Modules.ItemProfile}/${Actions.fetchOffer}`, route.params.id as string);
      } else {
        store.commit(`${Modules.ItemProfile}/${Mutations.setItemUserOffer}`);
      }

      if (userId && item.value
        && item.value.salesStatus === ItemSalesStatus.OnOffChainOfferAuction
        && userId !== item.value.creatorId) {
        store.dispatch(`${Modules.ItemProfile}/${Actions.fetchItemOfferOnOffChainOfferAuction}`, route.params.id as string);
      } else {
        store.commit(`${Modules.ItemProfile}/${Mutations.setItemUserOffChainOffer}`);
      }
    });

    watch([item, currentUserId], ([value, userId]) => {
      isOwner.value = !!userId && userId === value?.ownerId;
      isJustOwner.value = isOwner.value && userId !== value?.creatorId;
      isJustCreator.value = !!userId && userId !== value?.ownerId && userId === value?.creatorId;
    });

    // As for now metamask cancel unapproved transactions on network change,
    // but do not send any response to that, so we reload forcefully, though,
    // there may be ongoing transaction, too.
    watch(isMainNet, () => {
      if (isLoading.value) {
        reloadPage();
      } else {
        loadItem(route.params.id as string);
      }
    });

    watch(() => success.value.isOpen, (open) => {
      if (!open) {
        reloadPage();
      }
    });

    onMounted(() => {
      setTimeout(() => {
        loadItem(route.params.id as string);
      }, 500);
    });

    onUnmounted(() => {
      store.dispatch(`${Modules.ItemProfile}/${Actions.clearItem}`);
    });

    return {
      bidsHelperText,
      offersHelperText,
      width,
      RewardStatus,
      isPageLoading,
      isOwner,
      isJustOwner,
      isJustCreator,
      isItemSelling,
      currentUserId,
      isMainNet,
      item,
      userOffChainOffer,
      userOffer,
      bids,
      SaleStatus: ItemSalesStatus,
      CryptoCurrency,
      isVideoInitiallyMuted,
      isCheckoutModalOpen,
      isConnectionModalOpen,
      isMakeOffChainOfferModalOpen,
      isPutOnSaleModalOpen,
      isSetPriceModalOpen,
      isMakeOfferModalOpen,
      isMakeBidModalOpen,
      hasPriceChanged,
      isAcceptBidModalOpen,
      selectedOffer,
      enableEmbedded,
      isLoading,
      secretCodeModal,
      errors,
      error,
      success,
      isExternalType,
      reloadPage,
      getDate,
      transformSlotProps,
      validateAcceptBid,
      callModalOnBuy,
      callModalOnOffer,
      callModalOnOffChainOffer,
      subscribeOnSale,
      buyItem,
      makeOffChainOfferOnItem,
      withdrawOffChainOfferOnItem,
      makeOfferOnItem,
      withdrawOfferOnItem,
      checkBidOnItem,
      updateItemPrice,
      acceptBidOnItem,
      acceptOfferOnItem,
      withdrawItemFromSelling,
      openRewardModal,
      copyCode,
      copyEmail,
      markAsRedeemed,
      itemBids,
    };
  },

  beforeRouteEnter(to, from, next) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next((vm: any) => {
      // Mute video on details only if this page is starting one,
      // otherwise user has interacted with SPA via link
      // and browser should allow to play video with sound
      // eslint-disable-next-line no-param-reassign
      vm.isVideoInitiallyMuted = from === START_LOCATION;
    });
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

$info-max-width: 434px;

.content-container {
  @include items-wrapper;

  margin-top: -2rem;

  & > .preview-container {
    width: 70%;

    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }
  }

  & > .info-container {
    width: 30%;

    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }
  }
}

.embedded-switcher-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  @include for-md-lg-xl-width {
    padding: 0 2rem;
  }

  .embedded-switcher-header {
    display: flex;
    align-items: center;
    margin-right: 32px;

    h4 {
      @include typo-body-2-bold;
      color: $neutrals-2;
    }
  }
}

.file-wrapper {
  @include for-md-lg-xl-width {
    padding: 0 2rem;
  }

  .file-preview {
    max-width: 100%;
    min-height: 100px;
    max-height: 70vh;
    margin: 0 auto;
    object-fit: contain;
    box-shadow: $depth-item;
  }
}

.input-container {
  @include input-container;
  margin-bottom: 1rem;

  .textarea-container {
    position: relative;
    display: flex;
    align-items: center;

    .input {
      @include input-common;
      @include typo-caption-bold;

      padding: 0.75rem 1rem;
      border-color: #ece6e6;
      border-radius: 0.75rem;

      &:read-only {
        background-color: #f6f4f4;
      }
    }

    .icon {
      position: absolute;
      right: 1rem;
      width: 1.5rem;
      cursor: pointer;
    }

    .copy-text {
      position: absolute;
      right: 1rem;
    }
  }
}

.info-card {
  @include card;
  @include box-shadow-default;
  @include typo-body-2;

  position: relative;
  display: flex;
  flex-direction: column;
  max-width: $info-max-width;
  padding: 1rem;
  overflow: visible;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  .heading-container {
    display: flex;
    align-items: center;

    .spacer {
      flex-grow: 1;
    }

    .icon {
      display: block;
      width: 3rem;
      height: 3rem;
      margin-right: 0.75rem;
      border-radius: 50%;

      &.with-shadow {
        box-shadow: 0 0 1.75rem -0.5rem rgba(15, 15, 15, 0.2);
      }
    }
  }

  &.with-overflow {
    overflow: unset;
  }

  .small-title {
    @include typo-body-2;
    color: $neutrals-3;
    font-weight: bold;
  }

  .extra-content {
    padding-top: 1rem;
    color: $neutrals-2;
  }

  .offchain-offer-description {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &.has-padding {
      margin-bottom: 1rem;
    }
  }

  .offchain-highest-bid {
    margin-bottom: 0.5rem;
  }

  .auction-ending {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .dutch-auction-info {
    margin-top: 1rem;

    & > *:not(:first-child) {
      margin-top: 0.25rem;
    }
  }

  .price-label {
    @include typo-button-1;
    color: $neutrals-3;

    .reserve-price {
      margin-left: 10px;
      color: $neutrals-4;
    }
  }

  .price {
    @include typo-body-1;

    margin-top: 0.5rem;
  }

  .user-offer {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .price {
      @include typo-body-2;
    }
  }

  .outline-button {
    @include typo-button-1;
    @include button-outline($neutrals-1, $control-border-color);

    padding: 13px 1rem;
    text-align: center;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  .primary-button {
    @include typo-button-1;
    @include button-primary-filled;

    padding: 13px 1rem;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  .text-button {
    @include typo-button-1;

    margin-right: auto;
    margin-left: auto;
    color: $primary-1;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  .helper-text {
    @include typo-body-2;

    margin-top: 1.75rem;
    color: $neutrals-3;
    text-align: center;

    .helper-tooltip-link {
      color: $primary-1;
      text-decoration: underline;
    }

    @media (max-width: 370px) {
      align-self: center;
      width: 7.5rem;
    }
  }
}

.tabs {
  max-width: $info-max-width;
  margin-top: 2rem;
}

.modal-primary-button {
  @include modal-button-primary-filled;
}

.em {
  font-weight: 500;
}

.hint {
  @include typo-caption;

  margin-top: 1.5rem;
  color: $neutrals-4;
}

.error-text {
  @include typo-caption-2;

  margin-top: 1.5rem;
  color: $primary-1;
}

.info-text {
  color: #907778;
  font-size: 14px;
  line-height: 24px;
}
</style>
