
import { defineComponent } from 'vue';

import userImageUrl from '@/shared/helpers/user-image-url';

export default defineComponent({
  name: 'UserImage',

  props: {
    userName: {
      required: true,
    },
    userImagePath: {
      required: true,
    },
    isUserVerified: {
      type: Boolean,
      default: false,
    },
    isUserRegistered: {
      type: Boolean,
      default: false,
    },
    imageSize: {
      type: String,
      default: '2rem',
    },
    iconSize: {
      type: String,
      default: '0.5rem',
    },
    iconOffset: {
      type: String,
      default: '0',
    },
    offchainTooltipPosition: {
      type: String,
      default: 'is-top',
    },
  },

  setup() {
    return {
      userImageUrl,
    };
  },
});
