import http from '@/instances/http';

// models
import NotifyRequest from '@/shared/models/notify-request';

const NotifyApi = {
  async notifySuccess(body: NotifyRequest, notifyType: string, token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.post(`/transactions/${notifyType}`, body, { headers });
  },

  // Calls on user's inactivity at transaction process
  // (e.g. user left the tab, provider failed, etc.)
  async notifyFailure(token: string): Promise<void> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await http.post('/transactions/notify-failure', {}, { headers });
  },
};

export default NotifyApi;
