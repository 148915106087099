enum Actions {
  fetchCategories = 'fetchCategories',
  fetchTypes = 'fetchTypes',
  fetchTextFilters = 'fetchTextFilters',
  fetchCreators = 'fetchCreators',
  fetchItems = 'fetchItems',
  clearTextFilters = 'clearTextFilters',
  clearCreators = 'clearCreators',
  clearItems = 'clearItems',
}

export default Actions;
