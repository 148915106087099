import { Module } from 'vuex';

// apis
import UserListApi from '@/api/user-list.api';

// global
import GlobalState from '@/store/models/global-state';

// shared
import PaginationOptions from '@/shared/models/pagination-options';

// module types
import { Actions, Mutations } from './props';

// models
import FeaturedUserListItem from './models/featured-user-list-item';

import UserListFilters from './models/user-list-filters';

import UserListUsersState from './models/user-list-users-state';
import UserListState from './models/user-list-state';
import UserItemFilter from './models/user-item-filter.enum';
import UserStatsFilter from './models/user-stats-filter.enum';
import UserStatsListFilters from './models/user-stats-list-filters';
import SocialStatsFilter from './models/social-stats-filter.enum';
import UserListStatsState from './models/user-list-stats-state';
import CreatorsFilter from './models/creators-filter.enum';
import ItemSalesStatus from '../item-profile/models/item-sales-status.enum';

const USERS_PER_PAGE = 12;

const UserList: Module<UserListState, GlobalState> = {
  namespaced: true,

  state: () => ({
    namespaced: true,

    featuredUsers: [],

    users: {
      data: [],
      pagination: {
        page: 1,
        pageSize: USERS_PER_PAGE,
      },
      isLastPage: false,
      filters: {
        orderBy: UserItemFilter.MaxItemsOnSale,
        isVerified: true,
        search: '',
      },
    },

    stats: {
      data: [],
      isLastPage: false,
      isUsersLoading: false,
      filters: {
        sortBy: UserStatsFilter.FollowersDesc,
        followersFilter: SocialStatsFilter.All,
        creatorsFilter: `${CreatorsFilter.Verified},${CreatorsFilter.Unregistered}`,
        creatorItemsFilters: `${ItemSalesStatus.Minted},${ItemSalesStatus.OnSale},${ItemSalesStatus.OnEnglishAuction},${ItemSalesStatus.OnOffChainOfferAuction}`,
        offset: 0,
        pageSize: 10,
        isNoneItemsCreated: 'true',
      },
    },
  }),

  mutations: {
    [Mutations.setFeaturedUsers](state: UserListState, users?: FeaturedUserListItem[]): void {
      state.featuredUsers = users ?? [];
    },

    [Mutations.setUsers](
      state: UserListState,
      {
        data, pagination, isLastPage, filters,
      }: Partial<UserListUsersState>,
    ): void {
      state.users.data = data ?? [];
      state.users.pagination.page = pagination?.page ?? 1;
      state.users.pagination.pageSize = pagination?.pageSize ?? USERS_PER_PAGE;
      state.users.isLastPage = isLastPage ?? false;
      state.users.filters.orderBy = filters?.orderBy ?? UserItemFilter.MaxItemsOnSale;
      state.users.filters.isVerified = filters?.isVerified ?? true;
      state.users.filters.search = filters?.search ?? '';
    },

    [Mutations.setRankedUsers](
      state: UserListState,
      { data, filters }: Partial<UserListStatsState>,
    ): void {
      state.stats.data = data ?? [];
      state.stats.filters.sortBy = filters?.sortBy ?? state.stats.filters.sortBy;
      state.stats.filters.followersFilter = filters?.followersFilter
        ?? state.stats.filters.followersFilter;
      state.stats.filters.creatorsFilter = filters?.creatorsFilter
        ?? state.stats.filters.creatorsFilter;
      state.stats.filters.creatorItemsFilters = filters?.creatorItemsFilters
        ?? state.stats.filters.creatorItemsFilters;
      state.stats.filters.offset = filters?.offset ?? 0;
      state.stats.filters.pageSize = filters?.pageSize ?? 10;
      state.stats.filters.isNoneItemsCreated = filters?.isNoneItemsCreated
        ?? state.stats.filters.isNoneItemsCreated;
    },

    [Mutations.setUserLoading](
      state: UserListState,
      isUserLoading: boolean,
    ): void {
      state.stats.isUsersLoading = isUserLoading;
    },

    [Mutations.setRankedUsersLastPage](
      state: UserListState,
      isLastPage: boolean,
    ): void {
      state.stats.isLastPage = isLastPage;
    },
  },

  actions: {
    async [Actions.fetchFeaturedUsers]({ commit }): Promise<void> {
      const data = await UserListApi.getFeaturedUsers();
      commit(Mutations.setFeaturedUsers, data);
    },

    async [Actions.fetchUsers](
      { state, commit },
      {
        page, pageSize, orderBy, isVerified, search,
      }: Partial<PaginationOptions> & Partial<UserListFilters>,
    ): Promise<void> {
      let {
        data, pagination, isLastPage, filters,
      } = state.users;

      const isPaginationSchemeChanged = (pageSize !== undefined && pageSize !== pagination.pageSize)
        || (orderBy !== undefined && orderBy !== filters.orderBy)
        || (isVerified !== undefined && isVerified !== filters.isVerified)
        || (search !== undefined && search !== filters.search);

      pagination = {
        page: isPaginationSchemeChanged ? 1 : (page ?? pagination.page),
        pageSize: pageSize ?? pagination.pageSize,
      };

      filters = {
        orderBy: orderBy !== undefined ? orderBy : filters.orderBy,
        isVerified: isVerified !== undefined ? isVerified : filters.isVerified,
        search: search !== undefined ? search : filters.search,
      };

      if (isPaginationSchemeChanged) {
        data = [];
        isLastPage = false;
      }

      if (!isLastPage) {
        const dataPerPage = await UserListApi.getUsers(pagination, filters);

        data.push(...dataPerPage);
        isLastPage = dataPerPage.length < pagination.pageSize;
      }

      commit(Mutations.setUsers, {
        data, pagination, isLastPage, filters,
      });
    },

    async [Actions.fetchRankedUsers](
      { state, commit },
      {
        sortBy, followersFilter, creatorsFilter,
        creatorItemsFilters, offset, pageSize, isNoneItemsCreated,
      }: UserStatsListFilters,
    ): Promise<void> {
      const filters = {
        sortBy: sortBy ?? UserStatsFilter.FollowersDesc,
        followersFilter: followersFilter ?? SocialStatsFilter.All,
        creatorsFilter: creatorsFilter ?? CreatorsFilter.All,
        creatorItemsFilters: creatorItemsFilters ?? '',
        offset: offset ?? 0,
        pageSize: pageSize ?? 10,
        isNoneItemsCreated,
      };

      commit(Mutations.setUserLoading, true);

      const { data } = state.stats;
      try {
        const dataPerPage = await UserListApi.getRankedUsers(filters);
        data.push(...dataPerPage);
        commit(Mutations.setRankedUsers, { data, filters });
        commit(Mutations.setUserLoading, false);
        commit(Mutations.setRankedUsersLastPage, !dataPerPage.length);
      } catch (error) {
        commit(Mutations.setRankedUsers, {});
        commit(Mutations.setUserLoading, false);
      }
    },

    [Actions.clearUsers]({ commit }): void {
      commit(Mutations.setUsers, {});
    },

    [Actions.clearRankedUsers]({ commit }): void {
      commit(Mutations.setRankedUsers, {});
      commit(Mutations.setRankedUsersLastPage, false);
    },
  },
};

export default UserList;
