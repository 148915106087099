
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import Toggle from '@vueform/toggle';

import useDebouncedRef from '@/shared/hooks/use-debounced-ref';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-list/props';
import PaginationOptions from '@/shared/models/pagination-options';
import RewardStatus from '@/shared/models/reward-status.enum';
import ItemSaleStatus from '@/store/modules/item-profile/models/item-sales-status.enum';

import ItemListOrderByFilter from '@/store/modules/item-list/models/item-list-order-by-filter.enum';
import ItemListFilters from '@/store/modules/item-list/models/item-list-filters';

import ContentLoader from '@/components/ContentLoader.vue';
import ItemCard from '@/components/ItemCard.vue';
import Select from '@/components/Select.vue';
import ItemFilterSelect from '@/components/ItemFilterSelect.vue';
import ItemTypeSelect from '@/components/ItemTypeSelect.vue';
import ItemRewardSelect from '@/components/ItemRewardSelect.vue';
import ItemSaleStatusSelect from '@/components/ItemSaleStatusSelect.vue';

import CreatorSelect from './ItemList/CreatorSelect.vue';

export default defineComponent({
  name: 'ItemList',

  components: {
    ItemRewardSelect,
    ItemTypeSelect,
    CreatorSelect,
    ItemFilterSelect,
    ItemCard,
    Select,
    ContentLoader,
    Toggle,
    ItemSaleStatusSelect,
  },

  setup() {
    const store = useStore();
    const route = useRoute();

    const isItemsLoading = ref<boolean>(false);

    const initialSearch = computed(() => route.query.search);

    const search = useDebouncedRef(initialSearch.value as string ?? '', 400);

    const categoryIds = ref<string[]>([...store.state.ItemList.items.filters.categoryIds]);

    const categories = computed(() => store.state.ItemList.categories);

    const orderBy = ref<ItemListOrderByFilter>(ItemListOrderByFilter.SaleEndingSoon);

    const sortOptions = [
      {
        id: ItemListOrderByFilter.SaleEndingSoon,
        label: 'Sale ending soon',
      },
      {
        id: ItemListOrderByFilter.RecentlyCreated,
        label: 'Recently Created',
      },
      {
        id: ItemListOrderByFilter.RecentlyListed,
        label: 'Recently Listed',
      },
      {
        id: ItemListOrderByFilter.Cheapest,
        label: 'Cheapest',
      },
      {
        id: ItemListOrderByFilter.Expensive,
        label: 'Expensive',
      },
    ];

    const creatorId = ref<string | null>(store.state.ItemList.items.filters.creatorId);

    const typeId = ref<string | null>(store.state.ItemList.items.filters.typeId);

    const rewardStatus = ref<RewardStatus | null>(store.state.ItemList.items.filters.rewardStatus);

    const saleStatus = ref<ItemSaleStatus | null>(store.state.ItemList.items.filters.saleStatus);

    const isNSFW = ref<boolean>(false);

    const textFilters = ref(store.state.ItemList.items.filters.textFilters);

    const data = computed(() => store.state.ItemList.items.data);
    const pagination = computed(() => store.state.ItemList.items.pagination);
    const isLastPage = computed(() => store.state.ItemList.items.isLastPage);

    const scrollComponent = ref<HTMLElement | null>(null);

    const loadItems = (options: Partial<PaginationOptions> & Partial<ItemListFilters>) => {
      isItemsLoading.value = true;
      store.dispatch(`${Modules.ItemList}/${Actions.fetchItems}`, {
        search: search.value,
        categoryIds: [...categoryIds.value],
        orderBy: orderBy.value,
        creatorId: creatorId.value,
        typeId: typeId.value,
        rewardStatus: rewardStatus.value,
        saleStatus: saleStatus.value,
        textFilters: [...textFilters.value],
        isNSFW: isNSFW.value,
        ...options,
      }).finally(() => {
        isItemsLoading.value = false;
      });
    };

    const clearItems = () => {
      store.dispatch(`${Modules.ItemList}/${Actions.clearItems}`);
    };

    const handleScroll = () => {
      const element = scrollComponent.value;
      if (element && !isLastPage.value && !isItemsLoading.value
        && element.getBoundingClientRect().bottom < window.innerHeight
      ) {
        loadItems({ page: pagination.value.page + 1 });
      }
    };

    const resetFilters = () => {
      search.value = '';
      categoryIds.value = [];
      isNSFW.value = false;
      orderBy.value = ItemListOrderByFilter.SaleEndingSoon;
      creatorId.value = null;
      typeId.value = null;
      textFilters.value = [];
    };

    watch(search, (value) => {
      clearItems();
      loadItems({ search: value });
    });

    watch(initialSearch, (value) => {
      search.value = value as string ?? '';
    });

    watch(categoryIds, (value, prevValue) => {
      if (!isEqual(new Set([...value]), new Set([...prevValue]))) {
        clearItems();
        loadItems({ categoryIds: [...value] });
      }
    });

    watch(orderBy, (value) => {
      clearItems();
      loadItems({ orderBy: value });
    });

    watch(creatorId, (value) => {
      clearItems();
      loadItems({ creatorId: value });
    });

    watch(typeId, (value) => {
      clearItems();
      loadItems({ typeId: value });
    });

    watch(rewardStatus, (value) => {
      clearItems();
      loadItems({ rewardStatus: value });
    });

    watch(saleStatus, (value) => {
      clearItems();
      loadItems({ saleStatus: value });
    });

    watch(textFilters, (value, prevValue) => {
      if (!isEqual(new Set([...value]), new Set([...prevValue]))) {
        clearItems();
        loadItems({ textFilters: [...value] });
      }
    });

    watch(isNSFW, (value) => {
      clearItems();
      loadItems({ isNSFW: value });
    });

    onMounted(() => {
      isNSFW.value = store.state.ItemList.items.filters.isNSFW;
      store.dispatch(`${Modules.ItemList}/${Actions.fetchCategories}`);

      loadItems({ page: 1 });

      window.addEventListener('scroll', throttle(handleScroll, 800, { leading: false }));
    });

    onUnmounted(() => {
      clearItems();

      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isItemsLoading,
      search,
      categoryIds,
      categories,
      orderBy,
      sortOptions,
      creatorId,
      typeId,
      rewardStatus,
      saleStatus,
      isNSFW,
      textFilters,
      data,
      pagination,
      scrollComponent,
      ItemListOrderByFilter,
      resetFilters,
    };
  },
});
