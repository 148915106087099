<template>
  <Modal
    v-show="isOpen"
    :has-footer="false"
    max-width="100%"
    height="100%"
    width="100%"
    borderRadius="0"
    @close="closeModal"
  >
    <template v-slot:header>
      <h2>Filters</h2>
    </template>
    <template v-slot:body>
      <!-- <div class="filter">
        <p class="filter-title">Followers</p>
        <Select
          v-model="followers"
          :data="followersSortOptions"
          :multiple="true"
          placeholder="All social platforms"
        />
      </div> -->
      <!-- <div class="filter">
        <p class="filter-title">Likes</p>
        <Select
          v-model="likes"
          :data="likesSortOptions"
          :multiple="true"
          placeholder="Instagram, Twitter"
        />
      </div>
      <div class="filter">
        <p class="filter-title">Views</p>
        <Select
          v-model="views"
          :data="viewsSortOptions"
          :multiple="true"
          placeholder="Twitch, YouTube, TikTok"
        />
      </div> -->
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  defineComponent, ref, computed, watch,
} from 'vue';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-list/props';
import SocialStatsFilter from '@/store/modules/user-list/models/social-stats-filter.enum';

import Modal from '@/components/Modal.vue';
import CreatorsFilter from '@/store/modules/user-list/models/creators-filter.enum';
import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';
// import Select from '@/components/Select.vue';

interface loadOptions {
  followersFilter?: string,
  creatorsFilter?: string,
  creatorItemsFilters?: string,
}

export default defineComponent({
  components: {
    Modal,
    // Select,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close-modal'],
  setup(props, { emit }) {
    const store = useStore();

    const followers = ref<string[]>([]);
    const likes = ref<string[]>([]);
    const views = ref<string[]>([]);

    const filters = computed(() => store.state.UserList.stats.filters);

    const followersSortOptions = [
      {
        id: SocialStatsFilter.Instagram,
        label: 'Instagram',
      },
      {
        id: SocialStatsFilter.Twitter,
        label: 'Twitter',
      },
      {
        id: SocialStatsFilter.Twitch,
        label: 'Twitch',
      },
      {
        id: SocialStatsFilter.TikTok,
        label: 'TikTok',
      },
      {
        id: SocialStatsFilter.Youtube,
        label: 'YouTube',
      },
    ];

    const likesSortOptions = [
      {
        id: SocialStatsFilter.Instagram,
        label: 'Instagram',
      },
      {
        id: SocialStatsFilter.Twitter,
        label: 'Twitter',
      },
    ];

    const viewsSortOptions = [
      {
        id: SocialStatsFilter.Twitch,
        label: 'Twitch',
      },
      {
        id: SocialStatsFilter.Youtube,
        label: 'YouTube',
      },
      {
        id: SocialStatsFilter.TikTok,
        label: 'TikTok',
      },
    ];

    const clearRankedUsers = () => {
      store.dispatch(`${Modules.UserList}/${Actions.clearRankedUsers}`);
    };

    const loadUsers = (options: loadOptions) => {
      store.dispatch(`${Modules.UserList}/${Actions.fetchRankedUsers}`, {
        offset: filters.value.offset,
        followersFilter: options.followersFilter || filters.value.followersFilter,
        creatorsFilter: options.creatorsFilter || filters.value.creatorsFilter,
        creatorItemsFilters: options.creatorItemsFilters || filters.value.creatorItemsFilters,
        sortBy: filters.value.sortBy,
        pageSize: filters.value.pageSize,
      });
    };

    watch([followers, likes, views], ([fl, li, ve]) => {
      clearRankedUsers();
      loadUsers({
        followersFilter: fl.toString() || SocialStatsFilter.All,
        creatorsFilter: li.toString() || CreatorsFilter.All,
        creatorItemsFilters: ve.toString() || ItemSalesStatus.Created,
      });
    });

    const closeModal = () => {
      emit('close-modal');
    };

    return {
      followers,
      likes,
      views,
      closeModal,
      followersSortOptions,
      likesSortOptions,
      viewsSortOptions,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter {
  margin-bottom: 1rem;

  .filter-title {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
  }
}
</style>
