<template>
  <div :style="{ height: isMenuOpen ? `${height}px` : 'auto'}" class="sticky-container">
    <div v-if="banner?.isShown" role="alert" class="main-column-wrapper alert-container inverted">
      <span class="alert">
        <span>{{ banner?.description }}</span>
      </span>
    </div>

    <div v-if="!isMainNet" role="alert" class="main-column-wrapper alert-container">
      <span class="alert">
        <span>
          Your current network is not {{ networkName }}. Click
          <button type="button" @click="switchNetwork"> here </button>
          to set the network to {{ networkName }} and follow instructions in your wallet
        </span>
      </span>
    </div>

    <header class="main-column-wrapper">
      <div class="content-container">
        <div>
          <router-link :to="{ name: 'home' }" @click="closeMenu" class="logo-link">
            <img src="@/assets/images/logo.svg" alt="Melon logo">
          </router-link>
          <div class="vertical-split desktop"></div>
          <nav class="desktop">
            <router-link :to="{ name: 'item-list' }" @click="closeMenu" class="space-no-wrap">
              NFT marketplace
            </router-link>
            <router-link :to="{ name: 'user-list' }" @click="closeMenu">Community</router-link>
            <div class="dropdown">
              <button class="dropdown-button">Resources</button>
              <div class="dropdown-content">
                <a href="https://info.melon.ooo/en/collections/3119186-getting-started" target="_blank">
                  Getting Started
                </a>
                <a href="https://info.melon.ooo/en/articles/5572505-introduction-to-nfts" target="_blank">
                  FAQ
                </a>
                <a href="https://info.melon.ooo/en/articles/5606500-how-to-buy-nfts-on-the-polygon-network" target="_blank">
                  How to buy NFTs on the Polygon Network
                </a>
                <a href="https://info.melon.ooo/en/articles/5608639-everything-you-need-to-know-about-the-melon-token-coming-soon" target="_blank">
                  About the $MELON Token
                </a>
                <a href="https://info.melon.ooo/en/collections/3082913-partner-with-melon" target="_blank">
                  Partnerships
                </a>
                <button @click="openIntercomBot">Contact us</button>
                <a href="https://info.melon.ooo/en/collections/3157375-about-melon" target="_blank">
                  About Melon
                </a>
                <Socials class="socials" />
              </div>
            </div>
            <router-link v-if="isAuthenticated" :to="{ name: 'feed' }" @click="closeMenu">
              Feed
            </router-link>
          </nav>
        </div>

        <div>
          <div class="search-container not-mobile">
            <input
              id="header-search"
              type="search"
              placeholder="Search"
              v-model="search"
              @keyup.enter="onSearch"
              @focus="showSuggestions = true"
              @blur="closeDropdownOnBlur"
            >
            <button v-if="search" @click="search = ''" class="icon close-icon">
              <img src="@/assets/images/common/close.svg" alt="clear icon">
            </button>
            <button v-else @click="onSearch" class="icon">
              <img src="@/assets/images/common/search.svg" alt="search icon">
            </button>
            <!-- suggestions dropdown -->
            <div v-if="suggestions && showSuggestions" class="suggestions-wrapper">
              <div ref="suggestionRef" class="suggestions">
                <Suggestions
                  :suggestions="suggestions"
                  :search="search"
                  @select="showSuggestions = false"
                />
              </div>
            </div>
          </div>

          <div class="desktop">
            <ConnectButton v-if="width >= 1200" @buttonClick="closeMenu" />
          </div>

          <button class="menu-toggle-button" @click="isMenuOpen = !isMenuOpen">
            <span class="hamburger" :class="{ 'open': isMenuOpen }">
              <span class="line"></span>
              <span class="line"></span>
            </span>
          </button>
        </div>
      </div>
    </header>

    <div v-show="isMenuOpen" class="main-column-wrapper menu-panel">
      <div class="menu-content-container">
        <div>
          <div class="search-container">
            <input
              id="header-search"
              type="search"
              placeholder="Search"
              v-model="search"
              @keyup.enter="onSearch"
            >
            <button v-if="search" @click="search = ''" class="icon close-icon">
              <img src="@/assets/images/common/close.svg" alt="clear icon">
            </button>
            <button v-else @click="onSearch" class="icon">
              <img src="@/assets/images/common/search.svg" alt="search icon">
            </button>
          </div>
          <Suggestions
            v-if="suggestions && width < 768"
            :suggestions="suggestions"
            :search="search"
            @select="closeMenu"
          />
          <nav v-else>
            <router-link :to="{ name: 'home' }" @click="closeMenu">Home</router-link>
            <router-link :to="{ name: 'item-list' }" @click="closeMenu">
              NFT marketplace
            </router-link>
            <router-link :to="{ name: 'user-list' }" @click="closeMenu">Community</router-link>
            <router-link v-if="isAuthenticated" :to="{ name: 'feed' }" @click="closeMenu">
              Feed
            </router-link>
            <a href="https://info.melon.ooo/en/collections/3119186-getting-started" target="_blank">
              Getting Started
            </a>
            <a href="https://info.melon.ooo/en/articles/5572505-introduction-to-nfts" target="_blank">
              FAQ
            </a>
            <a href="https://info.melon.ooo/en/articles/5606500-how-to-buy-nfts-on-the-polygon-network" target="_blank">
              How to buy NFTs on the Polygon Network
            </a>
            <a href="https://info.melon.ooo/en/articles/5608639-everything-you-need-to-know-about-the-melon-token-coming-soon" target="_blank">
              About the $MELON Token
            </a>
            <a href="https://info.melon.ooo/en/collections/3082913-partner-with-melon" target="_blank">
              Partnerships
            </a>
            <button @click="openIntercomBot">Contact us</button>
            <a href="https://info.melon.ooo/en/collections/3157375-about-melon" target="_blank">
              About Melon
            </a>
          </nav>
        </div>
        <div class="connect-container">
          <ConnectButton v-if="width < 1200" @buttonClick="closeMenu" />
          <Socials class="socials" />
        </div>
      </div>
    </div>
  </div>

  <InfoModal :isOpen="error.isOpen" title="Error" @close="error.isOpen = false">
    <p>{{ error.text }}</p>
  </InfoModal>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import debounce from 'lodash/debounce';

// store
import { useStore } from '@/store';
import { Actions, Modules, Mutations } from '@/store/props';
import { Actions as CommonServiceActions } from '@/store/modules/common-service/props';

// components
import InfoModal from '@/components/InfoModal.vue';
import Suggestions from '@/components/Suggestions.vue';
import ConnectButton from './ConnectButton.vue';
import Socials from './Socials.vue';

// hooks
import useWindowResize from '@/shared/hooks/use-window-resize';

// models
import SearchSuggestions from '@/store/modules/common-service/models/search-suggestions';

// constants
import { ERROR_UNKNOWN } from '@/shared/constants/messages';

export default defineComponent({
  name: 'Header',

  components: {
    Suggestions,
    ConnectButton,
    InfoModal,
    Socials,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { width, height } = useWindowResize();

    const isMainNet = computed(() => store.state.user.isMainNet);
    const networkName = computed(() => process.env.VUE_APP_NETWORK_NAME);
    const banner = computed(() => store.state.banner);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    const isMenuOpen = ref(false);

    const search = ref('');

    const suggestionRef = ref<HTMLElement | null>(null);
    const suggestions = ref<null | SearchSuggestions>(null);
    const showSuggestions = ref<boolean>(false);

    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });

    async function reqSuggestions(): Promise<void> {
      try {
        suggestions.value = await store.dispatch(
          `${Modules.CommonService}/${CommonServiceActions.reqSearchSuggestions}`,
          search.value,
        );
      } catch (e) {
        console.error(e);
      }
    }

    const suggestionsDebounceReq = debounce(reqSuggestions, 400);

    const closeMenu = () => {
      isMenuOpen.value = false;
    };

    const onSearch = () => {
      if (search.value) {
        router.push({ name: 'item-list', query: { search: search.value } });
        closeMenu();
      }
    };

    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    async function switchNetwork() {
      store.commit(Mutations.setLoading, true);
      try {
        await store.dispatch(Actions.switchEthNetwork);
      } catch (err) {
        buildErrorModal(err.message);
      } finally {
        store.commit(Mutations.setLoading, false);
      }
    }

    async function openIntercomBot() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).Intercom) {
        closeMenu();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).Intercom('show');
      }
    }

    function closeDropdownOnBlur(e: FocusEvent): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let parent: any = e.relatedTarget;

      while (parent) {
        if (parent === suggestionRef.value) {
          break;
        }

        parent = parent.parentNode;
      }

      if (!parent) {
        showSuggestions.value = false;
      }
    }

    watch(isMenuOpen, () => {
      document.body.classList.toggle('has-overlay');
    });

    watch(width, (value) => {
      if (isMenuOpen.value && value && value >= 1200) {
        closeMenu();
      }
    });

    watch(() => route.path, () => {
      if (isMenuOpen.value) {
        closeMenu();
      }
    });

    watch(search, (value) => {
      if (value) {
        suggestionsDebounceReq();
      } else {
        suggestionsDebounceReq.cancel();
        suggestions.value = null;
      }
    });

    return {
      isAuthenticated,
      error,
      isMainNet,
      networkName,
      banner,
      width,
      height,
      isMenuOpen,
      search,
      suggestionRef,
      suggestions,
      showSuggestions,
      closeDropdownOnBlur,
      onSearch,
      closeMenu,
      switchNetwork,
      openIntercomBot,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.desktop {
  @include for-xs-sm-md-lg-width {
    display: none;
  }
}

.not-mobile {
  @include for-xs-sm-width {
    display: none;
  }
}

.sticky-container {
  position: sticky;
  top: 0;
  z-index: 900;
  display: flex;
  flex-direction: column;
  background-color: $background-color;
}

.alert-container {
  flex-shrink: 0;
  padding: 0.5rem 0;
  background-color: $primary-1;
  box-shadow: 0px -8px 16px -8px rgba(15, 15, 15, 0.2);

  &.inverted {
    background-color: $primary-1-inverted;
  }

  .alert {
    color: $neutrals-8;
    text-align: center;

    button {
      color: $neutrals-8;
      text-decoration: underline;
    }
  }
}

header {
  flex-shrink: 0;
  height: 90px;
  background-color: $background-color-secondary;
  border-bottom: 1px solid $container-border-color;
  box-shadow: 0px -8px 16px -8px rgba(15, 15, 15, 0.2);
}

.content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  & > * {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.logo-link {
  display: inline-flex;
}

.vertical-split {
  width: 2px;
  height: 40px;
  margin: 0 1rem 0 2rem;
  background: $container-border-color;
}

nav {
  display: flex;
  width: calc(280px - 1.5rem);

  @include for-xs-sm-md-lg-width {
    flex-direction: column;
    align-items: flex-start;
    margin-left: -1rem;
  }

  // stylelint-disable-next-line no-descending-specificity
  a, button {
    @include link;
    padding: 1rem;
    text-align: left;

    &.active {
      color: $secondary-1;
    }
  }
}

.dropdown {
  position: relative;

  .dropdown-content {
    position: absolute;
    display: none;
    flex-direction: column;
    width: 260px;
    padding: 0.5rem;
    background-color: #fff;
    border: 1px solid $container-border-color;
    border-radius: 1rem;
    box-shadow: $depth-4;
  }

  .socials {
    justify-content: center;
    padding: 0.5rem 0.75rem;
  }

  &:hover {
    .dropdown-button {
      color: $secondary-1;
    }

    .dropdown-content {
      display: flex;
    }
  }
}

.search-container {
  @include search-container;

  &.not-mobile {
    width: 256px;
    margin-right: 1.5rem;
  }

  input {
    @include input-common($neutrals-4);
    @include typo-caption-2;

    padding: 8px 2.75rem 8px 1rem;
    border-radius: 20px;
  }

  .icon {
    right: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;

    &.close-icon {
      width: 14px;
      height: 14px;
    }

    img {
      width: 100%;
    }
  }

  .suggestions-wrapper {
    position: absolute;
    top: calc(100% + 40px);
    display: flex;
    justify-content: center;
    width: 100%;

    .suggestions {
      flex-shrink: 0;
      width: 200%;
      padding: 16px;
      background-color: $background-color-secondary;
      border-radius: 16px;
      box-shadow: 0 8px 16px -8px rgba(15, 15, 15, 0.2);

      @include for-sm-md-lg-width {
        width: 150%;
      }
    }
  }
}

.menu-toggle-button {
  padding: 0.5rem;

  @include for-xl-width {
    display: none;
  }

  .hamburger {
    position: relative;
    display: block;
    width: 21px;
    height: 10px;
    content: '';

    .line {
      @include transition-default;
      position: absolute;
      left: 0;
      width: 21px;
      height: 2px;
      background: $neutrals-4;
      border-radius: 4px;

      &:first-child {
        top: 0;
      }

      &:last-child {
        bottom: 0;
      }
    }

    &.open {
      .line:first-child {
        top: 4px;
        transform: rotate(45deg);
      }

      .line:last-child {
        bottom: 4px;
        transform: rotate(-45deg);
      }
    }
  }
}

.menu-panel {
  flex: 1;
  overflow: auto;
  background-color: $background-color-secondary;

  .menu-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 0;
  }

  .search-container {
    margin-bottom: 2rem;

    @include for-md-lg-xl-width {
      display: none;
    }
  }

  .connect-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
  }

  .socials {
    margin-top: 2rem;
  }
}
</style>
