
import {
  defineComponent, ref, toRefs, watch,
} from 'vue';
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: 'ContentLoader',

  components: {
    Loading,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    overlap: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const { loading } = toRefs(props);

    const active = ref<boolean>(loading.value);

    watch(loading, (value) => {
      active.value = value;
    });

    return {
      active,
    };
  },
});
