enum Actions {
  fetchOffChainOffers = 'fetchOffChainOffers',
  fetchOffers = 'fetchOffers',
  fetchBids = 'fetchBids',
  fetchItemOfferOnOffChainOfferAuction = 'fetchItemOfferOnOffChainOfferAuction',
  setItemOfferOnOffChainOfferAuction = 'setItemOfferOnOffChainOfferAuction',
  withdrawItemOfferFromOffChainOfferAuction = 'withdrawItemOfferFromOffChainOfferAuction',
  fetchOffer = 'fetchOffer',
  createItem = 'createItem',
  mintItemOnSale = 'mintItemOnSale',
  mintItemOnEnglishAuction = 'mintItemOnEnglishAuction',
  mintItemOnDutchAuction = 'mintItemOnDutchAuction',
  getItem = 'getItem',
  getItemOEmbedData = 'getItemOEmbedData',
  getItemEnglishAuction = 'getItemEnglishAuction',
  getItemDutchAuction = 'getItemDutchAuction',
  updateItem = 'updateItem',
  setItemForSale = 'setItemForSale',
  updateItemPrice = 'updateItemPrice',
  setItemsForEnglishAuction = 'setItemsForEnglishAuction',
  setItemsForDutchAuction = 'setItemsForDutchAuction',
  subscribeForSale = 'subscribeForSale',
  buyItem = 'buyItem',
  setItemOfferOnEnglishAuction = 'setItemOfferOnEnglishAuction',
  withdrawItemOfferFromEnglishAuction = 'withdrawItemOfferFromEnglishAuction',
  approveItemOfferOnEnglishAuction = 'approveItemOfferOnEnglishAuction',
  withdrawItemFromSelling = 'withdrawItemFromSelling',
  finalizeItemOnEnglishAuction = 'finalizeItemOnEnglishAuction',
  clearItem = 'clearItem',
  getUsdPrice = 'getUsdPrice',
  getSecretCode = 'getSecretCode',
  setRewardAsRedeemed = 'setRewardAsRedeemed',
  fetchItemsStats = 'fetchItemsStats',
}

export default Actions;
