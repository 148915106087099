<template>
  <modal
    v-show="isOpen"
    :has-cancel-button="false"
    @close="$emit('close')"
  >
    <template v-slot:header>
      <h2>{{ title }}</h2>
    </template>

    <template v-slot:body>
      <form
        ref="formRef"
        :id="formId"
        class="inputs-block"
        @submit.prevent="$emit(`set${sellingType}`, saleData)"
      >
        <SaleForm
          :formId="formId"
          :isEditing="isEditing"
          :isCurrencyReadonly="isCurrencyReadonly"
          :required="true"
          :minAmount="minAmount"
          v-model:type="sellingType"
          v-model:currency="saleData.currency"
          v-model:amount="saleData.amount"
          v-model:amountTo="saleData.amountTo"
          v-model:duration="saleData.duration"
          v-model:numberOfIntervals="saleData.numberOfIntervals"
        />
      </form>

      <slot name="body"></slot>
    </template>

    <template v-slot:footer>
      <button
        :form="formId"
        type="submit"
        class="modal-primary-button"
        :disabled="!formRef?.checkValidity() || !isMainNet"
      >{{ submitButtonLabel }}</button>
    </template>
  </modal>
</template>

<script lang="ts">
import {
  computed, defineComponent, PropType, ref, toRefs, watch,
} from 'vue';

import CryptoCurrency from '@/shared/models/crypto-currency.enum';

import { useStore } from '@/store';

import ItemSellingType from '@/store/modules/item-profile/models/item-selling-type';

import Modal from '@/components/Modal.vue';
import SaleForm from '@/components/SaleForm.vue';

export default defineComponent({
  name: 'SaleModal',

  components: {
    Modal,
    SaleForm,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    formId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Sale Modal',
    },
    submitButtonLabel: {
      type: String,
      default: 'Submit',
    },
    initialCurrency: {
      type: String as PropType<CryptoCurrency>,
      default: CryptoCurrency.MATIC,
    },
    initialAmount: {
      type: Number,
      default: 1,
    },
    minAmount: {
      type: Number,
      default: null,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isCurrencyReadonly: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'close',
    'setOnSale',
    'setOnEnglishAuction',
    'setOnDutchAuction',
  ],

  setup(props) {
    const store = useStore();

    const { initialAmount, initialCurrency } = toRefs(props);

    const isMainNet = computed(() => store.state.user.isMainNet);

    const formRef = ref<HTMLFormElement | null>(null);

    const sellingType = ref<ItemSellingType>(ItemSellingType.OnSale);
    const saleData = ref({
      currency: initialCurrency.value,
      amount: initialAmount.value,
      amountTo: 2,
      duration: 60 * 60 * 24,
      numberOfIntervals: 1,
    });

    watch(initialCurrency, (value) => {
      saleData.value.currency = value ?? CryptoCurrency.MATIC;
    });

    watch(initialAmount, (value) => {
      saleData.value.amount = value ?? 1;
    });

    return {
      formRef,
      sellingType,
      saleData,
      isMainNet,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.inputs-block {
  display: flex;
  flex-direction: column;
}

.modal-primary-button {
  @include modal-button-primary-filled;
}
</style>
