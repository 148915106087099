import http from '../instances/http';

// models
import PaginationOptions from '@/shared/models/pagination-options';
// import ListWithTotalResponse from '@/shared/models/list-with-total-response';

import FeedListItem from '@/store/modules/feed/models/feed-list-item';

const FeedApi = {
  async getItems({ page, pageSize }: PaginationOptions, token: string): Promise<FeedListItem[]> {
    // TODO change path
    const res = await http.get('/feed', {
      params: {
        offset: (page - 1) * pageSize,
        pageSize,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
    // return (res.data as ListWithTotalResponse<FeedListItem>).data;
  },
};

export default FeedApi;
