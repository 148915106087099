
import { defineComponent } from 'vue';

import UserImage from '@/components/UserImage.vue';

export default defineComponent({
  name: 'ItemProfileListItem',

  components: {
    UserImage,
  },

  props: {
    userNickname: {
      type: String,
      required: true,
    },
    photoPath: {
      type: String,
      default: null,
    },
    isVerified: {
      type: Boolean,
      default: false,
    },
    isRegistered: {
      type: Boolean,
      default: false,
    },
    hasBottomPadding: {
      type: Boolean,
      default: true,
    },
  },
});
