<template>
  <tr
    class="table-row"
    @click="showInnerRow = !showInnerRow"
  >
    <td class="table-cell" colspan="1">{{ index }}</td>
    <td class="table-cell" colspan="3">
      <div class="table-cell-content">
        <img
          class="table-cell-image"
          :src="userImageUrl(data.creator.profileImage)"
        />
        <router-link
          :to="{ name: 'user-profile', params: { userNickname: data.creator.nickname }}"
        >
          <span class="table-cell-text">{{ data.creator.name }}</span>
        </router-link>
        <Tooltip
          position="is-bottom"
          label="This user is unlinked"
          v-if="!data.creator.isRegistered && !data.creator.isVerified"
        >
          <img src="@/assets/images/common/unregistered.svg">
        </Tooltip>
        <Tooltip
          position="is-bottom"
          label="This user is verified"
          v-else-if="data.creator.isVerified"
        >
          <img src="@/assets/images/common/verified.svg">
        </Tooltip>
      </div>
    </td>
    <td class="table-cell" colspan="6" :title="data.selectedFollowers">
      Total: {{ followersFilters.length ? formatNumber(data.selectedFollowers) : '0' }}
    </td>
    <!-- <td class="table-cell" colspan="1" :title="data.selectedLikes">
      Total: {{ formatNumber(data.selectedLikes) }}
    </td>
    <td class="table-cell" colspan="1" :title="data.selectedViews">
      Total: {{ formatNumber(data.selectedViews) }}
    </td> -->
    <td class="table-cell" colspan="2">{{ formatNumber(data.itemsData.total) }}</td>
    <td class="table-cell" colspan="1">
      <img v-if="!showInnerRow" src="@/assets/images/common/arrow-down.svg">
      <img v-else src="@/assets/images/common/arrow-down.svg" class="rotate-180">
    </td>
    <td class="collapse-toggle">
      <div>
        <p v-if="showInnerRow">Hide details</p>
        <p v-else>Show details</p>
      </div>
      <div class="collapse-toggle-button">
        <img
          v-if="!showInnerRow"
          src="@/assets/images/common/arrow-down.svg"
          alt="arrow down icon"
        />
        <img
          v-else
          src="@/assets/images/common/arrow-down.svg"
          class="rotate-180"
          alt="arrow up icon"
        />
      </div>
    </td>
  </tr>
  <tr v-if="showInnerRow" class="table-row table-inner-row">
    <td colspan="1" class="table-inner-cell"></td>
    <td colspan="3" class="table-inner-cell"></td>
    <td colspan="6" class="table-inner-cell">
      <span class="table-row-title">Followers</span>
      <div
        v-if="
          data.tiktokFollowers
          && (followersFilters === 'All' || followersFilters.includes('TikTok'))
        "
        :title="data.tiktokFollowers">
        <img class="img-social" src="@/assets/images/socials/tiktok-solid.svg">
        <span class="table-cell-text">{{ formatNumber(data.tiktokFollowers) }}</span>
      </div>
      <div
        v-if="
          data.instagramFollowers
          && (followersFilters === 'All' || followersFilters.includes('Instagram'))
        "
        :title="data.instagramFollowers">
        <img class="img-social" src="@/assets/images/socials/instagram.svg">
        <span class="table-cell-text">{{ formatNumber(data.instagramFollowers) }}</span>
      </div>
      <div
        v-if="
          data.twitchFollowers
          && (followersFilters === 'All' || followersFilters.includes('Twitch'))
        "
        :title="data.twitchFollowers">
        <img class="img-social" src="@/assets/images/socials/twitch.svg">
        <span class="table-cell-text">{{ formatNumber(data.twitchFollowers) }}</span>
      </div>
      <div
        v-if="
          data.twitterFollowers
          && (followersFilters === 'All' || followersFilters.includes('Twitter'))
        "
        :title="data.twitterFollowers">
        <img class="img-social" src="@/assets/images/socials/twitter-solid.svg">
        <span class="table-cell-text">{{ formatNumber(data.twitterFollowers) }}</span>
      </div>
      <div
        v-if="
          data.youtubeFollowers
          && (followersFilters === 'All' || followersFilters.includes('Youtube'))
        "
        :title="data.youtubeFollowers">
        <img class="img-social" src="@/assets/images/socials/youtube-solid.svg">
        <span class="table-cell-text">{{ formatNumber(data.youtubeFollowers) }}</span>
      </div>
    </td>
    <!-- <td colspan="1" class="table-inner-cell">
      <span class="table-row-title">Likes</span>
      <p v-if="data.instagramLikes" :title="data.instagramLikes">
        <img class="img-social" src="@/assets/images/socials/instagram.svg">
        {{ formatNumber(data.instagramLikes) }}
      </p>
      <p v-if="data.twitterLikes" :title="data.twitterLikes">
        <img class="img-social" src="@/assets/images/socials/twitter-solid.svg">
        {{ formatNumber(data.twitterLikes) }}
      </p>
    </td>
    <td colspan="1" class="table-inner-cell">
      <span class="table-row-title">Views</span>
      <p v-if="data.tiktokViews" :title="data.tiktokViews">
        <img class="img-social" src="@/assets/images/socials/tiktok-solid.svg">
        {{ formatNumber(data.tiktokViews) }}
      </p>
      <p v-if="data.twitchViews" :title="data.twitchViews">
        <img class="img-social" src="@/assets/images/socials/twitch.svg">
        {{ formatNumber(data.twitchViews) }}
      </p>
      <p v-if="data.youtubeViews" :title="data.youtubeViews">
        <img class="img-social" src="@/assets/images/socials/youtube-solid.svg">
        {{ formatNumber(data.youtubeViews) }}
      </p>
    </td> -->
    <td colspan="2" class="table-inner-cell">
      <span class="table-row-title">NFT Items</span>
      <p>
        Minted {{ formatNumber(data.itemsData.minted) }}
      </p>
      <p>
        On sale {{ formatNumber(data.itemsData.onSale) }}
      </p>
      <p>
        On auction {{ formatNumber(data.itemsData.onAuction) }}
      </p>
      <p>
        Offchain auction {{ formatNumber(data.itemsData.onOffChainAuction) }}
      </p>
    </td>
    <td colspan="1" class="table-inner-cell"></td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';

import { useStore } from '@/store';

import formatNumber from '@/shared/helpers/format-number';
import userImageUrl from '@/shared/helpers/user-image-url';
import UserStatsItem from '@/store/modules/user-list/models/user-stats-item';

export default defineComponent({
  props: {
    data: {
      type: Object as () => UserStatsItem,
    },
    index: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    const store = useStore();

    const showInnerRow = ref<boolean>(false);
    const followersFilters = computed(() => store.state.UserList.stats.filters.followersFilter);

    return {
      showInnerRow,
      formatNumber,
      userImageUrl,
      followersFilters,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.table-row {
  padding: 0.2rem 1rem;
  background-color: $neutrals-9;

  .collapse-toggle {
    display: none;
    color: $neutrals-4;
    font-size: 12px;

    .collapse-toggle-button {
      margin-right: -6px;
    }
  }

  @include for-xs-sm-width {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    border-radius: 8px;

    &:nth-child(3) {
      flex: 0 0 100%;
    }

    .collapse-toggle {
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      justify-content: space-between;
    }
  }

  .table-cell {
    padding: 10px 0;

    &:nth-child(1) {
      padding-left: 20px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:nth-child(5) {
      padding-right: 30px;
      text-align: right;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .table-cell-content {
      display: flex;
      align-items: center;
    }

    .table-cell-text {
      margin-right: 5px;
    }

    .table-cell-image {
      width: 49px;
      height: 47px;
      margin-right: 5px;
      object-fit: cover;
      border-radius: 50%;
    }

    @include for-xs-sm-width {
      &:nth-child(1) {
        padding-left: 0;
      }

      &:nth-child(2) {
        flex: 1 1 auto;
        padding-left: 10px;
      }

      &:nth-child(3),
      // &:nth-child(4),
      // &:nth-child(5),
      &:nth-child(5) {
        display: none;
      }
    }
  }
}

.table-inner-row {
  margin-top: 0;

  @include for-xs-sm-width {
    position: relative;
    top: -8px;
    display: block;
    padding: 1rem 0;
  }

  .img-social {
    width: 20px;
    margin-right: 5px;
  }

  .table-inner-cell {
    position: relative;
    top: -20px;
    padding: 1rem 0;
    line-height: 2;
    vertical-align: baseline;
    background-color: $neutrals-9;
    border-top: 1px solid $neutrals-6;

    div {
      display: flex;
      align-items: center;
    }

    .table-row-title {
      display: none;
      color: $neutrals-3;
    }

    .table-cell-text {
      margin-right: 5px;
    }

    &:nth-child(1) {
      padding-left: 20px;
      border-bottom-left-radius: 8px;
    }

    &:nth-child(5) {
      border-bottom-right-radius: 8px;
    }

    @include for-xs-sm-width {
      display: block;
      padding: 0.5rem 1rem;
      border-top: none;

      div, p {
        display: inline-flex;
        min-width: 33%;
      }

      &:empty {
        display: none;
      }

      .table-row-title {
        display: block;
      }
    }
  }
}

.rotate-180 {
  transform: rotate(180deg);
}
</style>
