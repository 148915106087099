<template>
  <div v-if="data.length" ref="scrollComponent" class="items-container">
    <div v-for="item in data" :key="item.id">
      <FeedItemCard :data="item" />
    </div>
  </div>
  <div v-if="!isItemsLoading && !data.length" class="empty-container">
    <p>No events yet.</p>
  </div>
  <ContentLoader :loading="isItemsLoading"/>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import throttle from 'lodash/throttle';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-list/props';
import PaginationOptions from '@/shared/models/pagination-options';

import ContentLoader from '@/components/ContentLoader.vue';
import FeedItemCard from './Feed/FeedItemCard.vue';

export default defineComponent({
  name: 'Feed',

  components: {
    FeedItemCard,
    ContentLoader,
  },

  setup() {
    const store = useStore();

    const isItemsLoading = ref<boolean>(false);

    const data = computed(() => store.state.Feed.items.data);
    const pagination = computed(() => store.state.Feed.items.pagination);
    const isLastPage = computed(() => store.state.Feed.items.isLastPage);

    const scrollComponent = ref<HTMLElement | null>(null);

    const loadItems = (options: Partial<PaginationOptions>) => {
      isItemsLoading.value = true;
      store.dispatch(`${Modules.Feed}/${Actions.fetchItems}`, { ...options }).finally(() => {
        isItemsLoading.value = false;
      });
    };

    const clearItems = () => {
      store.dispatch(`${Modules.Feed}/${Actions.clearItems}`);
    };

    const handleScroll = () => {
      const element = scrollComponent.value;
      if (element && !isLastPage.value && !isItemsLoading.value
        && element.getBoundingClientRect().bottom < window.innerHeight
      ) {
        loadItems({ page: pagination.value.page + 1 });
      }
    };

    onMounted(() => {
      loadItems({ page: 1 });

      window.addEventListener('scroll', throttle(handleScroll, 800, { leading: false }));
    });

    onUnmounted(() => {
      clearItems();

      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isItemsLoading,
      data,
      pagination,
      scrollComponent,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.items-container {
  @include items-container;

  margin-top: -2rem;
}

.empty-container {
  @include empty-container;
}
</style>
