<template>
  <Header />
  <Banner v-if="showBanner" />
  <main class="main-column-wrapper">
    <slot />
  </main>
  <Footer />
</template>

<script lang="ts">
import { defineComponent, watch, ref } from 'vue';

import { useRoute } from 'vue-router';

// components
import Header from './Header.vue';
import Footer from './Footer.vue';
import Banner from './Banner.vue';

export default defineComponent({
  name: 'MainLayout',

  components: {
    Header,
    Footer,
    Banner,
  },

  setup() {
    const route = useRoute();
    const showBanner = ref<boolean>(false);

    watch(route, (newRoute) => {
      if (newRoute.name === 'home') {
        showBanner.value = true;
      } else {
        showBanner.value = false;
      }
    });

    return {
      showBanner,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/variables';

main {
  flex: 1 0 auto; // sticky footer
  padding-top: 4rem;
  padding-bottom: 4rem;
}
</style>
