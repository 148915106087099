
import {
  defineComponent, ref, computed, watch,
} from 'vue';

// store
import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-profile/props';

// models
import Item from '@/store/modules/item-profile/models/item';
import ItemSaleData from '@/store/modules/item-profile/models/item-sale-data';

// components
import ItemProfileTabs from './ItemProfileTabs.vue';
import ItemProfileListItem from './ItemProfileListItem.vue';
import Markdown from '@/components/Markdown.vue';
import InfoTooltip from '@/components/InfoTooltip.vue';
import SaleModal from '@/components/SaleModal.vue';

import {
  ITEM_SELLING_SET_SUCCESS,
  ERROR_UNKNOWN,
  ACTION_SUCCESS_DEFAULT,
} from '@/shared/constants/messages';

export default defineComponent({
  props: {
    item: {
      type: Object as () => Item,
      required: true,
    },
  },
  components: {
    ItemProfileListItem,
    ItemProfileTabs,
    Markdown,
    InfoTooltip,
    SaleModal,
  },
  setup(props) {
    const store = useStore();

    // refs
    const isLoading = ref<boolean>(false);
    const isOwner = ref<boolean>(false);
    const showMoreDescription = ref<boolean>(false);
    const isEditor = ref<boolean>(false);
    const isPutOnSaleModalOpen = ref<boolean>(false);
    const SHORT_CONTENT_COUNT = ref<number>(90);

    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });
    const success = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });

    // computed
    const currentUserId = computed(() => store.state.user.id);
    const itemDescription = computed(() => (!showMoreDescription.value
      ? props.item?.description.substring(0, SHORT_CONTENT_COUNT.value)
      : props.item?.description));

    // methods
    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    function buildSuccessModal(text: string): void {
      success.value = {
        isOpen: true,
        text: text || ACTION_SUCCESS_DEFAULT,
      };
    }

    async function putItemOnSale(data: ItemSaleData) {
      isPutOnSaleModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.setItemForSale}`, data);
        buildSuccessModal(ITEM_SELLING_SET_SUCCESS);
      } catch (e: any) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function putItemOnEnglishAuction(data: ItemSaleData) {
      isPutOnSaleModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.setItemsForEnglishAuction}`, { ...data, ids: [props.item?.id] });
        buildSuccessModal(ITEM_SELLING_SET_SUCCESS);
      } catch (e: any) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function putItemOnDutchAuction(data: ItemSaleData) {
      isPutOnSaleModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.setItemsForDutchAuction}`, { ...data, ids: [props.item?.id] });
        buildSuccessModal(ITEM_SELLING_SET_SUCCESS);
      } catch (e: any) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    // watch
    watch([props.item, currentUserId], ([value, userId]) => {
      isOwner.value = !!userId && userId === value?.ownerId;
      isEditor.value = isOwner.value && userId === value?.creatorId && !value.isSold;
    });

    return {
      itemDescription,
      showMoreDescription,
      isOwner,
      isEditor,
      isPutOnSaleModalOpen,
      putItemOnSale,
      putItemOnEnglishAuction,
      putItemOnDutchAuction,
    };
  },
});
