<template>
  <div v-show="active" class="loading-container" :style="{ zIndex: overlap ? null : 0 }">
    <div>
      <Loading
        v-model:active="active"
        loader="dots"
        :is-full-page="false"
        background-color="transparent"
        color="#d92b3a"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, ref, toRefs, watch,
} from 'vue';
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: 'ContentLoader',

  components: {
    Loading,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    overlap: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const { loading } = toRefs(props);

    const active = ref<boolean>(loading.value);

    watch(loading, (value) => {
      active.value = value;
    });

    return {
      active,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.loading-container {
  position: relative;
  width: 100%;

  & > * {
    @include data-prefetch-container;
    position: relative; // loader is absolute positioned
  }
}
</style>
