import { AbiItem } from 'web3-utils';
import { Modules } from 'web3';

import { fmWeb3 } from '@/instances/web3';

import mContract from '@/assets/data/contracts/market-contract.json';
import nContract from '@/assets/data/contracts/nft-contract.json';
import eContract from '@/assets/data/contracts/english-auction-contract.json';
import dContract from '@/assets/data/contracts/dutch-auction-contract.json';
import tContract from '@/assets/data/contracts/token-contract.json';

type Contract = InstanceType<InstanceType<Modules['Eth']>['Contract']>;

function createContract(abi: AbiItem[], address?: string) {
  return new fmWeb3.eth.Contract(abi, address);
}

const melonTokenContractAddress = process.env.VUE_APP_MELON_TOKEN_CONTRACT_ADDRESS as string;
const wethTokenContractAddress = process.env.VUE_APP_WETH_TOKEN_CONTRACT_ADDRESS as string;

const marketContract = createContract(mContract as AbiItem[]);
const nftContract = createContract(nContract as AbiItem[]);
const englishAuctionContract = createContract(eContract as AbiItem[]);
const dutchAuctionContract = createContract(dContract as AbiItem[]);
const melonTokenContract = createContract(tContract as AbiItem[], melonTokenContractAddress);
const wethTokenContract = createContract(tContract as AbiItem[], wethTokenContractAddress);

export {
  marketContract,
  nftContract,
  englishAuctionContract,
  dutchAuctionContract,
  melonTokenContract,
  melonTokenContractAddress,
  wethTokenContract,
  wethTokenContractAddress,
  Contract,
};
