<template>
  <div v-if="timeLeft.totalLeft >= 0" class="info-card">
    <h2 class="title">Sale starts in:</h2>
    <div class="timer-wrapper">
      <div class="timer-column">
        <span class="timer-number">{{ timeLeft.days }}</span>
        <span class="timer-description">days</span>
      </div>
      <div class="timer-column">
        <span class="timer-number">{{ timeLeft.hours }}</span>
        <span class="timer-description">hours</span>
      </div>
      <div class="timer-column">
        <span class="timer-number">{{ timeLeft.minutes }}</span>
        <span class="timer-description">minutes</span>
      </div>
      <div class="timer-column">
        <span class="timer-number">{{ timeLeft.seconds }}</span>
        <span class="timer-description">seconds</span>
      </div>
    </div>
    <h3 class="small-title">Don't miss the start</h3>
    <p class="input-description">You will recieve an email when the sale starts</p>
    <input
      ref="emailRef"
      v-model="email"
      type="email"
      required
      placeholder="Enter your email"
      autocomplete="email"
      class="input"
      :class="{ pristine: emailPristine }"
      @blur="emailPristine = false"
    >
    <p
      v-if="!emailRef?.validity.valid && !emailPristine"
      class="error-text"
    >Invalid email</p>
    <button
      class="primary-button"
      :disabled="!emailRef?.validity.valid"
      @click="$emit('subscribe', email)"
    >
      Subscribe to sale start
    </button>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
} from 'vue';

// helpers
import calcTimeDiffSeparately from '@/shared/helpers/calc-time-diff-separately';

// models
import TimeLeftData from '@/shared/models/time-left-data';

export default defineComponent({
  name: 'ItemProfileSaleTimer',
  props: {
    saleDate: {
      type: String,
      required: true,
    },
  },
  emits: ['subscribe'],
  setup(props) {
    const { saleDate } = toRefs(props);
    const timerId = ref<number | null>(null);
    const timeLeft = ref<TimeLeftData>({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      totalLeft: 0,
    });
    const emailRef = ref<HTMLInputElement | null>(null);
    const emailPristine = ref<boolean>(true);
    const email = ref<string>('');

    function updateLeftTime() {
      timeLeft.value = calcTimeDiffSeparately(saleDate.value);

      if (timeLeft.value.totalLeft < 0 && timerId.value) {
        // eslint-disable-next-line
        clearInterval(timerId.value as any);
        timerId.value = null;
      }
    }

    onMounted(() => {
      timerId.value = setInterval(updateLeftTime, 1000) as unknown as number;
      updateLeftTime();
    });

    onUnmounted(() => {
      if (timerId.value) {
        // eslint-disable-next-line
        clearInterval(timerId.value as any);
        timerId.value = null;
      }
    });

    return {
      emailRef,
      emailPristine,
      email,
      timeLeft,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

$info-max-width: 434px;

.info-card {
  @include card;
  @include box-shadow-default;
  @include typo-body-2;

  position: relative;
  display: flex;
  flex-direction: column;
  max-width: $info-max-width;
  padding: 1rem;
  overflow: visible;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  .title {
    @include typo-headline-5;
    color: $neutrals-2;
    font-family: $font-family-heading-accent;
  }

  .timer-wrapper {
    display: flex;
    margin-top: 0.5rem;

    .timer-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-width: 3rem;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      .timer-number {
        @include typo-body-1-bold;
      }

      .timer-description {
        @include typo-body-2;
        margin-top: 0.25rem;
        color: $neutrals-3;
      }
    }
  }

  .small-title {
    @include typo-hairline-2;
    margin-top: 2rem;
    color: $neutrals-3;
  }

  .input-description {
    @include typo-caption;
    margin-top: 0.25rem;
    color: $neutrals-4;
  }

  .input {
    @include input-common;
    @include typo-caption-bold;
    margin-top: 0.25rem;
    padding: 0.75rem 1rem;
    border-radius: 0.75rem;
  }

  .error-text {
    @include typo-caption-2;
    margin-top: 0.25rem;
    color: $primary-1;
  }

  .primary-button {
    @include typo-button-1;
    @include button-primary-filled;

    padding: 13px 1rem;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }
}
</style>
