import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// apis
import AnalyticsApi from '@/api/analytics.api';

// guards
import isAuthenticated from '@/shared/guards/auth.guard';
import selfMintGuard from '@/shared/guards/self-mint.guard';

// pages
import Home from '../views/Home.vue';
import Feed from '../views/account/Feed.vue';

import ItemList from '../views/items/ItemList.vue';
import ItemProfile from '../views/items/ItemProfile.vue';
import CreateItem from '../views/items/CreateItem.vue';
import EditItem from '../views/items/EditItem.vue';

import UserList from '../views/users/UserList.vue';
import UserProfile from '../views/users/UserProfile.vue';
import EditUser from '../views/users/EditUser.vue';
import UserList2 from '../views/users/UserList2.vue';

import TermsAndConditions from '../views/policies/TermsAndConditions.vue';
import PrivacyPolicy from '../views/policies/PrivacyPolicy.vue';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'home',
    path: '/',
    component: Home,
    meta: { title: ['Home'] },
  },
  {
    name: 'feed',
    path: '/feed',
    component: Feed,
    beforeEnter: [isAuthenticated],
    meta: { title: ['Feed'] },
  },
  {
    name: 'item-list',
    path: '/explore',
    component: ItemList,
    meta: { title: ['NFT marketplace'] },
  },
  {
    name: 'item-profile',
    path: '/items/:id',
    component: ItemProfile,
    meta: { title: ['Item', { param: 'id' }] },
  },
  {
    name: 'create-item',
    path: '/items/create',
    component: CreateItem,
    beforeEnter: [selfMintGuard],
    meta: { title: ['Create item'] },
  },
  {
    name: 'edit-item',
    path: '/items/:id/edit',
    component: EditItem,
    meta: { title: ['Edit item', { param: 'id' }] },
  },
  {
    name: 'user-list',
    path: '/community',
    component: UserList2,
    meta: { title: ['Community'] },
  },
  {
    name: 'user-search',
    path: '/search-community',
    component: UserList,
    meta: { title: ['Community'] },
  },
  {
    name: 'user-profile',
    path: '/users/:userNickname',
    component: UserProfile,
    meta: { title: ['Profile', { param: 'userNickname' }] },
  },
  {
    name: 'edit-user',
    path: '/users/:userNickname/edit',
    component: EditUser,
    meta: { title: ['Edit profile'] },
  },
  {
    name: 'terms-and-conditions',
    path: '/terms-and-conditions',
    component: TermsAndConditions,
    meta: { title: ['Terms and Conditions'] },
  },
  {
    name: 'privacy-policy',
    path: '/privacy-policy',
    component: PrivacyPolicy,
    meta: { title: ['Privacy policy'] },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }

    return { top: 0 };
  },
});

router.beforeEach((toRoute, fromRoute, next) => {
  const titleParts = ['MELON'];
  if (toRoute.meta?.title) {
    // eslint-disable-next-line
    for (const part of (toRoute.meta.title as any[])) {
      titleParts.push(part.param ? toRoute.params[part.param] : part);
    }
  }
  window.document.title = titleParts.join(' - ');
  next();
});

router.afterEach((to, from) => {
  AnalyticsApi.callScreenView(from);
});

export default router;
