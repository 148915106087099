
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  toRefs,
  watch,
} from 'vue';
import Toggle from '@vueform/toggle';
import debounce from 'lodash/debounce';

import CryptoCurrency from '@/shared/models/crypto-currency.enum';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-profile/props';
import ItemSellingType from '@/store/modules/item-profile/models/item-selling-type';

import Select from './Select.vue';

export default defineComponent({
  name: 'SaleForm',

  components: {
    Toggle,
    Select,
  },

  props: {
    formId: {
      type: String,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      default: ItemSellingType.OnSale,
    },
    currency: {
      default: CryptoCurrency.MATIC,
    },
    isCurrencyReadonly: {
      type: Boolean,
      default: false,
    },
    amount: {
      default: 1,
    },
    minAmount: {
      type: Number,
      default: null,
    },
    amountTo: {
      default: 2,
    },
    duration: {
      type: Number,
      default: null,
    },
    numberOfIntervals: {
      default: null,
    },
    hasAmountToVisible: {
      default: false,
    },
  },

  emits: [
    'update:type',
    'update:currency',
    'update:amount',
    'update:fiat',
    'update:amountTo',
    'update:duration',
    'update:numberOfIntervals',
    'update:hasAmountToVisible',
  ],

  setup(props, { emit }) {
    const store = useStore();

    const {
      currency: cryptoCurrency,
    } = toRefs(props);

    const cryptoAmount = computed({
      get: () => props.amount,
      set: debounce((value) => {
        emit('update:amount', value);
      }, 400),
    });

    const cryptoAmountTo = computed({
      get: () => props.amountTo,
      set: debounce((value) => {
        emit('update:amountTo', value);
      }, 400),
    });

    const numberOfIntervalsRef = ref<HTMLInputElement | null>(null);
    const cryptoAmountRef = ref<HTMLInputElement | null>(null);
    const cryptoAmountToRef = ref<HTMLInputElement | null>(null);
    const fiatAmount = ref<string | null>('');
    const fiatAmountTo = ref<string | null>('');

    async function getUsdPrice(amount: number, currency: CryptoCurrency): Promise<string | null> {
      return store.dispatch(`${Modules.ItemProfile}/${Actions.getUsdPrice}`, { amount, currency });
    }

    async function getFiatAmount(
      fromEl: HTMLInputElement | null,
      amount: number,
      currency: CryptoCurrency,
    ) {
      return fromEl?.validity.valid ? getUsdPrice(amount, currency) : '0';
    }

    async function setFiatAmount(amount: number, currency: CryptoCurrency) {
      fiatAmount.value = await getFiatAmount(cryptoAmountRef.value, amount, currency);
      emit('update:fiat', fiatAmount.value);
    }

    async function setFiatAmountTo(amount: number, currency: CryptoCurrency) {
      fiatAmountTo.value = await getFiatAmount(cryptoAmountToRef.value, amount, currency);
    }

    watch(cryptoCurrency, (value) => {
      setFiatAmount(cryptoAmount.value, value);
      setFiatAmountTo(cryptoAmountTo.value, value);
    });

    watch(cryptoAmount, (value) => {
      setFiatAmount(value, cryptoCurrency.value);
    });

    watch(cryptoAmountTo, (value) => {
      setFiatAmountTo(value, cryptoCurrency.value);
    });

    watch(cryptoAmountToRef, (value) => {
      if (value) {
        setFiatAmountTo(cryptoAmountTo.value, cryptoCurrency.value);
      }
    });

    onMounted(() => {
      setFiatAmount(cryptoAmount.value, cryptoCurrency.value);
      setFiatAmountTo(cryptoAmountTo.value, cryptoCurrency.value);
    });

    const sellingTypeOptions = [
      {
        id: ItemSellingType.OnSale,
        label: 'Fixed price',
      },
      {
        id: ItemSellingType.OnEnglishAuction,
        label: 'English auction',
      },
      // {
      //   id: ItemSellingType.OnDutchAuction,
      //   label: 'Dutch auction',
      // },
    ];

    const currencyOptions = [
      {
        id: CryptoCurrency.MATIC,
        label: 'MATIC',
      },
      {
        id: CryptoCurrency.WETH,
        label: 'ETH on Polygon',
      },
    ];

    const durationOptions = [
      { label: '1 day', id: 60 * 60 * 24 },
      { label: '5 days', id: 60 * 60 * 24 * 5 },
      { label: '1 week', id: 60 * 60 * 24 * 7 },
      { label: '2 weeks', id: 60 * 60 * 24 * 7 * 2 },
      { label: '3 weeks', id: 60 * 60 * 24 * 7 * 3 },
      { label: '4 weeks', id: 60 * 60 * 24 * 7 * 4 },
    ];

    return {
      cryptoAmount,
      cryptoAmountTo,
      fiatAmount,
      fiatAmountTo,
      numberOfIntervalsRef,
      cryptoAmountRef,
      cryptoAmountToRef,
      sellingTypeOptions,
      currencyOptions,
      durationOptions,
      CryptoCurrency,
      ItemSellingType,
    };
  },
});
