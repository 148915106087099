// helpers
import unpackToken from '@/shared/helpers/unpack-token';

// models
import TokenFieldAlias from '@/shared/models/token-field-alias.enum';
import PaginationOptions from '@/shared/models/pagination-options';

import CurrentUserInfo from '@/store/models/current-user-info';

import User from '@/store/modules/user-profile/models/user';
import UserPersonalInfo from '@/store/modules/user-profile/models/user-personal-info';
import ItemListItem from '@/store/modules/item-list/models/item-list-item';
import SocialFollowersStats from '@/store/modules/user-profile/models/social-followers-stats';
import UsersStats from '@/store/modules/user-profile/models/users-stats';

import http from '../instances/http';

const UserProfileApi = {
  async getUser(nickname: string, token: string | null): Promise<User> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const res = await http.get(`/profile/by-nickname/${nickname}`, { headers });
    return res.data;
  },

  async checkIsUserFollowing(userId: string, token: string): Promise<boolean> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const res = await http.get('/profile/follow-status', { headers, params: { userId } });
    return res.data;
  },

  async getCurrentUser(token: string): Promise<CurrentUserInfo> {
    const usedId = unpackToken(token)[TokenFieldAlias.id];
    const res = await http.get(`/profile/current/${usedId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },

  async updateUser(user: UserPersonalInfo, token: string): Promise<null> {
    const body = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(user)) {
      body.push({
        op: 'replace',
        path: `/${key}`,
        value: user[key],
      });
    }

    const res = await http.patch('/profile', body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json-patch+json',
      },
    });
    return res.data;
  },

  async updateUserFile(userId: string, path: string, file: File, token: string): Promise<null> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const res = await http.put(`/profile/${userId}/${path}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  },

  async getUserItems(
    userId: string,
    { page, pageSize }: PaginationOptions,
    path: string,
  ): Promise<ItemListItem[]> {
    const params = {
      offset: (page - 1) * pageSize,
      pageSize,
    };

    const res = await http.get(`/profile/${userId}/${path}`, { params });
    return res.data;
  },

  // TODO recheck api
  async followUser(userId: string, token: string): Promise<void> {
    const res = await http.post(`/profile/${userId}/follow`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },

  async unfollowUser(userId: string, token: string): Promise<void> {
    const res = await http.post(`/profile/${userId}/unfollow`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },

  async getSocialFollowersStats(): Promise<SocialFollowersStats> {
    const res = await http.get('/socials-followers-stats');
    return res.data;
  },

  async getGlobalUsersStats(): Promise<UsersStats> {
    const res = await http.get('/melon-users-stats');
    return res.data;
  },
};

export default UserProfileApi;
