
import { defineComponent, ref } from 'vue';

import formatNumber from '@/shared/helpers/format-number';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const tiktokIcon = require('@/assets/images/socials/tiktok-solid.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const instagramIcon = require('@/assets/images/socials/instagram.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const twitterIcon = require('@/assets/images/socials/twitter-solid.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const youtubeIcon = require('@/assets/images/socials/youtube-solid.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const twitchIcon = require('@/assets/images/socials/twitch.svg');

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'changed',
  ],
  setup(props) {
    const checked = ref<boolean>(props.isChecked);

    const getIcon = (icon: string) => {
      switch (icon) {
        case 'TikTok':
          return tiktokIcon;
        case 'Instagram':
          return instagramIcon;
        case 'Twitter':
          return twitterIcon;
        case 'Youtube':
          return youtubeIcon;
        case 'Twitch':
          return twitchIcon;
        default:
          return '';
      }
    };

    return {
      getIcon,
      formatNumber,
      checked,
    };
  },
});
