
import { computed, defineComponent } from 'vue';

import Select from '@/components/Select.vue';
import RewardStatus from '@/shared/models/reward-status.enum';

export default defineComponent({
  name: 'ItemTypeSelect',

  props: {
    modelValue: {
      default: null,
    },
    id: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    withDefaultOption: {
      type: Boolean,
      default: false,
    },
    isFormElement: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Select,
  },

  emits: [
    'update:modelValue',
  ],

  setup(props, { emit }) {
    const selectValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const data = Object.keys(RewardStatus).map((status) => ({ id: status, label: status }));

    return {
      selectValue,
      data,
    };
  },
});
