import { Module } from 'vuex';

// apis
import FeedApi from '@/api/feed.api';

// global
import GlobalState from '@/store/models/global-state';

// shared
import ListState from '@/shared/models/list-state';
import PaginationOptions from '@/shared/models/pagination-options';

// module types
import { Actions, Mutations } from './props';

// models
import FeedListItem from './models/feed-list-item';
import FeedState from './models/feed-state';
import { USER_NOT_AUTHENTICATED } from '@/shared/constants/messages';

const ITEMS_PER_PAGE = 12;

const MarketItemList: Module<FeedState, GlobalState> = {
  namespaced: true,

  state: () => ({
    namespaced: true,

    items: {
      data: [],
      pagination: {
        page: 1,
        pageSize: ITEMS_PER_PAGE,
      },
      isLastPage: false,
    },
  }),

  mutations: {
    [Mutations.setItems](
      state: FeedState,
      {
        data, pagination, isLastPage,
      }: Partial<ListState<FeedListItem>>,
    ): void {
      state.items.data = data ?? [];
      state.items.pagination.page = pagination?.page ?? 1;
      state.items.pagination.pageSize = pagination?.pageSize ?? ITEMS_PER_PAGE;
      state.items.isLastPage = isLastPage ?? false;
    },
  },

  actions: {
    async [Actions.fetchItems](
      { state, commit, rootState },
      { page, pageSize }: Partial<PaginationOptions>,
    ): Promise<void> {
      let {
        data, pagination, isLastPage,
      } = state.items;
      const { token } = rootState.session;

      const isPaginationSchemeChanged = pageSize !== undefined && pageSize !== pagination.pageSize;

      if (!token) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      pagination = {
        page: isPaginationSchemeChanged ? 1 : (page ?? pagination.page),
        pageSize: pageSize ?? pagination.pageSize,
      };

      if (isPaginationSchemeChanged) {
        data = [];
        isLastPage = false;
      }

      if (!isLastPage) {
        const dataPerPage = await FeedApi.getItems(pagination, token);

        data.push(...dataPerPage);
        isLastPage = dataPerPage.length < pagination.pageSize;
      }

      commit(Mutations.setItems, {
        data, pagination, isLastPage,
      });
    },

    [Actions.clearItems]({ commit }): void {
      commit(Mutations.setItems, {});
    },
  },
};

export default MarketItemList;
