<template>
  <article>
    <h2>Terms & Conditions</h2>

    <h4>USER AGREEMENT</h4>

    <p>Last Updated: 26 August 2021</p>

    <p>
      This User Agreement (the <strong><em>&quot;Agreement&quot;</em></strong
      >) has been established by Melon to govern the Services. By accessing and
      using our website and the Platform (collectively, the
      <strong><em>&quot;Services&quot;</em></strong
      >), you will be deemed to be a
      <strong><em>&quot;User&quot;</em></strong> who is legally bound by this
      Agreement. If User is an individual, User is of legal age in the
      jurisdiction in which User resides (and in any event is older than
      thirteen years of age) and is of sound mind. If User is a business entity,
      User is duly organized, validly existing and in good standing under the
      laws of the jurisdiction in which it is organized, and has all requisite
      power and authority for a business entity of its type to carry on its
      business as now conducted.
    </p>

    <p>
      <strong><u>Please read this Agreement in full carefully as it governs
      your use of the Services and establishes a legally binding contract
      between you,and Melon.</u></strong>
      Particular attention must be given to Sections 3 (<strong>Fees; No
      Refunds</strong>), 6 (<strong>Limitations of Liability; Disclaimers;
      Risks</strong>) and 7 (<strong>Governing Law; Dispute Resolution</strong>).
      By making use of the Services, you acknowledge and agree that you
      understand how NFTs work, that you are aware of the associated risks
      (including but not limited to the inherent risks in blockchain
      technology), and that you shall assume all risks related to your use of
      Services that Melon shall not be liable for any such risks or adverse
      outcomes.
    </p>

    <ol class="index">
      <li>
        <router-link to="#tc-definitions">
          Definitions
        </router-link>
      </li>
      <li>
        <router-link to="#tc-general">
          General
        </router-link>
      </li>
      <li>
        <router-link to="#tc-fees">
          FEES; NO REFUNDS
        </router-link>
      </li>
      <li>
        <router-link to="#tc-prohibited-uses">
          PROHIBITED USES
        </router-link>
      </li>
      <li>
        <router-link to="#tc-warranties">
          REPRESENTATIONS AND WARRANTIES OF MELON TOKEN RECIPIEN
        </router-link>
      </li>
      <li>
        <router-link to="#tc-disclaimers">
          LIMITATIONS OF LIABILITY; DISCLAIMERS; RISKS
        </router-link>
      </li>
      <li>
        <router-link to="#tc-governing-law">
          GOVERNING LAW; DISPUTE RESOLUTION
        </router-link>
      </li>
      <li>
        <router-link to="#tc-miscellaneous">
          MISCELLANEOUS
        </router-link>
      </li>
    </ol>

    <h3 id="tc-definitions">Definitions</h3>

    <p>
      <strong><em>&quot;NFT&quot;</em></strong> means a public blockchain based
      token complying with the BEP-20 standard or other similar
      <strong><em>&quot;non-fungible&quot;</em></strong> token standard. NFTs
      are intended to be
      <strong><em>&quot;non-fungible&quot;</em></strong> tokens representing a
      collectible item, including, but not limited to: visual works,
      audio-visual works, animations, audio, photographs, 3D works, GIFS, and
      other creative digital works.
    </p>

    <p>
      <strong><em>&quot;Melon&quot;</em></strong
      >, <strong><em>&quot;we&quot;</em></strong
      >, <strong><em>&quot;us&quot;</em></strong> or
      <strong><em>&quot;our&quot;</em></strong> means Melon Technology Inc., a
      company registered in the British Virgin Islands with its address at
      Intershore Chambers, Road Town, Tortola, British Virgin Islands.
    </p>

    <p>
      <strong><em>&quot;Melon Platform&quot;</em></strong> or
      <strong><em>&quot;Platform&quot;</em></strong> means Melon’s NFT
      marketplace platform where Users may purchase and sell NFTs.
    </p>

    <h3 id="tc-general">General</h3>

    <p>
      <strong>User Responsibility for Accounts.</strong> The User is responsible
      for its Platform, blockchain and any other accounts and/or addresses
      through which a User interacts with the Services, and that their use
      thereof complies with this Agreement. Users are also responsible for
      protecting their Platform login information and passwords or the private
      keys controlling the relevant blockchain accounts or addresses used to
      interact with the Services.
    </p>

    <p>
      <strong>Your Access to Services and Features.</strong> Melon may terminate
      a User’s access to the Platform at any time without notice for violation
      of this Agreement or for any other reason based on our sole discretion.
      Melon shall have the right at any time to change or discontinue any or all
      aspects or features of the Services.
    </p>

    <p>
      <strong>Content Disclaimer.</strong> All content on the Platform is
      created by the Users. Melon makes no representations or warranties as to
      the quality, origin, or ownership of any content found in the Services.
      Melon shall not be liable for any errors, misrepresentations, or omissions
      related to the content, nor for its availability. Melon shall not be
      liable for any losses, injuries, or damages from the purchase, inability
      to purchase, display, or use of content.
    </p>

    <p>
      <strong>Monitoring.</strong> Melon shall have the right, but not the
      obligation, to monitor the content of the Services, to determine
      compliance with this Agreement and any rules established by Melon and to
      satisfy any law, regulation or authorized government request.
    </p>

    <p>
      <strong>Copyright Notice.</strong>
      <strong><em>&quot;Melon&quot;</em></strong> and its logos are trademarks
      of Melon. All rights reserved. All other trademarks appearing in the
      Services are the property of their respective owners.
    </p>

    <p>
      <strong>Privacy Policy.</strong> To access the Services, a User must
      explicitly consent to Melon’s privacy and data security practices, which
      can be found by visiting our privacy policy at [insert link to privacy
      policy.
    </p>

    <h3 id="tc-fees">FEES; NO REFUNDS</h3>

    <p>
      <strong>Fees.</strong> Creating, buying, selling or transferring NFTs may
      be subject to fees, commissions, royalties and other charges (<strong
        ><em>&quot;Fees&quot;</em></strong
      >) established from time to time in the sole discretion of Melon, a NFT
      creator or participants in the relevant blockchain technology. Platform
      fees are paid in the cryptocurrency with which the transaction takes
      place. Gas fees will be paid in the network appropriate token. Any other
      fees may be paid or payable solely in the manner determined in the sole
      discretion of Melon.
    </p>

    <p>
      <strong>No Refunds.</strong> All transactions involving the Services are
      final. All Fees relating are non-refundable except at the sole discretion
      of Melon (for service fees and other fees within its control) or
      applicable third parties (such as NFTs creators).
    </p>

    <h3 id="tc-prohibited-uses">PROHIBITED USES</h3>

    <p>
      <strong>Acceptable Uses.</strong> The Services are intended exclusively
      for lawful consumer entertainment and artistic purposes (the
      <strong><em>&quot;Permitted Uses&quot;</em></strong
      >).
    </p>

    <p>
      <strong>Prohibited Uses.</strong> As a User you must not directly or
      indirectly:
    </p>

    <ol class="letter">
      <li>commit any violation of applicable laws, rules or regulations;</li>
      <li>
        engage in any act, practice or course of business that operates or would
        operate as a fraud or deceit upon Melon or other Users, such as
        impersonating or assuming any false identity;
      </li>
      <li>
        violate, breach or fail to comply with any applicable provision of this
        Agreement or any other terms of service, privacy policy, or other
        contract governing the use of any the Services or any relevant NFTs;
      </li>
      <li>
        use the Platform by or on behalf of a competitor of Melon or competing
        platform or service for the purpose of interfering with the Services to
        obtain a competitive advantage;
      </li>
      <li>
        engage or attempt to engage in or assist any hack of or attack on Melon
        Platform or another User;
      </li>
      <li>
        create, upload, buy, sell or use any NFT in a manner infringing the
        copyright, trademark, patent, trade secret or other intellectual
        property or other proprietary rights of others, or upload, or otherwise
        make available, files that contain images, photographs, software or
        other material protected by intellectual property laws (including,
        copyright or trademark laws) or rights of privacy or publicity, unless
        the applicable User is lawfully authorized to do so (for example, by
        obtaining the necessary consents);
      </li>
      <li>
        defame, abuse, harass, stalk, threaten or otherwise violate the legal
        rights of others;
      </li>
      <li>
        publish, post, distribute or disseminate any profane, obscene,
        pornographic indecent or unlawful content;
      </li>
      <li>
        engage in or knowingly facilitate any
        <strong><em>&quot;front-running&quot;</em></strong
        >, <strong><em>&quot;wash trading&quot;</em></strong
        >, <strong><em>&quot;pump and dump trading&quot;</em></strong
        >, <strong><em>&quot;ramping&quot;</em></strong
        >, <strong><em>&quot;cornering&quot;</em></strong> or fraudulent,
        deceptive or manipulative trading activities.
      </li>
    </ol>

    <p>
      The foregoing prohibited conduct may be referred to as
      <strong><em>&quot;Prohibited Uses&quot;</em></strong
      >.
    </p>

    <h3 id="tc-warranties">
      REPRESENTATIONS AND WARRANTIES OF MELON TOKEN RECIPIENT
    </h3>

    <p>
      Melon may launch its own crypto-token (<strong
        ><em>&quot;Melon Token&quot;</em></strong
      >) in the future. As such, each User hereby represents and warrants to
      Melon the following:
    </p>

    <ol class="letter">
      <li>
        Compliance with law; Sanctions. User agreeing to these Term and buying,
        selling holding, using or receiving Melon Tokens does not constitute,
        and would not reasonably be expected to result in a breach of any law,
        contract or other obligations applicable to the User, including any
        applicable sanctions, such as those maintained by the U.N. Security
        Council, the United States, the European Union or its member states, and
        the government of the User’s country.
      </li>
      <li>
        No Reliance. User represents that they are knowledgeable and experienced
        in the field of blockchain, crypto-assets and any other matters that may
        be relevant to the their use of the Services. User has not relied upon
        any information, statement, omission, representation or warranty except
        as expressly set forth by Melon in this Agreement.
      </li>
    </ol>

    <h3 id="tc-disclaimers">LIMITATIONS OF LIABILITY; DISCLAIMERS; RISKS</h3>

    <p>
      <strong>NO CONSEQUENTIAL, INCIDENTAL OR PUNITIVE DAMAGES.</strong>
      NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THIS AGREEMENT,
      MELON SHALL NOT BE LIABLE TO ANY PERSON, WHETHER IN CONTRACT, TORT
      (INCLUDING PURSUANT TO ANY CAUSE OF ACTION ALLEGING NEGLIGENCE), WARRANTY
      OR OTHERWISE, FOR SPECIAL, INCIDENTAL, CONSEQUENTIAL, INDIRECT, PUNITIVE
      OR EXEMPLARY DAMAGES (INCLUDING BUT NOT LIMITED TO LOST DATA, LOST PROFITS
      OR SAVINGS, LOSS OF BUSINESS OR OTHER ECONOMIC LOSS) ARISING OUT OF OR
      RELATED TO THIS AGREEMENT, WHETHER OR NOT MELON HAS BEEN ADVISED OR KNEW
      OF THE POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF THE NATURE OF THE
      CAUSE OF ACTION OR THEORY ASSERTED.
    </p>

    <p>
      <strong>LIMITATION OF LIABILITY.</strong> MELON’S LIABILITY FOR DAMAGES TO
      EACH USER SHALL IN ALL CASES BE LIMITED TO, AND UNDER NO CIRCUMSTANCES
      SHALL EXCEED, MELON’S SERVICE FEES ACTUALLY RECEIVED BY MELON FROM SUCH
      USER.
    </p>

    <p>
      <strong>DISCLAIMER OF REPRESENTATIONS.</strong> THE SERVICES ARE BEING
      PROVIDED ON AN <strong><em>&quot;AS IS&quot;</em></strong> AND
      <strong><em>&quot;AS AVAILABLE&quot;</em></strong> BASIS. TO THE FULLEST
      EXTENT PERMITTED BY LAW, MELON DISCLAIMS, ANY AND ALL INFORMATION,
      STATEMENTS, OMISSIONS, REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED,
      WRITTEN OR ORAL, EQUITABLE, LEGAL OR STATUTORY, IN CONNECTION WITH THE
      SERVICES AND THE OTHER MATTERS CONTEMPLATED BY THIS AGREEMENT, INCLUDING
      ANY REPRESENTATIONS OR WARRANTIES OF TITLE, NON-INFRINGEMENT,
      MERCHANTABILITY, USAGE, SECURITY, UPTIME, RELIABILITY, SUITABILITY OR
      FITNESS FOR ANY PARTICULAR PURPOSE, WORKMANSHIP OR TECHNICAL QUALITY OF
      ANY CODE OR SOFTWARE USED IN OR RELATING TO THE SERVICES. USER
      ACKNOWLEDGES AND AGREES THAT USE OF THE SERVICES IS AT THE USER’S OWN
      RISK.
    </p>

    <p>
      <strong>No Responsibility for NFTs.</strong> Melon has no responsibility
      for the NFTs created or traded by Users on Melon Platform. Melon merely
      provides a marketplace for such NFTs and as such does cannot guarantee or
      warrant the authenticity, originality, uniqueness, marketability, legality
      or value of any individual NFT created or traded by Users.
    </p>

    <p>
      <strong>Time Bars on Claims.</strong> Any claim against Melon made by a
      User in connection with the Services or this Agreement is forever barred
      unless made on the earlier of (a) the expiration of the applicable statute
      of limitations or (b) six months after the date on which the cause of
      action first arose.
    </p>

    <p>
      <strong>Third-Party Services and Content.</strong> User further
      acknowledges and agrees that Melon shall not be responsible or liable,
      directly or indirectly, for any damage or loss caused or alleged to be
      caused by or in connection with use of or reliance on any such resources,
      products, services, content or promotions from third parties. For the
      avoidance of doubt, such third-party services include any add-ons,
      experiences or other promises offered by sellers of NFTs (such as
      meet-and-greets, gifts, etc.).
    </p>

    <p>
      <strong>Certain Uses and Risks of Blockchain Technology and NFTs.</strong>
      You understand and agree that your access and use of the Services is
      subject to certain risks including, without limitation:
    </p>

    <ol class="letter">
      <li>
        You do not have any ownership of the underlying content (such as a
        video, image, etc.) that is associated with a NFT (for example, if you
        buy an NFT associated with a piece of digital artwork, you do not own
        the artwork itself; rather, you own the NFT associated with the
        artwork);
      </li>
      <li>
        Price and liquidity of blockchain assets, including the NFTs, are
        extremely volatile and may be subject to fluctuations;
      </li>
      <li>
        Fluctuations in the price of other digital assets could materially and
        adversely affect the NFTs;
      </li>
      <li>
        Legislative and regulatory changes or actions may adversely affect the
        use, transfer, and value of the NFTs;
      </li>
      <li>NFTs are not legal tender and are not back by any government;</li>
      <li>
        Transactions involving NFTs may be irreversible, and losses due to
        fraudulent or accidental transactions may not be recoverable;
      </li>
      <li>
        The value of NFTs may be derived from the continued willingness of
        market participants to exchange fiat currency or digital assets for
        NFTs, and therefore the value of NFTs is subject to the potential for
        permanent or total loss of value should the market for NFTs disappe
      </li>
      <li>
        NFTs are subject to the risk of fraud, counterfeiting, cyber attacks and
        other technological difficulties which may prevent access to or use of
        your NFTs; and
      </li>
      <li>
        Withdrawal of an NFT to a decentralised digital asset wallet is at your
        own risk, and the transfer for any NFT to an incorrect digital asset
        wallet address will result in the irreversible loss of such NFT.
      </li>
    </ol>

    <p>
      <strong>Legal Limitations on Disclaimers.</strong> Some of the disclaimers
      and limitations set forth in this Agreement may not apply in full to
      certain Users based on the legal requirements of their local jurisdiction.
      The disclaimers and limitations of liability provided in these terms shall
      apply to the fullest extent permitted by applicable law.
    </p>

    <p>
      <strong>Officers, Directors, Etc.</strong> All provisions of this
      Agreement which disclaim or limit obligations or liabilities of Melon
      shall also apply, mutatis mutandis, to the officers, directors, members,
      employees, independent contractors, agents, stockholders, debtholders and
      affiliates of Melon.
    </p>

    <p>
      <strong>Indemnification.</strong> Each User shall defend, indemnify,
      compensate, reimburse and hold harmless Melon (and each of its officers,
      directors, members, employees, agents and affiliates) from any claim,
      demand, action, damage, loss, cost or expense, including without
      limitation reasonable attorneys’ fees, arising out or relating to (a)
      User’s use of, or conduct in connection with, the Services; (b) User’s
      violation of this Agreement or any other applicable policy or contract of
      Melon; or (c) your violation of any rights of any other person or entity.
    </p>

    <h3 id="tc-governing-law">GOVERNING LAW; DISPUTE RESOLUTION</h3>

    <p>
      <strong>Governing law.</strong> These Agreement shall be governed by and
      construed and interpreted in accordance with the laws of the British
      Virgin Islands irrespective of choice of laws principles. Although the
      Services may be available in other jurisdictions, each User hereby
      acknowledges and agrees that such availability shall not be deemed to give
      rise to general or specific personal jurisdiction over Melon in any forum
      outside the British Virgin Islands.
    </p>

    <p>
      <strong>Dispute resolution.</strong> If a User has a potential legal
      dispute, claim or cause of action against Melon, prior to initiating any
      litigation the User must contact Melon by sending an email to
      legal@melon.ooo describing the nature of the potential dispute, claim or
      cause of action and providing all relevant documentation and evidence
      thereof. If so elected by Melon, User shall use commercially reasonable
      efforts to negotiate a settlement of any such legal dispute, claim or
      cause of action within 60 days of the delivery of such email.
    </p>

    <p>
      <strong>Copyright Infringement Claims.</strong> Melon will respond to
      notices of alleged copyright infringement. If a User or other person
      believes that their intellectual property rights have been infringed,
      please notify us immediately per our takedown request policy available
      here.
    </p>

    <h3 id="tc-miscellaneous">MISCELLANEOUS</h3>

    <p>
      <strong>Headings.</strong> The headings and captions contained in this
      Agreement are for convenience of reference only, shall not be deemed to be
      a part of this Agreement and shall not be referred to in connection with
      the construction or interpretation of this Agreement.
    </p>

    <p>
      <strong>Successors and Assigns.</strong> These Agreement shall inure to
      the benefit of Melon, the Users, and their respective permitted
      successors, permitted assigns, permitted transferees and permitted
      delegates. A User shall not assign any of a User rights or delegate any of
      a User liabilities or obligations under this Agreement to any other person
      without Melon’s advance written consent. Melon may freely assign, transfer
      or delegate its rights, obligations and liabilities under this Agreement
      to the maximum extent permitted by applicable law.
    </p>

    <p>
      <strong>Severability.</strong> In the event that any provision of this
      Agreement, or the application of any such provision to any person or set
      of circumstances, shall be determined by an arbitrator or court of
      competent jurisdiction to be invalid, unlawful, void or unenforceable to
      any extent: (a) the remainder of this Agreement, and the application of
      such provision to persons or circumstances other than those as to which it
      is determined to be invalid, unlawful, void or unenforceable, shall not be
      impaired or otherwise affected and shall continue to be valid and
      enforceable to the fullest extent permitted by law; and (b) Melon shall
      have the right to modify this Agreement so as to effect the original
      intent of the parties as closely as possible in an acceptable manner in
      order that the transactions contemplated hereby be consumed as originally
      contemplated to the fullest extent possible.
    </p>

    <p>
      <strong>Force Majeure.</strong> Melon shall not incur any liability or
      penalty for not performing any act or fulfilling any duty or obligation
      hereunder or in connection with the matters contemplated hereby by reason
      of any occurrence that is not within its control (including any provision
      of any present or future law or regulation or any act of any governmental
      authority, any act of God or war or terrorism, any epidemic or pandemic,
      or the unavailability, disruption or malfunction of the Internet, the
      World Wide Web or any other electronic network, the relevant blockchain or
      any aspect thereof, or any consensus attack, or hack, or denial-of-service
      or other attack on the foregoing or any aspect thereof, or on the other
      software, networks and infrastructure that enables Melon to provide the
      Services), it being understood that Melon shall use commercially
      reasonable efforts, consistent with accepted practices in the industries
      in which Melon operates, as applicable, to resume performance as soon as
      reasonably practicable under the circumstances.
    </p>

    <p>
      <strong>Amendments.</strong> These Agreement may only be amended,
      modified, altered or supplemented by or with the written consent of Melon.
      Melon reserves the right, in its sole and absolute discretion, to amend,
      modify, alter or supplement this Agreement from time to time. The most
      current version of this Agreement will be posted on Melon’s website. Any
      changes or modifications will be effective immediately upon the modified
      Agreement being posted to Melon’s website. A User shall be responsible for
      reviewing and becoming familiar with any such modifications. A User hereby
      waive any right a User may have to receive specific notice of such changes
      or modifications. Use of the Services by a User after any modification of
      this Agreement constitutes a User acceptance of the modified terms and
      conditions. If a User do not agree to any such modifications, a User must
      immediately stop using the Services.
    </p>

    <p>
      <strong>No Implied Waivers.</strong> No failure or delay on the part of
      Melon in the exercise of any power, right, privilege or remedy under this
      Agreement shall operate as a waiver of such power, right, privilege or
      remedy.
    </p>

    <p>
      <strong>Entire Agreement.</strong> These Agreement constitutes the entire
      agreement between the parties relating to the subject matter hereof and
      supersede all prior or contemporaneous agreements and understandings, both
      written and oral, between the parties with respect to the subject matter
      hereof.
    </p>

    <p>
      <strong>Rules of Interpretation.</strong> Any rule of construction to the
      effect that ambiguities are to be resolved against the drafting party
      shall not be applied in the construction or interpretation of this
      Agreement.
    </p>
  </article>
</template>

<style lang="scss" scoped>
@import 'src/styles/mixins';

article {
  @include policy-typo;
}
</style>
