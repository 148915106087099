import CryptoCurrency from '../models/crypto-currency.enum';
import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';

// extensions
export const METAMASK_NOT_SUPPORTED = 'Your browser doesn\'t support Metamask. Enable Metamask and try again';

// user
export const USER_AUTH_CANCELED = 'You disapproved authentication';
export const USER_AUTH_PENDING = 'You have the connection request as pending. Please Approve or Cancel it and try again';
export const USER_NOT_AUTHENTICATED = 'User is not authenticated';
export const USER_NOT_EXIST = 'User doesn\'t exist';
export const USER_FOLLOW_FAILURE = 'Failed to follow user';
export const USER_UNFOLLOW_FAILURE = 'Failed to unfollow user';

// item
export const ITEM_NOT_EXIST = 'This item doesn\'t exist';
export const ITEM_MINT_SUCCESS = 'Transaction has successfully completed, the platform will add your item shortly';
export const ITEM_MINT_FAILURE = 'Failed to mint item.';
export const ITEM_SALE_STATUS_INVALID = (status: ItemSalesStatus): string => `Invalid sale status: ${status}`;
export const ITEM_SELLING_SET_SUCCESS = 'Transaction has successfully completed, the platform will update sale status shortly';
export const ITEM_SELLING_SET_FAILURE = (sellingType: string): string => `Failed to set item for ${sellingType}.`;
export const ITEMS_SELLING_SET_SUCCESS = 'Transaction has successfully completed, the platform will update sale status shortly';
export const ITEMS_SELLING_SET_FAILURE = (sellingType: string): string => `Failed to set item for ${sellingType}.`;
export const ITEM_SELLING_WITHDRAW_SUCCESS = 'Transaction has successfully completed, the platform will update sale status shortly';
export const ITEM_SELLING_WITHDRAW_FAILURE = (sellingType: string): string => `Failed to withdraw item from ${sellingType}.`;
export const ITEM_SELLING_FINALIZE_SUCCESS = 'Transaction has successfully completed, the platform will update sale status shortly';
export const ITEM_SELLING_FINALIZE_FAILURE = (sellingType: string): string => `Failed to finalize item ${sellingType}.`;
export const ITEM_PRICE_UPDATE_SUCCESS = 'Transaction has successfully completed, the platform will update item price shortly';
export const ITEM_PRICE_UPDATE_FAILURE = 'Failed to set new price for item.';
export const ITEM_OFF_CHAIN_OFFER_SET_SUCCESS = 'Offer has been added successfully';
export const ITEM_OFF_CHAIN_OFFER_SET_FAILURE = 'Failed to add offer.';
export const ITEM_OFF_CHAIN_OFFER_WITHDRAW_SUCCESS = 'Offer has been withdrawn successfully';
export const ITEM_OFF_CHAIN_OFFER_WITHDRAW_FAILURE = 'Failed to remove offer.';
export const ITEM_OFFER_SET_SUCCESS = 'Transaction has successfully completed, the platform will add your offer shortly';
export const ITEM_OFFER_SET_FAILURE = 'Failed to add offer.';
export const ITEM_OFFER_WITHDRAW_SUCCESS = 'Transaction has successfully completed, the platform will remove your offer shortly';
export const ITEM_OFFER_WITHDRAW_FAILURE = 'Failed to remove offer.';
export const ITEM_OFFER_APPROVE_SUCCESS = 'Transaction has successfully completed, the platform will approve offer shortly';
export const ITEM_OFFER_APPROVE_FAILURE = 'Failed to approve offer.';
export const ITEM_BID_SET_SUCCESS = 'Transaction has successfully completed, the platform will add your bid shortly';
export const ITEM_BUY_NOT_ENOUGH_TOKENS = (currency: CryptoCurrency): string => `Not enough ${currency} tokens`;
export const ITEM_BUY_SUCCESS = 'Transaction has successfully completed, the platform will update your ownership status shortly';
export const ITEM_BUY_FAILURE = 'Transaction failed. Please try again or write a message to support about problem.';
export const ITEM_SET_REWARD_AS_REDEEMED_SUCCESS = 'Item is successfully marked as redeemed, the platform will update sale status shortly';
export const ITEM_SALE_SUBSCRIBE_SUCCESS = 'You successfully subscribed for this sale';

// unknown error
export const ERROR_UNKNOWN = 'Unknown error';

// default success
export const ACTION_SUCCESS_DEFAULT = 'Action completed successfully';
