<template>
  <Modal
    v-show="isOpen"
    :has-close-button="false"
    :has-cancel-button="false"
    :has-footer="false"
    :max-width="'730px'"
  >
    <template v-slot:header>
      <h2>Never miss a drop</h2>
    </template>
    <template v-slot:body>
      <div class="email-container">
        <div class="email-description">
          <img src="@/assets/images/currency/melon.svg" alt="email" />
          <p>
            Enter your email to get an exclusive sneak peek of MELON NFTs right now and be notified
            as soon as the NFTs go on sale.
          </p>
        </div>
        <div class="input-container">
          <label for="first-visit-email-input">Enter your email</label>
          <input
            v-model="email"
            id="first-visit-email-input"
            type="email"
            placeholder="example@gmail.com"
            required
            class="input"
            :class="{ pristine: emailPristine }"
            @input="handleEmailChange"
            @blur="emailPristine = false"
          >
          <p
            v-if="emailError && !emailPristine"
            class="error-text"
          >
            Invalid email
          </p>
        </div>
        <button class="primary-button" :disabled="emailError || !email" @click="sendEmail(email)">
          Submit
        </button>
        <p class="warning-policy-text">
          By clicking Submit, you agree with the website
          <router-link
            :to="{ name: 'terms-and-conditions' }"
            class="policy-link"
          >
            Terms & Conditions
          </router-link>
          and
          <router-link
            :to="{ name: 'privacy-policy' }"
            class="policy-link"
          >
            Privacy policy
          </router-link>
        </p>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from '@/store';

// store
import { Modules } from '@/store/props';
import { Actions as CommonServiceActions } from '@/store/modules/common-service/props';

// components
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['sent'],

  setup(_, { emit }) {
    const email = ref<string | null>(null);
    const emailPristine = ref<boolean>(true);
    const emailError = ref<boolean>(true);

    const store = useStore();

    // eslint-disable-next-line
    function handleEmailChange(e: any): void {
      emailError.value = !e.target.validity.valid;
    }

    async function sendEmail(): Promise<void> {
      await store.dispatch(
        `${Modules.CommonService}/${CommonServiceActions.sendFirstVisitEmail}`,
        { email: email.value, url: window.location.href },
      );
      emit('sent');
    }

    return {
      email,
      emailPristine,
      emailError,
      handleEmailChange,
      sendEmail,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.email-container {
  display: flex;
  flex-direction: column;

  .email-description {
    display: flex;
    align-items: center;

    img {
      width: 8rem;
      height: 8rem;
      margin-right: 1rem;
    }

    p {
      @include typo-body-1;
    }

    @include for-xs-sm-width {
      flex-direction: column;
    }
  }

  .input-container {
    @include input-container;
    margin-top: 2rem;

    .input {
      @include input-common;
      @include typo-caption-bold;

      padding: 0.75rem 1rem;
      border-radius: 0.75rem;
    }

    .error-text {
      @include typo-caption-2;
      margin-top: 0.25rem;
      color: $primary-1;
    }
  }

  .primary-button {
    @include typo-button-1;
    @include button-primary-filled;

    margin-top: 2rem;
    padding: 13px 1rem;
  }

  .warning-policy-text {
    @include typo-caption;

    margin-top: 1rem;
    color: $neutrals-4;
    text-align: center;

    .policy-link {
      color: $neutrals-1;
      text-decoration: underline;
    }
  }
}
</style>
