
import { defineComponent, watch, ref } from 'vue';

import { useRoute } from 'vue-router';

// components
import Header from './Header.vue';
import Footer from './Footer.vue';
import Banner from './Banner.vue';

export default defineComponent({
  name: 'MainLayout',

  components: {
    Header,
    Footer,
    Banner,
  },

  setup() {
    const route = useRoute();
    const showBanner = ref<boolean>(false);

    watch(route, (newRoute) => {
      if (newRoute.name === 'home') {
        showBanner.value = true;
      } else {
        showBanner.value = false;
      }
    });

    return {
      showBanner,
    };
  },
});
