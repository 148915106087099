
import { defineComponent, ref, watch } from 'vue';
import InlineSvg from 'vue-inline-svg';

import svgIconUrl from '@/shared/helpers/svg-icon-url';

export default defineComponent({
  name: 'ExpansionCard',

  components: {
    InlineSvg,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    titleTextPos: {
      type: String,
      default: 'center',
    },
    titleTextColor: {
      type: String,
      default: '#2f2323',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['expanded'],

  setup(props, { emit }) {
    const isExpanded = ref(props.expanded);

    watch(() => props.expanded, (newVal) => {
      isExpanded.value = newVal;
    });

    watch(isExpanded, (value) => {
      emit('expanded', value);
    });

    return {
      isExpanded,
      svgIconUrl,
    };
  },
});
