<template>
  <modal
    v-show="isOpen"
    cancel-button-label="Ok"
    :hasInitialBackdrop="true"
    @close="$emit('close')"
  >
    <template v-slot:header>
      <h2>{{ title }}</h2>
    </template>

    <template v-slot:body>
      <slot />
    </template>
  </modal>
</template>

<script>
import { defineComponent } from 'vue';

import Modal from './Modal.vue';

export default defineComponent({
  name: 'InfoModal',

  components: {
    Modal,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Info',
    },
  },

  emits: [
    'close',
  ],
});
</script>
