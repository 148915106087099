
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';

// store
import { useStore } from '@/store';

// components
import ItemFilePreview from './ItemFilePreview.vue';
import ItemPrice from './ItemPrice.vue';
import UserImage from './UserImage.vue';

// helpers
import calcTimeDiffSeparately from '@/shared/helpers/calc-time-diff-separately';

// models
import ItemListItem from '@/store/modules/item-list/models/item-list-item';
import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';
import RewardStatus from '@/shared/models/reward-status.enum';
import TimeLeftData from '@/shared/models/time-left-data';

export default defineComponent({
  name: 'ItemCard',

  components: {
    ItemFilePreview,
    ItemPrice,
    UserImage,
  },

  props: {
    data: {
      type: Object as () => ItemListItem,
      required: true,
    },
    previewing: {
      type: Boolean,
      default: false,
    },
    selecting: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      default: [],
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const timerId = ref<number | null>(null);
    const timeLeft = ref<TimeLeftData>({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      totalLeft: 0,
    });

    const timeLeftAsStr = computed(() => {
      const {
        days,
        hours,
        minutes,
        seconds,
        totalLeft,
      } = timeLeft.value;
      return totalLeft > 0 ? `${days}d ${hours}h ${minutes}m ${seconds}s` : null;
    });

    const selected = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const isItemSelling = computed(() => (
      props.data?.salesStatus === ItemSalesStatus.OnSale
      || props.data?.salesStatus === ItemSalesStatus.OnEnglishAuction
      || props.data?.salesStatus === ItemSalesStatus.OnDutchAuction
    ));

    const hasPrice = computed(() => (
      !!props.data.price && !!props.data.cryptoCurrency && !!props.data.usdPrice
      && (props.data?.salesStatus === ItemSalesStatus.OnSale
        || props.data?.salesStatus === ItemSalesStatus.OnEnglishAuction
        || props.data?.salesStatus === ItemSalesStatus.Sold
        || props.data?.salesStatus === ItemSalesStatus.OnOffChainOfferAuction)
    ));

    const currentUserId = computed(() => store.state.user.id);

    const openCreator = (userNickname: string, e: MouseEvent) => {
      e.preventDefault();

      router.push({ name: 'user-profile', params: { userNickname } });
    };

    const stopPropagation = (e: MouseEvent) => {
      e.stopPropagation();
    };

    const typeImageUrl = (typeId: string) => `${process.env.VUE_APP_API_URL}/types/${typeId}/image`;

    function updateLeftTime() {
      timeLeft.value = calcTimeDiffSeparately(props.data?.saleDate as string);

      if (timeLeft.value.totalLeft < 0 && timerId.value) {
        // eslint-disable-next-line
        clearInterval(timerId.value as any);
        timerId.value = null;
      }
    }

    onMounted(() => {
      if (props.data?.saleDate) {
        timerId.value = setInterval(updateLeftTime, 1000) as unknown as number;
        updateLeftTime();
      }
    });

    onUnmounted(() => {
      if (timerId.value) {
        // eslint-disable-next-line
        clearInterval(timerId.value as any);
        timerId.value = null;
      }
    });

    return {
      timeLeftAsStr,
      RewardStatus,
      selected,
      isItemSelling,
      hasPrice,
      currentUserId,
      ItemSalesStatus,
      typeImageUrl,
      openCreator,
      stopPropagation,
    };
  },
});
