<template>
  <router-link
    :to="{ name: 'user-profile', params: { userNickname }}"
    class="list-item"
    :class="{ 'with-bottom-padding': hasBottomPadding }"
  >
    <UserImage
      userName="user"
      :userImagePath="photoPath"
      :isUserVerified="isVerified"
      :isUserRegistered="isRegistered"
      imageSize="48px"
      iconSize="24px"
      iconOffset="-4px"
      class="user-image-container"
    />
    <slot />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import UserImage from '@/components/UserImage.vue';

export default defineComponent({
  name: 'ItemProfileListItem',

  components: {
    UserImage,
  },

  props: {
    userNickname: {
      type: String,
      required: true,
    },
    photoPath: {
      type: String,
      default: null,
    },
    isVerified: {
      type: Boolean,
      default: false,
    },
    isRegistered: {
      type: Boolean,
      default: false,
    },
    hasBottomPadding: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.list-item {
  display: flex;
  align-items: center;

  &.with-bottom-padding {
    padding-bottom: 1rem;
  }

  &:not(:first-child) {
    padding-top: 1rem;
  }

  .user-image-container {
    flex-shrink: 0;
    margin-right: 1rem;
  }
}
</style>
