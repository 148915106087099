<template>
  <span class="crypto-price">
    <span class="amount">{{ amount }}</span>
    <img
      v-if="currency === CryptoCurrency.MELON"
      src="@/assets/images/currency/melon.svg"
      class="icon"
    >
    <Tooltip
      v-else-if="currency === CryptoCurrency.WETH"
      label="ETH on Polygon"
    >
      <img
        src="@/assets/images/currency/weth.svg"
        class="icon"
        @click="navigateWeth"
      >
    </Tooltip>
    <template v-else>{{ currency }}</template>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// instances
import Tooltip from '@/instances/tooltip';

// models
import CryptoCurrency from '@/shared/models/crypto-currency.enum';

export default defineComponent({
  name: 'CryptoPrice',
  components: { Tooltip },
  props: {
    amount: {
      required: true,
    },
    currency: {
      required: true,
    },
  },

  setup() {
    // eslint-disable-next-line
    function navigateWeth(e: any): void {
      e.preventDefault();
      window.open('https://polygonscan.com/address/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', '_blank');
    }

    return {
      CryptoCurrency,
      navigateWeth,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/variables';

.crypto-price {
  display: inline-flex;
  align-items: center;

  .amount {
    margin-right: 0.25em;
  }

  .icon {
    display: inline-flex;
    height: 1.25em;
  }
}
</style>
