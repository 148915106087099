import VueCustomTooltip, { TooltipOptions } from '@adamdehaven/vue-custom-tooltip';

export const tooltipOptions: TooltipOptions = {
  name: 'Tooltip',
  color: '#fdfcfc',
  background: '#907778',
  borderRadius: 8,
  fontWeight: 400,
};

export default VueCustomTooltip;
