<template>
  <div v-if="data.length" ref="scrollComponent" class="row">
    <div v-for="item in data" :key="item.id">
      <ItemCard :data="item" :selecting="selecting" v-model="selected" />
    </div>
  </div>
  <div v-if="!isItemsLoading && !data.length" class="empty-container">
    <p>No items found.</p>
  </div>
  <ContentLoader :loading="isItemsLoading"/>
</template>

<script lang="ts">
import {
  computed, defineComponent, onMounted, onUnmounted, PropType, ref, toRefs, watch,
} from 'vue';
import throttle from 'lodash/throttle';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-profile/props';
import PaginationOptions from '@/shared/models/pagination-options';

import UserItemFilter from '@/store/modules/user-list/models/user-item-filter.enum';

import ContentLoader from '@/components/ContentLoader.vue';
import ItemCard from '@/components/ItemCard.vue';

export default defineComponent({
  name: 'UserItems',

  components: {
    ItemCard,
    ContentLoader,
  },

  props: {
    filter: {
      type: String as PropType<UserItemFilter.Created | UserItemFilter.Collectibles>,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    selecting: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const store = useStore();

    const { filter: filterRef, userId: userIdRef } = toRefs(props);

    const isItemsLoading = ref<boolean>(false);

    const data = computed(() => store.state.UserProfile.items[filterRef.value].data);
    const pagination = computed(() => store.state.UserProfile.items[filterRef.value].pagination);
    const isLastPage = computed(() => store.state.UserProfile.items[filterRef.value].isLastPage);

    const selected = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const scrollComponent = ref<HTMLElement | null>(null);

    const loadItems = (
      options: Partial<PaginationOptions>, filter?: UserItemFilter, userId?: string,
    ) => {
      isItemsLoading.value = true;
      store.dispatch(
        `${Modules.UserProfile}/${Actions.fetchItems}`,
        { ...options, filter: filter ?? filterRef.value, userId: userId ?? userIdRef.value },
      ).finally(() => {
        isItemsLoading.value = false;
      });
    };

    const clearItems = (filter: UserItemFilter) => {
      store.dispatch(`${Modules.UserProfile}/${Actions.clearItems}`, filter);
    };

    const handleScroll = () => {
      const element = scrollComponent.value;
      if (element && !isLastPage.value && !isItemsLoading.value
        && element.getBoundingClientRect().bottom < window.innerHeight
      ) {
        loadItems({ page: pagination.value.page + 1 });
      }
    };

    watch(userIdRef, (userId) => {
      clearItems(filterRef.value);
      loadItems({ page: 1 }, filterRef.value, userId);
    });

    watch(filterRef, (filter, prevFilter) => {
      clearItems(prevFilter);
      loadItems({ page: 1 }, filter);
    });

    onMounted(() => {
      loadItems({ page: 1 });

      window.addEventListener('scroll', throttle(handleScroll, 800, { leading: false }));
    });

    onUnmounted(() => {
      clearItems(filterRef.value);

      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isItemsLoading,
      data,
      selected,
      scrollComponent,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.row {
  @include items-wrapper;

  & > * {
    width: 33.33%;

    @include for-lg-width {
      width: 50%;
    }

    @include for-md-width {
      width: 50%;
    }

    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }
  }
}

.empty-container {
  @include empty-container;
}
</style>
