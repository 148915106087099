
import {
  computed, defineComponent, onMounted, onUnmounted, ref,
} from 'vue';
import throttle from 'lodash/throttle';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-profile/props';
import { Actions as UserProfileActions } from '@/store/modules/user-profile/props';
import { Actions as UserListActions } from '@/store/modules/user-list/props';

import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';

import CommunityOverviewCard from '@/components/CommunityOverviewCard.vue';
import CreatorsTable from '@/components/CreatorsTable.vue';
import CreatorsFilter from '@/components/CreatorsFilter.vue';
import ContentLoader from '@/components/ContentLoader.vue';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const overviewIcon1 = require('@/assets/images/currency/melon.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const overviewIcon2 = require('@/assets/images/default/user-solid.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const overviewIcon3 = require('@/assets/images/common/verified.svg');

interface loadOptions {
  offset: number,
}

export default defineComponent({
  components: {
    CommunityOverviewCard,
    CreatorsTable,
    CreatorsFilter,
    ContentLoader,
  },
  setup() {
    const store = useStore();

    const melonItemStats = computed(() => store.state.ItemProfile.stats.itemStats);
    const socialFollowersStats = computed(() => store.state.UserProfile.stats.socialFollowersStats);
    const usersStats = computed(() => store.state.UserProfile.stats.usersStats);
    const isLastPage = computed(() => store.state.UserList.stats.isLastPage);
    const isUsersLoading = computed(() => store.state.UserList.stats.isUsersLoading);
    const data = computed(() => store.state.UserList.stats.data);
    const filters = computed(() => store.state.UserList.stats.filters);

    const scrollComponent = ref<HTMLElement | null>(null);

    const salesStatusOptions = computed(() => [
      {
        id: ItemSalesStatus.Minted,
        label: 'Minted',
        value: melonItemStats.value.minted,
      },
      {
        id: ItemSalesStatus.OnSale,
        label: 'On sale',
        value: melonItemStats.value.onSale,
      },
      {
        id: ItemSalesStatus.OnEnglishAuction,
        label: 'On auction',
        value: melonItemStats.value.onAuction,
      },
      {
        id: ItemSalesStatus.OnOffChainOfferAuction,
        label: 'Offchain auction',
        value: melonItemStats.value.onOffChainAuction,
      },
    ]);

    const socialFollowersStatsOptions = computed(() => [
      {
        id: 'tiktok',
        label: 'TikTok',
        value: socialFollowersStats.value.tiktokCount,
        hasIcon: true,
      },
      {
        id: 'instagram',
        label: 'Instagram',
        value: socialFollowersStats.value.instagramCount,
        hasIcon: true,
      },
      {
        id: 'twitter',
        label: 'Twitter',
        value: socialFollowersStats.value.twitterCount,
        hasIcon: true,
      },
      {
        id: 'youtube',
        label: 'Youtube',
        value: socialFollowersStats.value.youtubeCount,
        hasIcon: true,
      },
      {
        id: 'twitch',
        label: 'Twitch',
        value: socialFollowersStats.value.twitchCount,
        hasIcon: true,
      },
    ]);

    const usersStatsOptions = computed(() => [
      {
        id: 'verified',
        label: 'Verified',
        value: usersStats.value.verifiedCount,
      },
      {
        id: 'unverified',
        label: 'Not verified',
        value: usersStats.value.unverifiedCount,
      },
      {
        id: 'unlinked',
        label: 'Unlinked',
        value: usersStats.value.unregisteredCount,
      },
    ]);

    const loadUsers = (options: loadOptions) => {
      store.dispatch(`${Modules.UserList}/${UserListActions.fetchRankedUsers}`, {
        offset: options.offset,
        followersFilter: filters.value.followersFilter,
        creatorsFilter: filters.value.creatorsFilter,
        creatorItemsFilters: filters.value.creatorItemsFilters,
        sortBy: filters.value.sortBy,
        pageSize: filters.value.pageSize,
        isNoneItemsCreated: filters.value.isNoneItemsCreated,
      });
    };

    const loadMelonItemsStats = () => {
      store.dispatch(`${Modules.ItemProfile}/${Actions.fetchItemsStats}`);
      store.dispatch(`${Modules.UserProfile}/${UserProfileActions.fetchSocialFollowersStats}`);
      store.dispatch(`${Modules.UserProfile}/${UserProfileActions.fetchGlobalUsersStats}`);
      loadUsers({ offset: 0 });
    };

    const handleScroll = () => {
      const element = scrollComponent.value;
      if (element && !isLastPage.value && !isUsersLoading.value
        && element.getBoundingClientRect().bottom < window.innerHeight
      ) {
        loadUsers({ offset: filters.value.offset + 10 });
      }
    };

    const clearRankedUsers = () => {
      store.dispatch(`${Modules.UserList}/${UserListActions.clearRankedUsers}`);
    };

    onMounted(() => {
      clearRankedUsers();
      loadMelonItemsStats();
      window.addEventListener('scroll', throttle(handleScroll, 800, { leading: false }));
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      salesStatusOptions,
      usersStatsOptions,
      socialFollowersStatsOptions,
      socialFollowersStats,
      usersStats,
      melonItemStats,
      scrollComponent,
      isUsersLoading,
      data,
      overviewIcon1,
      overviewIcon2,
      overviewIcon3,
      isLastPage,
    };
  },
});
