
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import FeedListItem from '@/store/modules/feed/models/feed-list-item';

import ItemCard from '@/components/ItemCard.vue';
import UserImage from '@/components/UserImage.vue';

export default defineComponent({
  name: 'FeedItemCard',

  components: {
    ItemCard,
    UserImage,
  },

  props: {
    data: {
      type: Object as () => FeedListItem,
      required: true,
    },
  },

  setup() {
    const router = useRouter();

    const openCreator = (userNickname: string, e: MouseEvent) => {
      e.preventDefault();

      router.push({ name: 'user-profile', params: { userNickname } });
    };

    function getDate(date: string): string {
      return new Date(date).toDateString();
    }

    return {
      openCreator,
      getDate,
    };
  },
});
