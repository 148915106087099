<template>
  <Select
    v-model="selectValue"
    :initialFullValue="initialFullValue"
    :id="id"
    :multiple="true"
    labelKey="title"
    placeholder="Select filters"
    :withSearch="true"
    :data="data"
    :pagination="pagination"
    :isLastPage="isLastPage"
    :isFormElement="isFormElement"
    @loadOptions="loadTextFilters"
  />
</template>

<script lang="ts">
import { computed, defineComponent, onUnmounted } from 'vue';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-list/props';
import PaginationOptions from '@/shared/models/pagination-options';

import ItemListTextFiltersFilters from '@/store/modules/item-list/models/item-list-text-filters-filters';

import Select from '@/components/Select.vue';

export default defineComponent({
  name: 'ItemFilterSelect',

  props: {
    modelValue: {
      default: null,
    },
    initialFullValue: {
      default: [],
    },
    id: {
      type: String,
    },
    isFormElement: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Select,
  },

  emits: [
    'update:modelValue',
  ],

  setup(props, { emit }) {
    const store = useStore();

    const selectValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const data = computed(() => store.state.ItemList.textFilters.data);
    const pagination = computed(() => store.state.ItemList.textFilters.pagination);
    const isLastPage = computed(() => store.state.ItemList.textFilters.isLastPage);

    const loadTextFilters = (
      options: Partial<PaginationOptions> & Partial<ItemListTextFiltersFilters>,
    ) => {
      store.dispatch(`${Modules.ItemList}/${Actions.fetchTextFilters}`, options);
    };

    onUnmounted(() => {
      store.dispatch(`${Modules.ItemList}/${Actions.clearTextFilters}`);
    });

    return {
      selectValue,
      data,
      pagination,
      isLastPage,
      loadTextFilters,
    };
  },
});
</script>
