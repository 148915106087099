
import {
  defineComponent, ref, watch, computed,
} from 'vue';
import Toggle from '@vueform/toggle';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-list/props';

import SocialStatsFilter from '@/store/modules/user-list/models/social-stats-filter.enum';
// import CreatorsFilter from '@/store/modules/user-list/models/creators-filter.enum';
import UserStatsFilter from '@/store/modules/user-list/models/user-stats-filter.enum';

// import Select from '@/components/Select.vue';
import CreatorsFilterModal from '@/components/CreatorsFilterModal.vue';

interface loadOptions {
  followersFilter?: string,
  creatorsFilter?: string,
  creatorItemsFilters?: string,
  sortBy?: string,
  includeUpcomingNfts?: boolean,
}

export default defineComponent({
  components: {
    // Select,
    CreatorsFilterModal,
    Toggle,
  },
  setup() {
    const store = useStore();

    const modalOpened = ref<boolean>(false);
    const upcomingNfts = ref<boolean>(true);
    const followers = ref<string[]>([]);
    const likes = ref<string[]>([]);
    const views = ref<string[]>([]);
    const sortBy = ref<UserStatsFilter>(UserStatsFilter.FollowersDesc);

    const filters = computed(() => store.state.UserList.stats.filters);

    const followersSortOptions = [
      {
        id: SocialStatsFilter.Instagram,
        label: 'Instagram',
      },
      {
        id: SocialStatsFilter.Twitter,
        label: 'Twitter',
      },
      {
        id: SocialStatsFilter.Twitch,
        label: 'Twitch',
      },
      {
        id: SocialStatsFilter.TikTok,
        label: 'TikTok',
      },
      {
        id: SocialStatsFilter.Youtube,
        label: 'YouTube',
      },
    ];

    const likesSortOptions = [
      {
        id: SocialStatsFilter.Instagram,
        label: 'Instagram',
      },
      {
        id: SocialStatsFilter.Twitter,
        label: 'Twitter',
      },
    ];

    const viewsSortOptions = [
      {
        id: SocialStatsFilter.Twitch,
        label: 'Twitch',
      },
      {
        id: SocialStatsFilter.Youtube,
        label: 'YouTube',
      },
      {
        id: SocialStatsFilter.TikTok,
        label: 'TikTok',
      },
    ];

    const sortByOptions = [
      {
        id: UserStatsFilter.FollowersDesc,
        label: 'Number of followers (desc)',
      },
      {
        id: UserStatsFilter.Followers,
        label: 'Number of followers',
      },
      {
        id: UserStatsFilter.LikesDesc,
        label: 'Number of likes (desc)',
      },
      {
        id: UserStatsFilter.Likes,
        label: 'Number of likes',
      },
      {
        id: UserStatsFilter.ViewsDesc,
        label: 'Number of views (desc)',
      },
      {
        id: UserStatsFilter.Views,
        label: 'Number of views',
      },
    ];

    const openModal = () => {
      modalOpened.value = true;
    };

    const clearRankedUsers = () => {
      store.dispatch(`${Modules.UserList}/${Actions.clearRankedUsers}`);
    };

    const loadUsers = (options: loadOptions) => {
      store.dispatch(`${Modules.UserList}/${Actions.fetchRankedUsers}`, {
        offset: filters.value.offset,
        followersFilter: filters.value.followersFilter,
        creatorsFilter: filters.value.creatorsFilter,
        creatorItemsFilters: filters.value.creatorItemsFilters,
        sortBy: filters.value.sortBy,
        pageSize: filters.value.pageSize,
        isNoneItemsCreated: options.includeUpcomingNfts ? 'true' : 'false',
      });
    };

    watch([followers, upcomingNfts], ([fl, un]) => {
      clearRankedUsers();
      console.log(fl, un);
      loadUsers({
        includeUpcomingNfts: un,
      });
    });

    return {
      followers,
      likes,
      views,
      sortByOptions,
      sortBy,
      modalOpened,
      openModal,
      followersSortOptions,
      likesSortOptions,
      viewsSortOptions,
      upcomingNfts,
    };
  },
});
