// models
import PaginationOptions from '@/shared/models/pagination-options';

import FeaturedUserListItem from '@/store/modules/user-list/models/featured-user-list-item';

import UserListItem from '@/store/modules/user-list/models/user-list-item';
import UserListFilters from '@/store/modules/user-list/models/user-list-filters';
import UserStatsItem from '@/store/modules/user-list/models/user-stats-item';

import http from '../instances/http';
import UserStatsListFilters from '@/store/modules/user-list/models/user-stats-list-filters';

const UserListApi = {
  async getFeaturedUsers(): Promise<FeaturedUserListItem[]> {
    const res = await http.get('/profile/featured');
    return res.data;
  },

  async getUsers(
    { page, pageSize }: PaginationOptions,
    filters: UserListFilters,
  ): Promise<UserListItem[]> {
    const res = await http.get('/profile', {
      params: {
        offset: (page - 1) * pageSize,
        pageSize,
        ...filters,
      },
    });
    return res.data;
  },

  async getRankedUsers(filters: UserStatsListFilters): Promise<UserStatsItem[]> {
    // compute followers, likes, views filter params
    const followersFilter = filters.followersFilter.split(',')
      .reduce((pre: string, cur: string) => `${pre}&followersFilter=${cur}`);
    const creatorsFilter = filters.creatorsFilter.split(',')
      .reduce((pre: string, cur: string) => `${pre}&creatorsFilter=${cur}`);
    const creatorItemsFilters = filters.creatorItemsFilters.split(',')
      .reduce((pre: string, cur: string) => `${pre}&creatorItemsFilters=${cur}`);

    const followersFilterParam = followersFilter.length ? `followersFilter=${followersFilter}` : '';
    const creatorsFilterParam = creatorsFilter.length ? `&creatorsFilter=${creatorsFilter}` : '';
    const creatorItemsFilterParam = creatorItemsFilters.length ? `&creatorItemsFilters=${creatorItemsFilters}` : '';
    const isNoneItemsCreated = `&isNoneItemsCreated=${filters.isNoneItemsCreated}`;

    const res = await http.get(
      `/ranking-users-stats?${followersFilterParam}${creatorsFilterParam}${creatorItemsFilterParam}${isNoneItemsCreated}`, {
        params: {
          sortBy: filters.sortBy,
          offset: filters.offset,
          pageSize: filters.pageSize,
        },
      },
    );
    return res.data;
  },
};

export default UserListApi;
