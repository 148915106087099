<template>
  <Select
    v-model="selectValue"
    :id="id"
    :placeholder="placeholder"
    labelKey="title"
    :getOptionIconUrl="typeImageUrl"
    :withDefaultOption="withDefaultOption"
    :data="data"
    :isLastPage="true"
    :isFormElement="isFormElement"
    @loadOptions="loadItemTypes"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-list/props';

import ItemTypeSimplified from '@/store/modules/item-list/models/item-type-simplified';

import Select from '@/components/Select.vue';

export default defineComponent({
  name: 'ItemTypeSelect',

  props: {
    modelValue: {
      default: null,
    },
    id: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    withDefaultOption: {
      type: Boolean,
      default: false,
    },
    isFormElement: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Select,
  },

  emits: [
    'update:modelValue',
  ],

  setup(props, { emit }) {
    const store = useStore();

    const selectValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const data = computed(() => store.state.ItemList.types);

    const loadItemTypes = () => {
      store.dispatch(`${Modules.ItemList}/${Actions.fetchTypes}`);
    };

    const typeImageUrl = (option: ItemTypeSimplified) => `${process.env.VUE_APP_API_URL}/types/${option.id}/image`;

    return {
      selectValue,
      data,
      typeImageUrl,
      loadItemTypes,
    };
  },
});
</script>
