import { createApp } from 'vue';
import LoadScript from 'vue-plugin-load-script';

import './registerServiceWorker';

import HttpStatusCode from '@/shared/models/http-status-code.enum';

import { eth } from '@/instances/web3';
import { fm, fmKey } from '@/instances/fortmatic';

import http from '@/instances/http';
import store, { key } from './store';
import { Actions } from '@/store/props';
import router from './router';

import VueCustomTooltip, { tooltipOptions } from '@/instances/tooltip';

import App from './App.vue';

import './styles/index.scss';

http.interceptors.response.use(undefined, (err) => {
  if (err.response?.status === HttpStatusCode.Unauthorized) {
    router.push({ name: 'home' });
    store.dispatch(Actions.logout);
  }

  // return error to parse errors
  return Promise.reject(err);
});

const app = createApp(App).use(store, key).use(router);

app.use(LoadScript);
app.use(VueCustomTooltip, tooltipOptions);

app.config.globalProperties.$ethProvider = eth;
app.config.globalProperties.$fmProvider = fm;

app.provide(fmKey, fm);

app.mount('#app');
