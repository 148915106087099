<template>
  <div class="select-container" :class="{ 'default': isFormElement }">
    <input
      :id="id"
      type="text"
      ref="searchInput"
      class="search-input"
      :class="{ 'active': isDropdownOpen }"
      :placeholder="placeholder"
      v-bind:value="search"
      v-on:input="onSearch"
      :readonly="!withSearch || readOnly || !isDropdownOpen"
      autocomplete="off"
      @focus="onSearchInputFocus"
      @blur="onSearchInputBlur"
    >

    <img v-if="readOnly" src="@/assets/images/common/lock.svg" alt="lock icon" class="lock-icon">
    <span
      v-else
      ref="toggleButton"
      class="toggle-button"
      :class="{ 'active': isDropdownOpen }"
      @click="toggleDropdown"
      tabindex="0"
      role="button"
    >
      <inline-svg :src="svgIconUrl('arrow-down')" width="24" height="24"/>
    </span>

    <div v-show="isDropdownOpen" class="group-wrapper">
      <div
        ref="dropdownSelect"
        class="group-container"
        role="group"
        tabIndex="0"
        @click="onDropdownSelectClick"
        @blur="onDropdownSelectBlur"
      >
        <span class="option" v-if="!data.length && withSearch && !withDefaultOption">
          No options found.
        </span>

        <label
          v-if="withDefaultOption && !multiple"
          :ref="setOptionRef"
          class="option"
          :class="{ 'active': selectValue === null }"
        >
          <input
            v-model="selectValue"
            :value="null"
            :name="id + '-options'"
            type="radio"
            hidden
          >
          <span class="label">{{ placeholder }}</span>
        </label>

        <label
          v-for="option in data"
          :key="option[idKey]"
          :ref="setOptionRef"
          class="option"
          :class="{
            'active': multiple
              ? selectValue?.includes(option[idKey])
              : selectValue === option[idKey],
            'checkbox-button': multiple
          }"
        >
          <input
            v-model="selectValue"
            :value="option[idKey]"
            :name="id + '-options'"
            :type="multiple ? 'checkbox' : 'radio'"
            hidden
          >
          <span v-if="multiple" class="checkmark"></span>
          <span class="label">
            <img v-if="getOptionIconUrl" :src="getOptionIconUrl(option)" class="icon" />
            {{ option[labelKey] || option[idKey] }}
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUpdate,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
  watch,
} from 'vue';
import InlineSvg from 'vue-inline-svg';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import svgIconUrl from '@/shared/helpers/svg-icon-url';

import PaginationOptions from '@/shared/models/pagination-options';

interface IData {
  [key: string]: string | null | boolean | undefined,
}

export default defineComponent({
  name: 'Select',

  components: {
    InlineSvg,
  },

  props: {
    modelValue: {
      default: null,
    },
    initialFullValue: {
      default: [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    idKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    getOptionIconUrl: {
      type: Function,
    },
    data: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object as () => PaginationOptions,
      default: () => ({ page: 1, pageSize: 10 }),
    },
    isLastPage: {
      type: Boolean,
      default: false,
    },
    withDefaultOption: {
      type: Boolean,
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isFormElement: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
    'loadOptions',
  ],

  setup(props, { emit }) {
    const {
      idKey,
      labelKey,
      multiple,
      data,
      pagination,
      isLastPage,
      withSearch,
      readOnly,
    } = toRefs(props);

    const isDropdownOpen = ref(false);

    const getSelectFullValue = (value: string | boolean | string[] | null, prevValue: IData[]) => {
      if (value === null) {
        return [];
      }

      if (!Array.isArray(value)) {
        const option = (data.value as IData[]).find((o) => o[idKey.value] === value);
        return option ? [{ ...option }] : [];
      }

      if (value.length === 0) {
        return [];
      }

      const nextValue: IData[] = [];

      value.forEach((id) => {
        const option = prevValue.find((o) => o[idKey.value] === id)
          ?? (data.value as IData[]).find((o) => o[idKey.value] === id);

        if (option) {
          nextValue.push({ ...option });
        }
      });

      return nextValue;
    };

    const selectFullValue = ref<IData[]>(
      getSelectFullValue(props.modelValue, props.initialFullValue),
    );

    const getOptionLabel = (options: IData[]) => {
      const labels = options.map((o) => (o ? (o[labelKey.value] ?? o[idKey.value] ?? '') : ''));
      return labels.join(', ');
    };

    const selectValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const search = ref<string | boolean | null>(getOptionLabel(selectFullValue.value));

    const searchInput = ref<HTMLInputElement | null>(null);
    const dropdownSelect = ref<HTMLInputElement | null>(null);
    const toggleButton = ref<HTMLButtonElement | null>(null);

    let optionRefs: HTMLOptionElement[] = [];

    const setOptionRef = (el: HTMLOptionElement) => {
      if (el) {
        optionRefs.push(el);
      }
    };

    const checkElementsBounds = () => {
      if (!isLastPage.value && dropdownSelect.value) {
        const element = optionRefs[optionRefs.length - 1];
        if (element && (element.getBoundingClientRect().top
          < dropdownSelect.value.getBoundingClientRect().bottom)
        ) {
          emit('loadOptions', { page: pagination.value.page + 1 });
        }
      }
    };

    const openDropdown = () => {
      isDropdownOpen.value = true;
    };

    const closeDropdown = () => {
      search.value = getOptionLabel(selectFullValue.value);
      isDropdownOpen.value = false;
    };

    const toggleDropdown = () => {
      if (searchInput.value) {
        if (isDropdownOpen.value) {
          closeDropdown();
        } else {
          searchInput.value.focus();
        }
      }
    };

    const onSearchInputFocus = () => {
      if (readOnly.value) {
        return;
      }

      if (withSearch.value) {
        search.value = '';
      }

      emit('loadOptions', { search: search.value });
      openDropdown();
      checkElementsBounds();
    };

    const onSearchInputBlur = (e: FocusEvent) => {
      if (readOnly.value) {
        return;
      }

      if (e.relatedTarget !== dropdownSelect.value && e.relatedTarget !== toggleButton.value) {
        closeDropdown();
      }
    };

    const onSearchInputChange = (e: InputEvent) => {
      search.value = (e.target as HTMLInputElement)?.value;
      emit('loadOptions', { search: search.value });
    };

    const onDropdownSelectClick = () => {
      if (!multiple.value) {
        closeDropdown();
      }
    };

    const onDropdownSelectBlur = (e: FocusEvent) => {
      if (e.relatedTarget !== searchInput.value && e.relatedTarget !== toggleButton.value) {
        closeDropdown();
      }
    };

    watch(selectValue, (value) => {
      selectFullValue.value = getSelectFullValue(value, [...selectFullValue.value]);
      search.value = getOptionLabel(selectFullValue.value);
    });

    onBeforeUpdate(() => {
      optionRefs = [];
    });

    onMounted(() => {
      emit('loadOptions', { search: search.value });

      if (dropdownSelect.value) {
        dropdownSelect.value.addEventListener('scroll', throttle(checkElementsBounds, 800, { leading: false }));
      }
    });

    onUnmounted(() => {
      if (dropdownSelect.value) {
        dropdownSelect.value.removeEventListener('scroll', throttle(checkElementsBounds, 800, { leading: false }));
      }
    });

    return {
      search,
      selectValue,
      isDropdownOpen,
      searchInput,
      dropdownSelect,
      toggleButton,
      svgIconUrl,
      onSearchInputFocus,
      onSearchInputBlur,
      onSearch: debounce(onSearchInputChange, 400),
      onDropdownSelectClick,
      onDropdownSelectBlur,
      openDropdown,
      toggleDropdown,
      setOptionRef,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

@mixin select-container($main-color, $main-color-active) {
  position: relative;
  width: 100%;

  .search-input {
    @include typo-caption-bold;

    width: 100%;
    padding: 0.75rem 3rem 0.75rem 1rem;
    color: $neutrals-3;
    background-color: transparent;
    border: 2px solid $main-color;
    border-radius: 0.75rem;
    transition: color 0.4s $transition-timing-function-default,
      background-color 0.4s $transition-timing-function-default,
      border-color 0.4s $transition-timing-function-default;

    &:focus, &.active {
      color: $neutrals-2;
      background-color: $neutrals-8;
      border-color: $main-color-active;
    }

    &.active {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:read-only {
      @include text-ellipsis;
      cursor: pointer;
    }

    &::placeholder {
      color: $neutrals-4;
    }
  }

  .toggle-button {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: $neutrals-3;
    border: 2px solid $main-color;
    border-radius: 50%;
    outline: 0;
    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;

    & > * {
      pointer-events: none;
    }

    &.active {
      & > * {
        transform: rotate(180deg);
      }
    }
  }

  .lock-icon {
    position: absolute;
    top: 50%;
    right: 0.75rem;
    display: block;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
  }

  .group-wrapper {
    position: absolute;
    bottom: 2px;
    left: 0;
    z-index: 2;
    width: 100%;
    height: fit-content;
    overflow: hidden;
    background-color: $neutrals-8;
    border: 2px solid $main-color-active;
    border-top: 0;
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    transform: translateY(100%);
  }

  .group-container {
    @include typo-caption-bold;
    @include scrollbar(rgba($main-color-active, 0.1), $main-color-active);

    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 32px * 4;
    overflow-x: hidden;
    overflow-y: auto;
    color: $neutrals-3;
    background: transparent;
    border: 0;
    outline: none;

    .option {
      display: flex;
      align-items: center;

      padding: 0.25rem 1rem 0.25rem 1rem;
      cursor: pointer;

      &.checkbox-button {
        @include checkbox-button-common(1rem, 0.25rem);
      }

      .label {
        display: inline-flex;
        align-items: center;

        .icon {
          display: inline-flex;
          height: 20px;
          margin-right: 0.5rem;
        }
      }

      &:hover {
        background-color: rgba($main-color, 0.2);
      }

      &.active {
        background-color: rgba($main-color, 0.6);
      }
    }
  }
}

.select-container {
  &:not(.default) {
    @include select-container($neutrals-5, $neutrals-5);
  }

  &.default {
    @include select-container($neutrals-4, $neutrals-3);
  }
}
</style>
