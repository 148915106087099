enum UserStatsFilter {
  FollowersDesc = 'FollowersDesc',
  Followers = 'Followers',
  LikesDesc = 'LikesDesc',
  Likes = 'Likes',
  ViewsDesc = 'ViewsDesc',
  Views = 'Views',
}

export default UserStatsFilter;
