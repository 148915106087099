<template>
  <div class="top-container">
    <div>
      <div class="search-container">
        <input
          id="explore-search"
          type="search"
          placeholder="Search for collectible"
          v-model="search"
        >
        <img src="@/assets/images/common/search.svg" alt="search icon" class="icon">
      </div>

      <div class="input-buttons-container">
        <label v-for="c in categories" :key="c.id" class="button">
          <input
            v-model="categoryIds"
            name="explore-category-ids-options"
            :value="c.id"
            type="checkbox"
            hidden
          >
          <span class="label">
            <span v-if="c.emoji" v-html="c.emoji"></span>
            {{ c.title }}
          </span>
        </label>
      </div>
    </div>
  </div>

  <div class="content-container">
    <div class="filters">
      <div>
        <Select id="explore-order-by" v-model="orderBy" :data="sortOptions"/>
      </div>

      <div>
        <div class="input-container">
          <label for="explore-text-filters">Sale status</label>
          <ItemSaleStatusSelect
            id="explore-sale-status-filters"
            v-model="saleStatus"
            placeholder="Choose sale status"
            :withDefaultOption="true"
          />
        </div>
      </div>

      <div>
        <div class="input-container">
          <label for="explore-creator-id">Creators</label>
          <CreatorSelect id="explore-creator-id" v-model="creatorId"/>
        </div>
      </div>

      <div>
        <div class="input-container">
          <label for="explore-type-id">Types</label>
          <ItemTypeSelect
            id="explore-type-id"
            placeholder="Choose item type"
            :withDefaultOption="true"
            v-model="typeId"
          />
        </div>
      </div>

      <div>
        <div class="input-container">
          <label for="explore-reward-status">Reward status</label>
          <ItemRewardSelect
            id="explore-reward-status"
            placeholder="Choose reward status"
            :withDefaultOption="true"
            v-model="rewardStatus"
          />
        </div>
      </div>

      <div>
        <div class="input-container">
          <label for="explore-text-filters">Filters</label>
          <ItemFilterSelect id="explore-text-filters" v-model="textFilters"/>
        </div>
      </div>

      <label class="nsfw-toggle" for="explore-is-nsfw-shown">
        <span class="label">Show NSFW</span>
        <Toggle v-model="isNSFW" id="explore-is-nsfw-shown" :disabled="isItemsLoading"/>
      </label>

      <div class="filters-options">
        <button @click="resetFilters" class="reset-button">
          <img src="@/assets/images/common/clear.svg" alt="clear icon" class="icon">
          Reset all filters
        </button>
      </div>
    </div>

    <div class="list">
      <div v-if="data.length" ref="scrollComponent" class="row">
        <div v-for="item in data" :key="item.id">
          <ItemCard :data="item" />
        </div>
      </div>
      <div v-if="!isItemsLoading && !data.length" class="empty-container">
        <p>{{ !!search ? 'No items found.' : 'Coming soon.' }}</p>
      </div>
      <ContentLoader :loading="isItemsLoading"/>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import Toggle from '@vueform/toggle';

import useDebouncedRef from '@/shared/hooks/use-debounced-ref';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-list/props';
import PaginationOptions from '@/shared/models/pagination-options';
import RewardStatus from '@/shared/models/reward-status.enum';
import ItemSaleStatus from '@/store/modules/item-profile/models/item-sales-status.enum';

import ItemListOrderByFilter from '@/store/modules/item-list/models/item-list-order-by-filter.enum';
import ItemListFilters from '@/store/modules/item-list/models/item-list-filters';

import ContentLoader from '@/components/ContentLoader.vue';
import ItemCard from '@/components/ItemCard.vue';
import Select from '@/components/Select.vue';
import ItemFilterSelect from '@/components/ItemFilterSelect.vue';
import ItemTypeSelect from '@/components/ItemTypeSelect.vue';
import ItemRewardSelect from '@/components/ItemRewardSelect.vue';
import ItemSaleStatusSelect from '@/components/ItemSaleStatusSelect.vue';

import CreatorSelect from './ItemList/CreatorSelect.vue';

export default defineComponent({
  name: 'ItemList',

  components: {
    ItemRewardSelect,
    ItemTypeSelect,
    CreatorSelect,
    ItemFilterSelect,
    ItemCard,
    Select,
    ContentLoader,
    Toggle,
    ItemSaleStatusSelect,
  },

  setup() {
    const store = useStore();
    const route = useRoute();

    const isItemsLoading = ref<boolean>(false);

    const initialSearch = computed(() => route.query.search);

    const search = useDebouncedRef(initialSearch.value as string ?? '', 400);

    const categoryIds = ref<string[]>([...store.state.ItemList.items.filters.categoryIds]);

    const categories = computed(() => store.state.ItemList.categories);

    const orderBy = ref<ItemListOrderByFilter>(ItemListOrderByFilter.SaleEndingSoon);

    const sortOptions = [
      {
        id: ItemListOrderByFilter.SaleEndingSoon,
        label: 'Sale ending soon',
      },
      {
        id: ItemListOrderByFilter.RecentlyCreated,
        label: 'Recently Created',
      },
      {
        id: ItemListOrderByFilter.RecentlyListed,
        label: 'Recently Listed',
      },
      {
        id: ItemListOrderByFilter.Cheapest,
        label: 'Cheapest',
      },
      {
        id: ItemListOrderByFilter.Expensive,
        label: 'Expensive',
      },
    ];

    const creatorId = ref<string | null>(store.state.ItemList.items.filters.creatorId);

    const typeId = ref<string | null>(store.state.ItemList.items.filters.typeId);

    const rewardStatus = ref<RewardStatus | null>(store.state.ItemList.items.filters.rewardStatus);

    const saleStatus = ref<ItemSaleStatus | null>(store.state.ItemList.items.filters.saleStatus);

    const isNSFW = ref<boolean>(false);

    const textFilters = ref(store.state.ItemList.items.filters.textFilters);

    const data = computed(() => store.state.ItemList.items.data);
    const pagination = computed(() => store.state.ItemList.items.pagination);
    const isLastPage = computed(() => store.state.ItemList.items.isLastPage);

    const scrollComponent = ref<HTMLElement | null>(null);

    const loadItems = (options: Partial<PaginationOptions> & Partial<ItemListFilters>) => {
      isItemsLoading.value = true;
      store.dispatch(`${Modules.ItemList}/${Actions.fetchItems}`, {
        search: search.value,
        categoryIds: [...categoryIds.value],
        orderBy: orderBy.value,
        creatorId: creatorId.value,
        typeId: typeId.value,
        rewardStatus: rewardStatus.value,
        saleStatus: saleStatus.value,
        textFilters: [...textFilters.value],
        isNSFW: isNSFW.value,
        ...options,
      }).finally(() => {
        isItemsLoading.value = false;
      });
    };

    const clearItems = () => {
      store.dispatch(`${Modules.ItemList}/${Actions.clearItems}`);
    };

    const handleScroll = () => {
      const element = scrollComponent.value;
      if (element && !isLastPage.value && !isItemsLoading.value
        && element.getBoundingClientRect().bottom < window.innerHeight
      ) {
        loadItems({ page: pagination.value.page + 1 });
      }
    };

    const resetFilters = () => {
      search.value = '';
      categoryIds.value = [];
      isNSFW.value = false;
      orderBy.value = ItemListOrderByFilter.SaleEndingSoon;
      creatorId.value = null;
      typeId.value = null;
      textFilters.value = [];
    };

    watch(search, (value) => {
      clearItems();
      loadItems({ search: value });
    });

    watch(initialSearch, (value) => {
      search.value = value as string ?? '';
    });

    watch(categoryIds, (value, prevValue) => {
      if (!isEqual(new Set([...value]), new Set([...prevValue]))) {
        clearItems();
        loadItems({ categoryIds: [...value] });
      }
    });

    watch(orderBy, (value) => {
      clearItems();
      loadItems({ orderBy: value });
    });

    watch(creatorId, (value) => {
      clearItems();
      loadItems({ creatorId: value });
    });

    watch(typeId, (value) => {
      clearItems();
      loadItems({ typeId: value });
    });

    watch(rewardStatus, (value) => {
      clearItems();
      loadItems({ rewardStatus: value });
    });

    watch(saleStatus, (value) => {
      clearItems();
      loadItems({ saleStatus: value });
    });

    watch(textFilters, (value, prevValue) => {
      if (!isEqual(new Set([...value]), new Set([...prevValue]))) {
        clearItems();
        loadItems({ textFilters: [...value] });
      }
    });

    watch(isNSFW, (value) => {
      clearItems();
      loadItems({ isNSFW: value });
    });

    onMounted(() => {
      isNSFW.value = store.state.ItemList.items.filters.isNSFW;
      store.dispatch(`${Modules.ItemList}/${Actions.fetchCategories}`);

      loadItems({ page: 1 });

      window.addEventListener('scroll', throttle(handleScroll, 800, { leading: false }));
    });

    onUnmounted(() => {
      clearItems();

      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isItemsLoading,
      search,
      categoryIds,
      categories,
      orderBy,
      sortOptions,
      creatorId,
      typeId,
      rewardStatus,
      saleStatus,
      isNSFW,
      textFilters,
      data,
      pagination,
      scrollComponent,
      ItemListOrderByFilter,
      resetFilters,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.top-container {
  @include items-wrapper;

  margin-top: -2rem;

  @include for-md-width {
    justify-content: center;
  }

  & > * {
    width: 100%;

    @include for-md-width {
      max-width: 366px * 2;
    }

    @include for-xs-sm-width {
      max-width: 366px;
    }
  }

  .search-container {
    @include large-search-container;
  }
}

.content-container {
  @include items-wrapper;

  @include for-md-width {
    justify-content: center;
  }

  & > * {
    @include for-md-width {
      width: 100%;
      max-width: 366px * 2;
    }

    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }
  }

  .filters {
    display: flex;
    flex-direction: column;

    @include for-lg-xl-width {
      width: 288px;
    }

    & > * {
      &:not(:first-child) {
        padding-top: 1.5rem;
      }

      &:not(:last-child) {
        padding-bottom: 1.5rem;
        border-bottom: 1px solid $container-border-color;
      }
    }
  }

  .list {
    @include for-lg-xl-width {
      width: calc(100% - 288px);
    }
  }
}

.input-buttons-container {
  @include input-buttons-container;

  margin-top: 1.25rem;
}

.input-container {
  @include input-container;
}

.nsfw-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  /* stylelint-disable-next-line no-descending-specificity */
  .label {
    @include typo-body-2-bold;

    margin-right: 0.5rem;
    color: $neutrals-2;
  }
}

.filters-options {
  .reset-button {
    @include typo-button-2;
    @include transition-default;

    display: inline-flex;
    align-items: center;
    color: $neutrals-2;
    opacity: 0.5;

    &:hover, &:focus {
      opacity: 1;
    }

    .icon {
      width: 24px;
      margin-right: 0.5rem;
    }
  }
}

.row {
  @include items-wrapper;

  margin-top: -2rem;

  /* stylelint-disable-next-line no-descending-specificity */
  & > * {
    width: 33.33%;

    @include for-lg-width {
      width: 50%;
    }

    @include for-md-width {
      width: 50%;
    }

    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }
  }
}

.empty-container {
  @include empty-container;
}
</style>
