// instances
import http from '@/instances/http';

// models
import SearchSuggestions from '@/store/modules/common-service/models/search-suggestions';

const CommonApi = {
  async reqSearchSuggestions(search: string): Promise<SearchSuggestions> {
    const res = await http.get('/search', { params: { search } });
    return res.data;
  },
  async sendFirstVisitEmail(email: string, url: string): Promise<void> {
    await http.post('/email-gathering', { email, url });
  },
};

export default CommonApi;
