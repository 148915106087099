
import {
  defineComponent, ref, watch, computed,
} from 'vue';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-list/props';

import InlineSvg from 'vue-inline-svg';

import svgIconUrl from '@/shared/helpers/svg-icon-url';
import formatNumber from '@/shared/helpers/format-number';

import CheckboxItem from '@/components/CheckboxItem.vue';

enum NftStatus {
  Minted = 'Minted',
  'On sale' = 'OnSale',
  'On auction' = 'OnEnglishAuction',
  'Offchain auction' = 'OnOffChainOfferAuction',
}

interface CheckboxState {
  checked: boolean,
  value: number,
  label: string,
}

interface loadOptions {
  followersFilter?: string,
  creatorsFilter?: string,
  creatorItemsFilters?: string,
}

interface CreatorOptions {
  id: string,
  label: string,
  value: number,
}

export default defineComponent({
  name: 'ExpansionCard',

  components: {
    InlineSvg,
    CheckboxItem,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    data: {
      type: Array as () => CreatorOptions[],
      default: () => [],
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();

    const isExpanded = ref<boolean>(false);
    const dataFetched = ref<boolean>(false);
    const computedTotal = ref<number>(props.total);
    const selectedSocials = ref<string[]>(['TikTok', 'Instagram', 'Twitter', 'Youtube', 'Twitch']);
    const selectedCreators = ref<string[]>(['Verified', 'Unlinked']);
    const selectedNfts = ref<string[]>(['Minted', 'OnSale', 'OnEnglishAuction', 'OnOffChainOfferAuction']);

    const filters = computed(() => store.state.UserList.stats.filters);

    const socials = [...selectedSocials.value];
    const statuses = [...selectedCreators.value];
    const nfts = ['Minted', 'On sale', 'On auction', 'Offchain auction'];

    const clearRankedUsers = () => {
      store.dispatch(`${Modules.UserList}/${Actions.clearRankedUsers}`);
    };

    const loadUsers = (options: loadOptions) => {
      store.dispatch(`${Modules.UserList}/${Actions.fetchRankedUsers}`, {
        offset: filters.value.offset,
        followersFilter: options.followersFilter,
        creatorsFilter: options.creatorsFilter || filters.value.creatorsFilter,
        creatorItemsFilters: options.creatorItemsFilters || filters.value.creatorItemsFilters,
        sortBy: filters.value.sortBy,
        pageSize: filters.value.pageSize,
        isNoneItemsCreated: filters.value.isNoneItemsCreated,
      });
    };

    const updateSocialFollowersStats = (options: CheckboxState): void => {
      // filter creators list by selected socials
      if (socials.includes(options.label)) {
        selectedSocials.value = options.checked
          ? [...selectedSocials.value, options.label]
          : selectedSocials.value.filter((label) => label !== options.label);
        clearRankedUsers();
        loadUsers({
          followersFilter: selectedSocials.value.toString(),
        });
      }

      // filter creators list by registered status
      if (statuses.includes(options.label)) {
        selectedCreators.value = options.checked
          ? [...selectedCreators.value, options.label]
          : selectedCreators.value.filter((label) => label !== options.label && label !== 'Unregistered');
        clearRankedUsers();
        loadUsers({
          creatorsFilter: selectedCreators.value.toString().replace('Unlinked', 'Unregistered'),
        });
      }

      // filter creators list by selected NFTs
      if (nfts.includes(options.label)) {
        selectedNfts.value = options.checked
          ? [...selectedNfts.value, NftStatus[options.label as keyof typeof NftStatus]]
          : selectedNfts.value
            .filter((label) => label !== NftStatus[options.label as keyof typeof NftStatus]);
        clearRankedUsers();
        loadUsers({
          creatorItemsFilters: selectedNfts.value.toString(),
        });
      }

      // compute total
      if (options.checked) {
        if (computedTotal.value === props.total) {
          computedTotal.value = 0;
        }
        computedTotal.value += options.value;
      } else {
        computedTotal.value -= options.value;
        if (computedTotal.value === 0) {
          computedTotal.value = props.total;
        }
      }
    };

    watch(
      () => props.total,
      () => {
        dataFetched.value = true;
        if (props.title === 'Creators' && dataFetched.value) {
          computedTotal.value = props.total - props.data[1].value;
          return;
        }
        computedTotal.value = props.total;
      },
    );

    return {
      isExpanded,
      svgIconUrl,
      computedTotal,
      formatNumber,
      updateSocialFollowersStats,
      filters,
    };
  },
});
