
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from 'vue';
import VueHorisontal from 'vue-horizontal';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-list/props';

// import CryptoPrice from '@/components/CryptoPrice.vue';
import UserImage from '@/components/UserImage.vue';
import ContentLoader from '@/components/ContentLoader.vue';

export default defineComponent({
  name: 'FeaturedCreators',

  components: {
    VueHorisontal,
    // CryptoPrice,
    UserImage,
    ContentLoader,
  },

  setup() {
    const store = useStore();

    const isUsersLoading = ref<boolean>(false);

    const data = computed(() => store.state.UserList.featuredUsers);

    const hasPrevItems = ref(false);
    const hasNextItems = ref(true);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const horizontal = ref<any>(null);

    const loadUsers = () => {
      isUsersLoading.value = true;
      store.dispatch(`${Modules.UserList}/${Actions.fetchFeaturedUsers}`).finally(() => {
        isUsersLoading.value = false;
      });
    };

    const prev = (e: Event) => {
      if (horizontal.value) {
        horizontal.value.prev(e);
      }
    };

    const next = (e: Event) => {
      if (horizontal.value) {
        horizontal.value.next(e);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onScroll = ({ hasNext, hasPrev }: any) => {
      hasNextItems.value = hasNext;
      hasPrevItems.value = hasPrev;
    };

    onMounted(() => {
      loadUsers();
    });

    return {
      isUsersLoading,
      data,
      hasNextItems,
      hasPrevItems,
      horizontal,
      next,
      prev,
      onScroll,
    };
  },
});
