<template>
  <ExpansionCard
    id="item-profile-banner"
    :title="`${userNickname} hasn't joined Melon yet 👀`"
    :titleTextPos="'left'"
    :titleTextColor="'#45B36B'"
    :expanded="expanded"
    @expanded="toggleExpanded">
    <ul class="list">
      <li class="list-item">
        Melon allows fans to simply request and express interest in buying creators’
        publicly available social content as NFTs.
      </li>
      <li class="list-item">
        Melon does <span class="text-emphasis">NOT</span>
        own the content, nor is officially associated with {{ userNickname }}.
      </li>
      <li class="list-item">
        The creator can claim their account on MELON and mint the requested NFT,
        which fans will then be able to officially bid on.
      </li>
    </ul>
    <button class="button" @click="toggleExpanded(!expanded)">I Got It 👍</button>
  </ExpansionCard>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import ExpansionCard from '@/components/ExpansionCard.vue';

export default defineComponent({
  components: {
    ExpansionCard,
  },
  props: {
    userNickname: {
      type: String,
      default: '',
    },
  },
  setup() {
    const expanded = ref<boolean>(true);

    const toggleExpanded = (newVal: boolean) => {
      expanded.value = newVal;
    };

    return {
      expanded,
      toggleExpanded,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.list {
  display: block;
  padding-left: 40px;
  line-height: 24px;

  .list-item {
    display: list-item;
    margin-bottom: 10px;
    list-style-type: disc;
  }
}

.button {
  width: 100%;
  margin-top: 20px;
  padding: 12px 0;
  border: 2px solid $primary-4;
  border-radius: 90px;
}

.text-emphasis {
  color: $primary-4;
  font-weight: 500;
}
</style>
