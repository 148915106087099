<template>
  <footer class="main-column-wrapper">
    <div class="nav-container">
      <router-link :to="{ name: 'home' }" class="logo-link">
        <img src="@/assets/images/logo.svg" alt="Melon logo">
      </router-link>
      <nav>
        <div>
          <router-link :to="{ name: 'home' }">Home</router-link>
          <router-link :to="{ name: 'item-list' }">NFT marketplace</router-link>
          <router-link :to="{ name: 'user-list' }">Community</router-link>
        </div>
        <div>
          <a href="https://www.melon.ooo" target="_blank">
            Discover MELON
          </a>
          <a href="https://info.melon.ooo/en/collections/3119186-getting-started" target="_blank">
            Getting Started
          </a>
          <a href="https://info.melon.ooo/en/articles/5572505-introduction-to-nfts" target="_blank">
            FAQ
          </a>
          <a href="https://info.melon.ooo/en/articles/5606500-how-to-buy-nfts-on-the-polygon-network" target="_blank">
            How to buy NFTs on the Polygon Network
          </a>
          <a href="https://info.melon.ooo/en/articles/5608639-everything-you-need-to-know-about-the-melon-token-coming-soon" target="_blank">
            About the $MELON Token
          </a>
          <a href="https://info.melon.ooo/en/collections/3082913-partner-with-melon" target="_blank">
            Partnerships
          </a>
          <button @click="openIntercomBot">Contact us</button>
          <a href="https://info.melon.ooo/en/collections/3157375-about-melon" target="_blank">
            About Melon
          </a>
        </div>
        <div>
          <router-link :to="{ name: 'terms-and-conditions' }">Terms & Conditions</router-link>
          <router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>
        </div>
      </nav>
    </div>

    <div class="split-container">
      <div class="horizontal-split"></div>
    </div>

    <div class="claims-container">
      <span class="copyright">Copyright © 2021 MELON. All rights reserved</span>
      <Socials />
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Socials from './Socials.vue';

export default defineComponent({
  name: 'Footer',

  components: {
    Socials,
  },

  setup() {
    async function openIntercomBot() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).Intercom) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).Intercom('show');
      }
    }

    return {
      openIntercomBot,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

footer {
  flex-shrink: 0; // sticky footer
  background-color: $background-color-secondary;
  border-top: 1px solid $container-border-color;
}

.nav-container {
  padding-top: 5rem;
  padding-bottom: 1rem;

  .logo-link {
    display: inline-flex;
  }

  nav {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.75rem;
    padding: 1.25rem 0;

    & > * {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 280px;
      padding: 0 1rem;
    }

    a, button {
      @include link;
      padding: 0.75rem;
      text-align: left;

      &.active {
        color: $secondary-1;
      }
    }
  }
}

.split-container {
  @include for-xs-sm-md-lg-width {
    @include full-bleed;
  }

  .horizontal-split {
    width: 100%;
    height: 1px;
    background: $container-border-color;
  }
}

.claims-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include for-xs-sm-width {
    flex-direction: column;
    text-align: center;

    & > *:not(:first-child) {
      margin-top: 1.5rem;
    }
  }

  .copyright {
    @include typo-caption-2;

    color: $neutrals-4;
  }
}
</style>
