
import { defineComponent } from 'vue';

// instances
import Tooltip from '@/instances/tooltip';

// models
import CryptoCurrency from '@/shared/models/crypto-currency.enum';

export default defineComponent({
  name: 'CryptoPrice',
  components: { Tooltip },
  props: {
    amount: {
      required: true,
    },
    currency: {
      required: true,
    },
  },

  setup() {
    // eslint-disable-next-line
    function navigateWeth(e: any): void {
      e.preventDefault();
      window.open('https://polygonscan.com/address/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', '_blank');
    }

    return {
      CryptoCurrency,
      navigateWeth,
    };
  },
});
