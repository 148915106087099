
import { defineComponent, ref, computed } from 'vue';

import { useStore } from '@/store';

import formatNumber from '@/shared/helpers/format-number';
import userImageUrl from '@/shared/helpers/user-image-url';
import UserStatsItem from '@/store/modules/user-list/models/user-stats-item';

export default defineComponent({
  props: {
    data: {
      type: Object as () => UserStatsItem,
    },
    index: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    const store = useStore();

    const showInnerRow = ref<boolean>(false);
    const followersFilters = computed(() => store.state.UserList.stats.filters.followersFilter);

    return {
      showInnerRow,
      formatNumber,
      userImageUrl,
      followersFilters,
    };
  },
});
