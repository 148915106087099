<template>
  <div class="info-card">
    <ItemProfileListItem
      :userNickname="item.creatorNickname"
      :photoPath="item.creatorPhotoIpfsPath"
      :isVerified="item.creatorVerifiedStatus"
      :isRegistered="item.isCreatorRegistered"
      :hasBottomPadding="false"
    >
      <div class="list-item-content">
        <span class="role">
          <template v-if="item.creatorVerifiedStatus || item.isCreatorRegistered">
            Creator
          </template>
          <template v-else>
            Unregistered Creator
            <InfoTooltip
              :label="'This creator is not yet registered on the platform. '
                + 'You can name your price for this item & invite them to sell'"
            />
          </template>
        </span>
        <span class="name">{{ item.creatorName }}</span>
      </div>
    </ItemProfileListItem>

    <div class="main-info">
      <h2 class="title">{{ item.title }}</h2>
      <p class="edition">#{{ item.id }} {{ item.edition }}</p>

      <Markdown
        v-if="item.description"
        :data="itemDescription"
        :isMarked="item.isDescriptionMarked"
        class="description"
      />

      <div>
        <button class="show-more-btn" @click="showMoreDescription = !showMoreDescription">
          <span v-if="!showMoreDescription">Show more</span>
          <span v-else>Show less</span>
        </button>
      </div>
      <div class="properties">
        <span v-for="c in item.categories" :key="c.id">{{ c.title }}</span>
        <span v-for="f in item.filters" :key="f.id" v-html="f.title"></span>
      </div>

      <ItemProfileTabs :item="item" class="tabs" />

      <template v-if="isOwner">
        <router-link
          v-if="isEditor && (item.salesStatus === SaleStatus.Minted || isItemSelling)"
          class="outline-button"
          :to="{ name: 'edit-item', params: { id: item.id } }"
        >
          Edit details
        </router-link>
        <button
          v-if="item.salesStatus === SaleStatus.Minted || item.salesStatus === SaleStatus.Sold"
          class="outline-button"
          :disabled="!isMainNet"
          @click="isPutOnSaleModalOpen = true"
        >
          Put on sale
        </button>
      </template>
    </div>

    <SaleModal
      key="item-profile-sale-form"
      :isOpen="isPutOnSaleModalOpen"
      formId="item-profile-sale-form"
      :title="`Put “${item?.title}” on sale`"
      submitButtonLabel="Put on sale"
      @setOnSale="putItemOnSale"
      @setOnEnglishAuction="putItemOnEnglishAuction"
      @setOnDutchAuction="putItemOnDutchAuction"
      @close="isPutOnSaleModalOpen = false"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, ref, computed, watch,
} from 'vue';

// store
import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-profile/props';

// models
import Item from '@/store/modules/item-profile/models/item';
import ItemSaleData from '@/store/modules/item-profile/models/item-sale-data';

// components
import ItemProfileTabs from './ItemProfileTabs.vue';
import ItemProfileListItem from './ItemProfileListItem.vue';
import Markdown from '@/components/Markdown.vue';
import InfoTooltip from '@/components/InfoTooltip.vue';
import SaleModal from '@/components/SaleModal.vue';

import {
  ITEM_SELLING_SET_SUCCESS,
  ERROR_UNKNOWN,
  ACTION_SUCCESS_DEFAULT,
} from '@/shared/constants/messages';

export default defineComponent({
  props: {
    item: {
      type: Object as () => Item,
      required: true,
    },
  },
  components: {
    ItemProfileListItem,
    ItemProfileTabs,
    Markdown,
    InfoTooltip,
    SaleModal,
  },
  setup(props) {
    const store = useStore();

    // refs
    const isLoading = ref<boolean>(false);
    const isOwner = ref<boolean>(false);
    const showMoreDescription = ref<boolean>(false);
    const isEditor = ref<boolean>(false);
    const isPutOnSaleModalOpen = ref<boolean>(false);
    const SHORT_CONTENT_COUNT = ref<number>(90);

    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });
    const success = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });

    // computed
    const currentUserId = computed(() => store.state.user.id);
    const itemDescription = computed(() => (!showMoreDescription.value
      ? props.item?.description.substring(0, SHORT_CONTENT_COUNT.value)
      : props.item?.description));

    // methods
    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    function buildSuccessModal(text: string): void {
      success.value = {
        isOpen: true,
        text: text || ACTION_SUCCESS_DEFAULT,
      };
    }

    async function putItemOnSale(data: ItemSaleData) {
      isPutOnSaleModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.setItemForSale}`, data);
        buildSuccessModal(ITEM_SELLING_SET_SUCCESS);
      } catch (e: any) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function putItemOnEnglishAuction(data: ItemSaleData) {
      isPutOnSaleModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.setItemsForEnglishAuction}`, { ...data, ids: [props.item?.id] });
        buildSuccessModal(ITEM_SELLING_SET_SUCCESS);
      } catch (e: any) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function putItemOnDutchAuction(data: ItemSaleData) {
      isPutOnSaleModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.setItemsForDutchAuction}`, { ...data, ids: [props.item?.id] });
        buildSuccessModal(ITEM_SELLING_SET_SUCCESS);
      } catch (e: any) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    // watch
    watch([props.item, currentUserId], ([value, userId]) => {
      isOwner.value = !!userId && userId === value?.ownerId;
      isEditor.value = isOwner.value && userId === value?.creatorId && !value.isSold;
    });

    return {
      itemDescription,
      showMoreDescription,
      isOwner,
      isEditor,
      isPutOnSaleModalOpen,
      putItemOnSale,
      putItemOnEnglishAuction,
      putItemOnDutchAuction,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

$info-max-width: 434px;

.info-card {
  @include card;
  @include box-shadow-default;
  @include typo-body-2;

  position: relative;
  display: flex;
  flex-direction: column;
  max-width: $info-max-width;
  padding: 1rem;
  overflow: visible;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  .main-info {
    height: 400px;
    overflow-y: scroll;
  }

  .heading-container {
    display: flex;
    align-items: center;

    .spacer {
      flex-grow: 1;
    }

    .icon {
      display: block;
      width: 3rem;
      height: 3rem;
      margin-right: 0.75rem;
      border-radius: 50%;

      &.with-shadow {
        box-shadow: 0 0 1.75rem -0.5rem rgba(15, 15, 15, 0.2);
      }
    }
  }

  &.with-overflow {
    overflow: unset;
  }

  .title {
    @include typo-headline-3;
    margin-top: 20px;
    color: $neutrals-2;
    word-break: break-word;
  }

  .small-title {
    @include typo-body-2;
    color: $neutrals-3;
    font-weight: bold;
  }

  .show-more-btn {
    @include typo-caption-bold;
    margin-top: 0.5rem;
    color: $primary-1;
  }

  .extra-content {
    padding-top: 1rem;
    color: $neutrals-2;
  }

  .edition {
    @include typo-button-1;

    margin-top: 0.5rem;
    color: $neutrals-4;
  }

  .list-item-content {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 64px);

    & > * {
      word-break: break-word;
    }

    .role {
      @include typo-caption;
      display: inline-flex;
      align-items: center;
      color: $neutrals-4;
    }

    .name {
      @include typo-caption-bold;
      @include text-ellipsis;
      margin-top: 2px;
      color: $neutrals-2;
    }
  }

  .properties {
    display: flex;
    flex-wrap: wrap;

    &:not(:empty) {
      margin-top: 1.25rem;
      margin-right: -0.5rem;
      margin-bottom: 1.25rem;
      margin-left: -0.5rem;
    }

    & > * {
      @include typo-button-2;
      @include transition-default;

      display: inline-flex;
      margin: 0.75rem 0.5rem 0;
      padding: 8px 14px;
      color: $neutrals-3;
      background: transparent;
      border: 1px solid $neutrals-4;
      border-radius: 100px;
    }
  }

  .offchain-offer-description {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &.has-padding {
      margin-bottom: 1rem;
    }
  }

  .offchain-highest-bid {
    margin-bottom: 0.5rem;
  }

  .auction-ending {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .dutch-auction-info {
    margin-top: 1rem;

    & > *:not(:first-child) {
      margin-top: 0.25rem;
    }
  }

  .price-label {
    @include typo-button-1;
    color: $neutrals-3;

    .reserve-price {
      margin-left: 10px;
      color: $neutrals-4;
    }
  }

  .price {
    @include typo-body-1;

    margin-top: 0.5rem;
  }

  .user-offer {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .price {
      @include typo-body-2;
    }
  }

  .outline-button {
    @include typo-button-1;
    @include button-outline($neutrals-1, $control-border-color);

    padding: 13px 1rem;
    text-align: center;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  .primary-button {
    @include typo-button-1;
    @include button-primary-filled;

    padding: 13px 1rem;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  .text-button {
    @include typo-button-1;

    margin-right: auto;
    margin-left: auto;
    color: $primary-1;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  .helper-text {
    @include typo-body-2;

    margin-top: 1.75rem;
    color: $neutrals-3;
    text-align: center;

    .helper-tooltip-link {
      color: $primary-1;
      text-decoration: underline;
    }

    @media (max-width: 370px) {
      align-self: center;
      width: 7.5rem;
    }
  }
}
</style>
