enum Mutations {
  setUser = 'setUser',
  updateFollowingStatus = 'updateFollowingStatus',
  updateFollowingCounter = 'updateFollowingCounter',
  setItems = 'setItems',
  setSocialFollowersStats = 'setSocialFollowersStats',
  setGlobalUsersStats = 'setGlobalUsersStats',
}

export default Mutations;
