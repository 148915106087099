export const TIKTOK_TYPE_ID = 'd69231dd-c4bc-4d52-ad5e-ce3b1327eeb8';
export const INSTAGRAM_TYPE_ID = 'b0fa59c6-445a-4eeb-a3e5-4f515ff8e167';
export const TWITTER_TYPE_ID = '1AACD248-5EBC-4D2D-AC75-6382D9F32EE8';
export const YOUTUBE_TYPE_ID = 'c6b8585d-23aa-4012-ba95-07180f2a66fa';
export const TWITCH_TYPE_ID = 'f0a86010-b719-4768-aa9a-a1cec5fb723c';

export function isTikTokType(typeId?: string | null): boolean {
  return typeId === TIKTOK_TYPE_ID;
}

export function isInstagramType(typeId?: string | null): boolean {
  return typeId === INSTAGRAM_TYPE_ID;
}

export function isTwitterType(typeId?: string | null): boolean {
  return typeId === TWITTER_TYPE_ID;
}

export function isYouTubeType(typeId?: string | null): boolean {
  return typeId === YOUTUBE_TYPE_ID;
}

export function isTwitchType(typeId?: string | null): boolean {
  return typeId === TWITCH_TYPE_ID;
}

export function isOEmbedType(typeId?: string | null): boolean {
  return [isTikTokType, isInstagramType, isTwitterType, isYouTubeType].some((fn) => fn(typeId));
}

export function isExternalType(typeId?: string | null): boolean {
  return isOEmbedType(typeId) || isTwitchType(typeId);
}

export function getUrlRegExpByTypeId(typeId?: string | null): RegExp {
  switch (typeId) {
    case TIKTOK_TYPE_ID:
      return /https:\/\/www\.tiktok\.com\/([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/;
    case INSTAGRAM_TYPE_ID:
      return /https:\/\/(www\.)?(instagram\.com|instagr\.am)\/([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/;
    case TWITTER_TYPE_ID:
      return /https:\/\/(www\.)?(twitter\.com)\/([-a-zA-Z0-9()!@:%_+.~#?&//=]*)\/status\/([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/;
    case YOUTUBE_TYPE_ID:
      return /https:\/\/((www\.)?youtube\.com|youtu\.be)\/([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/;
    case TWITCH_TYPE_ID:
      return /https:\/\/clips\.twitch\.tv\/([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/;
    default:
      return /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}([-a-zA-Z0-9()!@:%_+.~#?&/=]*)/;
  }
}
