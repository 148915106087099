
import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import debounce from 'lodash/debounce';

// store
import { useStore } from '@/store';
import { Actions, Modules, Mutations } from '@/store/props';
import { Actions as CommonServiceActions } from '@/store/modules/common-service/props';

// components
import InfoModal from '@/components/InfoModal.vue';
import Suggestions from '@/components/Suggestions.vue';
import ConnectButton from './ConnectButton.vue';
import Socials from './Socials.vue';

// hooks
import useWindowResize from '@/shared/hooks/use-window-resize';

// models
import SearchSuggestions from '@/store/modules/common-service/models/search-suggestions';

// constants
import { ERROR_UNKNOWN } from '@/shared/constants/messages';

export default defineComponent({
  name: 'Header',

  components: {
    Suggestions,
    ConnectButton,
    InfoModal,
    Socials,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { width, height } = useWindowResize();

    const isMainNet = computed(() => store.state.user.isMainNet);
    const networkName = computed(() => process.env.VUE_APP_NETWORK_NAME);
    const banner = computed(() => store.state.banner);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    const isMenuOpen = ref(false);

    const search = ref('');

    const suggestionRef = ref<HTMLElement | null>(null);
    const suggestions = ref<null | SearchSuggestions>(null);
    const showSuggestions = ref<boolean>(false);

    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });

    async function reqSuggestions(): Promise<void> {
      try {
        suggestions.value = await store.dispatch(
          `${Modules.CommonService}/${CommonServiceActions.reqSearchSuggestions}`,
          search.value,
        );
      } catch (e) {
        console.error(e);
      }
    }

    const suggestionsDebounceReq = debounce(reqSuggestions, 400);

    const closeMenu = () => {
      isMenuOpen.value = false;
    };

    const onSearch = () => {
      if (search.value) {
        router.push({ name: 'item-list', query: { search: search.value } });
        closeMenu();
      }
    };

    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    async function switchNetwork() {
      store.commit(Mutations.setLoading, true);
      try {
        await store.dispatch(Actions.switchEthNetwork);
      } catch (err) {
        buildErrorModal(err.message);
      } finally {
        store.commit(Mutations.setLoading, false);
      }
    }

    async function openIntercomBot() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).Intercom) {
        closeMenu();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).Intercom('show');
      }
    }

    function closeDropdownOnBlur(e: FocusEvent): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let parent: any = e.relatedTarget;

      while (parent) {
        if (parent === suggestionRef.value) {
          break;
        }

        parent = parent.parentNode;
      }

      if (!parent) {
        showSuggestions.value = false;
      }
    }

    watch(isMenuOpen, () => {
      document.body.classList.toggle('has-overlay');
    });

    watch(width, (value) => {
      if (isMenuOpen.value && value && value >= 1200) {
        closeMenu();
      }
    });

    watch(() => route.path, () => {
      if (isMenuOpen.value) {
        closeMenu();
      }
    });

    watch(search, (value) => {
      if (value) {
        suggestionsDebounceReq();
      } else {
        suggestionsDebounceReq.cancel();
        suggestions.value = null;
      }
    });

    return {
      isAuthenticated,
      error,
      isMainNet,
      networkName,
      banner,
      width,
      height,
      isMenuOpen,
      search,
      suggestionRef,
      suggestions,
      showSuggestions,
      closeDropdownOnBlur,
      onSearch,
      closeMenu,
      switchNetwork,
      openIntercomBot,
    };
  },
});
