enum CryptoCurrency {
  MATIC = 'MATIC',
  MELON = 'MELON',
  WETH = 'WETH',
}

const currencyToNumberMap = {
  [CryptoCurrency.MATIC]: 0,
  [CryptoCurrency.MELON]: 1,
  [CryptoCurrency.WETH]: 2,
};

const numberToCurrencyMap = [
  CryptoCurrency.MATIC,
  CryptoCurrency.MELON,
  CryptoCurrency.WETH,
];

export { currencyToNumberMap, numberToCurrencyMap };

export default CryptoCurrency;
