<template>
  <div class="top-container">
    <div>
      <div class="search-container">
        <input
          id="community-search"
          type="search"
          placeholder="Search for creator"
          v-model="search"
        >
        <img src="@/assets/images/common/search.svg" alt="search icon" class="icon">
      </div>
    </div>
  </div>

  <div class="content-container">
    <div class="filters">
      <div>
        <Select id="community-order-by" v-model="orderBy" :data="sortOptions"/>
      </div>

      <div>
        <div class="input-container">
          <label for="community-is-verified">Creators</label>
          <Select id="community-is-verified" v-model="isVerified" :data="verifiedOptions"/>
        </div>
      </div>
    </div>

    <div class="list">
      <div v-if="data.length" ref="scrollComponent" class="row">
        <div v-for="user in data" :key="user.userId">
          <router-link
            :to="{ name: 'user-profile', params: { userNickname: user.nickname }}"
            class="user-card"
          >
            <UserImage
              :userName="user.name"
              :userImagePath="user.photoIpfsPath"
              :isUserVerified="user.isVerified"
              :isUserRegistered="user.isRegistered"
              imageSize="112px"
              iconSize="24px"
              class="user-image-container"
            />
            <h3 class="name">{{ user.name || user.nickname }}</h3>
            <span class="nickname">@{{ user.nickname }}</span>
          </router-link>
        </div>
      </div>
      <div v-if="!isUsersLoading && !data.length" class="empty-container">
        <p>{{ !!search ? 'No users found.' : 'Coming soon.' }}</p>
      </div>
      <ContentLoader :loading="isUsersLoading"/>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import throttle from 'lodash/throttle';

import useDebouncedRef from '@/shared/hooks/use-debounced-ref';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-list/props';
import PaginationOptions from '@/shared/models/pagination-options';

import UserListFilters from '@/store/modules/user-list/models/user-list-filters';
import UserItemFilter from '@/store/modules/user-list/models/user-item-filter.enum';

import ContentLoader from '@/components/ContentLoader.vue';
import Select from '@/components/Select.vue';
import UserImage from '@/components/UserImage.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'UserList',

  components: {
    ContentLoader,
    Select,
    UserImage,
  },

  setup() {
    const store = useStore();
    const route = useRoute();

    const isUsersLoading = ref<boolean>(false);

    const initialSearch = computed(() => route.query.search);

    const search = useDebouncedRef(initialSearch.value as string ?? '', 400);

    const orderBy = ref<UserItemFilter>(store.state.UserList.users.filters.orderBy);

    const isVerified = ref<string | boolean>('');

    const sortOptions = [
      {
        id: UserItemFilter.MaxItemsOnSale,
        label: 'Most items on sale',
      },
      {
        id: UserItemFilter.Collectibles,
        label: 'Collected',
      },
      {
        id: UserItemFilter.Created,
        label: 'Created',
      },
    ];

    const verifiedOptions = [
      {
        id: false,
        label: 'Non-verified',
      },
      {
        id: true,
        label: 'Verified Only',
      },
      {
        id: '',
        label: 'All',
      },
    ];

    const data = computed(() => store.state.UserList.users.data);
    const pagination = computed(() => store.state.UserList.users.pagination);
    const isLastPage = computed(() => store.state.UserList.users.isLastPage);

    const scrollComponent = ref<HTMLElement | null>(null);

    const loadUsers = (options: Partial<PaginationOptions> & Partial<UserListFilters>) => {
      isUsersLoading.value = true;
      store.dispatch(`${Modules.UserList}/${Actions.fetchUsers}`, {
        search: search.value,
        orderBy: orderBy.value,
        isVerified: isVerified.value,
        ...options,
      }).finally(() => {
        isUsersLoading.value = false;
      });
    };

    const clearUsers = () => {
      store.dispatch(`${Modules.UserList}/${Actions.clearUsers}`);
    };

    const handleScroll = () => {
      const element = scrollComponent.value;
      if (element && !isLastPage.value && !isUsersLoading.value
        && element.getBoundingClientRect().bottom < window.innerHeight
      ) {
        loadUsers({ page: pagination.value.page + 1 });
      }
    };

    watch(search, (value) => {
      clearUsers();
      loadUsers({ search: value });
    });

    watch(orderBy, (value) => {
      clearUsers();
      loadUsers({ orderBy: value });
    });

    watch(isVerified, (value) => {
      clearUsers();
      loadUsers({ isVerified: value });
    });

    onMounted(() => {
      loadUsers({ page: 1 });

      window.addEventListener('scroll', throttle(handleScroll, 800, { leading: false }));
    });

    onUnmounted(() => {
      clearUsers();

      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isUsersLoading,
      search,
      orderBy,
      sortOptions,
      isVerified,
      verifiedOptions,
      data,
      pagination,
      scrollComponent,
      UserItemFilter,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.top-container {
  @include items-wrapper;

  margin-top: -2rem;

  @include for-md-width {
    justify-content: center;
  }

  & > * {
    width: 100%;

    @include for-md-width {
      max-width: 366px * 2;
    }

    @include for-xs-sm-width {
      max-width: 366px;
    }
  }

  .search-container {
    @include large-search-container;
  }
}

.content-container {
  @include items-wrapper;

  @include for-md-width {
    justify-content: center;
  }

  .filters {
    width: 288px;

    @include for-md-width {
      width: 100%;
      max-width: 366px * 2;
    }

    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }

    & > * {
      &:not(:first-child) {
        padding-top: 1.5rem;
      }

      &:not(:last-child) {
        padding-bottom: 1.5rem;
        border-bottom: 1px solid $container-border-color;
      }
    }
  }

  .list {
    width: calc(100% - 288px);

    @include for-md-width {
      width: 100%;
      max-width: 366px * 2;
    }

    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }
  }
}

.input-container {
  @include input-container;
}

.row {
  @include items-wrapper;

  margin-top: -2rem;

  /* stylelint-disable-next-line no-descending-specificity */
  & > * {
    width: 33.33%;

    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }
  }
}

.user-card {
  @include user-card;

  @include for-xs-sm-md-lg-width {
    @include box-shadow-mobile;
  }

  .user-image-container {
    margin-bottom: 1rem;
  }

  .name {
    @include typo-caption-bold;
    @include text-ellipsis;

    max-width: 90%;
    color: $neutrals-2;
  }

  .nickname {
    @include typo-caption-2-bold;
    @include text-ellipsis;

    max-width: 90%;
    color: $neutrals-2;
  }
}

.empty-container {
  @include empty-container;
}
</style>
