import { Module } from 'vuex';
import isEqual from 'lodash/isEqual';

// apis
import ItemListApi from '@/api/item-list.api';

// global
import GlobalState from '@/store/models/global-state';

// shared
import PaginationOptions from '@/shared/models/pagination-options';

// module types
import { Actions, Mutations } from './props';

// models
import CategorySimplified from './models/category-simplified';
import ItemTypeSimplified from './models/item-type-simplified';
import ItemListTextFiltersFilters from './models/item-list-text-filters-filters';
import ItemListCreatorsFilters from './models/item-list-creators-filters';

import ItemListOrderByFilter from './models/item-list-order-by-filter.enum';
import ItemListFilters from './models/item-list-filters';

import ItemListTextFiltersState from './models/item-list-text-filters-state';
import ItemListCreatorsState from './models/item-list-creators-state';
import ItemListItemsState from './models/item-list-items-state';
import ItemListState from './models/item-list-state';

const CREATORS_PER_PAGE = 50;
const TEXT_FILTERS_PER_PAGE = 20;
const ITEMS_PER_PAGE = 12;

const MarketItemList: Module<ItemListState, GlobalState> = {
  namespaced: true,

  state: () => ({
    namespaced: true,

    categories: [],
    types: [],

    textFilters: {
      data: [],
      pagination: {
        page: 1,
        pageSize: TEXT_FILTERS_PER_PAGE,
      },
      isLastPage: false,
      filters: {
        search: '',
      },
    },

    creators: {
      data: [],
      pagination: {
        page: 1,
        pageSize: CREATORS_PER_PAGE,
      },
      isLastPage: false,
      filters: {
        search: '',
      },
    },

    items: {
      data: [],
      pagination: {
        page: 1,
        pageSize: ITEMS_PER_PAGE,
      },
      isLastPage: false,
      filters: {
        orderBy: ItemListOrderByFilter.SaleEndingSoon,
        categoryIds: [],
        typeId: null,
        rewardStatus: null,
        textFilters: [],
        creatorId: null,
        search: '',
        isNSFW: false,
        isFeatured: null,
        saleStatus: null,
      },
    },
  }),

  mutations: {
    [Mutations.setCategories](state: ItemListState, data?: CategorySimplified[]): void {
      state.categories = data ?? [];
    },
    [Mutations.setTypes](state: ItemListState, data?: ItemTypeSimplified[]): void {
      state.types = data ?? [];
    },

    [Mutations.setTextFilters](
      state: ItemListState,
      {
        data, pagination, isLastPage, filters,
      }: Partial<ItemListTextFiltersState>,
    ): void {
      state.textFilters.data = data ?? [];
      state.textFilters.pagination.page = pagination?.page ?? 1;
      state.textFilters.pagination.pageSize = pagination?.pageSize ?? TEXT_FILTERS_PER_PAGE;
      state.textFilters.isLastPage = isLastPage ?? false;
      state.textFilters.filters.search = filters?.search ?? '';
    },

    [Mutations.setCreators](
      state: ItemListState,
      {
        data, pagination, isLastPage, filters,
      }: Partial<ItemListCreatorsState>,
    ): void {
      state.creators.data = data ?? [];
      state.creators.pagination.page = pagination?.page ?? 1;
      state.creators.pagination.pageSize = pagination?.pageSize ?? CREATORS_PER_PAGE;
      state.creators.isLastPage = isLastPage ?? false;
      state.creators.filters.search = filters?.search ?? '';
    },

    [Mutations.setItems](
      state: ItemListState,
      {
        data, pagination, isLastPage, filters,
      }: Partial<ItemListItemsState>,
    ): void {
      state.items.data = data ?? [];
      state.items.pagination.page = pagination?.page ?? 1;
      state.items.pagination.pageSize = pagination?.pageSize ?? ITEMS_PER_PAGE;
      state.items.isLastPage = isLastPage ?? false;
      state.items.filters.orderBy = filters?.orderBy
        ?? ItemListOrderByFilter.SaleEndingSoon;
      state.items.filters.categoryIds = filters?.categoryIds ?? [];
      state.items.filters.textFilters = filters?.textFilters ?? [];
      state.items.filters.creatorId = filters?.creatorId ?? null;
      state.items.filters.typeId = filters?.typeId ?? null;
      state.items.filters.rewardStatus = filters?.rewardStatus ?? null;
      state.items.filters.search = filters?.search ?? '';
      state.items.filters.isNSFW = filters?.isNSFW ?? false;
      state.items.filters.isFeatured = filters?.isFeatured ?? null;
    },
  },

  actions: {
    async [Actions.fetchCategories]({ commit }): Promise<void> {
      const data = await ItemListApi.getCategories();
      commit(Mutations.setCategories, data);
    },
    async [Actions.fetchTypes]({ commit }): Promise<void> {
      const data = await ItemListApi.getTypes();
      commit(Mutations.setTypes, data);
    },

    async [Actions.fetchTextFilters](
      { state, commit },
      { page, pageSize, search }: Partial<PaginationOptions> & Partial<ItemListTextFiltersFilters>,
    ): Promise<void> {
      let {
        data, pagination, isLastPage, filters,
      } = state.textFilters;

      const isPaginationSchemeChanged = (pageSize !== undefined && pageSize !== pagination.pageSize)
        || (search !== undefined);

      pagination = {
        page: isPaginationSchemeChanged ? 1 : (page ?? pagination.page),
        pageSize: pageSize ?? pagination.pageSize,
      };

      filters = {
        search: search !== undefined ? search : filters.search,
      };

      if (isPaginationSchemeChanged) {
        data = [];
        isLastPage = false;
      }

      if (!isLastPage) {
        const dataPerPage = await ItemListApi.getTextFilters(pagination, filters);

        data.push(...dataPerPage);
        isLastPage = dataPerPage.length < pagination.pageSize;
      }

      commit(Mutations.setTextFilters, {
        data, pagination, isLastPage, filters,
      });
    },

    async [Actions.fetchCreators](
      { state, commit },
      { page, pageSize, search }: Partial<PaginationOptions> & Partial<ItemListCreatorsFilters>,
    ): Promise<void> {
      let {
        data, pagination, isLastPage, filters,
      } = state.creators;

      const isPaginationSchemeChanged = (pageSize !== undefined && pageSize !== pagination.pageSize)
        || (search !== undefined);

      pagination = {
        page: isPaginationSchemeChanged ? 1 : (page ?? pagination.page),
        pageSize: pageSize ?? pagination.pageSize,
      };

      filters = {
        search: search !== undefined ? search : filters.search,
      };

      if (isPaginationSchemeChanged) {
        data = [];
        isLastPage = false;
      }

      if (!isLastPage) {
        const dataPerPage = await ItemListApi.getCreators(pagination, filters);

        data.push(...dataPerPage);
        isLastPage = dataPerPage.length < pagination.pageSize;
      }

      commit(Mutations.setCreators, {
        data, pagination, isLastPage, filters,
      });
    },

    async [Actions.fetchItems](
      { state, commit },
      {
        page,
        pageSize,
        orderBy,
        categoryIds,
        typeId,
        rewardStatus,
        textFilters,
        creatorId,
        search,
        isNSFW,
        isFeatured,
        saleStatus,
      }: Partial<PaginationOptions> & Partial<ItemListFilters>,
    ): Promise<void> {
      let {
        data, pagination, isLastPage, filters,
      } = state.items;

      const isPaginationSchemeChanged = (pageSize !== undefined && pageSize !== pagination.pageSize)
        || (orderBy !== undefined && orderBy !== filters.orderBy)
        || (categoryIds !== undefined
            && !isEqual(new Set(categoryIds), new Set(filters.categoryIds)))
        || (typeId !== undefined && typeId !== filters.typeId)
        || (rewardStatus !== undefined && rewardStatus !== filters.rewardStatus)
        || (textFilters !== undefined
            && !isEqual(new Set(textFilters), new Set(filters.textFilters)))
        || (creatorId !== undefined && creatorId !== filters.creatorId)
        || (search !== undefined && search !== filters.search)
        || (isNSFW !== undefined && isNSFW !== filters.isNSFW)
        || (isFeatured !== undefined && isFeatured !== filters.isFeatured)
        || (saleStatus !== undefined && saleStatus !== filters.saleStatus);

      pagination = {
        page: isPaginationSchemeChanged ? 1 : (page ?? pagination.page),
        pageSize: pageSize ?? pagination.pageSize,
      };

      filters = {
        categoryIds: categoryIds !== undefined ? [...categoryIds] : [...filters.categoryIds],
        typeId: typeId !== undefined ? typeId : filters.typeId,
        rewardStatus: rewardStatus !== undefined ? rewardStatus : filters.rewardStatus,
        textFilters: textFilters !== undefined ? [...textFilters] : [...filters.textFilters],
        creatorId: creatorId !== undefined ? creatorId : filters.creatorId,
        search: search !== undefined ? search : filters.search,
        isNSFW: isNSFW !== undefined ? isNSFW : filters.isNSFW,
        isFeatured: isFeatured !== undefined ? isFeatured : filters.isFeatured,
        saleStatus: saleStatus !== undefined ? saleStatus : filters.saleStatus,
      };

      if (orderBy) {
        filters.orderBy = orderBy;
      }

      if (isPaginationSchemeChanged) {
        data = [];
        isLastPage = false;
      }

      if (!isLastPage) {
        const dataPerPage = await ItemListApi.getItems(pagination, filters);

        data.push(...dataPerPage);
        isLastPage = dataPerPage.length < pagination.pageSize;
      }

      commit(Mutations.setItems, {
        data, pagination, isLastPage, filters,
      });
    },

    [Actions.clearTextFilters]({ commit }): void {
      commit(Mutations.setTextFilters, {});
    },

    [Actions.clearCreators]({ commit }): void {
      commit(Mutations.setCreators, {});
    },

    [Actions.clearItems]({ commit }): void {
      commit(Mutations.setItems, {});
    },
  },
};

export default MarketItemList;
