import { Module } from 'vuex';

// apis
import CommonApi from '@/api/common.api';

// module types
import { Actions } from './props';

// models
import GlobalState from '@/store/models/global-state';
import SearchSuggestions from '@/store/modules/common-service/models/search-suggestions';

const MarketItemList: Module<null, GlobalState> = {
  namespaced: true,
  state: () => null,
  mutations: {},
  actions: {
    async [Actions.sendFirstVisitEmail](
      _,
      { email, url }: { email: string; url: string },
    ): Promise<void> {
      await CommonApi.sendFirstVisitEmail(email, url);
    },
    async [Actions.reqSearchSuggestions](_, search: string): Promise<SearchSuggestions> {
      return CommonApi.reqSearchSuggestions(search);
    },
  },
};

export default MarketItemList;
