
import { defineComponent } from 'vue';

import Socials from './Socials.vue';

export default defineComponent({
  name: 'Footer',

  components: {
    Socials,
  },

  setup() {
    async function openIntercomBot() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).Intercom) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).Intercom('show');
      }
    }

    return {
      openIntercomBot,
    };
  },
});
