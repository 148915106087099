
import {
  computed, defineComponent, onMounted, onUnmounted, ref, toRefs,
} from 'vue';

import fileUrl from '@/shared/helpers/file-url';

export default defineComponent({
  name: 'ItemFilePreview',

  props: {
    id: {
      type: Number,
      default: null,
    },
    filePath: {
      type: String,
      required: true,
    },
    mimeType: {
      type: String,
      default: null,
    },
    hasControls: {
      type: Boolean,
      default: false,
    },
    withCustomUrl: {
      type: Boolean,
      default: false,
    },
    isInitiallyMuted: {
      type: Boolean,
      default: true,
    },
    playStart: {
      type: Number,
      default: null,
    },
    playEnd: {
      type: Number,
      default: null,
    },
  },

  setup(props) {
    const { hasControls, isInitiallyMuted } = toRefs(props);

    const isVideo = computed(() => props.mimeType?.includes('video/'));
    const url = computed(() => (props.withCustomUrl ? props.filePath : fileUrl(props.filePath)));

    const videoRef = ref<HTMLVideoElement | null>(null);

    const isPlaying = (el: HTMLVideoElement) => el.currentTime > 0 && !el.paused
      && el.readyState > 2;

    const onTimeUpdate = () => {
      if (videoRef.value && props.playEnd && videoRef.value.currentTime > props.playEnd) {
        videoRef.value.currentTime = props.playStart || 0;
      }
    };

    const muteVideo = (e: Event) => {
      (e.target as HTMLVideoElement).muted = true;
    };

    const playVideo = () => {
      if (videoRef.value && !isPlaying(videoRef.value)) {
        videoRef.value.play();
      }
    };

    const pauseVideo = () => {
      if (videoRef.value && isPlaying(videoRef.value)) {
        videoRef.value.pause();
        videoRef.value.removeEventListener('timeupdate', onTimeUpdate);
      }
    };

    const onLoadStart = (e: Event) => {
      if (isInitiallyMuted.value) {
        muteVideo(e);
      }
    };

    const onCanPlayThrough = () => {
      if (hasControls.value) {
        playVideo();
      }
    };

    const onMouseOver = () => {
      if (!hasControls.value) {
        if (videoRef.value && !hasControls.value) {
          videoRef.value.addEventListener('timeupdate', onTimeUpdate);
        }

        playVideo();
      }
    };

    const onMouseLeave = () => {
      if (!hasControls.value) {
        pauseVideo();
        if (videoRef.value) {
          videoRef.value.removeEventListener('timeupdate', onTimeUpdate);
        }
      }
    };

    onMounted(() => {
      window.addEventListener('blur', pauseVideo);
    });

    onUnmounted(() => {
      window.removeEventListener('blur', pauseVideo);
    });

    return {
      isVideo,
      videoRef,
      url,
      onLoadStart,
      onCanPlayThrough,
      onMouseOver,
      onMouseLeave,
    };
  },
});
