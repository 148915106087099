import { Module } from 'vuex';

// apis
import UserProfileApi from '@/api/user-profile.api';
import ContractApi from '@/api/contract.api';
import NotifyApi from '@/api/notify.api';

// global
import GlobalState from '@/store/models/global-state';

// shared
import PaginationOptions from '@/shared/models/pagination-options';
import ListState from '@/shared/models/list-state';
import CryptoCurrency, { currencyToNumberMap } from '@/shared/models/crypto-currency.enum';
import NotifyType from '@/shared/constants/notify-type';
import FAILURE_TIMEOUT from '@/shared/constants/failure-timeout';
import { ITEMS_SELLING_SET_FAILURE, USER_NOT_AUTHENTICATED, USER_NOT_EXIST } from '@/shared/constants/messages';

// module types
import { Actions, Mutations } from './props';

// models
import ItemListItem from '../item-list/models/item-list-item';
import UserItemFilter from '../user-list/models/user-item-filter.enum';

import User from './models/user';
import UserPersonalInfo from './models/user-personal-info';

import UserProfileState from './models/user-profile-state';
import SocialFollowersStats from './models/social-followers-stats';
import UsersStats from './models/users-stats';

const ITEMS_PER_PAGE = 12;

const UserProfile: Module<UserProfileState, GlobalState> = {
  namespaced: true,

  state: () => ({
    namespaced: true,
    user: null,
    items: {
      [UserItemFilter.Collectibles]: {
        data: [],
        pagination: {
          page: 1,
          pageSize: ITEMS_PER_PAGE,
        },
        isLastPage: false,
      },
      [UserItemFilter.Created]: {
        data: [],
        pagination: {
          page: 1,
          pageSize: ITEMS_PER_PAGE,
        },
        isLastPage: false,
      },
    },
    stats: {
      usersStats: {
        total: 0,
        verifiedCount: 0,
        unverifiedCount: 0,
        unregisteredCount: 0,
      },
      socialFollowersStats: {
        total: 0,
        tiktokCount: 0,
        twitchCount: 0,
        twitterCount: 0,
        instagramCount: 0,
        youtubeCount: 0,
      },
    },
  }),

  mutations: {
    [Mutations.setUser](state: UserProfileState, user?: User): void {
      state.user = user ?? null;
    },

    [Mutations.updateFollowingStatus](state: UserProfileState, isFollowing: boolean | null): void {
      if (state.user) {
        state.user.isFollowing = isFollowing;
      }
    },

    [Mutations.updateFollowingCounter](state: UserProfileState, count: number): void {
      if (state.user) {
        state.user.followersCounter += count;
      }
    },

    [Mutations.setItems](
      state: UserProfileState,
      {
        data, pagination, isLastPage, filter,
      }: Partial<ListState<ItemListItem>>
        & { filter: UserItemFilter.Created | UserItemFilter.Collectibles },
    ): void {
      state.items[filter].data = data ?? [];
      state.items[filter].pagination.page = pagination?.page ?? 1;
      state.items[filter].pagination.pageSize = pagination?.pageSize ?? ITEMS_PER_PAGE;
      state.items[filter].isLastPage = isLastPage ?? false;
    },

    [Mutations.setSocialFollowersStats](
      state: UserProfileState,
      stats?: SocialFollowersStats,
    ): void {
      state.stats.socialFollowersStats = stats ?? <SocialFollowersStats>{};
    },

    [Mutations.setGlobalUsersStats](
      state: UserProfileState,
      stats?: UsersStats,
    ): void {
      state.stats.usersStats = stats ?? <UsersStats>{};
    },
  },

  actions: {
    async [Actions.fetchUser]({ commit, rootState }, nickname: string): Promise<void> {
      const { token } = rootState.session;

      const user = await UserProfileApi.getUser(nickname, token);

      if (!token) {
        user.isFollowing = null;
      }

      commit(Mutations.setUser, user);
    },

    async [Actions.fetchIsFollowing]({ commit, rootState }, userId: string): Promise<void> {
      const { token } = rootState.session;

      if (token) {
        const isFollowing = await UserProfileApi.checkIsUserFollowing(userId, token);
        commit(Mutations.updateFollowingStatus, isFollowing);
      } else {
        commit(Mutations.updateFollowingStatus, null);
      }
    },

    async [Actions.updateUser](
      { rootState },
      {
        banner, photo, ...updateUserData
      }: UserPersonalInfo & { banner: File | null; photo: File | null },
    ): Promise<void> {
      const { id } = rootState.user;
      const { token } = rootState.session;
      if (!token || !id) {
        throw new Error(USER_NOT_AUTHENTICATED);
      } else {
        await UserProfileApi.updateUser(updateUserData, token);

        if (banner) {
          await UserProfileApi.updateUserFile(id, 'banner', banner, token);
        }

        if (photo) {
          await UserProfileApi.updateUserFile(id, 'photo', photo, token);
        }
      }
    },

    async [Actions.fetchItems](
      { state, commit },
      {
        page, pageSize, filter, userId,
      }: Partial<PaginationOptions>
        & { filter: UserItemFilter.Created | UserItemFilter.Collectibles; userId: string },
    ): Promise<void> {
      let { data, pagination, isLastPage } = state.items[filter];

      const isPaginationSchemeChanged = pageSize !== undefined
        && pageSize !== pagination.pageSize;

      pagination = {
        page: isPaginationSchemeChanged ? 1 : (page ?? pagination.page),
        pageSize: pageSize ?? pagination.pageSize,
      };

      if (isPaginationSchemeChanged) {
        data = [];
        isLastPage = false;
      }

      if (!isLastPage) {
        const dataPerPage = await UserProfileApi.getUserItems(
          userId,
          pagination,
          filter.toLowerCase(),
        );

        data.push(...dataPerPage);
        isLastPage = dataPerPage.length < pagination.pageSize;
      }

      commit(Mutations.setItems, {
        data, pagination, isLastPage, filter,
      });
    },

    async [Actions.setItemsForSale](
      { rootState },
      { amount, currency, ids }: { amount: number; currency: CryptoCurrency; ids: number[] },
    ): Promise<void> {
      const { user: { address }, session: { isMetamask, token } } = rootState;

      if (!(address && token) || isMetamask === null) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      const timerId = setTimeout(() => NotifyApi.notifyFailure(token), FAILURE_TIMEOUT);

      try {
        const blockNum = await ContractApi.setOnSaleMultiple(
          ids,
          amount,
          currencyToNumberMap[currency],
          address,
          isMetamask as boolean,
        );
        clearTimeout(timerId);
        await NotifyApi.notifySuccess(
          { blockNum, marketItemIds: [...ids] },
          NotifyType.SetOnSaleMultiple,
          token,
        );
      } catch (e) {
        clearTimeout(timerId);
        throw new Error(ITEMS_SELLING_SET_FAILURE('sale'));
      }
    },

    [Actions.clearUser]({ commit }): void {
      commit(Mutations.setUser);
    },

    [Actions.clearItems]({ commit }, filter: UserItemFilter): void {
      commit(Mutations.setItems, { filter });
    },

    async [Actions.followUser]({ rootState, state, commit }): Promise<void> {
      const userId = state.user?.userId;
      const { token } = rootState.session;

      if (!token) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!userId) {
        throw new Error(USER_NOT_EXIST);
      }

      await UserProfileApi.followUser(userId, token);
      commit(Mutations.updateFollowingStatus, true);
      commit(Mutations.updateFollowingCounter, 1);
    },

    async [Actions.unfollowUser]({ rootState, state, commit }): Promise<void> {
      const userId = state.user?.userId;
      const { token } = rootState.session;

      if (!token) {
        throw new Error(USER_NOT_AUTHENTICATED);
      }

      if (!userId) {
        throw new Error(USER_NOT_EXIST);
      }

      await UserProfileApi.unfollowUser(userId, token);
      commit(Mutations.updateFollowingStatus, false);
      commit(Mutations.updateFollowingCounter, -1);
    },

    async [Actions.fetchSocialFollowersStats]({ commit }): Promise<void> {
      const data = await UserProfileApi.getSocialFollowersStats();
      commit(Mutations.setSocialFollowersStats, data);
    },

    async [Actions.fetchGlobalUsersStats]({ commit }): Promise<void> {
      const data = await UserProfileApi.getGlobalUsersStats();
      commit(Mutations.setGlobalUsersStats, data);
    },
  },
};

export default UserProfile;
