import http from '@/instances/http';

// models
import TokenBundle from '@/store/models/token-bundle';

const AuthApi = {
  async getNonce(publicAddress: string): Promise<number> {
    const res = await http.get('/auth/nonce', { params: { publicAddress } });
    return res.data;
  },
  async authUser(publicAddress: string, signature: string): Promise<TokenBundle> {
    const res = await http.get('/auth/verify-signature', { params: { publicAddress, signature } });
    return res.data;
  },
  async refreshToken(tokenBundle: TokenBundle): Promise<TokenBundle> {
    const res = await http.post('/auth/refresh', tokenBundle);
    return res.data;
  },
};

export default AuthApi;
