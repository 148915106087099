<template>
  <div class="tabs">
    <div class="tabs-header">
      <label class="tab-button">
        <input
          v-model="tab"
          name="item-profile-info-tab-options"
          value="offers"
          type="radio"
          hidden
        >
        <span class="label">Offers</span>
      </label>
      <label class="tab-button">
        <input
          v-model="tab"
          name="item-profile-info-tab-options"
          value="owner"
          type="radio"
          hidden
        >
        <span class="label">Owner</span>
      </label>
      <label class="tab-button">
        <input
          v-model="tab"
          name="item-profile-info-tab-options"
          value="history"
          type="radio"
          hidden
        >
        <span class="label">History</span>
      </label>
      <label
        v-if="item.salesStatus !== SaleStatus.OnOffChainOfferAuction || item.originalLink"
        class="tab-button"
      >
        <input
          v-model="tab"
          name="item-profile-info-tab-options"
          value="info"
          type="radio"
          hidden
        >
        <span class="label">Info</span>
      </label>
    </div>

    <div class="tabs-body">
      <div v-if="tab === 'offers'" class="tab-panel">
        <template v-if="item.salesStatus === SaleStatus.OnOffChainOfferAuction">
          <ItemProfileOffChainOfferList class="info-card" :id="String(item.id)" />
        </template>

        <template v-if="item.salesStatus === SaleStatus.OnEnglishAuction">
          <ItemProfileBidList class="info-card" :id="String(item.id)" />
          <ItemProfileOfferList
            v-if="isOwner"
            class="info-card"
            :id="String(item.id)"
            @acceptOffer="selectedOffer = $event"
          />
        </template>

        <div v-if="item.salesStatus === SaleStatus.Sold" class="info-card">
          <span class="price-label">
            {{ currentUserId === item.ownerId ? 'Bought' : 'Sold' }} for
          </span>
          <ItemPrice
            class="price"
            :cryptoAmount="item.price"
            :cryptoCurrency="item.cryptoCurrency"
            :usdAmount="item.usdPrice"
          />
        </div>
      </div>

      <div v-if="tab === 'owner'" class="tab-panel">
        <ItemProfileListItem
          :userNickname="item.ownerNickname"
          :photoPath="item.ownerPhotoIpfsPath"
          :isVerified="item.ownerVerifiedStatus"
          :isRegistered="item.isOwnerRegistered"
          class="list-item"
        >
          <div class="list-item-content">
            <span class="role">Owner</span>
            <span class="name">{{ item.ownerName }}</span>
          </div>
        </ItemProfileListItem>
        <ItemProfileListItem
          :userNickname="item.creatorNickname"
          :photoPath="item.creatorPhotoIpfsPath"
          :isVerified="item.creatorVerifiedStatus"
          :isRegistered="item.isCreatorRegistered"
          class="list-item"
        >
          <div class="list-item-content">
            <span class="role">Creator</span>
            <span class="name">{{ item.creatorName }}</span>
          </div>
        </ItemProfileListItem>
      </div>

      <div v-if="tab === 'history'" class="tab-panel">
        <ItemProfileListItem
          v-for="record in item.transactionHistory"
          :key="record.createdAt + record.recordMessage"
          :userNickname="record.creatorNickname"
          :photoPath="record.creatorPhotoIpfsPath"
          :isVerified="record.isVerified"
          :isRegistered="true"
          class="list-item"
        >
          <div class="list-item-content">
            <span class="nickname">{{ record.creatorNickname }}</span>
            <span class="message">{{ record.recordMessage }}</span>
            <span class="date">{{ formatDate(record.createdAt) }}</span>
          </div>
        </ItemProfileListItem>
      </div>

      <div v-if="tab === 'info'" class="tab-panel">
        <div v-if="item.contractAddress" class="info-field">
          <span class="label">NFT ID</span>
          <a :href="tokenUrl(item.id, item.contractAddress)" target="_blank" class="value">
            {{ item.id }}
          </a>
        </div>
        <div v-if="item.mintTransaction" class="info-field">
          <span class="label">Mint Transaction</span>
          <a :href="txUrl(item.mintTransaction)" target="_blank" class="value">
            {{ item.mintTransaction }}
          </a>
        </div>
        <div v-if="item.contractAddress" class="info-field">
          <span class="label">Contract Address</span>
          <a :href="contractUrl(item.contractAddress)" target="_blank" class="value">
            {{ item.contractAddress }}
          </a>
        </div>
        <div v-if="item.originalLink" class="info-field">
          <span class="label">Original link</span>
          <a :href="item.originalLink" target="_blank" class="value">
            {{ item.originalLink }}
          </a>
        </div>
        <div v-if="item.salesStatus !== SaleStatus.OnOffChainOfferAuction" class="info-field">
          <a :href="openSeaUrl(item.contractAddress, item.id)" target="_blank" class="value">
            View on OpenSea
          </a>
        </div>
        <div v-if="item.salesStatus !== SaleStatus.OnOffChainOfferAuction" class="info-field">
          <a :href="metadataUrl(item.id)" target="_blank" class="value">
            View NFT metadata
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { format } from 'timeago.js';

import Item from '@/store/modules/item-profile/models/item';
import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';

// import Markdown from '@/components/Markdown.vue';
import ItemProfileListItem from './ItemProfileListItem.vue';
import ItemProfileOffChainOfferList from './ItemProfileOffChainOfferList.vue';
import ItemProfileBidList from './ItemProfileBidList.vue';
import ItemProfileOfferList from './ItemProfileOfferList.vue';
import ItemPrice from '@/components/ItemPrice.vue';

export default defineComponent({
  name: 'ItemProfileTabs',

  components: {
    ItemProfileListItem,
    ItemProfileOffChainOfferList,
    ItemProfileBidList,
    ItemProfileOfferList,
    ItemPrice,
    // Markdown,
  },

  props: {
    item: {
      type: Object as () => Item,
      required: true,
    },
  },

  setup() {
    const tab = ref<string>('offers');

    const formatDate = (date: string): string => format(date);

    const requiredBlockExplorer = process.env[`VUE_APP_${process.env.VUE_APP_NETWORK_NAME}_BLOCK_EXPLORER_URL`] as string;

    const tokenUrl = (id: string, address: string) => `${requiredBlockExplorer}/token/${address}?a=${id}`;

    const txUrl = (value: string) => `${requiredBlockExplorer}/tx/${value}`;

    const contractUrl = (address: string) => `${requiredBlockExplorer}/token/${address}`;

    const metadataUrl = (id: string) => `${process.env.VUE_APP_API_URL}/items/metadata/${id}`;

    const openSeaUrl = (contractAddress: string, id: string) => (
      `${process.env.VUE_APP_OPEN_SEA_URL}/${contractAddress}/${id}`
    );

    return {
      tab,
      SaleStatus: ItemSalesStatus,
      formatDate,
      tokenUrl,
      txUrl,
      contractUrl,
      openSeaUrl,
      metadataUrl,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.tabs-header {
  display: flex;
  border: 2px solid $neutrals-6;
  border-radius: 36px;

  .tab-button {
    display: inline-flex;
    margin-top: 0.2em;
    margin-right: 0.2rem;
    margin-bottom: 0.2em;
    cursor: pointer;

    .label {
      @include transition-default;
      @include typo-button-2;
      padding: 8px 14px;
      color: $neutrals-3;
      border-radius: 100px;

      &:hover {
        color: $neutrals-8;
        background: $neutrals-3;
      }
    }

    input:checked + .label {
      color: $neutrals-8;
      background: $neutrals-3;
    }
  }
}

.tabs-body {
  margin-top: 2rem;

  .description {
    @include typo-body-2;
    color: $neutrals-1;
  }

  .properties {
    display: flex;
    flex-wrap: wrap;

    &:not(:empty) {
      margin-top: 1.25rem;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
    }

    & > * {
      @include typo-button-2;
      @include transition-default;

      display: inline-flex;
      margin: 0.75rem 0.5rem 0;
      padding: 8px 14px;
      color: $neutrals-3;
      background: transparent;
      border: 1px solid $neutrals-4;
      border-radius: 100px;
    }
  }

  .list-item {
    border-bottom: 1px solid $container-border-color;
  }

  .list-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100% - 64px);
    min-height: 64px;

    & > * {
      word-break: break-word;
    }

    .role {
      @include typo-caption;
      color: $neutrals-4;
    }

    .name {
      @include typo-caption-bold;
      @include text-ellipsis;
      margin-top: 2px;
      color: $neutrals-2;
    }

    .nickname {
      @include typo-caption-2-bold;
      @include text-ellipsis;
      color: $neutrals-2;
    }

    .message {
      @include typo-caption-bold;
      color: $neutrals-2;
    }

    .date {
      @include typo-caption-2;
      color: $neutrals-4;
    }
  }

  .info-field {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 2rem;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .label {
      @include typo-caption-bold;
      color: $neutrals-2;
    }

    .value {
      @include typo-body-2-bold;
      @include text-ellipsis;

      max-width: 100%;
      color: $primary-1;
    }

    a {
      text-decoration: underline;
    }
  }
}

.table-container {
  @include table-container-common;
  max-height: 300px;
  overflow: visible;
}
</style>
