
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import userImageUrl from '@/shared/helpers/user-image-url';

import { useStore } from '@/store';
import { Actions } from '@/store/props';

import ConnectionModal from '@/components/ConnectionModal.vue';

export default defineComponent({
  name: 'ConnectButton',

  components: {
    ConnectionModal,
  },

  emits: ['buttonClick'],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const isConnectWalletModalOpen = ref<boolean>(false);

    const token = computed(() => store.state.session.token);
    const user = computed(() => store.state.user.personalInfo);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    const getUserPersonalInfo = () => {
      store.dispatch(Actions.getUserPersonalInfo);
    };

    const emitClick = () => {
      emit('buttonClick');
    };

    const navToHome = () => {
      router.push({ name: 'home' });
      emitClick();
    };

    watch(token, (value) => {
      if (value) {
        getUserPersonalInfo();
      }
    });

    onMounted(() => {
      if (isAuthenticated.value) {
        getUserPersonalInfo();
      }
    });

    return {
      user,
      isAuthenticated,
      isConnectWalletModalOpen,
      emitClick,
      navToHome,
      userImageUrl,
    };
  },
});
