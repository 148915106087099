<template>
  <div class="card">
    <h3 class="card-header">
      <button
        :id="`${id}-toggle`"
        type="button"
        class="toggle-button"
        :class="{ 'active': isExpanded }"
        @click="isExpanded = !isExpanded"
        aria-label="Toggle card"
        :aria-expanded="isExpanded"
        :aria-controls="`${id}-region`"
      >
        <span
          :id="`${id}-label`"
          class="label"
          :style="{ textAlign: titleTextPos, color: titleTextColor }">
          {{ title }}
        </span>
        <inline-svg :src="svgIconUrl('arrow-down')" width="32" height="32"/>
      </button>
    </h3>

    <section
      :id="`${id}-region`"
      class="card-body"
      role="region"
      :aria-labelledby="`${id}-label`"
      :hidden="!isExpanded"
    >
      <slot />
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import InlineSvg from 'vue-inline-svg';

import svgIconUrl from '@/shared/helpers/svg-icon-url';

export default defineComponent({
  name: 'ExpansionCard',

  components: {
    InlineSvg,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    titleTextPos: {
      type: String,
      default: 'center',
    },
    titleTextColor: {
      type: String,
      default: '#2f2323',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['expanded'],

  setup(props, { emit }) {
    const isExpanded = ref(props.expanded);

    watch(() => props.expanded, (newVal) => {
      isExpanded.value = newVal;
    });

    watch(isExpanded, (value) => {
      emit('expanded', value);
    });

    return {
      isExpanded,
      svgIconUrl,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.card {
  @include card;
  @include box-shadow-default;

  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.card-header {
  .toggle-button {
    @include typo-body-1;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    color: $neutrals-4;

    .label {
      margin-right: 1rem;
      color: $neutrals-2;
    }

    &.active {
      & > *:not(.label) {
        transform: rotate(180deg);
      }
    }
  }
}

.card-body {
  margin-top: 1rem;
}
</style>
