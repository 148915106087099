
import { defineComponent } from 'vue';

// store
import SearchSuggestions from '@/store/modules/common-service/models/search-suggestions';

// components
import ItemFilePreview from '@/components/ItemFilePreview.vue';
import UserImage from '@/components/UserImage.vue';

export default defineComponent({
  name: 'Suggestions',
  components: {
    ItemFilePreview,
    UserImage,
  },
  props: {
    suggestions: {
      type: Object as () => SearchSuggestions,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
  },
  emits: ['select'],
});
