
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Loading from 'vue-loading-overlay';
import copy from 'copy-to-clipboard';

import { fmKey } from '@/instances/fortmatic';

import HttpStatusCode from '@/shared/models/http-status-code.enum';
import fileUrl from '@/shared/helpers/file-url';
import formatNumber from '@/shared/helpers/format-number';
import {
  ACTION_SUCCESS_DEFAULT,
  ERROR_UNKNOWN,
  ITEMS_SELLING_SET_SUCCESS,
  USER_FOLLOW_FAILURE,
  USER_UNFOLLOW_FAILURE,
} from '@/shared/constants/messages';

import { useStore } from '@/store';
import { Actions as GlobalActions, Modules, Mutations as GlobalMutations } from '@/store/props';
import { Actions } from '@/store/modules/user-profile/props';
import { Actions as ItemProfileActions } from '@/store/modules/item-profile/props';

import ItemSaleData from '@/store/modules/item-profile/models/item-sale-data';
import UserItemFilter from '@/store/modules/user-list/models/user-item-filter.enum';

import ContentLoader from '@/components/ContentLoader.vue';
import InfoModal from '@/components/InfoModal.vue';
import Markdown from '@/components/Markdown.vue';
import SaleModal from '@/components/SaleModal.vue';
import UserImage from '@/components/UserImage.vue';
import EmailSubscribeModal from '@/components/EmailSubscribeModal.vue';
import UserProfileOffChainBanner from '@/components/UserProfileOffChainBanner.vue';

import UserItems from './UserItems.vue';

export default defineComponent({
  name: 'UserProfile',

  components: {
    Loading,
    UserItems,
    ContentLoader,
    InfoModal,
    Markdown,
    SaleModal,
    UserImage,
    EmailSubscribeModal,
    UserProfileOffChainBanner,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const fmProvider = inject(fmKey);

    const isPageLoading = ref<boolean>(false);

    const isPutOnSaleModalOpen = ref<boolean>(false);
    const isAddressCopied = ref<boolean>(false);
    const showEmailSubscribeModal = ref<boolean>(false);

    const isLoading = ref<boolean>(false);
    const disableFollowing = ref<boolean>(false);
    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });
    const success = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });

    const filter = ref<UserItemFilter>(UserItemFilter.Created);
    const isSelectingModeOn = ref<boolean>(false);
    const selected = ref<number[]>([]);

    const userNickname = computed(() => route.params.userNickname);
    const user = computed(() => store.state.UserProfile.user);
    const userId = computed(() => store.state.UserProfile.user?.userId);
    const userProfileInfo = computed(() => store.state.user.personalInfo);
    const currentUserId = computed(() => store.state.user.id);
    const isMainNet = computed(() => store.state.user.isMainNet);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const isSelectingModeAvailable = computed(
      () => (
        filter.value === UserItemFilter.Created
          ? store.state.UserProfile.items.Created.data.length > 0
          : store.state.UserProfile.items.Collectibles.data.length > 0
      ),
    );

    const loadUser = async (nickname: string) => {
      isPageLoading.value = true;

      try {
        await store.dispatch(`${Modules.UserProfile}/${Actions.fetchUser}`, nickname);
      } catch (e) {
        if (e.isAxiosError && e.response.status === HttpStatusCode.NotFound) {
          router.push({ name: 'user-list' });
        }
      } finally {
        isPageLoading.value = false;
      }
    };

    const updateFollowingStatus = async (nickname: string) => {
      isPageLoading.value = true;

      try {
        await store.dispatch(`${Modules.UserProfile}/${Actions.fetchIsFollowing}`, nickname);
      } catch (e) {
        console.error(e);
      } finally {
        isPageLoading.value = false;
      }
    };

    const clearSelectingState = () => {
      isSelectingModeOn.value = false;
      selected.value = [];
    };

    const clearUser = () => {
      store.dispatch(`${Modules.UserProfile}/${Actions.clearUser}`);
    };

    const copyToClipboard = (address: string) => {
      if (copy(address)) {
        isAddressCopied.value = true;

        setTimeout(() => {
          isAddressCopied.value = false;
        }, 1000);
      }
    };

    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    function buildSuccessModal(text: string): void {
      success.value = {
        isOpen: true,
        text: text || ACTION_SUCCESS_DEFAULT,
      };
    }

    async function putItemsOnSale(data: ItemSaleData) {
      isPutOnSaleModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.UserProfile}/${Actions.setItemsForSale}`, { ...data, ids: [...selected.value] });
        buildSuccessModal(ITEMS_SELLING_SET_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function putItemsOnEnglishAuction(data: ItemSaleData) {
      isPutOnSaleModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${ItemProfileActions.setItemsForEnglishAuction}`, { ...data, ids: [...selected.value] });
        buildSuccessModal(ITEMS_SELLING_SET_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function putItemsOnDutchAuction(data: ItemSaleData) {
      isPutOnSaleModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${ItemProfileActions.setItemsForDutchAuction}`, { ...data, ids: [...selected.value] });
        buildSuccessModal(ITEMS_SELLING_SET_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function followUser() {
      showEmailSubscribeModal.value = false;
      if (!userProfileInfo.value?.email || !userProfileInfo.value?.isNotificationAvailable) {
        showEmailSubscribeModal.value = true;
      }

      disableFollowing.value = true;

      try {
        await store.dispatch(`${Modules.UserProfile}/${Actions.followUser}`);
      } catch (e) {
        buildErrorModal(e.message || USER_FOLLOW_FAILURE);
      } finally {
        disableFollowing.value = false;
      }
    }

    async function unfollowUser() {
      disableFollowing.value = true;

      try {
        await store.dispatch(`${Modules.UserProfile}/${Actions.unfollowUser}`);
      } catch (e) {
        buildErrorModal(e.message || USER_UNFOLLOW_FAILURE);
      } finally {
        disableFollowing.value = false;
      }
    }

    const turnSelectingModeOn = () => {
      selected.value = [];
      isSelectingModeOn.value = true;
    };

    const logout = () => {
      clearSelectingState();

      store.commit(GlobalMutations.setLoading, true);
      store
        .dispatch(GlobalActions.logout)
        .catch((err) => console.error(err))
        .finally(() => {
          if (fmProvider) {
            fmProvider.user.logout();
          }
          store.commit(GlobalMutations.setLoading, false);
          router.push({ name: 'home' });
        });
    };

    watch(userNickname, (nickname) => {
      clearUser();
      clearSelectingState();
      if (nickname) {
        loadUser(nickname as string);
      }
    });

    watch(currentUserId, () => {
      clearSelectingState();
      if (userId.value) {
        updateFollowingStatus(userId.value as string);
      }
    });

    // As for now metamask cancel unapproved transactions on network change,
    // but do not send any response to that, so we reload forcefully, though,
    // there may be ongoing transaction, too.
    watch(isMainNet, () => {
      if (isLoading.value) {
        router.go(0);
      }
    });

    watch(() => success.value.isOpen, (open) => {
      if (!open) {
        router.go(0);
      }
    });

    onMounted(() => {
      clearSelectingState();
      loadUser(userNickname.value as string).finally(() => {
        // reload user page if logged user got isSelfMint field updated
        if (userProfileInfo.value.id === userId.value
          && userProfileInfo.value.isSelfMint !== user.value?.isSelfMint) {
          router.go(0);
        }
      });
    });

    onUnmounted(() => {
      clearUser();
      clearSelectingState();
    });

    return {
      isPageLoading,
      user,
      userProfileInfo,
      userId,
      userNickname,
      currentUserId,
      isMainNet,
      isAuthenticated,
      filter,
      selected,
      isSelectingModeAvailable,
      isSelectingModeOn,
      isPutOnSaleModalOpen,
      isAddressCopied,
      isLoading,
      disableFollowing,
      error,
      success,
      UserItemFilter,
      copyToClipboard,
      turnSelectingModeOn,
      putItemsOnSale,
      putItemsOnEnglishAuction,
      putItemsOnDutchAuction,
      followUser,
      unfollowUser,
      logout,
      fileUrl,
      showEmailSubscribeModal,
      formatNumber,
    };
  },
});
