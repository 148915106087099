
import { defineComponent, ref } from 'vue';
import { useStore } from '@/store';

// store
import { Modules } from '@/store/props';
import { Actions as CommonServiceActions } from '@/store/modules/common-service/props';

// components
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['sent'],

  setup(_, { emit }) {
    const email = ref<string | null>(null);
    const emailPristine = ref<boolean>(true);
    const emailError = ref<boolean>(true);

    const store = useStore();

    // eslint-disable-next-line
    function handleEmailChange(e: any): void {
      emailError.value = !e.target.validity.valid;
    }

    async function sendEmail(): Promise<void> {
      await store.dispatch(
        `${Modules.CommonService}/${CommonServiceActions.sendFirstVisitEmail}`,
        { email: email.value, url: window.location.href },
      );
      emit('sent');
    }

    return {
      email,
      emailPristine,
      emailError,
      handleEmailChange,
      sendEmail,
    };
  },
});
