import { anyWeb3 } from '@/instances/web3';

import CryptoCurrency, { currencyToNumberMap } from '@/shared/models/crypto-currency.enum';

type NftMetadata = [number[], string[], string, string, number, string[], number, boolean];

export function buildNftMetadata(
  isOnSale: boolean,
  amount: number,
  currency: CryptoCurrency,
  address: string,
): NftMetadata {
  // isOnSale means is on fixed price sale, for auctions it's falsy
  return [
    isOnSale ? [currencyToNumberMap[currency]] : [],
    isOnSale ? [anyWeb3.utils.toWei(amount.toString(), 'ether')] : [],
    address,
    address,
    0,
    [],
    isOnSale ? 1 : 0,
    true,
  ];
}

export default NftMetadata;
