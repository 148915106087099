import http from '@/instances/http';

// models
import Banner from '@/store/models/banner';
import FeaturedBanner from '@/store/models/featured-banner';

const BannerApi = {
  async getBanner(): Promise<Banner> {
    const res = await http.get('/banner/storefront');
    return res.data;
  },
  async getFeaturedBanner(): Promise<FeaturedBanner> {
    const res = await http.get('/global-config/banner');
    return res.data;
  },
};

export default BannerApi;
