interface INotifyType {
  [key: string]: string,
}

const NotifyType: INotifyType = {
  Mint: 'notify-mint',
  BuyOnSale: 'notify-bought',
  BuyOnEnglishAuction: 'notify-bid-posted-on-english-auction',
  BuyOnDutchAuction: 'notify-dutch-auction-finished',
  SetOnSale: 'notify-change-sale-status',
  SetOnSaleMultiple: 'notify-multiple-change-sale-status',
  SetOnEnglishAuctionMultiple: 'notify-english-auction-created',
  SetOnDutchAuctionMultiple: 'notify-set-on-dutch-auction-multiple',
  SetPrice: 'notify-change-sale-status',
  WithdrawOnSale: 'notify-change-sale-status',
  WithdrawOnEnglishAuction: 'notify-withdraw-from-english-auction',
  WithdrawOnDutchAuction: 'notify-withdraw-from-dutch-auction',
  FinalizeOnEnglishAuction: 'notify-english-auction-finished',
  SetOfferOnEnglishAuction: 'notify-english-auction-offer-placed',
  WithdrawOfferOnEnglishAuction: 'notify-english-auction-offer-denied',
  ApproveOfferOnEnglishAuction: 'notify-english-auction-offer-approved',
  SetBidOnEnglishAuction: 'notify-bid-posted-on-english-auction',
};

export default NotifyType;
