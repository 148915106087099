/* eslint-disable max-len */
import http from '../instances/http';

import { anyWeb3, ethWeb3, fmWeb3 } from '@/instances/web3';
import {
  marketContract,
  nftContract,
  englishAuctionContract,
  dutchAuctionContract,
  melonTokenContract,
  melonTokenContractAddress,
  wethTokenContract,
  wethTokenContractAddress,
} from '@/instances/contract';

// errors
import { ITEM_BUY_NOT_ENOUGH_TOKENS, METAMASK_NOT_SUPPORTED } from '@/shared/constants/messages';

// helpers
import createTx from '@/shared/helpers/create-tx';
import estimateGas from '@/shared/helpers/estimate-gas';

// models
import CryptoCurrency, { numberToCurrencyMap } from '@/shared/models/crypto-currency.enum';
import NftMetadata from '@/store/modules/item-profile/models/nft-metadata';
import UsdPrice from '@/store/modules/item-profile/models/usd-price';
import ItemEnglishAuction from '@/store/modules/item-profile/models/item-english-auction';
import ItemDutchAuction from '@/store/modules/item-profile/models/item-dutch-auction';

enum ContractType {
  Marketplace = 'Marketplace',
  NFT = 'NFT',
  DutchAuction = 'DutchAuction',
  EnglishAuction = 'EnglishAuction',
}

interface IContractApi {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any,
  initializeContractAddresses: () => Promise<void>,
  getContractAddress: (type: ContractType) => Promise<string>,
  getUsdPrice: (amount: number, currency: CryptoCurrency) => Promise<UsdPrice>,
  sendTransaction: (
    encodedTransaction: string,
    address: string,
    price: number,
    contractAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  mintToken: (
    data: NftMetadata,
    ids: number[],
    sign: string,
    creatorAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  mintTokenOnEnglishAuction: (
    data: NftMetadata,
    ids: number[],
    currency: number,
    minBid: number,
    reservePrice: number,
    duration: number,
    sign: string,
    creatorAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  mintTokenOnDutchAuction: (
    data: NftMetadata,
    ids: number[],
    currency: number,
    startPrice: number,
    finishPrice: number,
    duration: number,
    numberOfIntervals: number,
    sign: string,
    creatorAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  setOnSale: (
    tokenID: number,
    price: number,
    currency: number,
    ownerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  setOnSaleMultiple: (
    tokenIDs: number[],
    price: number,
    currency: number,
    ownerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  setPrice: (tokenID: number, price: number, ownerAddress: string, isMetamask: boolean) => Promise<number>,
  setOnEnglishAuctionMultiple: (
    tokenIDs: number[],
    currency: number,
    minBid: number,
    reservePrice: number,
    duration: number,
    ownerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  setOnDutchAuctionMultiple: (
    tokenIDs: number[],
    currency: number,
    startPrice: number,
    finishPrice: number,
    duration: number,
    numberOfIntervals: number,
    ownerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  getEnglishAuction: (tokenID: number) => Promise<ItemEnglishAuction>,
  getDutchAuction: (tokenID: number) => Promise<ItemDutchAuction>,
  withdrawOnSale: (tokenID: number, ownerAddress: string, isMetamask: boolean) => Promise<number>,
  withdrawOnEnglishAuction: (
    tokenID: number,
    ownerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  withdrawOnDutchAuction: (tokenID: number, ownerAddress: string, isMetamask: boolean) => Promise<number>,
  finalizeOnEnglishAuction: (tokenID: number, ownerAddress: string, isMetamask: boolean) => Promise<number>,
  buyWithMATICOnSale: (
    tokenID: number,
    price: number,
    buyerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  buyWithMATICOnEnglishAuction: (
    tokenID: number,
    bid: number,
    buyerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  buyWithMATICOnDutchAuction: (
    tokenID: number,
    price: number,
    buyerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  getBalanceInMELON: (buyerAddress: string) => Promise<number>,
  getBalanceInWETH: (buyerAddress: string) => Promise<number>,
  buyWithMELONOnSale: (
    tokenID: number,
    price: number,
    buyerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  buyWithWETHOnSale: (
    tokenID: number,
    price: number,
    buyerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  buyWithMELONOnEnglishAuction: (
    tokenID: number,
    bid: number,
    buyerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  buyWithWETHOnEnglishAuction: (
    tokenID: number,
    bid: number,
    buyerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  buyWithMELONOnDutchAuction: (
    tokenID: number,
    price: number,
    buyerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  setOfferWithMATIC: (
    tokenID: number,
    offerPrice: number,
    offerorAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  setOfferWithMELON: (
    tokenID: number,
    offerPrice: number,
    offerorAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  setOfferWithWETH: (
    tokenID: number,
    offerPrice: number,
    offerorAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  withdrawOffer: (
    tokenID: number,
    offerId: number,
    offerorAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
  approveOffer: (
    tokenID: number,
    offerId: number,
    ownerAddress: string,
    isMetamask: boolean,
  ) => Promise<number>,
}

const ContractApi: IContractApi = {
  async initializeContractAddresses(): Promise<void> {
    const marketPromise = this.getContractAddress(ContractType.Marketplace).then((address) => {
      if (marketContract) {
        marketContract.options.address = address;
      }
    });

    const nftPromise = this.getContractAddress(ContractType.NFT).then((address) => {
      if (nftContract) {
        nftContract.options.address = address;
      }
    });

    const englishPromise = this.getContractAddress(ContractType.EnglishAuction).then((address) => {
      if (englishAuctionContract) {
        englishAuctionContract.options.address = address;
      }
    });

    const dutchPromise = this.getContractAddress(ContractType.DutchAuction).then((address) => {
      if (dutchAuctionContract) {
        dutchAuctionContract.options.address = address;
      }
    });

    await Promise.all([marketPromise, nftPromise, englishPromise, dutchPromise]);
  },

  async getContractAddress(type: ContractType): Promise<string> {
    let contractAddressUrl: string;

    switch (type) {
      case ContractType.Marketplace:
        contractAddressUrl = '/transactions/contract';
        break;
      case ContractType.NFT:
        contractAddressUrl = '/transactions/contract/token';
        break;
      case ContractType.DutchAuction:
        contractAddressUrl = '/transactions/contract/dutch';
        break;
      case ContractType.EnglishAuction:
        contractAddressUrl = '/transactions/contract/english';
        break;
      default:
        throw new Error('invalid contract type');
    }

    const res = await http.get(contractAddressUrl);
    return res.data;
  },

  async getUsdPrice(amount: number, currency: CryptoCurrency): Promise<UsdPrice> {
    const res = await http.get(`/transactions/usd-price/${currency}`, { params: { amount } });
    return res.data;
  },

  async sendTransaction(
    encodedTransaction: string,
    address: string,
    price: number,
    contractAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    const web3 = isMetamask ? ethWeb3 : fmWeb3;
    if (!web3) {
      throw Error(METAMASK_NOT_SUPPORTED);
    }

    const estimatedGas = await estimateGas(encodedTransaction, address, price, contractAddress);
    const tx = createTx(encodedTransaction, address, price, contractAddress, estimatedGas);
    const { blockNumber } = await web3.eth.sendTransaction(tx);
    return blockNumber;
  },

  async mintToken(
    data: NftMetadata,
    ids: number[],
    sign: string,
    creatorAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!marketContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const marketContractAddress = await this.getContractAddress(ContractType.Marketplace);

    const encodedTransaction = marketContract.methods.mintToken(
      data,
      ids,
      sign,
      [],
    ).encodeABI();

    return this.sendTransaction(encodedTransaction, creatorAddress, 0, marketContractAddress, isMetamask);
  },

  async mintTokenOnEnglishAuction(
    data: NftMetadata,
    ids: number[],
    currency: number,
    minBid: number,
    reservePrice: number,
    duration: number,
    sign: string,
    creatorAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!marketContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const marketContractAddress = await this.getContractAddress(ContractType.Marketplace);

    const encodedTransaction = marketContract.methods.mintWithEnglishAuction(
      data,
      ids,
      currency,
      anyWeb3.utils.toWei(minBid.toString(), 'ether'),
      anyWeb3.utils.toWei(reservePrice.toString(), 'ether'),
      duration,
      sign,
      [],
    ).encodeABI();

    return this.sendTransaction(encodedTransaction, creatorAddress, 0, marketContractAddress, isMetamask);
  },

  async mintTokenOnDutchAuction(
    data: NftMetadata,
    ids: number[],
    currency: number,
    startPrice: number,
    finishPrice: number,
    duration: number,
    numberOfIntervals: number,
    sign: string,
    creatorAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!marketContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const marketContractAddress = await this.getContractAddress(ContractType.Marketplace);

    const encodedTransaction = marketContract.methods.mintWithDutchAuction(
      data,
      ids,
      currency,
      anyWeb3.utils.toWei(startPrice.toString(), 'ether'),
      anyWeb3.utils.toWei(finishPrice.toString(), 'ether'),
      duration,
      numberOfIntervals,
      sign,
      [],
    ).encodeABI();

    return this.sendTransaction(encodedTransaction, creatorAddress, 0, marketContractAddress, isMetamask);
  },

  async setOnSale(
    tokenID: number,
    price: number,
    currency: number,
    ownerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!marketContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const marketContractAddress = await this.getContractAddress(ContractType.Marketplace);

    const encodedTransaction = marketContract.methods.setForSale(
      anyWeb3.utils.toWei(price.toString(), 'ether'),
      currency,
      tokenID,
    ).encodeABI();

    return this.sendTransaction(encodedTransaction, ownerAddress, 0, marketContractAddress, isMetamask);
  },

  async setOnSaleMultiple(
    tokenIDs: number[],
    price: number,
    currency: number,
    ownerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!marketContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const marketContractAddress = await this.getContractAddress(ContractType.Marketplace);

    const encodedTransaction = marketContract.methods.setForSaleMultiple(
      anyWeb3.utils.toWei(price.toString(), 'ether'),
      currency,
      tokenIDs,
    ).encodeABI();

    return this.sendTransaction(encodedTransaction, ownerAddress, 0, marketContractAddress, isMetamask);
  },

  async setPrice(
    tokenID: number,
    price: number,
    ownerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!marketContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const marketContractAddress = await this.getContractAddress(ContractType.Marketplace);

    const encodedTransaction = marketContract.methods.setPrice(
      anyWeb3.utils.toWei(price.toString(), 'ether'),
      tokenID,
    ).encodeABI();

    return this.sendTransaction(encodedTransaction, ownerAddress, 0, marketContractAddress, isMetamask);
  },

  async setOnEnglishAuctionMultiple(
    tokenIDs: number[],
    currency: number,
    minBid: number,
    reservePrice: number,
    duration: number,
    ownerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!nftContract || !englishAuctionContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const [nftContractAddress, englishAuctionContractAddress] = [
      await this.getContractAddress(ContractType.NFT),
      await this.getContractAddress(ContractType.EnglishAuction),
    ];

    const encodedTransactionApprove = nftContract.methods.approveMultiple(
      tokenIDs,
      englishAuctionContractAddress,
    ).encodeABI();

    await this.sendTransaction(encodedTransactionApprove, ownerAddress, 0, nftContractAddress, isMetamask);

    const encodedTransaction = englishAuctionContract.methods.createAuction(
      tokenIDs,
      currency,
      anyWeb3.utils.toWei(minBid.toString(), 'ether'),
      anyWeb3.utils.toWei(reservePrice.toString(), 'ether'),
      duration,
    ).encodeABI();

    return this.sendTransaction(encodedTransaction, ownerAddress, 0, englishAuctionContractAddress, isMetamask);
  },

  async setOnDutchAuctionMultiple(
    tokenIDs: number[],
    currency: number,
    startPrice: number,
    finishPrice: number,
    duration: number,
    numberOfIntervals: number,
    ownerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!nftContract || !dutchAuctionContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const [nftContractAddress, dutchAuctionContractAddress] = [
      await this.getContractAddress(ContractType.NFT),
      await this.getContractAddress(ContractType.DutchAuction),
    ];

    const encodedTransactionApprove = nftContract.methods.approveMultiple(
      tokenIDs,
      dutchAuctionContractAddress,
    ).encodeABI();

    await this.sendTransaction(encodedTransactionApprove, ownerAddress, 0, nftContractAddress, isMetamask);

    const encodedTransaction = dutchAuctionContract.methods.createAuction(
      tokenIDs,
      currency,
      anyWeb3.utils.toWei(startPrice.toString(), 'ether'),
      anyWeb3.utils.toWei(finishPrice.toString(), 'ether'),
      duration,
      numberOfIntervals,
    ).encodeABI();

    return this.sendTransaction(encodedTransaction, ownerAddress, 0, dutchAuctionContractAddress, isMetamask);
  },

  async getEnglishAuction(tokenID: number): Promise<ItemEnglishAuction> {
    if (!englishAuctionContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    let res = await englishAuctionContract.methods.getAuctionData(tokenID).call();

    res = {
      currentPrice: +anyWeb3.utils.fromWei(res.currentPrice, 'ether'),
      cryptoCurrency: numberToCurrencyMap[+res.currency],
      minBid: +anyWeb3.utils.fromWei(res.minBid, 'ether'),
      startDate: +res.startDate * 1000, // to ms
      endDate: +res.endDate * 1000, // to ms
      reservePrice: +anyWeb3.utils.fromWei(res.reservePrice, 'ether'),
      isFinished: res.isFinished,
    } as ItemEnglishAuction;

    const now = +new Date();

    res.isPending = res.endDate === 0;
    res.hasEnded = res.endDate < now;
    res.timeToEndDate = res.hasEnded ? 0 : res.endDate - now;
    res.currentMinBid = res.isPending ? res.reservePrice : +(res.currentPrice + res.minBid).toFixed(6);

    return res;
  },

  async getDutchAuction(tokenID: number): Promise<ItemDutchAuction> {
    if (!dutchAuctionContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const res = await dutchAuctionContract.methods.getAuctionByTokenId(tokenID).call();

    res.startPrice = +anyWeb3.utils.fromWei(res.startPrice, 'ether');
    res.finishPrice = +anyWeb3.utils.fromWei(res.finishPrice, 'ether');

    return res;
  },

  async withdrawOnSale(
    tokenID: number,
    ownerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!marketContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const marketContractAddress = await this.getContractAddress(ContractType.Marketplace);

    const encodedTransaction = marketContract.methods.withdrawFromSale(tokenID).encodeABI();

    return this.sendTransaction(encodedTransaction, ownerAddress, 0, marketContractAddress, isMetamask);
  },

  async withdrawOnEnglishAuction(
    tokenID: number,
    ownerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!englishAuctionContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const englishAuctionContractAddress = await this.getContractAddress(ContractType.EnglishAuction);

    const encodedTransaction = englishAuctionContract.methods.withdraw(tokenID).encodeABI();

    return this.sendTransaction(encodedTransaction, ownerAddress, 0, englishAuctionContractAddress, isMetamask);
  },

  async withdrawOnDutchAuction(
    tokenID: number,
    ownerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!dutchAuctionContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const dutchAuctionContractAddress = await this.getContractAddress(ContractType.DutchAuction);

    const encodedTransaction = dutchAuctionContract.methods.cancelAuctionByTokenId(tokenID).encodeABI();

    return this.sendTransaction(encodedTransaction, ownerAddress, 0, dutchAuctionContractAddress, isMetamask);
  },

  async finalizeOnEnglishAuction(
    tokenID: number,
    ownerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!englishAuctionContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const englishAuctionContractAddress = await this.getContractAddress(ContractType.EnglishAuction);

    const encodedTransaction = englishAuctionContract.methods.finalize(tokenID).encodeABI();

    return this.sendTransaction(encodedTransaction, ownerAddress, 0, englishAuctionContractAddress, isMetamask);
  },

  async buyWithMATICOnSale(
    tokenID: number,
    price: number,
    buyerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!marketContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const marketContractAddress = await this.getContractAddress(ContractType.Marketplace);

    const encodedTransaction = marketContract.methods.buy(tokenID).encodeABI();

    return this.sendTransaction(encodedTransaction, buyerAddress, price, marketContractAddress, isMetamask);
  },

  async buyWithMATICOnEnglishAuction(
    tokenID: number,
    bid: number,
    buyerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!englishAuctionContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const englishAuctionContractAddress = await this.getContractAddress(ContractType.EnglishAuction);

    const encodedTransaction = englishAuctionContract.methods.bidWithMATIC(tokenID).encodeABI();

    return this.sendTransaction(
      encodedTransaction,
      buyerAddress,
      bid,
      englishAuctionContractAddress,
      isMetamask,
    );
  },

  async buyWithMATICOnDutchAuction(
    tokenID: number,
    price: number,
    buyerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!dutchAuctionContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const dutchAuctionContractAddress = await this.getContractAddress(ContractType.DutchAuction);

    const encodedTransaction = dutchAuctionContract.methods.bidWithMATIC(tokenID).encodeABI();

    return this.sendTransaction(
      encodedTransaction,
      buyerAddress,
      price,
      dutchAuctionContractAddress,
      isMetamask,
    );
  },

  async getBalanceInMELON(buyerAddress: string): Promise<number> {
    if (!melonTokenContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const balanceWei = await melonTokenContract.methods.balanceOf(buyerAddress).call();
    return +anyWeb3.utils.fromWei(balanceWei, 'ether');
  },

  async getBalanceInWETH(buyerAddress: string): Promise<number> {
    if (!wethTokenContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const balanceWei = await wethTokenContract.methods.balanceOf(buyerAddress).call();
    return +anyWeb3.utils.fromWei(balanceWei, 'ether');
  },

  async buyWithMELONOnSale(
    tokenID: number,
    price: number,
    buyerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!marketContract || !melonTokenContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const balance = await ContractApi.getBalanceInMELON(buyerAddress);
    if (price > balance) {
      throw new Error(ITEM_BUY_NOT_ENOUGH_TOKENS(CryptoCurrency.MELON));
    }

    const marketContractAddress = await this.getContractAddress(ContractType.Marketplace);

    const encodedTransactionApprove = melonTokenContract.methods.approve(
      marketContractAddress,
      anyWeb3.utils.toWei(price.toString(), 'ether'),
    ).encodeABI();

    await this.sendTransaction(encodedTransactionApprove, buyerAddress, 0, melonTokenContractAddress, isMetamask);

    const encodedTransaction = marketContract.methods.buyWithMelon(tokenID).encodeABI();

    return this.sendTransaction(encodedTransaction, buyerAddress, 0, marketContractAddress, isMetamask);
  },

  async buyWithWETHOnSale(
    tokenID: number,
    price: number,
    buyerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!marketContract || !wethTokenContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const balance = await ContractApi.getBalanceInWETH(buyerAddress);
    if (price > balance) {
      throw new Error(ITEM_BUY_NOT_ENOUGH_TOKENS(CryptoCurrency.WETH));
    }

    const marketContractAddress = await this.getContractAddress(ContractType.Marketplace);

    const encodedTransactionApprove = wethTokenContract.methods.approve(
      marketContractAddress,
      anyWeb3.utils.toWei(price.toString(), 'ether'),
    ).encodeABI();

    await this.sendTransaction(encodedTransactionApprove, buyerAddress, 0, wethTokenContractAddress, isMetamask);

    const encodedTransaction = marketContract.methods.buyWithWETH(tokenID).encodeABI();

    return this.sendTransaction(encodedTransaction, buyerAddress, 0, marketContractAddress, isMetamask);
  },

  async buyWithMELONOnEnglishAuction(
    tokenID: number,
    bid: number,
    buyerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!englishAuctionContract || !melonTokenContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const balance = await ContractApi.getBalanceInMELON(buyerAddress);
    if (bid > balance) {
      throw new Error(ITEM_BUY_NOT_ENOUGH_TOKENS(CryptoCurrency.MELON));
    }

    const englishAuctionContractAddress = await this.getContractAddress(ContractType.EnglishAuction);

    const encodedTransactionApprove = melonTokenContract.methods.approve(
      englishAuctionContractAddress,
      anyWeb3.utils.toWei(bid.toString(), 'ether'),
    ).encodeABI();

    await this.sendTransaction(encodedTransactionApprove, buyerAddress, 0, melonTokenContractAddress, isMetamask);

    const encodedTransaction = englishAuctionContract.methods.bidWithMelon(
      tokenID,
      anyWeb3.utils.toWei(bid.toString(), 'ether'),
    ).encodeABI();

    return this.sendTransaction(encodedTransaction, buyerAddress, 0, englishAuctionContractAddress, isMetamask);
  },

  async buyWithWETHOnEnglishAuction(
    tokenID: number,
    bid: number,
    buyerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!englishAuctionContract || !wethTokenContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const balance = await ContractApi.getBalanceInWETH(buyerAddress);
    if (bid > balance) {
      throw new Error(ITEM_BUY_NOT_ENOUGH_TOKENS(CryptoCurrency.WETH));
    }

    const englishAuctionContractAddress = await this.getContractAddress(ContractType.EnglishAuction);

    const encodedTransactionApprove = wethTokenContract.methods.approve(
      englishAuctionContractAddress,
      anyWeb3.utils.toWei(bid.toString(), 'ether'),
    ).encodeABI();

    await this.sendTransaction(encodedTransactionApprove, buyerAddress, 0, wethTokenContractAddress, isMetamask);

    const encodedTransaction = englishAuctionContract.methods.bidWithWETH(
      tokenID,
      anyWeb3.utils.toWei(bid.toString(), 'ether'),
    ).encodeABI();

    return this.sendTransaction(encodedTransaction, buyerAddress, 0, englishAuctionContractAddress, isMetamask);
  },

  async buyWithMELONOnDutchAuction(
    tokenID: number,
    price: number,
    buyerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!dutchAuctionContract || !melonTokenContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const balance = await ContractApi.getBalanceInMELON(buyerAddress);
    if (price > balance) {
      throw new Error(ITEM_BUY_NOT_ENOUGH_TOKENS(CryptoCurrency.MELON));
    }

    const dutchAuctionContractAddress = await this.getContractAddress(ContractType.DutchAuction);

    const encodedTransactionApprove = melonTokenContract.methods.approve(
      dutchAuctionContractAddress,
      anyWeb3.utils.toWei(price.toString(), 'ether'),
    ).encodeABI();

    await this.sendTransaction(encodedTransactionApprove, buyerAddress, 0, melonTokenContractAddress, isMetamask);

    const encodedTransaction = dutchAuctionContract.methods.bidWithMelon(tokenID).encodeABI();

    return this.sendTransaction(encodedTransaction, buyerAddress, 0, dutchAuctionContractAddress, isMetamask);
  },

  // TODO: weth dutch request

  async setOfferWithMATIC(
    tokenID: number,
    offerPrice: number,
    offerorAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!englishAuctionContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const englishAuctionContractAddress = await this.getContractAddress(ContractType.EnglishAuction);

    const encodedTransaction = englishAuctionContract.methods.placeOfferMATIC(tokenID).encodeABI();

    return this.sendTransaction(
      encodedTransaction,
      offerorAddress,
      offerPrice,
      englishAuctionContractAddress,
      isMetamask,
    );
  },

  async setOfferWithMELON(
    tokenID: number,
    offerPrice: number,
    offerorAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!englishAuctionContract || !melonTokenContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const balance = await ContractApi.getBalanceInMELON(offerorAddress);
    if (offerPrice > balance) {
      throw new Error(ITEM_BUY_NOT_ENOUGH_TOKENS(CryptoCurrency.MELON));
    }

    const englishAuctionContractAddress = await this.getContractAddress(ContractType.EnglishAuction);

    const encodedTransactionApprove = melonTokenContract.methods.approve(
      englishAuctionContractAddress,
      anyWeb3.utils.toWei(offerPrice.toString(), 'ether'),
    ).encodeABI();

    await this.sendTransaction(encodedTransactionApprove, offerorAddress, 0, melonTokenContractAddress, isMetamask);

    const encodedTransaction = englishAuctionContract.methods.placeOfferMelon(
      tokenID,
      anyWeb3.utils.toWei(offerPrice.toString(), 'ether'),
    ).encodeABI();

    return this.sendTransaction(encodedTransaction, offerorAddress, 0, englishAuctionContractAddress, isMetamask);
  },

  async setOfferWithWETH(
    tokenID: number,
    offerPrice: number,
    offerorAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!englishAuctionContract || !wethTokenContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const balance = await ContractApi.getBalanceInWETH(offerorAddress);
    if (offerPrice > balance) {
      throw new Error(ITEM_BUY_NOT_ENOUGH_TOKENS(CryptoCurrency.WETH));
    }

    const englishAuctionContractAddress = await this.getContractAddress(ContractType.EnglishAuction);

    const encodedTransactionApprove = wethTokenContract.methods.approve(
      englishAuctionContractAddress,
      anyWeb3.utils.toWei(offerPrice.toString(), 'ether'),
    ).encodeABI();

    await this.sendTransaction(encodedTransactionApprove, offerorAddress, 0, wethTokenContractAddress, isMetamask);

    const encodedTransaction = englishAuctionContract.methods.placeOfferWETH(
      tokenID,
      anyWeb3.utils.toWei(offerPrice.toString(), 'ether'),
    ).encodeABI();

    return this.sendTransaction(encodedTransaction, offerorAddress, 0, englishAuctionContractAddress, isMetamask);
  },

  async withdrawOffer(
    tokenID: number,
    offerId: number,
    offerorAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!englishAuctionContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const englishAuctionContractAddress = await this.getContractAddress(ContractType.EnglishAuction);

    const encodedTransaction = englishAuctionContract.methods.denyOffer(tokenID, offerId).encodeABI();

    return this.sendTransaction(encodedTransaction, offerorAddress, 0, englishAuctionContractAddress, isMetamask);
  },

  async approveOffer(
    tokenID: number,
    offerId: number,
    ownerAddress: string,
    isMetamask: boolean,
  ): Promise<number> {
    if (!englishAuctionContract) {
      throw new Error(METAMASK_NOT_SUPPORTED);
    }

    const englishAuctionContractAddress = await this.getContractAddress(ContractType.EnglishAuction);

    const encodedTransaction = englishAuctionContract.methods.approveOffer(tokenID, offerId).encodeABI();

    return this.sendTransaction(encodedTransaction, ownerAddress, 0, englishAuctionContractAddress, isMetamask);
  },
};

export default ContractApi;
