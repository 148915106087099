enum Actions {
  initializeContractAddresses = 'initializeContractAddresses',
  getFromLocalStorage = 'getFromLocalStorage',
  getLastSelectedUser = 'getLastSelectedUser',
  setToLocalStorage = 'setToLocalStorage',
  clearLocalStorage = 'clearLocalStorage',
  updateFromLocalStorage = 'updateFromLocalStorage',
  reqUserAuth = 'reqUserAuth',
  reqRefreshToken = 'reqRefreshToken',
  waitTokenExpiration = 'waitTokenExpiration',
  switchEthNetwork = 'switchEthNetwork',
  getUserPersonalInfo = 'getUserPersonalInfo',
  logout = 'logout',
  fetchBanner = 'fetchBanner',
  fetchFeaturedBanner = 'fetchFeaturedBanner',
}

export default Actions;
