enum SocialStatsFilter {
  All = 'All',
  Instagram = 'Instagram',
  Twitter = 'Twitter',
  TikTok = 'TikTok',
  Youtube = 'Youtube',
  Twitch = 'Twitch',
}

export default SocialStatsFilter;
