
import {
  defineComponent, PropType, ref, toRefs, watch,
} from 'vue';
import InlineSvg from 'vue-inline-svg';

import svgIconUrl from '@/shared/helpers/svg-icon-url';

const DEFAULT_MAX_SIZE_BYTES = 72 * 1024 * 1024; // 72MB

export default defineComponent({
  name: 'FileUploadButton',

  components: {
    InlineSvg,
  },

  props: {
    id: {
      type: String,
    },
    accept: {
      type: String,
      default: 'image/*',
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 1,
    },
    iconName: {
      type: String,
      default: null,
    },
    buttonLabel: {
      type: String,
      default: 'Upload file',
    },
    modelValue: {
      type: Object as PropType<File[]>,
      default: null,
    },
    maxSize: {
      type: Number,
      default: DEFAULT_MAX_SIZE_BYTES,
    },
  },

  emits: [
    'update:modelValue',
    'rejected',
  ],

  setup(props, { emit }) {
    const { modelValue, maxSize, maxFiles } = toRefs(props);

    const fileRef = ref<HTMLInputElement | null>(null);

    function toMB(bytes: number): string {
      return ((bytes / 1024) / 1024).toFixed(4);
    }

    function handleFileChange() {
      const files = fileRef.value?.files ?? [];

      if (files.length > maxFiles.value && fileRef.value) {
        fileRef.value.value = '';
        emit('rejected', {
          name: 'maxFiles',
          message: `Selected number of files is invalid. Max possible file count: ${maxFiles.value}`,
        });
        return;
      }

      const filesArr = [];

      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];

        if (file && file.size > maxSize.value && fileRef.value) {
          fileRef.value.value = '';
          emit('rejected', {
            name: 'maxSize',
            message: `Selected file is too large. Max possible file size: ${toMB(maxSize.value)}MB`,
          });
          return;
        }

        filesArr.push(file);
      }

      emit('update:modelValue', filesArr);
    }

    watch(modelValue, (value) => {
      if ((!value || value.length === 0) && fileRef.value) {
        fileRef.value.value = '';
      }
    });

    return {
      fileRef,
      svgIconUrl,
      handleFileChange,
    };
  },
});
