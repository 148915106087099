<template>
  <form ref="formRef" id="edit-item-profile" class="form-container" @submit.prevent="updateItem">
    <div>
      <div class="columns">
        <div class="left-column">
          <h2>Edit collectible</h2>

          <div class="inputs-block">
            <h3>Upload File</h3>

            <div class="upload-container">
              <FileUploadButton
                v-model="files"
                :max-files="maxFilesCount"
                id="edit-item-profile-file"
                accept="image/*, video/*"
                iconName="upload-file"
                buttonLabel="Select file"
                class="upload-button"
                @rejected="onFileRejected"
              />
              <p v-if="!!errors.file" class="error-text">{{ errors.file }}</p>
            </div>
          </div>

          <div class="inputs-block">
            <h3>Items Details</h3>

            <div class="input-container">
              <label for="edit-item-profile-name">Item name *</label>
              <input
                v-model="title"
                id="edit-item-profile-name"
                type="text"
                placeholder="Item name"
                required
                class="input"
                :class="{ pristine: pristine.title }"
                @blur="pristine.title = false"
              >
              <p v-if="!title && !pristine.title" class="error-text">Field is required</p>
            </div>

            <div class="input-container">
              <label for="edit-item-profile-description">Description</label>
              <div class="textarea-container">
                <textarea
                  v-model.trim="description"
                  id="edit-item-profile-description"
                  placeholder="Item description"
                  class="input"
                ></textarea>
              </div>
            </div>

            <div :key="typeId" class="input-container">
              <label for="edit-item-profile-original-link">
                Original link {{ isExternalType(typeId) ? '*' : '' }}
              </label>
              <input
                ref="originalLinkRef"
                v-model.trim="originalLink"
                id="edit-item-profile-original-link"
                type="text"
                placeholder="Item original link"
                :pattern="getUrlRegExpByTypeId(typeId).source"
                :required="isExternalType(typeId)"
                class="input"
                :class="{ pristine: pristine.originalLink }"
                @blur="pristine.originalLink = false; $forceUpdate()"
              >
              <p
                v-if="isExternalType(typeId) && !originalLink && !pristine.originalLink"
                class="error-text"
              >Field is required for chosen type</p>
              <p
                v-else-if="!originalLinkRef?.validity.valid && !pristine.originalLink"
                class="error-text"
              >Invalid link</p>
            </div>

            <div class="input-container">
              <label for="edit-item-profile-extra">Extra</label>
              <div class="textarea-container">
                <input
                  v-model="extra"
                  id="edit-item-profile-extra"
                  type="text"
                  placeholder="Item extra"
                  class="input"
                >
              </div>
            </div>

            <div class="input-container">
              <label class="edit-item-profile-category-ids">Categories *</label>
              <Select
                v-model="categoryIds"
                id="edit-item-profile-category-ids"
                placeholder="Select categories"
                labelKey="title"
                :initialFullValue="categoriesInitialFullValue"
                :data="categories"
                :multiple="true"
                :isFormElement="true"
              />
            </div>

            <div class="input-container">
              <label class="edit-item-profile-type-id">Type *</label>
              <ItemTypeSelect
                v-model="typeId"
                id="edit-item-profile-type-id"
                placeholder="Select type"
                :isFormElement="true"
              />
            </div>

            <div class="input-container">
              <label for="edit-item-profile-text-filters">Filters</label>
              <ItemFilterSelect
                v-model="filters"
                :initialFullValue="filtersInitialFullValue"
                id="edit-item-profile-text-filters"
                :isFormElement="true"
              />
            </div>
          </div>
        </div>

        <div class="right-column">
          <div class="divider"></div>

          <div class="preview-container">
            <h3>Preview</h3>
            <ItemCard :previewing="true" :data="previewItem" />
          </div>
        </div>
      </div>
    </div>

    <div class="buttons">
      <!-- <p v-for="(err, i) in serverErrors" :key="i" class="error-message">
        {{ err }}
      </p> -->
      <button
        type="submit"
        class="submit-button"
        :disabled="!categoryIds.length || !typeId || !originalLinkRef?.validity.valid
          || !formRef?.checkValidity()"
      >
        <span>Update item</span>
      </button>
    </div>
  </form>

  <InfoModal :isOpen="error.isOpen" title="Error" @close="error.isOpen = false">
    <p>{{ error.text }}</p>
  </InfoModal>

  <Loading
    v-model:active="isLoading"
    loader="dots"
    background-color="#161414"
    color="#d92b3a"
    :opacity="0.9"
  />
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Loading from 'vue-loading-overlay';

import fileUrl from '@/shared/helpers/file-url';
import { getUrlRegExpByTypeId, isExternalType, isInstagramType } from '@/shared/helpers/get-item-file-type';
import CryptoCurrency from '@/shared/models/crypto-currency.enum';
import HttpStatusCode from '@/shared/models/http-status-code.enum';
import RewardStatus from '@/shared/models/reward-status.enum';
import { ERROR_UNKNOWN } from '@/shared/constants/messages';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-profile/props';
import { Actions as ItemListActions } from '@/store/modules/item-list/props';

import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';
import CategorySimplified from '@/store/modules/item-list/models/category-simplified';
import TextFilterSimplified from '@/store/modules/item-list/models/text-filter-simplified';
import ItemListItem from '@/store/modules/item-list/models/item-list-item';

import ItemUpdateRequest from '@/store/modules/item-profile/models/item-update-request';

import InfoModal from '@/components/InfoModal.vue';
import ItemCard from '@/components/ItemCard.vue';
import FileUploadButton from '@/components/FileUploadButton.vue';
import Select from '@/components/Select.vue';
import ItemFilterSelect from '@/components/ItemFilterSelect.vue';
import ItemTypeSelect from '@/components/ItemTypeSelect.vue';

export default defineComponent({
  name: 'EditItem',

  components: {
    ItemTypeSelect,
    Loading,
    InfoModal,
    ItemCard,
    FileUploadButton,
    Select,
    ItemFilterSelect,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref<boolean>(false);
    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });

    const id = computed(() => route.params.id);
    const currentUserId = computed(() => store.state.user.id);
    const item = computed(() => store.state.ItemProfile.item);

    const originalLinkRef = ref<HTMLInputElement | null>(null);

    const itemData = reactive<ItemUpdateRequest>({
      files: [],
      title: '',
      description: '',
      isDescriptionMarked: false,
      originalLink: '',
      extra: null,
      isExtraMarked: false,
      categoryIds: [],
      typeId: null,
      filters: [],
    });

    const maxFilesCount = computed(() => (isInstagramType(itemData.typeId) ? 10 : 1));

    const categoriesInitialFullValue = ref<CategorySimplified[]>([]);
    const filtersInitialFullValue = ref<TextFilterSimplified[]>([]);

    const pristine = ref<Record<string, boolean>>({
      title: true,
      originalLink: true,
    });

    const formRef = ref<HTMLFormElement | null>(null);

    const previewItem = ref<Partial<ItemListItem>>({
      title: itemData.title ?? '',
      ipfsPaths: [],
      mimeTypes: [],
      edition: '',

      price: 0,
      cryptoCurrency: CryptoCurrency.MATIC,
      usdPrice: '0',
      salesStatus: ItemSalesStatus.Minted,

      creatorNickname: '',
      creatorName: '',
      creatorPhotoIpfsPath: '',
      isCreatorVerified: false,
      isCreatorRegistered: true,

      rewardStatus: RewardStatus.None,

      ownerId: '',
    });

    const categories = computed(() => store.state.ItemList.categories);

    const errors = ref<Record<string, string | null>>({
      file: null,
    });

    function onFileRejected(e: Error) {
      errors.value.file = e.message;
    }

    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    const navigateToProfile = (): void => {
      router.push({ name: 'item-profile', params: { id: id.value } });
    };

    async function updateItem() {
      pristine.value.title = false;

      isLoading.value = true;

      try {
        await store.dispatch(
          `${Modules.ItemProfile}/${Actions.updateItem}`,
          { ...itemData },
        );
        setTimeout(() => {
          isLoading.value = false;
          navigateToProfile();
        }, 500);
      } catch (e) {
        if (e.response?.status === HttpStatusCode.BadRequest
          && e.response?.data?.TypeId) {
          if (originalLinkRef.value) {
            originalLinkRef.value.setCustomValidity(e.response?.data?.TypeId[0]);
            originalLinkRef.value.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else {
          buildErrorModal(e.message);
        }

        isLoading.value = false;
      }
    }

    function setItemData() {
      store.dispatch(`${Modules.ItemProfile}/${Actions.getItem}`, id.value as string).finally(() => {
        if (!currentUserId.value
          || currentUserId.value !== item.value?.creatorId
          || currentUserId.value !== item.value?.ownerId
        ) {
          navigateToProfile();
        }

        if (item.value) {
          itemData.title = item.value.title;
          itemData.description = item.value.description;
          itemData.isDescriptionMarked = item.value.isDescriptionMarked;
          itemData.originalLink = item.value.originalLink;
          itemData.extra = item.value.extra;
          itemData.isExtraMarked = item.value.isExtraMarked;
          itemData.typeId = item.value.typeId;

          categoriesInitialFullValue.value = [...item.value.categories];
          itemData.categoryIds = [...item.value.categories].map((f) => f.id);

          filtersInitialFullValue.value = [...item.value.filters];
          itemData.filters = [...item.value.filters].map((f) => f.id);

          previewItem.value.ipfsPaths = item.value.ipfsPaths.map((path) => fileUrl(path));
          previewItem.value.mimeTypes = item.value.mimeTypes;

          previewItem.value.edition = item.value.edition;

          previewItem.value.price = item.value.price;
          previewItem.value.cryptoCurrency = item.value.cryptoCurrency;
          previewItem.value.usdPrice = item.value.usdPrice;
          previewItem.value.salesStatus = item.value.salesStatus;

          previewItem.value.creatorNickname = item.value.creatorNickname;
          previewItem.value.creatorName = item.value.creatorName;
          previewItem.value.creatorPhotoIpfsPath = item.value.creatorPhotoIpfsPath;
          previewItem.value.isCreatorVerified = item.value.creatorVerifiedStatus;
          previewItem.value.isCreatorRegistered = item.value.isCreatorRegistered;

          previewItem.value.rewardStatus = item.value.rewardStatus;

          previewItem.value.ownerId = item.value.ownerId;
        }
      });
    }

    watch(() => itemData.files, (value: File[]) => {
      errors.value.file = null;

      if (value) {
        previewItem.value.ipfsPaths = value.map((file: File) => URL.createObjectURL(file));
        previewItem.value.mimeTypes = value.map((file: File) => file.type);
      }
    });

    watch(() => itemData.title, (value) => {
      pristine.value.title = false;
      previewItem.value.title = value ?? '';
    });

    watch(() => itemData.originalLink, () => {
      if (originalLinkRef.value) {
        originalLinkRef.value.setCustomValidity('');
      }
    });

    watch(() => itemData.extra, (value) => {
      if (value) {
        previewItem.value.rewardStatus = item.value?.rewardStatus === RewardStatus.Redeemed
          ? RewardStatus.Redeemed
          : RewardStatus.Available;
      } else {
        previewItem.value.rewardStatus = RewardStatus.None;
      }
    });

    watch(id, (value) => {
      if (value) {
        setItemData();
      }
    });

    watch(currentUserId, () => {
      router.push({ name: 'home' });
    });

    onMounted(() => {
      Promise.allSettled([
        store.dispatch(`${Modules.ItemList}/${ItemListActions.fetchCategories}`),
        store.dispatch(`${Modules.ItemList}/${ItemListActions.fetchTypes}`),
      ])
        .finally(() => {
          setItemData();
        });
    });

    onUnmounted(() => {
      store.dispatch(`${Modules.ItemProfile}/${Actions.clearItem}`);
    });

    return {
      ...toRefs(itemData),
      maxFilesCount,
      categoriesInitialFullValue,
      filtersInitialFullValue,
      previewItem,
      formRef,
      originalLinkRef,
      categories,
      isLoading,
      error,
      pristine,
      errors,
      CryptoCurrency,
      isExternalType,
      getUrlRegExpByTypeId,
      onFileRejected,
      updateItem,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.divider {
  width: 100%;
  margin-bottom: 2rem;
  border-bottom: 1px solid $container-border-color;
}

.form-container {
  @include items-wrapper;

  margin-top: -2rem;

  @include for-lg-xl-width {
    max-width: 576px + 384px;
    margin-right: auto;
    margin-left: auto;
  }

  & > * {
    width: 100%;
  }

  .columns {
    @include items-wrapper;

    margin-top: -2rem;

    .left-column {
      width: calc(100% - 384px);

      @include for-lg-xl-width {
        padding-right: 8rem;
      }

      @include for-xs-sm-width {
        width: 100%;
        max-width: 366px;
      }

      & > *:not(:first-child) {
        margin-top: 2.5rem;
      }
    }

    .right-column {
      width: 384px;

      @include for-xs-sm-width {
        width: 100%;
        max-width: 366px;
      }

      .divider {
        @include for-md-lg-xl-width {
          display: none;
        }
      }
    }
  }

  h2 {
    @include typo-headline-2;
    color: $neutrals-2;
  }

  h3 {
    @include typo-body-2-bold;
    color: $neutrals-2;
  }

  .buttons {
    width: 100%;
    max-width: 366px;
  }
}

.inputs-block {
  display: flex;
  flex-direction: column;

  .input-container {
    @include input-container;
  }

  & > .input-container {
    margin-top: 2rem;
  }

  .input {
    @include input-common;
    @include typo-caption-bold;

    padding: 0.75rem 1rem;
    border-radius: 0.75rem;
  }

  .error-text {
    @include typo-caption-2;
    margin-top: 0.25rem;
    color: $primary-1;
  }
}

.upload-container {
  margin-top: 1rem;

  .upload-button {
    @include typo-button-1;
    @include button-primary-filled;

    padding: 10px 1.5rem;

    @include for-xs-sm-width {
      width: 100%;
    }
  }
}

.preview-container {
  @include card;

  padding-top: 3rem;
  padding-bottom: 3rem;
  box-shadow: $depth-4;

  @include for-xs-sm-width {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  & > * {
    width: 256px;
    margin-right: auto;
    margin-left: auto;
  }

  h3 {
    @include typo-body-1-bold;

    margin-bottom: 1.5rem;
    color: $neutrals-2;

    @include for-xs-sm-width {
      text-align: center;
    }
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include for-xs-sm-width {
    flex-direction: column;
  }

  // .error-message {
  //   @include typo-caption-2;

  //   width: 100%;
  //   margin-top: 1rem;
  //   color: $primary-1;
  // }

  .submit-button {
    @include typo-button-1;
    @include button-primary-filled;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 13px 1.5rem;

    @include for-md-lg-xl-width {
      margin-right: 2rem;
    }

    @include for-xs-sm-width {
      width: 100%;
    }
  }
}
</style>
