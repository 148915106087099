
import { defineComponent } from 'vue';

import CryptoPrice from './CryptoPrice.vue';

export default defineComponent({
  name: 'ItemPrice',

  components: {
    CryptoPrice,
  },

  props: {
    cryptoAmount: {
      required: true,
    },
    cryptoCurrency: {
      required: true,
    },
    usdAmount: {
      default: null,
    },
  },
});
