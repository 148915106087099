<template>
  <template v-if="suggestions.profiles.data.length || suggestions.items.data.length">
    <div v-if="suggestions.profiles.data.length" class="suggestions-list">
      <div class="suggestions-header">
        <h4>Profiles</h4>
        <router-link
          v-if="suggestions.profiles.data.length < suggestions.profiles.total"
          class="more-button"
          :to="{ name: 'user-search', query: { search } }"
          @click="$emit('select')"
        >
          See all
        </router-link>
      </div>
      <a
        v-for="user in suggestions.profiles.data"
        class="suggestions-item"
        :key="user.userId"
        :href="`/users/${user.nickname}`"
        @click="$emit('select')"
      >
        <UserImage
          :userName="user.nickname"
          :userImagePath="user.photoIpfsPath"
          :isUserVerified="user.isVerified"
          :isUserRegistered="user.isRegistered"
          imageSize="46px"
          iconSize="15px"
        />
        <div class="suggestion-item-description">
          <p class="suggestion-item-title">{{ user.nickname }}</p>
        </div>
      </a>
    </div>
    <div v-if="suggestions.items.data.length" class="suggestions-list">
      <div class="suggestions-header">
        <h4>Items</h4>
        <router-link
          v-if="suggestions.items.data.length < suggestions.items.total"
          class="more-button"
          :to="{ name: 'item-list', query: { search } }"
          @click="$emit('select')"
        >
          See all
        </router-link>
      </div>
      <a
        v-for="item in suggestions.items.data"
        class="suggestions-item"
        :key="item.id"
        :href="`/items/${item.id}`"
        @click="$emit('select')"
      >
        <ItemFilePreview
          :id="item.id"
          :filePath="item.ipfsPath"
          :mimeType="item.mimeType"
          class="suggestion-file"
        />
        <div class="suggestion-item-description">
          <p class="suggestion-item-title">{{ item.title }}</p>
          <p class="suggestion-item-subtitle">@{{ item.creatorNickname }}</p>
        </div>
      </a>
    </div>
  </template>
  <div v-else class="suggestions-no-results">
    Sorry, we couldn’t find any results for this search
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// store
import SearchSuggestions from '@/store/modules/common-service/models/search-suggestions';

// components
import ItemFilePreview from '@/components/ItemFilePreview.vue';
import UserImage from '@/components/UserImage.vue';

export default defineComponent({
  name: 'Suggestions',
  components: {
    ItemFilePreview,
    UserImage,
  },
  props: {
    suggestions: {
      type: Object as () => SearchSuggestions,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
  },
  emits: ['select'],
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.suggestions-list {
  padding: 0 16px;

  .suggestions-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      padding: 8px 0;
      color: $neutrals-4;
      font-size: 16px;
      font-family: $font-family-content;
      line-height: 24px;
    }

    .more-button {
      @include typo-button-2;
      @include button-primary-filled;

      display: inline-flex;
      padding: 10px 1rem;
    }
  }
}

.suggestions-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding-left: 8px;

  .suggestion-file {
    width: 46px;
    height: 46px;
    border-radius: 8px;
  }

  .suggestion-item-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding-left: 16px;
    font-family: $font-family-content;

    .suggestion-item-title {
      color: $neutrals-2;
      font-size: 16px;
      line-height: 20px;
    }

    .suggestion-item-subtitle {
      color: $neutrals-3;
      font-size: 10px;
      line-height: 14px;
    }
  }
}

.suggestions-no-results {
  padding: 16px;
  font-size: 24px;
  font-family: $font-family-heading-accent;
  line-height: 32px;
}
</style>
