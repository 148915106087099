import store from '@/store';

function isAuthenticated(): boolean | { name: string } {
  if (store.getters.isAuthenticated) {
    return true;
  }

  return { name: 'home' };
}

export default isAuthenticated;
