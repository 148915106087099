function formatNumber(num: number): string {
  if (!num) {
    return '0';
  }
  const thousand = 999;
  const million = 999999;
  const billion = 999999999;
  const trillion = 999999999999;

  if (num < thousand) {
    return num.toString();
  }
  if (num < million) {
    return `${(num / thousand).toFixed(2)} K`;
  }
  if (num < billion) {
    return `${(num / million).toFixed(2)} M`;
  }
  if (num < trillion) {
    return `${(num / billion).toFixed(2)} B`;
  }
  return `${(num / trillion).toFixed(2)} T`;
}

export default formatNumber;
