import http from '../instances/http';

// helpers
import toFormData from '@/shared/helpers/to-form-data';

// models
import OEmbedData from '@/shared/models/oembed-data';
import ListWithTotalResponse from '@/shared/models/list-with-total-response';

import Item from '@/store/modules/item-profile/models/item';
import ItemCreateRequest from '@/store/modules/item-profile/models/item-create-request';
import ItemCreateResponse from '@/store/modules/item-profile/models/item-create-response';
import ItemUpdateRequest from '@/store/modules/item-profile/models/item-update-request';
import PaginationOptions from '@/shared/models/pagination-options';
import OffChainOffer from '@/store/modules/item-profile/models/off-chain-offer';
import OffChainOfferCreateRequest from '@/store/modules/item-profile/models/off-chain-offer-create-request';
import Offer from '@/store/modules/item-profile/models/offer';
import Bid from '@/store/modules/item-profile/models/bid';
import SocialFollowersStats from '@/store/modules/item-profile/models/item-stats';

const ItemProfileApi = {
  async getOffChainOffers(
    id: string,
    { page, pageSize }: PaginationOptions,
  ): Promise<ListWithTotalResponse<OffChainOffer>> {
    const res = await http.get(`/items/storefront/${id}/off-chain-offers`, {
      params: {
        offset: (page - 1) * pageSize,
        pageSize,
      },
    });

    return res.data;
  },

  async getOffers(id: string, { page, pageSize }: PaginationOptions): Promise<Offer[]> {
    const res = await http.get(`/items/storefront/${id}/offers`, {
      params: {
        offset: (page - 1) * pageSize,
        pageSize,
      },
    });

    return (res.data as ListWithTotalResponse<Offer>).data;
  },

  async getBids(id: string, { page, pageSize }: PaginationOptions): Promise<Bid[]> {
    const res = await http.get(`/items/storefront/${id}/bids`, {
      params: {
        offset: (page - 1) * pageSize,
        pageSize,
      },
    });

    return (res.data as ListWithTotalResponse<Bid>).data;
  },

  async getOffChainOffer(id: string, token: string): Promise<OffChainOffer> {
    const res = await http.get(`/items/storefront/${id}/off-chain-offer`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },

  async setOffChainOffer(
    id: string,
    offer: OffChainOfferCreateRequest,
    token: string,
  ): Promise<void> {
    const res = await http.post(`/items/storefront/${id}/off-chain-offer`, toFormData(offer), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  },

  async withdrawOffChainOffer(id: string, token: string): Promise<void> {
    const res = await http.delete(`/items/storefront/${id}/off-chain-offer`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  },

  async getOffer(id: string, token: string): Promise<Offer> {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const res = await http.get(`/items/storefront/${id}/offer`, { headers });
    return res.data;
  },

  async createItem(item: ItemCreateRequest, token: string): Promise<ItemCreateResponse> {
    const res = await http.post('/items/storefront', toFormData(item), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  },

  async getItem(itemId: string): Promise<Item> {
    const res = await http.get(`/items/storefront/${itemId}`);
    return res.data;
  },

  async getItemOEmbedData(
    itemId: number,
    maxwidth: number,
    maxheight: number,
  ): Promise<OEmbedData> {
    const res = await http.get(`/items/storefront/${itemId}/social-data`, {
      params: {
        maxwidth,
        maxheight,
      },
    });
    return res.data;
  },

  async updateItem(
    item: ItemUpdateRequest,
    itemId: number,
    token: string,
  ): Promise<string> {
    const res = await http.put(`/items/storefront/${itemId}`, toFormData(item), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  },

  async getSecretCode(id: number, token: string): Promise<string> {
    const res = await http.get(`/items/storefront/${id}/reward-info/secret-code`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data.secretCode;
  },

  async setRewardAsRedeemed(id: number, token: string): Promise<void> {
    await http.post(`/items/storefront/${id}/set-reward-as-redeemed`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  async subscribeOnSale(id: number, email: string): Promise<void> {
    await http.post(`/items/storefront/${id}/user-notifications`, toFormData({ email }));
  },

  async getMelonItemsStats(): Promise<SocialFollowersStats> {
    const res = await http.get('/melon-items-stats');
    return res.data;
  },
};

export default ItemProfileApi;
