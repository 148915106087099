<template>
  <div class="filters">
    <h1 class="title">
      <img
        src="@/assets/images/default/crown.svg"
        class="title-img"
      >
      <span>Top creators</span>
      <!-- <span class="title-highlight">
        All Categories
        <img
          src="@/assets/images/common/arrow-down-primary.svg"
        >
      </span> -->
    </h1>
    <div>
      <span class="upcoming-toggle">Include creators with upcoming NFTs</span>
      <Toggle id="upcoming-nfts" v-model="upcomingNfts" />
    </div>
    <!-- <div class="row filters-control">
      <div>
        <p class="filters-title">Followers</p>
        <Select
          id="followers-select"
          v-model="followers"
          :data="followersSortOptions"
          :multiple="true"
          placeholder="All social platforms"
        />
      </div>
      <div>
        <p class="filters-title">Likes</p>
        <Select
          id="likes-select"
          v-model="likes"
          :data="likesSortOptions"
          :multiple="true"
          placeholder="Instagram, Twitter"
        />
      </div>
      <div>
        <p class="filters-title">Views</p>
        <Select
          id="views-select"
          v-model="views"
          :data="viewsSortOptions"
          :multiple="true"
          placeholder="Twitch, YouTube, TikTok"
        />
      </div>
    </div> -->
    <!-- <div class="filters-mobile-control">
      <button @click="openModal" class="filters-button">
        Filters
      </button>
      <p class="filters-title">Sort by</p>
      <Select v-model="sortBy" :data="sortByOptions"/>
    </div> -->

    <CreatorsFilterModal
      :isOpen="modalOpened"
      @close-modal="modalOpened = false"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, ref, watch, computed,
} from 'vue';
import Toggle from '@vueform/toggle';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-list/props';

import SocialStatsFilter from '@/store/modules/user-list/models/social-stats-filter.enum';
// import CreatorsFilter from '@/store/modules/user-list/models/creators-filter.enum';
import UserStatsFilter from '@/store/modules/user-list/models/user-stats-filter.enum';

// import Select from '@/components/Select.vue';
import CreatorsFilterModal from '@/components/CreatorsFilterModal.vue';

interface loadOptions {
  followersFilter?: string,
  creatorsFilter?: string,
  creatorItemsFilters?: string,
  sortBy?: string,
  includeUpcomingNfts?: boolean,
}

export default defineComponent({
  components: {
    // Select,
    CreatorsFilterModal,
    Toggle,
  },
  setup() {
    const store = useStore();

    const modalOpened = ref<boolean>(false);
    const upcomingNfts = ref<boolean>(true);
    const followers = ref<string[]>([]);
    const likes = ref<string[]>([]);
    const views = ref<string[]>([]);
    const sortBy = ref<UserStatsFilter>(UserStatsFilter.FollowersDesc);

    const filters = computed(() => store.state.UserList.stats.filters);

    const followersSortOptions = [
      {
        id: SocialStatsFilter.Instagram,
        label: 'Instagram',
      },
      {
        id: SocialStatsFilter.Twitter,
        label: 'Twitter',
      },
      {
        id: SocialStatsFilter.Twitch,
        label: 'Twitch',
      },
      {
        id: SocialStatsFilter.TikTok,
        label: 'TikTok',
      },
      {
        id: SocialStatsFilter.Youtube,
        label: 'YouTube',
      },
    ];

    const likesSortOptions = [
      {
        id: SocialStatsFilter.Instagram,
        label: 'Instagram',
      },
      {
        id: SocialStatsFilter.Twitter,
        label: 'Twitter',
      },
    ];

    const viewsSortOptions = [
      {
        id: SocialStatsFilter.Twitch,
        label: 'Twitch',
      },
      {
        id: SocialStatsFilter.Youtube,
        label: 'YouTube',
      },
      {
        id: SocialStatsFilter.TikTok,
        label: 'TikTok',
      },
    ];

    const sortByOptions = [
      {
        id: UserStatsFilter.FollowersDesc,
        label: 'Number of followers (desc)',
      },
      {
        id: UserStatsFilter.Followers,
        label: 'Number of followers',
      },
      {
        id: UserStatsFilter.LikesDesc,
        label: 'Number of likes (desc)',
      },
      {
        id: UserStatsFilter.Likes,
        label: 'Number of likes',
      },
      {
        id: UserStatsFilter.ViewsDesc,
        label: 'Number of views (desc)',
      },
      {
        id: UserStatsFilter.Views,
        label: 'Number of views',
      },
    ];

    const openModal = () => {
      modalOpened.value = true;
    };

    const clearRankedUsers = () => {
      store.dispatch(`${Modules.UserList}/${Actions.clearRankedUsers}`);
    };

    const loadUsers = (options: loadOptions) => {
      store.dispatch(`${Modules.UserList}/${Actions.fetchRankedUsers}`, {
        offset: filters.value.offset,
        followersFilter: filters.value.followersFilter,
        creatorsFilter: filters.value.creatorsFilter,
        creatorItemsFilters: filters.value.creatorItemsFilters,
        sortBy: filters.value.sortBy,
        pageSize: filters.value.pageSize,
        isNoneItemsCreated: options.includeUpcomingNfts ? 'true' : 'false',
      });
    };

    watch([followers, upcomingNfts], ([fl, un]) => {
      clearRankedUsers();
      console.log(fl, un);
      loadUsers({
        includeUpcomingNfts: un,
      });
    });

    return {
      followers,
      likes,
      views,
      sortByOptions,
      sortBy,
      modalOpened,
      openModal,
      followersSortOptions,
      likesSortOptions,
      viewsSortOptions,
      upcomingNfts,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.filters {
  margin: 2rem 0;

  .filters-title {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
  }

  .upcoming-toggle {
    margin-right: 10px;
  }

  .title {
    @include typo-community-headline;
    margin-bottom: 2rem;

    .title-img {
      margin-right: 10px;
    }

    .title-highlight {
      color: $primary-1;
    }

    @include for-xs-sm-width {
      @include typo-community-headline-mobile;
      text-align: center;

      .title-highlight {
        display: block;
      }
    }
  }

  .filters-control {
    @include for-xs-sm-width {
      display: none;
    }
  }

  .filters-mobile-control {
    display: none;

    @include for-xs-sm-width {
      display: block;
    }
  }
}

.filters-button {
  @include typo-button-2;
  @include button-outline($neutrals-1);

  width: 100%;
  margin-bottom: 20px;
  padding: 15px 1rem;
}

.row {
  @include items-wrapper;

  margin-top: -2rem;

  /* stylelint-disable-next-line no-descending-specificity */
  & > * {
    width: 33.33%;

    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }
  }
}
</style>
