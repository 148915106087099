<template>
  <ExpansionCard id="item-profile-bids" title="Bids">
    <div v-if="data.length" ref="scrollComponent" class="table-container">
      <table class="table">
        <thead>
          <tr class="table-header-row">
            <th class="table-header-cell">Price</th>
            <th class="table-header-cell">USD Price</th>
            <th class="table-header-cell">From</th>
          </tr>
        </thead>
        <tbody>
          <tr :ref="setRowRef" v-for="item in data" :key="item.id" class="table-row">
            <td class="table-cell">
              <CryptoPrice :amount="item.price" :currency="item.cryptoCurrency"/>
            </td>
            <td class="table-cell">
              ${{ item.usdPrice }}
            </td>
            <td class="table-cell">
              <router-link
                :to="{ name: 'user-profile', params: { userNickname: item.userNickname }}"
                class="link"
              >
                {{ item.bidderName }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-if="!isBidsLoading && !data.length" class="empty-list">
      No bids yet.
    </p>
    <ContentLoader :loading="isBidsLoading"/>
  </ExpansionCard>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUpdate,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
} from 'vue';
import throttle from 'lodash/throttle';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-profile/props';

import PaginationOptions from '@/shared/models/pagination-options';

import CryptoPrice from '@/components/CryptoPrice.vue';
import ExpansionCard from '@/components/ExpansionCard.vue';
import ContentLoader from '@/components/ContentLoader.vue';

export default defineComponent({
  name: 'ItemProfileBidList',

  components: {
    CryptoPrice,
    ExpansionCard,
    ContentLoader,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { id } = toRefs(props);
    const scrollComponent = ref<HTMLElement | null>(null);
    const isBidsLoading = ref<boolean>(false);

    const store = useStore();
    const data = computed(() => store.state.ItemProfile.bids.data);
    const pagination = computed(() => store.state.ItemProfile.bids.pagination);
    const isLastPage = computed(() => store.state.ItemProfile.bids.isLastPage);

    let rowRefs: HTMLTableRowElement[] = [];

    const loadBids = (options: Partial<PaginationOptions> & { id: string }) => {
      isBidsLoading.value = true;
      store.dispatch(`${Modules.ItemProfile}/${Actions.fetchBids}`, { ...options })
        .finally(() => {
          isBidsLoading.value = false;
        });
    };

    const setRowRef = (el: HTMLTableRowElement) => {
      if (el) {
        rowRefs.push(el);
      }
    };

    const checkElementsBounds = () => {
      if (!isLastPage.value && !isBidsLoading.value && scrollComponent.value) {
        const element = rowRefs[rowRefs.length - 1];
        if (element && (element.getBoundingClientRect().top
          < scrollComponent.value.getBoundingClientRect().bottom)
        ) {
          loadBids({ id: id.value, page: pagination.value.page + 1 });
        }
      }
    };

    onBeforeUpdate(() => {
      rowRefs = [];
    });

    onMounted(() => {
      loadBids({ id: id.value, page: 1 });

      if (scrollComponent.value) {
        scrollComponent.value.addEventListener('scroll', throttle(checkElementsBounds, 800, { leading: false }));
      }
    });

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener('scroll', throttle(checkElementsBounds, 800, { leading: false }));
      }
    });

    return {
      isBidsLoading,
      data,
      scrollComponent,
      setRowRef,
      loadBids,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.table-container {
  @include table-container-common;
  max-height: 300px;
  overflow: visible;
}

.empty-list {
  @include typo-caption;
}
</style>
