enum Mutations {
  setUserData = 'setUserData',
  setUserPersonalInfo = 'setUserPersonalInfo',
  setTokenBundle = 'setTokenBundle',
  setRefreshTimerId = 'setRefreshTimerId',
  setLoading = 'setLoading',
  setBanner = 'setBanner',
  setFeaturedBanner = 'setFeaturedBanner',
}

export default Mutations;
