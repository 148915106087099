<template>
  <div class="top-container">
    <h2>Edit profile</h2>
  </div>

  <form ref="formRef" id="edit-user-profile" class="form-container" @submit.prevent="updateUser">
    <div class="left-column">
      <div class="divider"></div>

      <div class="photos-container">
        <div class="photo-container">
          <img v-if="photoUrl" :src="photoUrl" class="user-image" alt="user-image">
          <div>
            <h3>Profile photo</h3>
            <p class="hint">We recommend an image of at least 400x400.</p>
            <FileUploadButton
              v-model="photo"
              id="edit-user-profile-photo"
              buttonLabel="Upload"
              class="upload-button"
              @rejected="onPhotoRejected"
            />
            <p v-if="!!errors.photo" class="error-text">{{ errors.photo }}</p>
          </div>
        </div>

        <div class="banner-container">
          <img v-if="bannerUrl" :src="bannerUrl" class="banner-image" alt="banner-image">
          <FileUploadButton
            v-model="banner"
            id="edit-user-profile-banner"
            iconName="picture"
            buttonLabel="Upload Profile banner"
            class="upload-button"
            @rejected="onBannerRejected"
          />
          <p v-if="!!errors.banner" class="error-text">{{ errors.banner }}</p>
        </div>
      </div>
    </div>

    <div class="right-column">
      <div class="divider"></div>

      <div class="fields-container">
        <div class="inputs-block">
          <h3>Account info</h3>

          <div class="input-container">
            <label for="edit-user-profile-username">Username *</label>
            <input
              ref="nicknameRef"
              v-model="nickname"
              id="edit-user-profile-username"
              type="text"
              placeholder="Enter your name"
              maxlength="45"
              autocomplete="username"
              required
              class="input"
              :class="{ pristine: pristine.nickname }"
              @blur="pristine.nickname = false"
            >
            <p v-if="!nickname && !pristine.nickname" class="error-text">Field is required</p>
            <p
              v-if="nicknameRef?.validity.customError"
              class="error-text"
            >{{ nicknameRef.validationMessage }}</p>
          </div>

          <div class="input-container">
            <label for="edit-user-profile-email">Email</label>
            <input
              ref="emailRef"
              v-model="emails[0]"
              id="edit-user-profile-email"
              type="email"
              placeholder="Enter your email address"
              autocomplete="email"
              class="input"
              :class="{ pristine: pristine.email }"
              @blur="pristine.email = false"
            >
            <p
              v-if="!emailRef?.validity.valid && !pristine.email"
              class="error-text"
            >Invalid email</p>
          </div>

          <div class="toggle-container">
            <label for="edit-user-profile-has-notifications">
              Receive notifications via email
            </label>
            <Toggle v-model="isNotificationAvailable" id="edit-user-profile-has-notifications" />
          </div>

          <div class="input-container">
            <label for="edit-user-profile-name">Display name</label>
            <input
              v-model="name"
              id="edit-user-profile-name"
              type="text"
              placeholder="Enter your display name"
              class="input"
              maxlength="45"
              autocomplete="name"
            >
          </div>

          <div class="input-container">
            <label for="edit-user-profile-bio">Bio</label>
            <div class="textarea-container">
              <textarea
                v-model="description"
                id="edit-user-profile-bio"
                placeholder="About yourself in a few words"
                rows="3"
                maxlength="200"
                class="input"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="inputs-block">
          <h3>Social</h3>

          <div class="input-container">
            <label for="edit-user-profile-twitter">Twitter</label>
            <input
              ref="twitterRef"
              v-model="twitterLink"
              id="edit-user-profile-twitter"
              type="url"
              placeholder="Twitter link"
              class="input"
            >
            <p v-if="!twitterRef?.validity.valid" class="error-text">Invalid link</p>
            <!-- <button
              type="button"
              class="submit-button"
              @click="oauthWithTwitter"
            >
              Link Twitter
            </button> -->
          </div>

          <div class="input-container">
            <label for="edit-user-profile-instagram">Instagram</label>
            <input
              ref="instagramRef"
              v-model="instagramLink"
              id="edit-user-profile-instagram"
              type="url"
              placeholder="Instagram link"
              class="input"
            >
            <p
              v-if="!instagramRef?.validity.valid"
              class="error-text"
            >Invalid link</p>
            <!-- <button
              type="button"
              class="submit-button"
              @click="oauthSignInWithInstagram"
            >
              Link Instagram
            </button> -->
          </div>

          <div class="input-container">
            <label for="edit-user-profile-twitch">Twitch</label>
            <input
              ref="twitchRef"
              v-model="twitchLink"
              id="edit-user-profile-twitch"
              type="url"
              placeholder="Twitch link"
              class="input"
            >
            <p v-if="!twitchRef?.validity.valid" class="error-text">Invalid link</p>
            <!-- <button
              type="button"
              class="submit-button"
              @click="oauthSignInWithTwitch"
            >
              Link Twitch
            </button> -->
          </div>

          <div class="input-container">
            <label for="edit-user-profile-youtube">YouTube</label>
            <input
              ref="youtubeRef"
              v-model="youtubeLink"
              id="edit-user-profile-youtube"
              type="url"
              placeholder="YouTube link"
              class="input"
            >
            <!-- <button
              type="button"
              class="submit-button"
              @click="oauthSignInWithGoogle"
            >
              Link YouTube
            </button> -->
            <p v-if="!youtubeRef?.validity.valid" class="error-text">Invalid link</p>
          </div>

          <div class="input-container">
            <label for="edit-user-profile-tiktok">TikTok</label>
            <input
              ref="tiktokRef"
              v-model="tiktokLink"
              id="edit-user-profile-tiktok"
              type="url"
              placeholder="TikTok link"
              class="input"
            >
            <p v-if="!tiktokRef?.validity.valid" class="error-text">Invalid link</p>
            <!-- <button
              type="button"
              class="submit-button"
              @click="oauthWithTikTok"
            >
              Link TikTok
            </button> -->
          </div>
        </div>
      </div>

      <div class="buttons">
        <p v-for="(err, i) in serverErrors" :key="i" class="error-message">
          {{ err }}
        </p>
        <button type="submit" class="submit-button" :disabled="!formRef?.checkValidity()">
          Update Profile
        </button>
        <button type="button" @click="reset" class="reset-button">
          <img src="@/assets/images/common/clear.svg" alt="clear icon" class="icon">
          Reset all
        </button>
      </div>
    </div>
  </form>

  <Loading
    v-model:active="isLoading"
    loader="dots"
    background-color="#161414"
    color="#d92b3a"
    :opacity="0.9"
  />
</template>

<script lang="ts">
/* eslint-disable camelcase */
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Loading from 'vue-loading-overlay';
import Toggle from '@vueform/toggle';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getAuth, signInWithPopup, TwitterAuthProvider } from 'firebase/auth';

import userImageUrl from '@/shared/helpers/user-image-url';
import fileUrl from '@/shared/helpers/file-url';

import { useStore } from '@/store';
import { Actions as GlobalActions, Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-profile/props';

import UserPersonalInfo from '@/store/modules/user-profile/models/user-personal-info';
import SocialOauthParams from '@/store/modules/user-profile/models/social-oauth-params';

import FileUploadButton from '@/components/FileUploadButton.vue';

interface OauthApiParams extends SocialOauthParams {
  oauth_endpoint: string,
}

export default defineComponent({
  name: 'EditUser',

  components: {
    Loading,
    Toggle,
    FileUploadButton,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isLoading = ref<boolean>(false);

    const userNickname = computed(() => route.params.userNickname);
    const user = computed(() => store.state.UserProfile.user);
    const currentUserNickname = computed(() => store.state.user.personalInfo.nickname);

    const formRef = ref<HTMLFormElement | null>(null);

    const photoUrl = ref<string | null>(null);
    const bannerUrl = ref<string | null>(null);

    const nicknameRef = ref<HTMLInputElement | null>(null);
    const emailRef = ref<HTMLInputElement | null>(null);
    const twitterRef = ref<HTMLInputElement | null>(null);
    const instagramRef = ref<HTMLInputElement | null>(null);
    const twitchRef = ref<HTMLInputElement | null>(null);
    const youtubeRef = ref<HTMLInputElement | null>(null);
    const tiktokRef = ref<HTMLInputElement | null>(null);

    const userData = reactive<UserPersonalInfo & { banner: File[] | null; photo: File[] | null }>({
      nickname: '',
      emails: [''],
      isNotificationAvailable: false,
      name: null,
      description: null,
      isDescriptionMarked: false,
      twitterLink: null,
      instagramLink: null,
      twitchLink: null,
      youtubeLink: null,
      tiktokLink: null,
      photo: null,
      banner: null,
    });

    const pristine = ref<Record<string, boolean>>({
      nickname: true,
      email: true,
    });

    const errors = ref<Record<string, string | null>>({
      photo: null,
      banner: null,
    });
    const serverErrors = ref<string[] | null>(null);

    function onPhotoRejected(e: Error) {
      errors.value.photo = e.message;
    }

    function onBannerRejected(e: Error) {
      errors.value.banner = e.message;
    }

    const navigateToProfile = (): void => {
      router.push({
        name: 'user-profile',
        params: {
          userNickname: currentUserNickname.value as string,
        },
      });
    };

    const updateUser = async (): Promise<void> => {
      pristine.value.nickname = false;

      serverErrors.value = null;
      isLoading.value = true;

      try {
        await store.dispatch(
          `${Modules.UserProfile}/${Actions.updateUser}`, {
            ...userData,
            emails: userData.emails
              ? [...userData.emails].filter((value) => !!value).map((value) => value?.trim())
              : [],
            description: userData.description?.trim(),
            banner: userData.banner?.length ? userData.banner[0] : null,
            photo: userData.photo?.length ? userData.photo[0] : null,
          },
        );
        await store.dispatch(GlobalActions.getUserPersonalInfo);
        setTimeout(() => {
          isLoading.value = false;
          navigateToProfile();
        }, 500);
      } catch (err) {
        isLoading.value = false;

        if (err.response?.data?.errors) {
          serverErrors.value = Object
            .values(err.response.data.errors as { [key: string]: string[] })
            .reduce((prev, cur) => [...prev, ...cur], []);
        } else if (err.response?.data?.Nickname) {
          if (nicknameRef.value) {
            nicknameRef.value.setCustomValidity(err.response?.data?.Nickname[0]);
            nicknameRef.value.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else {
          serverErrors.value = [err.message];
        }
      }
    };

    const createOauthRequest = (payload: OauthApiParams): void => {
      const form = document.createElement('form');
      form.setAttribute('method', 'GET');
      form.setAttribute('action', payload.oauth_endpoint);

      const params = ref<SocialOauthParams>({
        redirect_uri: payload.redirect_uri,
        client_id: payload.client_id,
        response_type: payload.response_type,
        scope: payload.scope,
        state: payload.state,
      });

      Object.entries(params.value).forEach((item) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', item[0]);
        input.setAttribute('value', item[1]);
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    };

    const oauthSignInWithGoogle = (): void => {
      createOauthRequest({
        oauth_endpoint: process.env.VUE_APP_GOOGLE_OAUTH_ENDPOINT || '',
        redirect_uri: process.env.VUE_APP_GOOGLE_REDIRECT_URI || '',
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID || '',
        response_type: process.env.VUE_APP_GOOGLE_RESPONSE_TYPE || '',
        scope: process.env.VUE_APP_GOOGLE_SCOPE || '',
        state: process.env.VUE_APP_GOOGLE_STATE || '',
      });
    };

    const oauthSignInWithInstagram = (): void => {
      createOauthRequest({
        oauth_endpoint: process.env.VUE_APP_INSTAGRAM_OAUTH_ENDPOINT || '',
        redirect_uri: process.env.VUE_APP_INSTAGRAM_REDIRECT_URI || '',
        client_id: process.env.VUE_APP_INSTAGRAM_CLIENT_ID || '',
        response_type: process.env.VUE_APP_INSTAGRAM_RESPONSE_TYPE || '',
        scope: process.env.VUE_APP_INSTAGRAM_SCOPE || '',
        state: process.env.VUE_APP_INSTAGRAM_STATE || '',
      });
    };

    const oauthSignInWithTwitch = (): void => {
      createOauthRequest({
        oauth_endpoint: process.env.VUE_APP_TWITCH_OAUTH_ENDPOINT || '',
        redirect_uri: process.env.VUE_APP_TWITCH_REDIRECT_URI || '',
        client_id: process.env.VUE_APP_TWITCH_CLIENT_ID || '',
        response_type: process.env.VUE_APP_TWITCH_RESPONSE_TYPE || '',
        scope: process.env.VUE_APP_TWITCH_SCOPE || '',
        state: process.env.VUE_APP_TWITCH_STATE || '',
      });
    };

    const oauthWithTwitter = (): void => {
      const provider = new TwitterAuthProvider();

      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
          // You can use these server side with your app's credentials to access the Twitter API.
          // const credential = TwitterAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          // const secret = credential.secret;

          // The signed-in user info.
          console.log(result.user);

          // send result to API
        }).catch((error) => {
          console.log(error);
          // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.email;
          // The AuthCredential type that was used.
          // const credential = TwitterAuthProvider.credentialFromError(error);
          // ...
        });
    };

    const oauthWithTikTok = () => {
      window.open('https://be-dev.melon.aegas.it/socials/tiktok');
    };

    const reset = () => {
      userData.nickname = user.value?.nickname ?? '';
      userData.emails = user.value?.emails?.length ? [...user.value.emails] : [''];
      userData.isNotificationAvailable = user.value?.isNotificationAvailable ?? false;
      userData.name = user.value?.name ?? null;
      userData.description = user.value?.description ?? null;
      userData.isDescriptionMarked = user.value?.isDescriptionMarked ?? false;
      userData.twitterLink = user.value?.twitterLink ?? null;
      userData.instagramLink = user.value?.instagramLink ?? null;
      userData.twitchLink = user.value?.twitchLink ?? null;
      userData.youtubeLink = user.value?.youtubeLink ?? null;
      userData.tiktokLink = user.value?.tiktokLink ?? null;
      userData.photo = null;
      userData.banner = null;
    };

    const url = (path?: string | null): string | null => (path ? fileUrl(path) : null);

    watch(() => userData.photo, (files) => {
      errors.value.photo = null;
      photoUrl.value = files?.length
        ? URL.createObjectURL(files[0])
        : userImageUrl(user.value?.photoIpfsPath);
    });

    watch(() => userData.banner, (files) => {
      errors.value.banner = null;
      bannerUrl.value = files?.length
        ? URL.createObjectURL(files[0])
        : url(user.value?.bannerIpfsPath);
    });

    watch(() => userData.nickname, () => {
      pristine.value.nickname = false;
      if (nicknameRef.value) {
        nicknameRef.value.setCustomValidity('');
      }
    });

    watch(currentUserNickname, () => {
      router.push({ name: 'home' });
    });

    onMounted(() => {
      if (currentUserNickname.value === userNickname.value) {
        store.dispatch(`${Modules.UserProfile}/${Actions.fetchUser}`, userNickname.value as string).finally(() => {
          reset();
          photoUrl.value = userImageUrl(user.value?.photoIpfsPath);
          bannerUrl.value = url(user.value?.bannerIpfsPath);
        });
      } else {
        navigateToProfile();
      }
    });

    onUnmounted(() => {
      store.dispatch(`${Modules.UserProfile}/${Actions.clearUser}`);
    });

    return {
      ...toRefs(userData),
      isLoading,
      photoUrl,
      bannerUrl,
      formRef,
      nicknameRef,
      emailRef,
      twitterRef,
      instagramRef,
      twitchRef,
      youtubeRef,
      tiktokRef,
      user,
      pristine,
      errors,
      serverErrors,
      onPhotoRejected,
      onBannerRejected,
      updateUser,
      reset,
      oauthSignInWithGoogle,
      oauthSignInWithInstagram,
      oauthSignInWithTwitch,
      oauthWithTwitter,
      oauthWithTikTok,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.top-container {
  @include items-wrapper;

  margin-top: -2rem;

  @include for-lg-xl-width {
    width: 100%;
    max-width: 384px * 2;
    margin-right: auto;
    margin-left: auto;
  }

  h2 {
    @include typo-headline-2;

    width: 100%;
    max-width: 366px;
    color: $neutrals-2;
  }
}

.form-container {
  @include items-wrapper;

  @include for-lg-xl-width {
    max-width: 384px * 2;
    margin-right: auto;
    margin-left: auto;
  }

  .left-column,
  .right-column {
    width: 50%;

    @include for-md-width {
      width: 100%;
    }

    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }

    .divider {
      width: 100%;
      margin-bottom: 2rem;

      @include for-xs-sm-width {
        border-bottom: 1px solid $container-border-color;
      }
    }
  }

  h3 {
    @include typo-body-2-bold;
    color: $neutrals-2;
  }
}

.photos-container {
  @include items-wrapper;

  margin-top: -2rem;

  & > * {
    width: 100%;

    @include for-md-width {
      width: 50%;
    }
  }

  .upload-button {
    @include typo-button-2;
    @include button-outline($neutrals-1);

    padding: 10px 1rem;
  }
}

.fields-container {
  @include items-wrapper;

  margin-top: -2rem;

  & > * {
    width: 100%;

    @include for-md-width {
      width: 50%;
    }

    &:not(:first-child) {
      @include for-lg-xl-width {
        margin-top: 0.5rem;
      }
    }
  }
}

.error-text {
  @include typo-caption-2;
  margin-top: 0.25rem;
  color: $primary-1;
}

.photo-container {
  display: flex;
  align-items: flex-start;

  .user-image {
    flex-shrink: 0;
    width: 128px;
    height: 128px;
    margin-right: 2rem;
    object-fit: cover;
    border-radius: 50%;

    @include for-xs-sm-width {
      width: 64px;
      height: 64px;
    }
  }

  .hint {
    @include typo-caption-2;

    margin-top: 0.5rem;
    color: $neutrals-4;
  }

  .upload-button {
    margin-top: 1rem;
  }
}

.banner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  .banner-image {
    max-width: 100%;
    max-height: 128px;
    margin-bottom: 1rem;
  }
}

.inputs-block {
  display: flex;
  flex-direction: column;

  .input-container {
    @include input-container;

    margin-top: 2rem;
  }

  .input {
    @include input-common;
    @include typo-caption-bold;

    padding: 0.75rem 1rem;
    border-radius: 0.75rem;
  }

  .toggle-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0.5rem;

    label {
      @include typo-body-2-bold;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    & > *:not(:first-child) {
      margin-top: 0.5rem;
    }
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 2.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid $container-border-color;

  @include for-md-width {
    margin-top: 4rem;
  }

  @include for-xs-sm-width {
    flex-direction: column;
    margin-top: 2rem;
  }

  .error-message {
    @include typo-caption-2;

    width: 100%;
    margin-top: 1rem;
    color: $primary-1;
  }

  .submit-button {
    @include typo-button-1;
    @include button-primary-filled;

    margin-top: 2rem;
    padding: 13px 1.5rem;

    @include for-md-lg-xl-width {
      margin-right: 2rem;
    }

    @include for-xs-sm-width {
      width: 100%;
    }
  }

  .reset-button {
    @include typo-button-1;
    @include transition-default;

    display: inline-flex;
    align-items: center;
    margin-top: 2rem;
    color: $neutrals-2;
    opacity: 0.5;

    &:hover, &:focus {
      opacity: 1;
    }

    .icon {
      width: 24px;
      margin-right: 0.5rem;
    }
  }
}
</style>
