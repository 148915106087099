<template>
  <section v-if="banner.displayed" class="container">
    <div class="row banner">
      <div>
        <h1 class="banner-title">{{ banner.title }}</h1>
        <p class="banner-text">{{ banner.description }}</p>
        <a :href="banner.ctaLink" class="banner-cta">{{ banner.ctaButtonTitle }}</a>
      </div>
      <div>
        <img
          v-if="banner.image"
          class="banner-img"
          :src="fileUrl(banner.image)"
          :alt="banner.title"
        >
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import { useStore } from '@/store';
import fileUrl from '@/shared/helpers/file-url';

export default defineComponent({
  setup() {
    const store = useStore();
    const banner = computed(() => store.state.featuredBanner);

    return {
      banner,
      fileUrl,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.container {
  display: grid;
  grid-template-columns: 1fr min(#{$xl-breakpoint - 64px}, calc(100% - 64px)) 1fr;
  background-color: $neutrals-9;

  /* stylelint-disable no-descending-specificity */
  & > * {
    grid-column: 2;
  }
}

.banner {
  padding: 3rem 0;

  .banner-title {
    @include typo-headline-1;

    margin-bottom: 16px;
  }

  .banner-text {
    @include typo-body-1;

    margin-bottom: 32px;
  }

  .banner-cta {
    display: inline-block;
    padding: 12px 16px;
    color: $neutrals-9;
    background-color: $primary-1;
    border-radius: 90px;

    @include for-xs-width {
      display: block;
      text-align: center;
    }
  }

  .banner-img {
    width: 100%;
    max-width: 528px;
    height: 100%;
    max-height: 528px;
    object-fit: contain;
  }
}

.row {
  @include items-wrapper;

  margin-top: -2rem;

  & > * {
    width: 50%;

    @include for-xs-sm-width {
      width: 100%;
    }
  }
}
</style>
