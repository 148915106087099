
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Loading from 'vue-loading-overlay';

import fileUrl from '@/shared/helpers/file-url';
import { getUrlRegExpByTypeId, isExternalType, isInstagramType } from '@/shared/helpers/get-item-file-type';
import CryptoCurrency from '@/shared/models/crypto-currency.enum';
import HttpStatusCode from '@/shared/models/http-status-code.enum';
import RewardStatus from '@/shared/models/reward-status.enum';
import { ERROR_UNKNOWN } from '@/shared/constants/messages';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-profile/props';
import { Actions as ItemListActions } from '@/store/modules/item-list/props';

import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';
import CategorySimplified from '@/store/modules/item-list/models/category-simplified';
import TextFilterSimplified from '@/store/modules/item-list/models/text-filter-simplified';
import ItemListItem from '@/store/modules/item-list/models/item-list-item';

import ItemUpdateRequest from '@/store/modules/item-profile/models/item-update-request';

import InfoModal from '@/components/InfoModal.vue';
import ItemCard from '@/components/ItemCard.vue';
import FileUploadButton from '@/components/FileUploadButton.vue';
import Select from '@/components/Select.vue';
import ItemFilterSelect from '@/components/ItemFilterSelect.vue';
import ItemTypeSelect from '@/components/ItemTypeSelect.vue';

export default defineComponent({
  name: 'EditItem',

  components: {
    ItemTypeSelect,
    Loading,
    InfoModal,
    ItemCard,
    FileUploadButton,
    Select,
    ItemFilterSelect,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref<boolean>(false);
    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });

    const id = computed(() => route.params.id);
    const currentUserId = computed(() => store.state.user.id);
    const item = computed(() => store.state.ItemProfile.item);

    const originalLinkRef = ref<HTMLInputElement | null>(null);

    const itemData = reactive<ItemUpdateRequest>({
      files: [],
      title: '',
      description: '',
      isDescriptionMarked: false,
      originalLink: '',
      extra: null,
      isExtraMarked: false,
      categoryIds: [],
      typeId: null,
      filters: [],
    });

    const maxFilesCount = computed(() => (isInstagramType(itemData.typeId) ? 10 : 1));

    const categoriesInitialFullValue = ref<CategorySimplified[]>([]);
    const filtersInitialFullValue = ref<TextFilterSimplified[]>([]);

    const pristine = ref<Record<string, boolean>>({
      title: true,
      originalLink: true,
    });

    const formRef = ref<HTMLFormElement | null>(null);

    const previewItem = ref<Partial<ItemListItem>>({
      title: itemData.title ?? '',
      ipfsPaths: [],
      mimeTypes: [],
      edition: '',

      price: 0,
      cryptoCurrency: CryptoCurrency.MATIC,
      usdPrice: '0',
      salesStatus: ItemSalesStatus.Minted,

      creatorNickname: '',
      creatorName: '',
      creatorPhotoIpfsPath: '',
      isCreatorVerified: false,
      isCreatorRegistered: true,

      rewardStatus: RewardStatus.None,

      ownerId: '',
    });

    const categories = computed(() => store.state.ItemList.categories);

    const errors = ref<Record<string, string | null>>({
      file: null,
    });

    function onFileRejected(e: Error) {
      errors.value.file = e.message;
    }

    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    const navigateToProfile = (): void => {
      router.push({ name: 'item-profile', params: { id: id.value } });
    };

    async function updateItem() {
      pristine.value.title = false;

      isLoading.value = true;

      try {
        await store.dispatch(
          `${Modules.ItemProfile}/${Actions.updateItem}`,
          { ...itemData },
        );
        setTimeout(() => {
          isLoading.value = false;
          navigateToProfile();
        }, 500);
      } catch (e) {
        if (e.response?.status === HttpStatusCode.BadRequest
          && e.response?.data?.TypeId) {
          if (originalLinkRef.value) {
            originalLinkRef.value.setCustomValidity(e.response?.data?.TypeId[0]);
            originalLinkRef.value.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else {
          buildErrorModal(e.message);
        }

        isLoading.value = false;
      }
    }

    function setItemData() {
      store.dispatch(`${Modules.ItemProfile}/${Actions.getItem}`, id.value as string).finally(() => {
        if (!currentUserId.value
          || currentUserId.value !== item.value?.creatorId
          || currentUserId.value !== item.value?.ownerId
        ) {
          navigateToProfile();
        }

        if (item.value) {
          itemData.title = item.value.title;
          itemData.description = item.value.description;
          itemData.isDescriptionMarked = item.value.isDescriptionMarked;
          itemData.originalLink = item.value.originalLink;
          itemData.extra = item.value.extra;
          itemData.isExtraMarked = item.value.isExtraMarked;
          itemData.typeId = item.value.typeId;

          categoriesInitialFullValue.value = [...item.value.categories];
          itemData.categoryIds = [...item.value.categories].map((f) => f.id);

          filtersInitialFullValue.value = [...item.value.filters];
          itemData.filters = [...item.value.filters].map((f) => f.id);

          previewItem.value.ipfsPaths = item.value.ipfsPaths.map((path) => fileUrl(path));
          previewItem.value.mimeTypes = item.value.mimeTypes;

          previewItem.value.edition = item.value.edition;

          previewItem.value.price = item.value.price;
          previewItem.value.cryptoCurrency = item.value.cryptoCurrency;
          previewItem.value.usdPrice = item.value.usdPrice;
          previewItem.value.salesStatus = item.value.salesStatus;

          previewItem.value.creatorNickname = item.value.creatorNickname;
          previewItem.value.creatorName = item.value.creatorName;
          previewItem.value.creatorPhotoIpfsPath = item.value.creatorPhotoIpfsPath;
          previewItem.value.isCreatorVerified = item.value.creatorVerifiedStatus;
          previewItem.value.isCreatorRegistered = item.value.isCreatorRegistered;

          previewItem.value.rewardStatus = item.value.rewardStatus;

          previewItem.value.ownerId = item.value.ownerId;
        }
      });
    }

    watch(() => itemData.files, (value: File[]) => {
      errors.value.file = null;

      if (value) {
        previewItem.value.ipfsPaths = value.map((file: File) => URL.createObjectURL(file));
        previewItem.value.mimeTypes = value.map((file: File) => file.type);
      }
    });

    watch(() => itemData.title, (value) => {
      pristine.value.title = false;
      previewItem.value.title = value ?? '';
    });

    watch(() => itemData.originalLink, () => {
      if (originalLinkRef.value) {
        originalLinkRef.value.setCustomValidity('');
      }
    });

    watch(() => itemData.extra, (value) => {
      if (value) {
        previewItem.value.rewardStatus = item.value?.rewardStatus === RewardStatus.Redeemed
          ? RewardStatus.Redeemed
          : RewardStatus.Available;
      } else {
        previewItem.value.rewardStatus = RewardStatus.None;
      }
    });

    watch(id, (value) => {
      if (value) {
        setItemData();
      }
    });

    watch(currentUserId, () => {
      router.push({ name: 'home' });
    });

    onMounted(() => {
      Promise.allSettled([
        store.dispatch(`${Modules.ItemList}/${ItemListActions.fetchCategories}`),
        store.dispatch(`${Modules.ItemList}/${ItemListActions.fetchTypes}`),
      ])
        .finally(() => {
          setItemData();
        });
    });

    onUnmounted(() => {
      store.dispatch(`${Modules.ItemProfile}/${Actions.clearItem}`);
    });

    return {
      ...toRefs(itemData),
      maxFilesCount,
      categoriesInitialFullValue,
      filtersInitialFullValue,
      previewItem,
      formRef,
      originalLinkRef,
      categories,
      isLoading,
      error,
      pristine,
      errors,
      CryptoCurrency,
      isExternalType,
      getUrlRegExpByTypeId,
      onFileRejected,
      updateItem,
    };
  },
});
