<template>
  <component
    :is="previewing ? 'div' : 'router-link'"
    :to="{ name: 'item-profile', params: { id: data.id }}"
    class="item-card"
    :class="{
      overlay: selecting
        && (
          currentUserId !== data.ownerId
            || !(
              data.salesStatus === ItemSalesStatus.Minted
                || data.salesStatus === ItemSalesStatus.Sold
              )
          ),
      'no-shadow': previewing,
    }"
  >
    <div class="media-container">
      <ItemFilePreview
        :id="data.id"
        :filePath="data.ipfsPaths[0] || ''"
        :mimeType="data.mimeTypes[0]"
        :withCustomUrl="previewing"
        :playStart="data.playStart || null"
        :playEnd="data.playEnd || null"
        class="file-preview"
      />

      <button class="creator-container" @click="openCreator(data.creatorNickname, $event)">
        <UserImage
          :userName="data.creatorName"
          :userImagePath="data.creatorPhotoIpfsPath"
          :isUserVerified="data.isCreatorVerified"
          :isUserRegistered="data.isCreatorRegistered"
          offchain-tooltip-position="is-right"
          imageSize="36px"
          iconSize="12px"
        />
        <span class="creator-name">{{ data.creatorName }}</span>
      </button>
    </div>

    <div class="description-container">
      <h4 class="title">{{ data.title }}</h4>
      <p class="edition">{{ data.edition }}</p>

      <div class="selling-status">
        <span v-if="data.salesStatus === ItemSalesStatus.Minted">
          {{ data.saleDate ? 'Sale starting soon' : 'Coming soon' }}
        </span>
        <span v-else-if="data.salesStatus === ItemSalesStatus.OnOffChainOfferAuction">
          {{ !data.cryptoCurrency || !data.price
            ? 'Be first to name your price!'
            : 'Accepting offers' }}
        </span>
        <span v-else-if="isItemSelling">
          {{ data.salesStatus === ItemSalesStatus.OnEnglishAuction
            ? 'Auction'
            : 'Selling at price'}}
        </span>
        <span v-else-if="data.salesStatus === ItemSalesStatus.Sold">
          <template v-if="data.saleDate">
            Sale starting soon
          </template>
          <template v-else>
            {{ currentUserId === data.ownerId ? 'Bought' : 'Sold' }} for
          </template>
        </span>
        <img v-else src="@/assets/images/common/lock.svg" alt="lock icon" class="lock-icon">

        <ItemPrice
          v-if="hasPrice && !data.saleDate"
          class="price"
          :cryptoAmount="data.price"
          :cryptoCurrency="data.cryptoCurrency"
          :usdAmount="data.usdPrice"
        />
        <span v-else-if="data.saleDate">{{ timeLeftAsStr }}</span>
      </div>
    </div>

    <div
      v-if="selecting
        &&  currentUserId === data.ownerId
        && (
          data.salesStatus === ItemSalesStatus.Minted
            || data.salesStatus === ItemSalesStatus.Sold
        )"
      class="checkbox-wrapper"
      @click="stopPropagation"
    >
      <label class="checkbox-button">
        <input
          v-model="selected"
          name="item-card-selecting-options"
          :value="data.id"
          type="checkbox"
          hidden
        >
        <span class="checkmark"></span>
      </label>
    </div>

    <div
      v-if="!selecting"
      class="extra-container"
    >
      <img
        v-if="data.salesStatus === ItemSalesStatus.OnOffChainOfferAuction"
        src="@/assets/images/common/unminted.svg"
        alt="unminted"
        title="not minted"
        class="icon"
      >
      <img
        v-if="data.rewardStatus !== RewardStatus.None"
        src="@/assets/images/common/reward.svg"
        alt="reward"
        title="reward"
        :class="{ icon: true, 'icon-no-color': data.rewardStatus === RewardStatus.Redeemed }"
      >
      <img
        v-if="data.typeId"
        :src="typeImageUrl(data.typeId)"
        :alt="data.typeTitle"
        :title="data.typeTitle"
        class="icon"
      >
    </div>
  </component>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';

// store
import { useStore } from '@/store';

// components
import ItemFilePreview from './ItemFilePreview.vue';
import ItemPrice from './ItemPrice.vue';
import UserImage from './UserImage.vue';

// helpers
import calcTimeDiffSeparately from '@/shared/helpers/calc-time-diff-separately';

// models
import ItemListItem from '@/store/modules/item-list/models/item-list-item';
import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';
import RewardStatus from '@/shared/models/reward-status.enum';
import TimeLeftData from '@/shared/models/time-left-data';

export default defineComponent({
  name: 'ItemCard',

  components: {
    ItemFilePreview,
    ItemPrice,
    UserImage,
  },

  props: {
    data: {
      type: Object as () => ItemListItem,
      required: true,
    },
    previewing: {
      type: Boolean,
      default: false,
    },
    selecting: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      default: [],
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const timerId = ref<number | null>(null);
    const timeLeft = ref<TimeLeftData>({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      totalLeft: 0,
    });

    const timeLeftAsStr = computed(() => {
      const {
        days,
        hours,
        minutes,
        seconds,
        totalLeft,
      } = timeLeft.value;
      return totalLeft > 0 ? `${days}d ${hours}h ${minutes}m ${seconds}s` : null;
    });

    const selected = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const isItemSelling = computed(() => (
      props.data?.salesStatus === ItemSalesStatus.OnSale
      || props.data?.salesStatus === ItemSalesStatus.OnEnglishAuction
      || props.data?.salesStatus === ItemSalesStatus.OnDutchAuction
    ));

    const hasPrice = computed(() => (
      !!props.data.price && !!props.data.cryptoCurrency && !!props.data.usdPrice
      && (props.data?.salesStatus === ItemSalesStatus.OnSale
        || props.data?.salesStatus === ItemSalesStatus.OnEnglishAuction
        || props.data?.salesStatus === ItemSalesStatus.Sold
        || props.data?.salesStatus === ItemSalesStatus.OnOffChainOfferAuction)
    ));

    const currentUserId = computed(() => store.state.user.id);

    const openCreator = (userNickname: string, e: MouseEvent) => {
      e.preventDefault();

      router.push({ name: 'user-profile', params: { userNickname } });
    };

    const stopPropagation = (e: MouseEvent) => {
      e.stopPropagation();
    };

    const typeImageUrl = (typeId: string) => `${process.env.VUE_APP_API_URL}/types/${typeId}/image`;

    function updateLeftTime() {
      timeLeft.value = calcTimeDiffSeparately(props.data?.saleDate as string);

      if (timeLeft.value.totalLeft < 0 && timerId.value) {
        // eslint-disable-next-line
        clearInterval(timerId.value as any);
        timerId.value = null;
      }
    }

    onMounted(() => {
      if (props.data?.saleDate) {
        timerId.value = setInterval(updateLeftTime, 1000) as unknown as number;
        updateLeftTime();
      }
    });

    onUnmounted(() => {
      if (timerId.value) {
        // eslint-disable-next-line
        clearInterval(timerId.value as any);
        timerId.value = null;
      }
    });

    return {
      timeLeftAsStr,
      RewardStatus,
      selected,
      isItemSelling,
      hasPrice,
      currentUserId,
      ItemSalesStatus,
      typeImageUrl,
      openCreator,
      stopPropagation,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.item-card {
  @include card;
  @include card-interactive;

  position: relative;
  display: flex;
  flex-direction: column;

  @include for-xs-sm-md-lg-width {
    @include box-shadow-mobile;
  }

  &.overlay {
    box-shadow: none;
    pointer-events: none;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba($neutrals-8, 0.5);
      content: '';
    }
  }

  &.no-shadow {
    box-shadow: none;
  }
}

.media-container {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  .file-preview {
    width: 100%;
    height: 348px;
    object-fit: cover;
  }
}

.creator-container {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  display: flex;
  align-items: center;
  max-width: calc(100% - 1rem);

  .creator-name {
    @include typo-body-2;
    @include text-ellipsis;

    max-width: calc(100% - 42px);
    margin-left: 6px;
    color: $neutrals-8;
    font-weight: 500;
    text-shadow: 2px 2px 3px rgba($neutrals-1, .5);
  }
}

.description-container {
  padding: 0.75rem 0.75rem 1.25rem;
  font-weight: 600;

  .title {
    @include typo-body-2;
    @include text-ellipsis;

    max-width: 100%;
    color: $neutrals-2;
    font-weight: 500;
  }

  .edition {
    @include typo-caption-2;
    color: $neutrals-4;
  }

  .selling-status {
    @include typo-caption-2;

    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    color: $neutrals-3;

    .lock-icon {
      width: 20px;
    }

    .price {
      margin-left: 0.5rem;
    }
  }
}

.checkbox-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;

  .checkbox-button {
    @include checkbox-button;
  }
}

.extra-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  padding: 1rem;

  .icon {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 0.25rem 1rem 0.25rem rgba(15, 15, 15, 0.15);
  }

  .icon-no-color {
    filter: grayscale(1);
  }
}
</style>
