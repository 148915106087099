
import { defineComponent, ref } from 'vue';
import { format } from 'timeago.js';

import Item from '@/store/modules/item-profile/models/item';
import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';

// import Markdown from '@/components/Markdown.vue';
import ItemProfileListItem from './ItemProfileListItem.vue';
import ItemProfileOffChainOfferList from './ItemProfileOffChainOfferList.vue';
import ItemProfileBidList from './ItemProfileBidList.vue';
import ItemProfileOfferList from './ItemProfileOfferList.vue';
import ItemPrice from '@/components/ItemPrice.vue';

export default defineComponent({
  name: 'ItemProfileTabs',

  components: {
    ItemProfileListItem,
    ItemProfileOffChainOfferList,
    ItemProfileBidList,
    ItemProfileOfferList,
    ItemPrice,
    // Markdown,
  },

  props: {
    item: {
      type: Object as () => Item,
      required: true,
    },
  },

  setup() {
    const tab = ref<string>('offers');

    const formatDate = (date: string): string => format(date);

    const requiredBlockExplorer = process.env[`VUE_APP_${process.env.VUE_APP_NETWORK_NAME}_BLOCK_EXPLORER_URL`] as string;

    const tokenUrl = (id: string, address: string) => `${requiredBlockExplorer}/token/${address}?a=${id}`;

    const txUrl = (value: string) => `${requiredBlockExplorer}/tx/${value}`;

    const contractUrl = (address: string) => `${requiredBlockExplorer}/token/${address}`;

    const metadataUrl = (id: string) => `${process.env.VUE_APP_API_URL}/items/metadata/${id}`;

    const openSeaUrl = (contractAddress: string, id: string) => (
      `${process.env.VUE_APP_OPEN_SEA_URL}/${contractAddress}/${id}`
    );

    return {
      tab,
      SaleStatus: ItemSalesStatus,
      formatDate,
      tokenUrl,
      txUrl,
      contractUrl,
      openSeaUrl,
      metadataUrl,
    };
  },
});
