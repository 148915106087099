<template>
  <router-link
    v-if="isAuthenticated && user.id"
    :to="{ name: 'user-profile', params: { userNickname: user.nickname } }"
    class="profile-button"
    @click="emitClick"
  >
    <img :src="userImageUrl(user.photoPath)" alt="user image" class="user-image">
    <span class="name">{{ user.name || user.nickname }}</span>
  </router-link>
  <button
    v-else
    class="connect-button"
    @click="isConnectWalletModalOpen = true"
  >Connect Wallet</button>

  <ConnectionModal
    :is-open="isConnectWalletModalOpen"
    @close="isConnectWalletModalOpen = false"
    @endConnection="navToHome()"
  />
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import userImageUrl from '@/shared/helpers/user-image-url';

import { useStore } from '@/store';
import { Actions } from '@/store/props';

import ConnectionModal from '@/components/ConnectionModal.vue';

export default defineComponent({
  name: 'ConnectButton',

  components: {
    ConnectionModal,
  },

  emits: ['buttonClick'],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const isConnectWalletModalOpen = ref<boolean>(false);

    const token = computed(() => store.state.session.token);
    const user = computed(() => store.state.user.personalInfo);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    const getUserPersonalInfo = () => {
      store.dispatch(Actions.getUserPersonalInfo);
    };

    const emitClick = () => {
      emit('buttonClick');
    };

    const navToHome = () => {
      router.push({ name: 'home' });
      emitClick();
    };

    watch(token, (value) => {
      if (value) {
        getUserPersonalInfo();
      }
    });

    onMounted(() => {
      if (isAuthenticated.value) {
        getUserPersonalInfo();
      }
    });

    return {
      user,
      isAuthenticated,
      isConnectWalletModalOpen,
      emitClick,
      navToHome,
      userImageUrl,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.profile-button {
  @include typo-button-2;
  @include button-outline($neutrals-2, $neutrals-6);

  display: inline-flex;
  align-items: center;
  color: $neutrals-2;

  .user-image {
    width: 32px;
    height: 32px;
    margin-left: 0.25rem;
    object-fit: cover;
    border-radius: 50%;
  }

  .name {
    @include text-ellipsis;

    max-width: 150px;
    padding: 0.75rem;
  }
}

.connect-button {
  @include typo-button-2;
  @include button-outline($neutrals-1);

  padding: 10px 1rem;
}
</style>
