
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
} from 'vue';

// helpers
import calcTimeDiffSeparately from '@/shared/helpers/calc-time-diff-separately';

// models
import TimeLeftData from '@/shared/models/time-left-data';

export default defineComponent({
  name: 'ItemProfileSaleTimer',
  props: {
    saleDate: {
      type: String,
      required: true,
    },
  },
  emits: ['subscribe'],
  setup(props) {
    const { saleDate } = toRefs(props);
    const timerId = ref<number | null>(null);
    const timeLeft = ref<TimeLeftData>({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      totalLeft: 0,
    });
    const emailRef = ref<HTMLInputElement | null>(null);
    const emailPristine = ref<boolean>(true);
    const email = ref<string>('');

    function updateLeftTime() {
      timeLeft.value = calcTimeDiffSeparately(saleDate.value);

      if (timeLeft.value.totalLeft < 0 && timerId.value) {
        // eslint-disable-next-line
        clearInterval(timerId.value as any);
        timerId.value = null;
      }
    }

    onMounted(() => {
      timerId.value = setInterval(updateLeftTime, 1000) as unknown as number;
      updateLeftTime();
    });

    onUnmounted(() => {
      if (timerId.value) {
        // eslint-disable-next-line
        clearInterval(timerId.value as any);
        timerId.value = null;
      }
    });

    return {
      emailRef,
      emailPristine,
      email,
      timeLeft,
    };
  },
});
