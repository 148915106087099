import { anyWeb3 } from '@/instances/web3';

export default async function estimateGas(
  encodedTransaction: string,
  transactionCreator: string,
  price: number,
  contractAddress: string,
): Promise<number> {
  const estimatedGas = await anyWeb3.eth.estimateGas({
    from: transactionCreator,
    to: contractAddress,
    value: anyWeb3.utils.toWei(price.toString(), 'ether'),
    data: encodedTransaction,
  });
  return Math.round(estimatedGas * 1.5);
}
