/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, customRef, Ref } from 'vue';
import debounce from 'lodash/debounce';

const useDebouncedRef = (initialValue: unknown, delay: number): Ref<any> => {
  const state = ref(initialValue);
  const debouncedRef = customRef((track, trigger) => ({
    get() {
      track();
      return state.value;
    },
    set: debounce(
      (value: any) => {
        state.value = value;
        trigger();
      },
      delay,
    ),
  }));
  return debouncedRef;
};

export default useDebouncedRef;
