
import {
  defineComponent, ref, computed, onUnmounted, watch,
} from 'vue';
import Toggle from '@vueform/toggle';
import { useStore } from '@/store';

// store
import { Actions } from '@/store/modules/user-profile/props';
import { Actions as GlobalActions, Modules } from '@/store/props';

// components
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal,
    Toggle,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['updated-email'],

  setup(_, { emit }) {
    const store = useStore();
    const userProfileInfo = computed(() => store.state.user.personalInfo);

    const email = ref<string | null>(userProfileInfo.value?.email || null);
    const emailPristine = ref<boolean>(true);
    const emailError = ref<boolean>(true);
    const receiveNotificationsByEmail = ref<boolean>(false);
    const serverErrors = ref<string[] | null>(null);
    const isLoading = ref<boolean>(false);

    // eslint-disable-next-line
    const handleEmailChange = (e: any): void => {
      emailError.value = !e.target.validity.valid;
      emailPristine.value = false;
    };

    const updateUser = async (): Promise<void> => {
      serverErrors.value = null;
      isLoading.value = true;

      try {
        await store.dispatch(
          `${Modules.UserProfile}/${Actions.updateUser}`, {
            emails: [email.value],
            isNotificationAvailable: receiveNotificationsByEmail.value,
          },
        );
        await store.dispatch(GlobalActions.getUserPersonalInfo);
        emit('updated-email');
      } catch (err) {
        if (err.response?.data) {
          serverErrors.value = Object
            .values(err.response.data as { [key: string]: string[] })
            .reduce((prev, cur) => [...prev, ...cur], []);
        } else {
          serverErrors.value = [err.message];
        }
      } finally {
        isLoading.value = false;
      }
    };

    watch(receiveNotificationsByEmail, () => {
      emailPristine.value = false;
      emailError.value = false;
    });

    onUnmounted(() => {
      email.value = null;
      emailPristine.value = true;
      emailError.value = true;
      receiveNotificationsByEmail.value = false;
      serverErrors.value = null;
      isLoading.value = false;
    });

    return {
      isLoading,
      email,
      emailPristine,
      emailError,
      handleEmailChange,
      updateUser,
      receiveNotificationsByEmail,
      serverErrors,
    };
  },
});
