import { RouteLocationNormalized } from 'vue-router';

// eslint-disable-next-line import/no-extraneous-dependencies
import { logEvent, setCurrentScreen } from 'firebase/analytics';
import { analytics } from '@/instances/firebase';

const AnalyticsApi = {
  callScreenView(from: RouteLocationNormalized): void {
    if (analytics) {
      if (from.name) {
        logEvent(analytics, 'screen_view', {
          firebase_screen: window.location.origin + from.fullPath,
          firebase_screen_class: from.name as string,
        });
      }

      setTimeout(() => {
        if (analytics) {
          setCurrentScreen(analytics, window.location.pathname);
        }
      }, 30);
    }
  },
};

export default AnalyticsApi;
