<template>
  <form ref="formRef" id="create-item-profile" class="form-container" @submit.prevent="createItem">
    <div>
      <div class="columns">
        <div class="left-column">
          <h2>Create collectible</h2>

          <div class="inputs-block">
            <h3>Upload File *</h3>

            <div class="upload-container">
              <div class="upload-btns-container">
                <FileUploadButton
                  v-model="files"
                  :required="true"
                  :max-files="maxFilesCount"
                  id="create-item-profile-file"
                  accept="image/*, video/*"
                  iconName="upload-file"
                  buttonLabel="Select file"
                  class="upload-button"
                  @rejected="onFileRejected"
                />
                <button v-if="files.length" class="clear-upload-button" @click="clearAllFiles">
                  Clear Files
                </button>
              </div>
              <p v-if="!!errors.file" class="error-text">{{ errors.file }}</p>
              <div v-if="files.length" class="upload-files-list">
                <div v-for="(file, index) of files" class="upload-files-list-item" :key="index">
                  <img src="@/assets/images/common/file.svg" alt="File icon">
                  <p>{{ file.name }}</p>
                  <img
                    class="clear-uploaded-file"
                    src="@/assets/images/common/clear.svg"
                    alt="Clear icon"
                    @click="clearSelectedFile(index)"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="inputs-block">
            <h3>Items Details</h3>

            <div class="input-container">
              <label for="create-item-profile-name">Item name *</label>
              <input
                v-model="title"
                id="create-item-profile-name"
                type="text"
                placeholder="Item name"
                required
                class="input"
                :class="{ pristine: pristine.title }"
                @blur="pristine.title = false"
              >
              <p v-if="!title && !pristine.title" class="error-text">Field is required</p>
            </div>

            <div class="input-container">
              <label for="create-item-profile-description">Description</label>
              <div class="textarea-container">
                <textarea
                  v-model.trim="description"
                  id="create-item-profile-description"
                  placeholder="Item description"
                  class="input"
                ></textarea>
              </div>
            </div>

            <div :key="typeId" class="input-container">
              <label for="create-item-profile-original-link">
                Original link {{ isExternalType(typeId) ? '*' : '' }}
              </label>
              <input
                ref="originalLinkRef"
                v-model.trim="originalLink"
                id="create-item-profile-original-link"
                type="text"
                placeholder="Item original link"
                :pattern="getUrlRegExpByTypeId(typeId).source"
                :required="isExternalType(typeId)"
                class="input"
                :class="{ pristine: pristine.originalLink }"
                @blur="pristine.originalLink = false; $forceUpdate()"
              >
              <p
                v-if="isExternalType(typeId) && !originalLink && !pristine.originalLink"
                class="error-text"
              >Field is required for chosen type</p>
              <p
                v-else-if="!originalLinkRef?.validity.valid && !pristine.originalLink"
                class="error-text"
              >Invalid link</p>
            </div>

            <div class="input-container">
              <label for="create-item-profile-extra">Extra</label>
              <div class="textarea-container">
                <input
                  v-model="extra"
                  id="create-item-profile-extra"
                  type="text"
                  placeholder="Item extra"
                  class="input"
                >
              </div>
            </div>

            <div class="input-container">
              <label class="create-item-profile-category-ids">Categories *</label>
              <Select
                v-model="categoryIds"
                id="create-item-profile-category-ids"
                placeholder="Select categories"
                labelKey="title"
                :data="categories"
                :multiple="true"
                :isFormElement="true"
              />
            </div>

            <div class="input-container">
              <label class="create-item-profile-type-id">Type *</label>
              <ItemTypeSelect
                v-model="typeId"
                id="create-item-profile-type-id"
                placeholder="Select type"
                :isFormElement="true"
              />
            </div>

            <div class="input-container">
              <label for="create-item-profile-text-filters">Filters</label>
              <ItemFilterSelect
                v-model="filters"
                id="create-item-profile-text-filters"
                :isFormElement="true"
              />
            </div>
          </div>

          <div class="inputs-block">
            <div class="divider"></div>

            <div class="heading-container">
              <h3>Put on sale stright away</h3>
              <Toggle v-model="isOnSale" id="create-item-profile-is-on-sale" :disabled="isLoading"/>
            </div>

            <div v-if="isOnSale" class="input-container">
              <SaleForm
                formId="create-item-profile"
                :required="isOnSale"
                v-model:type="sellingType"
                v-model:currency="saleData.currency"
                v-model:amount="saleData.amount"
                v-model:amountTo="saleData.amountTo"
                v-model:duration="saleData.duration"
                v-model:numberOfIntervals="saleData.numberOfIntervals"
                @update:fiat="setFiat"
              />
            </div>

            <div class="input-container">
              <label for="create-item-profile-copies">Number of copies to be minted *</label>
              <input
                v-model="totalCopies"
                id="create-item-profile-copies"
                type="number"
                step="1"
                placeholder="Number of copies"
                class="input"
                required
              >
              <p v-if="!totalCopies" class="error-text">Field is required</p>
            </div>
          </div>
        </div>

        <div class="right-column">
          <div class="divider"></div>

          <div class="preview-container">
            <h3>Preview</h3>
            <ItemCard :previewing="true" :data="previewItem" />
          </div>
        </div>
      </div>
    </div>

    <div class="buttons">
      <!-- <p v-for="(err, i) in serverErrors" :key="i" class="error-message">
        {{ err }}
      </p> -->
      <button
        type="submit"
        class="submit-button"
        :disabled="!categoryIds.length || !typeId || !originalLinkRef?.validity.valid
          || !formRef?.checkValidity() || !!errors.file || !isMainNet"
      >
        <span>Create item</span>
        <img src="@/assets/images/common/arrow-right-light.svg" alt="arrow right icon" class="icon">
      </button>
    </div>
  </form>

  <InfoModal :isOpen="error.isOpen" title="Error" @close="error.isOpen = false">
    <p>{{ error.text }}</p>
  </InfoModal>

  <InfoModal :isOpen="success.isOpen" title="Success" @close="success.isOpen = false">
    <p>{{ success.text }}</p>
  </InfoModal>

  <Loading
    v-model:active="isLoading"
    loader="dots"
    background-color="#161414"
    color="#d92b3a"
    :opacity="0.9"
  />
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import Loading from 'vue-loading-overlay';
import Toggle from '@vueform/toggle';
import isEqual from 'lodash/isEqual';

import { getUrlRegExpByTypeId, isExternalType, isInstagramType } from '@/shared/helpers/get-item-file-type';
import CryptoCurrency from '@/shared/models/crypto-currency.enum';
import HttpStatusCode from '@/shared/models/http-status-code.enum';
import { ACTION_SUCCESS_DEFAULT, ERROR_UNKNOWN, ITEM_MINT_SUCCESS } from '@/shared/constants/messages';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-profile/props';
import { Actions as ItemListActions } from '@/store/modules/item-list/props';
import { Actions as UserProfileActions } from '@/store/modules/user-profile/props';

import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';
import ItemListItem from '@/store/modules/item-list/models/item-list-item';

import ItemSellingType from '@/store/modules/item-profile/models/item-selling-type';
import ItemSaleData from '@/store/modules/item-profile/models/item-sale-data';
import ItemCreateRequest from '@/store/modules/item-profile/models/item-create-request';
import ItemCreateResponse from '@/store/modules/item-profile/models/item-create-response';

import InfoModal from '@/components/InfoModal.vue';
import ItemCard from '@/components/ItemCard.vue';
import FileUploadButton from '@/components/FileUploadButton.vue';
import Select from '@/components/Select.vue';
import ItemFilterSelect from '@/components/ItemFilterSelect.vue';
import ItemTypeSelect from '@/components/ItemTypeSelect.vue';
import SaleForm from '@/components/SaleForm.vue';
import RewardStatus from '@/shared/models/reward-status.enum';

export default defineComponent({
  name: 'CreateItem',

  components: {
    ItemTypeSelect,
    Loading,
    Toggle,
    InfoModal,
    ItemCard,
    FileUploadButton,
    Select,
    ItemFilterSelect,
    SaleForm,
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const isLoading = ref<boolean>(false);
    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });
    const success = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });

    const token = computed(() => store.state.session.token);
    const currentUserNickname = computed(() => store.state.user.personalInfo.nickname);
    const isMainNet = computed(() => store.state.user.isMainNet);
    const user = computed(() => store.state.UserProfile.user);

    const originalLinkRef = ref<HTMLInputElement | null>(null);

    const isOnSale = ref<boolean>(false);
    const itemData = reactive<ItemCreateRequest>({
      files: [],
      title: '',
      description: '',
      isDescriptionMarked: false,
      originalLink: '',
      extra: null,
      isExtraMarked: false,
      categoryIds: [],
      typeId: null,
      filters: [],
      saleType: null,
      totalCopies: 1,
    });

    const maxFilesCount = computed(() => (isInstagramType(itemData.typeId) ? 10 : 1));

    const pristine = ref<Record<string, boolean>>({
      title: true,
      originalLink: true,
    });

    const createItemData = ref<{
      prevData: ItemCreateRequest,
      prevSaleData: ItemSaleData,
      res: ItemCreateResponse,
    } | null>(null);

    const formRef = ref<HTMLFormElement | null>(null);

    const sellingType = ref<ItemSellingType>(ItemSellingType.OnSale);
    const saleData = ref<ItemSaleData>({
      currency: CryptoCurrency.MATIC,
      amount: 1,
      amountTo: 2,
      duration: 60 * 60 * 24,
      numberOfIntervals: 1,
    });

    const previewItem = ref<Partial<ItemListItem>>({
      title: itemData.title ?? '',
      ipfsPaths: [],
      mimeTypes: [],
      edition: `1 of ${itemData.totalCopies}`,

      price: saleData.value.amount,
      cryptoCurrency: saleData.value.currency,
      usdPrice: '',
      salesStatus: isOnSale.value ? sellingType.value : ItemSalesStatus.Minted,

      creatorNickname: '',
      creatorName: '',
      creatorPhotoIpfsPath: '',
      isCreatorVerified: false,
      isCreatorRegistered: true,

      rewardStatus: RewardStatus.None,

      ownerId: '',
    });

    const categories = computed(() => store.state.ItemList.categories);

    const errors = ref<Record<string, string | null>>({
      file: null,
    });

    function onFileRejected(e: Error): void {
      errors.value.file = e.message;
    }

    function validateFilesCount(): void {
      const filesCount = itemData.files.length;

      if (maxFilesCount.value < filesCount) {
        errors.value.file = `Selected number of files is invalid. Max possible file count: ${maxFilesCount.value}`;
      } else {
        errors.value.file = null;
      }
    }

    function clearAllFiles(): void {
      itemData.files = [];
    }

    function clearSelectedFile(index: number): void {
      itemData.files = itemData.files.filter((_, i) => i !== index);
    }

    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    function buildSuccessModal(text: string): void {
      success.value = {
        isOpen: true,
        text: text || ACTION_SUCCESS_DEFAULT,
      };
    }

    async function createItem() {
      if (!isMainNet.value) {
        return;
      }

      pristine.value.title = false;

      isLoading.value = true;

      const data = { ...itemData };

      if (isOnSale.value) {
        data.saleType = sellingType.value;
      }

      if (!isEqual(data, createItemData.value?.prevData)
        || !isEqual(saleData.value, createItemData.value?.prevSaleData)) {
        createItemData.value = null;
      }

      if (!createItemData.value) {
        try {
          const res = await store.dispatch(`${Modules.ItemProfile}/${Actions.createItem}`, data);
          createItemData.value = { prevData: data, prevSaleData: { ...saleData.value }, res };
        } catch (e) {
          if (e.response?.status === HttpStatusCode.BadRequest
            && e.response?.data?.TypeId) {
            if (originalLinkRef.value) {
              originalLinkRef.value.setCustomValidity(e.response?.data?.TypeId[0]);
              originalLinkRef.value.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          } else {
            buildErrorModal(e.message);
          }
        }
      }

      if (createItemData.value) {
        try {
          const action = `mintItem${sellingType.value}` as Actions.mintItemOnSale | Actions.mintItemOnEnglishAuction | Actions.mintItemOnDutchAuction;

          await store.dispatch(`${Modules.ItemProfile}/${action}`, {
            data,
            saleData: { ...saleData.value },
            res: { ...createItemData.value.res },
          });
          buildSuccessModal(ITEM_MINT_SUCCESS);
        } catch (e) {
          buildErrorModal(e.message);
        }
      }

      isLoading.value = false;
    }

    function setUserData() {
      store.dispatch(`${Modules.UserProfile}/${UserProfileActions.fetchUser}`, currentUserNickname.value as string).finally(() => {
        previewItem.value.creatorNickname = currentUserNickname.value ?? '';
        previewItem.value.creatorName = user.value?.name ?? '';
        previewItem.value.creatorPhotoIpfsPath = user.value?.photoIpfsPath ?? '';
        previewItem.value.isCreatorVerified = user.value?.isVerified;
        previewItem.value.isCreatorRegistered = user.value?.isRegistered;

        previewItem.value.ownerId = user.value?.userId ?? '';
      });
    }

    function setFiat(usdPrice: string | null) {
      previewItem.value.usdPrice = usdPrice;
    }

    watch(() => itemData.files, (value: File[]) => {
      validateFilesCount();

      if (value) {
        previewItem.value.ipfsPaths = value.map((file: File) => URL.createObjectURL(file));
        previewItem.value.mimeTypes = value.map((file: File) => file.type);
      }
    });

    watch(() => itemData.title, (value) => {
      pristine.value.title = false;
      previewItem.value.title = value ?? '';
    });

    watch(() => itemData.originalLink, () => {
      if (originalLinkRef.value) {
        originalLinkRef.value.setCustomValidity('');
      }
    });

    watch(() => itemData.typeId, () => {
      validateFilesCount();
    });

    watch(() => itemData.extra, (value) => {
      previewItem.value.rewardStatus = value ? RewardStatus.Available : RewardStatus.None;
    });

    watch(() => itemData.totalCopies, (value) => {
      previewItem.value.edition = `1 of ${value}`;
    });

    watch(() => saleData.value.amount, (value) => {
      previewItem.value.price = value || 0;
    });

    watch(() => saleData.value.currency, (value) => {
      previewItem.value.cryptoCurrency = value;
    });

    watch(sellingType, (value) => {
      previewItem.value.salesStatus = isOnSale.value ? value : ItemSalesStatus.Minted;
    });

    watch(isOnSale, (value) => {
      previewItem.value.salesStatus = value ? sellingType.value : ItemSalesStatus.Minted;

      if (!value) {
        sellingType.value = ItemSalesStatus.OnSale;
        saleData.value = {
          currency: CryptoCurrency.MATIC,
          amount: 1,
          amountTo: 2,
          duration: 60 * 60 * 24,
          numberOfIntervals: 1,
        };
      }
    });

    watch(token, (value) => {
      if (value) {
        setUserData();
      }
    });

    // As for now metamask cancel unapproved transactions on network change,
    // but do not send any response to that, so we reload forcefully, though,
    // there may be ongoing transaction, too.
    watch(isMainNet, () => {
      if (isLoading.value) {
        router.go(0);
      }
    });

    watch(() => success.value.isOpen, (open) => {
      // Changed to closed
      if (!open) {
        const id = createItemData.value?.res.ids[0];
        createItemData.value = null;
        router.push(id ? { name: 'item-profile', params: { id } } : { name: 'item-list' });
      }
    });

    onMounted(() => {
      createItemData.value = null;

      store.dispatch(`${Modules.ItemList}/${ItemListActions.fetchCategories}`);

      setUserData();
    });

    onUnmounted(() => {
      createItemData.value = null;

      store.dispatch(`${Modules.UserProfile}/${UserProfileActions.clearUser}`);
    });

    return {
      ...toRefs(itemData),
      maxFilesCount,
      isOnSale,
      previewItem,
      formRef,
      originalLinkRef,
      sellingType,
      saleData,
      isMainNet,
      categories,
      isLoading,
      error,
      pristine,
      success,
      errors,
      CryptoCurrency,
      isExternalType,
      getUrlRegExpByTypeId,
      onFileRejected,
      clearAllFiles,
      clearSelectedFile,
      setFiat,
      createItem,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.divider {
  width: 100%;
  margin-bottom: 2rem;
  border-bottom: 1px solid $container-border-color;
}

.form-container {
  @include items-wrapper;

  margin-top: -2rem;

  @include for-lg-xl-width {
    max-width: 576px + 384px;
    margin-right: auto;
    margin-left: auto;
  }

  & > * {
    width: 100%;
  }

  .columns {
    @include items-wrapper;

    margin-top: -2rem;

    .left-column {
      width: calc(100% - 384px);

      @include for-lg-xl-width {
        padding-right: 8rem;
      }

      @include for-xs-sm-width {
        width: 100%;
        max-width: 366px;
      }

      & > *:not(:first-child) {
        margin-top: 2.5rem;
      }
    }

    .right-column {
      width: 384px;

      @include for-xs-sm-width {
        width: 100%;
        max-width: 366px;
      }

      .divider {
        @include for-md-lg-xl-width {
          display: none;
        }
      }
    }
  }

  h2 {
    @include typo-headline-2;
    color: $neutrals-2;
  }

  h3 {
    @include typo-body-2-bold;
    color: $neutrals-2;
  }

  .buttons {
    width: 100%;
    max-width: 366px;
  }
}

.inputs-block {
  display: flex;
  flex-direction: column;

  .heading-container {
    /* stylelint-disable-next-line no-descending-specificity */
    & > *:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  .input-container {
    @include input-container;
  }

  & > .input-container {
    margin-top: 2rem;
  }

  .input {
    @include input-common;
    @include typo-caption-bold;

    padding: 0.75rem 1rem;
    border-radius: 0.75rem;
  }

  .error-text {
    @include typo-caption-2;
    margin-top: 0.25rem;
    color: $primary-1;
  }
}

.upload-container {
  margin-top: 1rem;

  .upload-btns-container {
    /* stylelint-disable-next-line no-descending-specificity */
    & > *:not(:last-child) {
      margin-right: 1rem;

      @include for-xs-sm-width {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    .upload-button {
      @include typo-button-1;
      @include button-primary-filled;

      padding: 10px 1.5rem;
      transition: 0.4s background-color $transition-timing-function-default,
        0.4s border-color $transition-timing-function-default;

      @include for-xs-sm-width {
        width: 100%;
      }
    }

    .clear-upload-button {
      @include typo-button-1;
      @include button-outline($neutrals-1, $control-border-color);

      padding: 10px 1.5rem;
      text-align: center;

      @include for-xs-sm-width {
        width: 100%;
      }
    }
  }

  .upload-files-list {
    width: 100%;
    margin-top: 2.5rem;

    .upload-files-list-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0.5rem 0;

      img {
        flex-shrink: 0;
        margin-right: 0.5rem;
      }

      p {
        @include typo-body-2-bold;
        @include text-ellipsis;

        flex-grow: 1;
        color: $neutrals-3;
      }

      .clear-uploaded-file {
        flex-shrink: 0;
        margin-left: 0.5rem;
        cursor: pointer;
      }
    }
  }
}

.preview-container {
  @include card;

  padding-top: 3rem;
  padding-bottom: 3rem;
  box-shadow: $depth-4;

  @include for-xs-sm-width {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  & > * {
    width: 256px;
    margin-right: auto;
    margin-left: auto;
  }

  h3 {
    @include typo-body-1-bold;

    margin-bottom: 1.5rem;
    color: $neutrals-2;

    @include for-xs-sm-width {
      text-align: center;
    }
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include for-xs-sm-width {
    flex-direction: column;
  }

  // .error-message {
  //   @include typo-caption-2;

  //   width: 100%;
  //   margin-top: 1rem;
  //   color: $primary-1;
  // }

  .submit-button {
    @include typo-button-1;
    @include button-primary-filled;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 13px 1.5rem;

    @include for-md-lg-xl-width {
      margin-right: 2rem;
    }

    @include for-xs-sm-width {
      width: 100%;
    }

    .icon {
      width: 16px;
      margin-left: 0.75rem;
    }
  }
}
</style>
