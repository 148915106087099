<template>
  <div class="option-container">
    <div>
      <label class="option checkbox-button">
        <input
          type="checkbox"
          v-model="checked"
          @change="$emit('changed', { checked, value, label })"
          hidden
        >
        <span class="checkmark"></span>
        <div class="label">
          <span v-if="hasIcon" class="checkbox-icon">
            <img :src="getIcon(label)" :alt="`${label} icon`">
          </span>
          {{ label }}
        </div>
      </label>
    </div>
    <div class="option-text">{{ formatNumber(value) }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import formatNumber from '@/shared/helpers/format-number';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const tiktokIcon = require('@/assets/images/socials/tiktok-solid.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const instagramIcon = require('@/assets/images/socials/instagram.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const twitterIcon = require('@/assets/images/socials/twitter-solid.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const youtubeIcon = require('@/assets/images/socials/youtube-solid.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const twitchIcon = require('@/assets/images/socials/twitch.svg');

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'changed',
  ],
  setup(props) {
    const checked = ref<boolean>(props.isChecked);

    const getIcon = (icon: string) => {
      switch (icon) {
        case 'TikTok':
          return tiktokIcon;
        case 'Instagram':
          return instagramIcon;
        case 'Twitter':
          return twitterIcon;
        case 'Youtube':
          return youtubeIcon;
        case 'Twitch':
          return twitchIcon;
        default:
          return '';
      }
    };

    return {
      getIcon,
      formatNumber,
      checked,
    };
  },
});
</script>

<style scoped lang="scss">
@import 'src/styles/mixins';

.option-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  .option-text {
    color: $primary-1;
    font-weight: 500;
  }
}

.option {
  display: flex;
  align-items: center;

  padding: 0.25rem 0;
  cursor: pointer;

  &.checkbox-button {
    @include checkbox-button-common(0, 0.1rem);
  }

  .label {
    display: inline-flex;
    align-items: center;
    font-weight: 500;

    .checkbox-icon {
      display: inline-flex;
      justify-content: center;
      width: 20px;
      margin-right: 0.5rem;
    }
  }
}
</style>
