
/* eslint-disable camelcase */
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Loading from 'vue-loading-overlay';
import Toggle from '@vueform/toggle';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getAuth, signInWithPopup, TwitterAuthProvider } from 'firebase/auth';

import userImageUrl from '@/shared/helpers/user-image-url';
import fileUrl from '@/shared/helpers/file-url';

import { useStore } from '@/store';
import { Actions as GlobalActions, Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-profile/props';

import UserPersonalInfo from '@/store/modules/user-profile/models/user-personal-info';
import SocialOauthParams from '@/store/modules/user-profile/models/social-oauth-params';

import FileUploadButton from '@/components/FileUploadButton.vue';

interface OauthApiParams extends SocialOauthParams {
  oauth_endpoint: string,
}

export default defineComponent({
  name: 'EditUser',

  components: {
    Loading,
    Toggle,
    FileUploadButton,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isLoading = ref<boolean>(false);

    const userNickname = computed(() => route.params.userNickname);
    const user = computed(() => store.state.UserProfile.user);
    const currentUserNickname = computed(() => store.state.user.personalInfo.nickname);

    const formRef = ref<HTMLFormElement | null>(null);

    const photoUrl = ref<string | null>(null);
    const bannerUrl = ref<string | null>(null);

    const nicknameRef = ref<HTMLInputElement | null>(null);
    const emailRef = ref<HTMLInputElement | null>(null);
    const twitterRef = ref<HTMLInputElement | null>(null);
    const instagramRef = ref<HTMLInputElement | null>(null);
    const twitchRef = ref<HTMLInputElement | null>(null);
    const youtubeRef = ref<HTMLInputElement | null>(null);
    const tiktokRef = ref<HTMLInputElement | null>(null);

    const userData = reactive<UserPersonalInfo & { banner: File[] | null; photo: File[] | null }>({
      nickname: '',
      emails: [''],
      isNotificationAvailable: false,
      name: null,
      description: null,
      isDescriptionMarked: false,
      twitterLink: null,
      instagramLink: null,
      twitchLink: null,
      youtubeLink: null,
      tiktokLink: null,
      photo: null,
      banner: null,
    });

    const pristine = ref<Record<string, boolean>>({
      nickname: true,
      email: true,
    });

    const errors = ref<Record<string, string | null>>({
      photo: null,
      banner: null,
    });
    const serverErrors = ref<string[] | null>(null);

    function onPhotoRejected(e: Error) {
      errors.value.photo = e.message;
    }

    function onBannerRejected(e: Error) {
      errors.value.banner = e.message;
    }

    const navigateToProfile = (): void => {
      router.push({
        name: 'user-profile',
        params: {
          userNickname: currentUserNickname.value as string,
        },
      });
    };

    const updateUser = async (): Promise<void> => {
      pristine.value.nickname = false;

      serverErrors.value = null;
      isLoading.value = true;

      try {
        await store.dispatch(
          `${Modules.UserProfile}/${Actions.updateUser}`, {
            ...userData,
            emails: userData.emails
              ? [...userData.emails].filter((value) => !!value).map((value) => value?.trim())
              : [],
            description: userData.description?.trim(),
            banner: userData.banner?.length ? userData.banner[0] : null,
            photo: userData.photo?.length ? userData.photo[0] : null,
          },
        );
        await store.dispatch(GlobalActions.getUserPersonalInfo);
        setTimeout(() => {
          isLoading.value = false;
          navigateToProfile();
        }, 500);
      } catch (err) {
        isLoading.value = false;

        if (err.response?.data?.errors) {
          serverErrors.value = Object
            .values(err.response.data.errors as { [key: string]: string[] })
            .reduce((prev, cur) => [...prev, ...cur], []);
        } else if (err.response?.data?.Nickname) {
          if (nicknameRef.value) {
            nicknameRef.value.setCustomValidity(err.response?.data?.Nickname[0]);
            nicknameRef.value.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        } else {
          serverErrors.value = [err.message];
        }
      }
    };

    const createOauthRequest = (payload: OauthApiParams): void => {
      const form = document.createElement('form');
      form.setAttribute('method', 'GET');
      form.setAttribute('action', payload.oauth_endpoint);

      const params = ref<SocialOauthParams>({
        redirect_uri: payload.redirect_uri,
        client_id: payload.client_id,
        response_type: payload.response_type,
        scope: payload.scope,
        state: payload.state,
      });

      Object.entries(params.value).forEach((item) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', item[0]);
        input.setAttribute('value', item[1]);
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    };

    const oauthSignInWithGoogle = (): void => {
      createOauthRequest({
        oauth_endpoint: process.env.VUE_APP_GOOGLE_OAUTH_ENDPOINT || '',
        redirect_uri: process.env.VUE_APP_GOOGLE_REDIRECT_URI || '',
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID || '',
        response_type: process.env.VUE_APP_GOOGLE_RESPONSE_TYPE || '',
        scope: process.env.VUE_APP_GOOGLE_SCOPE || '',
        state: process.env.VUE_APP_GOOGLE_STATE || '',
      });
    };

    const oauthSignInWithInstagram = (): void => {
      createOauthRequest({
        oauth_endpoint: process.env.VUE_APP_INSTAGRAM_OAUTH_ENDPOINT || '',
        redirect_uri: process.env.VUE_APP_INSTAGRAM_REDIRECT_URI || '',
        client_id: process.env.VUE_APP_INSTAGRAM_CLIENT_ID || '',
        response_type: process.env.VUE_APP_INSTAGRAM_RESPONSE_TYPE || '',
        scope: process.env.VUE_APP_INSTAGRAM_SCOPE || '',
        state: process.env.VUE_APP_INSTAGRAM_STATE || '',
      });
    };

    const oauthSignInWithTwitch = (): void => {
      createOauthRequest({
        oauth_endpoint: process.env.VUE_APP_TWITCH_OAUTH_ENDPOINT || '',
        redirect_uri: process.env.VUE_APP_TWITCH_REDIRECT_URI || '',
        client_id: process.env.VUE_APP_TWITCH_CLIENT_ID || '',
        response_type: process.env.VUE_APP_TWITCH_RESPONSE_TYPE || '',
        scope: process.env.VUE_APP_TWITCH_SCOPE || '',
        state: process.env.VUE_APP_TWITCH_STATE || '',
      });
    };

    const oauthWithTwitter = (): void => {
      const provider = new TwitterAuthProvider();

      const auth = getAuth();
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
          // You can use these server side with your app's credentials to access the Twitter API.
          // const credential = TwitterAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          // const secret = credential.secret;

          // The signed-in user info.
          console.log(result.user);

          // send result to API
        }).catch((error) => {
          console.log(error);
          // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.email;
          // The AuthCredential type that was used.
          // const credential = TwitterAuthProvider.credentialFromError(error);
          // ...
        });
    };

    const oauthWithTikTok = () => {
      window.open('https://be-dev.melon.aegas.it/socials/tiktok');
    };

    const reset = () => {
      userData.nickname = user.value?.nickname ?? '';
      userData.emails = user.value?.emails?.length ? [...user.value.emails] : [''];
      userData.isNotificationAvailable = user.value?.isNotificationAvailable ?? false;
      userData.name = user.value?.name ?? null;
      userData.description = user.value?.description ?? null;
      userData.isDescriptionMarked = user.value?.isDescriptionMarked ?? false;
      userData.twitterLink = user.value?.twitterLink ?? null;
      userData.instagramLink = user.value?.instagramLink ?? null;
      userData.twitchLink = user.value?.twitchLink ?? null;
      userData.youtubeLink = user.value?.youtubeLink ?? null;
      userData.tiktokLink = user.value?.tiktokLink ?? null;
      userData.photo = null;
      userData.banner = null;
    };

    const url = (path?: string | null): string | null => (path ? fileUrl(path) : null);

    watch(() => userData.photo, (files) => {
      errors.value.photo = null;
      photoUrl.value = files?.length
        ? URL.createObjectURL(files[0])
        : userImageUrl(user.value?.photoIpfsPath);
    });

    watch(() => userData.banner, (files) => {
      errors.value.banner = null;
      bannerUrl.value = files?.length
        ? URL.createObjectURL(files[0])
        : url(user.value?.bannerIpfsPath);
    });

    watch(() => userData.nickname, () => {
      pristine.value.nickname = false;
      if (nicknameRef.value) {
        nicknameRef.value.setCustomValidity('');
      }
    });

    watch(currentUserNickname, () => {
      router.push({ name: 'home' });
    });

    onMounted(() => {
      if (currentUserNickname.value === userNickname.value) {
        store.dispatch(`${Modules.UserProfile}/${Actions.fetchUser}`, userNickname.value as string).finally(() => {
          reset();
          photoUrl.value = userImageUrl(user.value?.photoIpfsPath);
          bannerUrl.value = url(user.value?.bannerIpfsPath);
        });
      } else {
        navigateToProfile();
      }
    });

    onUnmounted(() => {
      store.dispatch(`${Modules.UserProfile}/${Actions.clearUser}`);
    });

    return {
      ...toRefs(userData),
      isLoading,
      photoUrl,
      bannerUrl,
      formRef,
      nicknameRef,
      emailRef,
      twitterRef,
      instagramRef,
      twitchRef,
      youtubeRef,
      tiktokRef,
      user,
      pristine,
      errors,
      serverErrors,
      onPhotoRejected,
      onBannerRejected,
      updateUser,
      reset,
      oauthSignInWithGoogle,
      oauthSignInWithInstagram,
      oauthSignInWithTwitch,
      oauthWithTwitter,
      oauthWithTikTok,
    };
  },
});
