<template>
  <div
    class="user-image-container"
    :style="{
      width: imageSize,
      height: imageSize,
    }"
  >
    <img
      :src="userImageUrl(userImagePath)"
      :alt="userName + ' image'"
      class="user-image"
    >
    <Tooltip
      v-if="!isUserRegistered && !isUserVerified"
      label="The user is unlinked"
      :position="offchainTooltipPosition"
      size="is-small"
      :style="{
        'vertical-align': 'middle',
        position: 'absolute',
        right: iconOffset,
        bottom: iconOffset,
        width: iconSize,
        height: iconSize,
      }"
    >
      <img
        src="@/assets/images/common/unregistered.svg"
        alt="unregistered user icon"
        class="icon"
        :style="{
          right: iconOffset,
          bottom: iconOffset,
          width: iconSize,
          height: iconSize,
        }"
      >
    </Tooltip>
    <img
      v-else-if="isUserVerified"
      src="@/assets/images/common/verified.svg"
      alt="verified user icon"
      class="icon"
      :style="{
        width: iconSize,
        height: iconSize,
        right: iconOffset,
        bottom: iconOffset,
      }"
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import userImageUrl from '@/shared/helpers/user-image-url';

export default defineComponent({
  name: 'UserImage',

  props: {
    userName: {
      required: true,
    },
    userImagePath: {
      required: true,
    },
    isUserVerified: {
      type: Boolean,
      default: false,
    },
    isUserRegistered: {
      type: Boolean,
      default: false,
    },
    imageSize: {
      type: String,
      default: '2rem',
    },
    iconSize: {
      type: String,
      default: '0.5rem',
    },
    iconOffset: {
      type: String,
      default: '0',
    },
    offchainTooltipPosition: {
      type: String,
      default: 'is-top',
    },
  },

  setup() {
    return {
      userImageUrl,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.user-image-container {
  position: relative;

  .user-image {
    @include image-fallback;

    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .icon {
    position: absolute;
  }
}
</style>
