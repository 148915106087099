import ItemSellingType from './item-selling-type';

enum ItemNotSellingType {
  Created = 'Created',
  Minted = 'Minted',
  OnOffChainOfferAuction = 'OnOffChainOfferAuction',
  Sold = 'Sold',
}

type ItemSalesStatus = ItemSellingType | ItemNotSellingType;

const ItemSalesStatus = { ...ItemSellingType, ...ItemNotSellingType };

export default ItemSalesStatus;
