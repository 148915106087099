
import { defineComponent, inject, ref } from 'vue';
import { fmKey } from '@/instances/fortmatic';

import { ERROR_UNKNOWN } from '@/shared/constants/messages';

// store
import { useStore } from '@/store';
import { Actions, Mutations } from '@/store/props';

// components
import Modal from '@/components/Modal.vue';
import InfoModal from '@/components/InfoModal.vue';

export default defineComponent({
  name: 'ConnectionModal',
  components: {
    Modal,
    InfoModal,
  },
  props: ['isOpen'],
  emits: ['close', 'endConnection'],
  setup(_, { emit }) {
    // data
    const fmProvider = inject(fmKey);
    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });
    // store
    const store = useStore();

    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    async function connect(isMetamask: boolean) {
      emit('close');

      store.commit(Mutations.setLoading, true);
      try {
        await store.dispatch(Actions.reqUserAuth, { forceConnection: true, isMetamask });
        if (isMetamask) {
          await store.dispatch(Actions.switchEthNetwork);
        }
        emit('endConnection');
      } catch (err) {
        buildErrorModal(err.message);
        if (!isMetamask && fmProvider) {
          fmProvider.user.logout();
        }
      } finally {
        store.commit(Mutations.setLoading, false);
      }
    }

    async function connectWithMetamask() {
      await connect(true);
    }

    async function connectWithFortmatic() {
      await connect(false);
    }

    return {
      error,
      connectWithMetamask,
      connectWithFortmatic,
    };
  },
});
