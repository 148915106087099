<template>
  <Modal
    v-show="isOpen"
    :has-cancel-button="false"
    :has-footer="false"
    :max-width="'730px'"
  >
    <template v-slot:header>
      <h2>Receive notifications by email</h2>
    </template>
    <template v-slot:body>
      <div class="email-container">
        <div class="email-description">
          <p>
            Set up your email and be informed about followed users actions and app notifications!
          </p>
        </div>
        <div class="input-container">
          <label for="email-sub-input">Enter your email</label>
          <input
            v-model="email"
            id="email-sub-input"
            type="email"
            placeholder="example@gmail.com"
            required
            class="input"
            :class="{ pristine: emailPristine }"
            @input="handleEmailChange"
            @blur="handleEmailChange"
          >
          <p
            v-if="emailError && !emailPristine"
            class="error-text"
          >
            Invalid email
          </p>
        </div>
        <div class="toggle-container">
          <label for="edit-user-profile-has-notifications">
            Receive notifications via email
          </label>
          <Toggle v-model="receiveNotificationsByEmail" id="receive-notifications" />
        </div>
        <p v-for="(err, i) in serverErrors" :key="i" class="error-message">
          {{ err }}
        </p>
        <button
          class="primary-button"
          :disabled="emailError || !email"
          @click="updateUser"
        >
          <span v-if="!isLoading">Confirm</span>
          <span v-else>Please wait...</span>
        </button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  defineComponent, ref, computed, onUnmounted, watch,
} from 'vue';
import Toggle from '@vueform/toggle';
import { useStore } from '@/store';

// store
import { Actions } from '@/store/modules/user-profile/props';
import { Actions as GlobalActions, Modules } from '@/store/props';

// components
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal,
    Toggle,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['updated-email'],

  setup(_, { emit }) {
    const store = useStore();
    const userProfileInfo = computed(() => store.state.user.personalInfo);

    const email = ref<string | null>(userProfileInfo.value?.email || null);
    const emailPristine = ref<boolean>(true);
    const emailError = ref<boolean>(true);
    const receiveNotificationsByEmail = ref<boolean>(false);
    const serverErrors = ref<string[] | null>(null);
    const isLoading = ref<boolean>(false);

    // eslint-disable-next-line
    const handleEmailChange = (e: any): void => {
      emailError.value = !e.target.validity.valid;
      emailPristine.value = false;
    };

    const updateUser = async (): Promise<void> => {
      serverErrors.value = null;
      isLoading.value = true;

      try {
        await store.dispatch(
          `${Modules.UserProfile}/${Actions.updateUser}`, {
            emails: [email.value],
            isNotificationAvailable: receiveNotificationsByEmail.value,
          },
        );
        await store.dispatch(GlobalActions.getUserPersonalInfo);
        emit('updated-email');
      } catch (err) {
        if (err.response?.data) {
          serverErrors.value = Object
            .values(err.response.data as { [key: string]: string[] })
            .reduce((prev, cur) => [...prev, ...cur], []);
        } else {
          serverErrors.value = [err.message];
        }
      } finally {
        isLoading.value = false;
      }
    };

    watch(receiveNotificationsByEmail, () => {
      emailPristine.value = false;
      emailError.value = false;
    });

    onUnmounted(() => {
      email.value = null;
      emailPristine.value = true;
      emailError.value = true;
      receiveNotificationsByEmail.value = false;
      serverErrors.value = null;
      isLoading.value = false;
    });

    return {
      isLoading,
      email,
      emailPristine,
      emailError,
      handleEmailChange,
      updateUser,
      receiveNotificationsByEmail,
      serverErrors,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.email-container {
  display: flex;
  flex-direction: column;

  .email-description {
    display: flex;
    align-items: center;

    img {
      width: 8rem;
      height: 8rem;
      margin-right: 1rem;
    }

    p {
      @include typo-body-1;
    }

    @include for-xs-sm-width {
      flex-direction: column;
    }
  }

  .input-container {
    @include input-container;
    margin-top: 2rem;

    .input {
      @include input-common;
      @include typo-caption-bold;

      padding: 0.75rem 1rem;
      border-radius: 0.75rem;
    }

    .error-text {
      @include typo-caption-2;
      margin-top: 0.25rem;
      color: $primary-1;
    }
  }

  .primary-button {
    @include typo-button-1;
    @include button-primary-filled;

    margin-top: 2rem;
    padding: 13px 1rem;
  }

  .warning-policy-text {
    @include typo-caption;

    margin-top: 1rem;
    color: $neutrals-4;
    text-align: center;

    .policy-link {
      color: $neutrals-1;
      text-decoration: underline;
    }
  }

  .checkbox-wrapper {
    margin-top: 1rem;

    .checkbox-button {
      @include checkbox-button;

      align-items: center;
      padding-left: 0;
    }

    .checkbox-text {
      margin-left: 0.5rem;

      @include typo-caption-2;
    }
  }
}

.error-message {
  @include typo-caption-2;

  width: 100%;
  margin-top: 1rem;
  color: $primary-1;
}

</style>
