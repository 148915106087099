<template>
  <div class="heading-container">
    <h3>Featured Creators ⭐</h3>
    <div class="options">
      <router-link
        :to="{ name: 'user-list' }"
        class="more-button"
      >See all</router-link>
    </div>
  </div>

  <div class="content-container">
    <VueHorisontal
      v-if="data.length"
      :button="false"
      ref="horizontal"
      @scroll-debounce="onScroll"
      :key="data.length"
    >
      <div v-for="user in data" :key="user.userId" class="user-profile-wrapper">
        <router-link
          :to="{ name: 'user-profile', params: { userNickname: user.nickname }}"
          class="user-card"
        >
          <UserImage
            :userName="user.name"
            :userImagePath="user.photoIpfsPath"
            :isUserVerified="user.isVerified"
            :isUserRegistered="user.isRegistered"
            imageSize="112px"
            iconSize="24px"
            class="user-image-container"
          />
          <h3 class="name">{{ user.name || user.nickname }}</h3>
          <!-- <CryptoPrice :amount="user.sum" :currency="user.cryptoCurrency"/> -->
        </router-link>
      </div>
    </VueHorisontal>

    <button
      v-if="data.length"
      class="carousel-button left"
      :class="{'active': hasPrevItems}"
      aria-label="scroll to the left"
      @click="prev"
    >
      <img src="@/assets/images/common/arrow-left.svg" alt="arrow left icon" class="icon">
    </button>
    <button
      v-if="data.length"
      class="carousel-button right"
      :class="{'active': hasNextItems}"
      aria-label="scroll to the right"
      @click="next"
    >
      <img src="@/assets/images/common/arrow-right.svg" alt="arrow right icon" class="icon">
    </button>

    <div v-if="!isUsersLoading && !data.length" class="empty-container">
      <p>Coming soon.</p>
    </div>
    <ContentLoader :loading="isUsersLoading"/>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from 'vue';
import VueHorisontal from 'vue-horizontal';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-list/props';

// import CryptoPrice from '@/components/CryptoPrice.vue';
import UserImage from '@/components/UserImage.vue';
import ContentLoader from '@/components/ContentLoader.vue';

export default defineComponent({
  name: 'FeaturedCreators',

  components: {
    VueHorisontal,
    // CryptoPrice,
    UserImage,
    ContentLoader,
  },

  setup() {
    const store = useStore();

    const isUsersLoading = ref<boolean>(false);

    const data = computed(() => store.state.UserList.featuredUsers);

    const hasPrevItems = ref(false);
    const hasNextItems = ref(true);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const horizontal = ref<any>(null);

    const loadUsers = () => {
      isUsersLoading.value = true;
      store.dispatch(`${Modules.UserList}/${Actions.fetchFeaturedUsers}`).finally(() => {
        isUsersLoading.value = false;
      });
    };

    const prev = (e: Event) => {
      if (horizontal.value) {
        horizontal.value.prev(e);
      }
    };

    const next = (e: Event) => {
      if (horizontal.value) {
        horizontal.value.next(e);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onScroll = ({ hasNext, hasPrev }: any) => {
      hasNextItems.value = hasNext;
      hasPrevItems.value = hasPrev;
    };

    onMounted(() => {
      loadUsers();
    });

    return {
      isUsersLoading,
      data,
      hasNextItems,
      hasPrevItems,
      horizontal,
      next,
      prev,
      onScroll,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.heading-container {
  @include items-wrapper;

  margin-top: -2rem;

  @include for-md-lg-xl-width {
    justify-content: space-between;
  }

  & > * {
    @include for-xs-sm-width {
      width: 100%;
      max-width: 366px;
    }
  }

  h3 {
    color: $neutrals-2;

    @include typo-headline-5;
  }

  .options {
    display: flex;
    align-items: center;

    @include for-xs-sm-width {
      padding-top: 1rem;
    }
  }

  .more-button {
    @include typo-button-2;
    @include button-primary-filled;

    display: inline-flex;
    padding: 10px 1rem;
  }
}

.content-container {
  position: relative;
  margin-right: -2rem;
  margin-left: -2rem;
  text-align: center;
}

.user-profile-wrapper {
  padding-top: 2rem;
  padding-bottom: 1rem;

  &:first-child {
    padding-left: 2rem;
  }

  &:last-child {
    padding-right: 2rem;
  }

  &:not(:first-child) {
    padding-left: 1rem;

    @include for-xs-sm-width {
      padding-left: 0.25rem;
    }
  }

  &:not(:last-child) {
    padding-right: 1rem;

    @include for-xs-sm-width {
      padding-right: 0.25rem;
    }
  }
}

.carousel-button {
  @include transition-default;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
  border-radius: 50%;

  @media (min-width: 1346px) {
    position: absolute;
    top: 124px;
  }

  &.left {
    left: -3.5rem;
    margin-right: 0.75rem;
  }

  &.right {
    right: -3.5rem;
    margin-left: 0.75rem;
  }

  .icon {
    width: 24px;
  }

  &.active {
    border-color: $neutrals-6;

    &:hover {
      background: $neutrals-6;
    }
  }
}

.user-card {
  @include user-card;

  width: 202px;
  overflow: visible;

  @include for-xs-sm-md-lg-width {
    @include box-shadow-default;
  }

  .user-image-container {
    margin-bottom: 1rem;
  }

  .name {
    @include typo-caption-bold;
    @include text-ellipsis;

    max-width: 100%;
    color: $neutrals-2;
  }

  .sum {
    @include typo-caption-2-bold;
    @include text-ellipsis;

    max-width: 90%;
    color: $neutrals-2;
  }
}

.empty-container {
  @include empty-container;
}
</style>
