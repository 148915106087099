
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import throttle from 'lodash/throttle';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/item-list/props';
import PaginationOptions from '@/shared/models/pagination-options';

import ItemListFilters from '@/store/modules/item-list/models/item-list-filters';

import ContentLoader from '@/components/ContentLoader.vue';
import ItemCard from '@/components/ItemCard.vue';

export default defineComponent({
  name: 'FeaturedItems',

  components: {
    ItemCard,
    ContentLoader,
  },

  setup() {
    const store = useStore();

    const isItemsLoading = ref<boolean>(false);

    const data = computed(() => store.state.ItemList.items.data);
    const pagination = computed(() => store.state.ItemList.items.pagination);
    const isLastPage = computed(() => store.state.ItemList.items.isLastPage);

    const scrollComponent = ref<HTMLElement | null>(null);

    const loadItems = (options: Partial<PaginationOptions> & Partial<ItemListFilters>) => {
      isItemsLoading.value = true;
      store.dispatch(`${Modules.ItemList}/${Actions.fetchItems}`, {
        isFeatured: true,
        isNSFW: true,
        ...options,
      }).finally(() => {
        isItemsLoading.value = false;
      });
    };

    const clearItems = () => {
      store.dispatch(`${Modules.ItemList}/${Actions.clearItems}`);
    };

    const handleScroll = () => {
      const element = scrollComponent.value;
      if (element && !isLastPage.value && !isItemsLoading.value
        && element.getBoundingClientRect().bottom < window.innerHeight
      ) {
        loadItems({ page: pagination.value.page + 1 });
      }
    };

    onMounted(() => {
      loadItems({ page: 1 });

      window.addEventListener('scroll', throttle(handleScroll, 800, { leading: false }));
    });

    onUnmounted(() => {
      clearItems();

      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isItemsLoading,
      data,
      pagination,
      scrollComponent,
    };
  },
});
