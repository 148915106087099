
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InfoTooltip',

  props: {
    label: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: 'is-top',
    },
  },

  setup() {
    const preventDefault = (e: MouseEvent) => {
      e.preventDefault();
    };

    return {
      preventDefault,
    };
  },
});
