enum Modules {
  CommonService = 'CommonService',
  ItemList = 'ItemList',
  ItemProfile = 'ItemProfile',
  UserList = 'UserList',
  UserProfile = 'UserProfile',
  Feed = 'Feed',
}

export default Modules;
