
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { START_LOCATION, useRoute, useRouter } from 'vue-router';
import Loading from 'vue-loading-overlay';
import copy from 'copy-to-clipboard';
import Toggle from '@vueform/toggle';
import VueCountdown from '@chenfengyuan/vue-countdown';

import HttpStatusCode from '@/shared/models/http-status-code.enum';
import CryptoCurrency from '@/shared/models/crypto-currency.enum';
import RewardStatus from '@/shared/models/reward-status.enum';
import {
  ACTION_SUCCESS_DEFAULT,
  ERROR_UNKNOWN,
  ITEM_BID_SET_SUCCESS,
  ITEM_BUY_SUCCESS,
  ITEM_OFFER_APPROVE_SUCCESS,
  ITEM_OFFER_SET_SUCCESS,
  ITEM_OFFER_WITHDRAW_SUCCESS,
  ITEM_OFF_CHAIN_OFFER_SET_FAILURE,
  ITEM_OFF_CHAIN_OFFER_SET_SUCCESS,
  ITEM_OFF_CHAIN_OFFER_WITHDRAW_FAILURE,
  ITEM_OFF_CHAIN_OFFER_WITHDRAW_SUCCESS,
  ITEM_PRICE_UPDATE_SUCCESS,
  ITEM_SELLING_FINALIZE_SUCCESS,
  ITEM_SELLING_WITHDRAW_SUCCESS,
  ITEM_SET_REWARD_AS_REDEEMED_SUCCESS,
  ITEM_SALE_SUBSCRIBE_SUCCESS,
} from '@/shared/constants/messages';

import { isExternalType } from '@/shared/helpers/get-item-file-type';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions, Mutations } from '@/store/modules/item-profile/props';

import ItemSalesStatus from '@/store/modules/item-profile/models/item-sales-status.enum';
import ItemSaleData from '@/store/modules/item-profile/models/item-sale-data';
import Offer from '@/store/modules/item-profile/models/offer';
import ItemEnglishAuction from '@/store/modules/item-profile/models/item-english-auction';

import ContentLoader from '@/components/ContentLoader.vue';
import Modal from '@/components/Modal.vue';
import Markdown from '@/components/Markdown.vue';
import InfoModal from '@/components/InfoModal.vue';
import SaleModal from '@/components/SaleModal.vue';
import ConnectionModal from '@/components/ConnectionModal.vue';
import ItemFilePreview from '@/components/ItemFilePreview.vue';
import ItemEmbedFilePreview from '@/components/ItemEmbedFilePreview.vue';
import CryptoPrice from '@/components/CryptoPrice.vue';
import ItemPrice from '@/components/ItemPrice.vue';
import InfoTooltip from '@/components/InfoTooltip.vue';

import ItemProfileMainInfo from './ItemProfile/ItemProfileMainInfo.vue';
import ItemProfileSaleTimer from './ItemProfile/ItemProfileSaleTimer.vue';
import UserProfileOffChainBanner from '@/components/UserProfileOffChainBanner.vue';
import useWindowResize from '@/shared/hooks/use-window-resize';

interface SecretCodeModal {
  isOpen: boolean,
  secretCode: string,
  email: string,
  isCodeCopied: boolean,
  isEmailCopied: boolean,
}

const bidsHelperText = 'When you place a bid, we store your bid on our escrow. If somebody outbids you, the owner cancels the auction or accepts an offer, your bid is returned to you. You can not cancel your bid.';
const offersHelperText = 'You can place an offer for the item and the owner can accept it independently on the highest bid. We store your offer on our escrow. If the owner accepts the highest bid, another offer or cancels the auction, your offer is returned to you. You also can cancel your offer at any time.';

export default defineComponent({
  name: 'ItemProfile',

  components: {
    ItemProfileSaleTimer,
    VueCountdown,
    Loading,
    ConnectionModal,
    ItemFilePreview,
    ItemEmbedFilePreview,
    Modal,
    Markdown,
    InfoModal,
    SaleModal,
    ContentLoader,
    CryptoPrice,
    ItemPrice,
    ItemProfileMainInfo,
    InfoTooltip,
    Toggle,
    UserProfileOffChainBanner,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const { width } = useWindowResize();

    const isPageLoading = ref<boolean>(false);

    const isVideoInitiallyMuted = ref<boolean | null>(null);
    const isCheckoutModalOpen = ref<boolean>(false);
    const isConnectionModalOpen = ref<boolean>(false);
    const isMakeOffChainOfferModalOpen = ref<boolean>(false);
    const isPutOnSaleModalOpen = ref<boolean>(false);
    const isSetPriceModalOpen = ref<boolean>(false);
    const isMakeOfferModalOpen = ref<boolean>(false);
    const isMakeBidModalOpen = ref<boolean>(false);
    const hasPriceChanged = ref<boolean>(false);
    const isAcceptBidModalOpen = ref<boolean>(false);
    const selectedOffer = ref<Offer | null>(null);

    const enableEmbedded = ref<boolean>(true);

    const isLoading = ref<boolean>(false);
    const secretCodeModal = ref<SecretCodeModal>({
      isOpen: false,
      secretCode: '',
      email: 'redeem@melon.ooo',
      isCodeCopied: false,
      isEmailCopied: false,
    });
    const errors = ref<Record<string, string | null>>({ // inline errors
      amount: null,
    });
    const error = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });
    const success = ref<{ isOpen: boolean; text: string }>({
      isOpen: false,
      text: '',
    });

    const isOwner = ref<boolean>(false);
    const isJustOwner = ref<boolean>(false);
    const isJustCreator = ref<boolean>(false);

    const item = computed(() => store.state.ItemProfile.item);
    const itemBids = computed(() => store.state.ItemProfile.offChainOffers.data);
    const userOffChainOffer = computed(() => store.state.ItemProfile.userOffChainOffer);
    const userOffer = computed(() => store.state.ItemProfile.userOffer);
    const isItemSelling = computed(() => (
      store.state.ItemProfile.item?.salesStatus === ItemSalesStatus.OnSale
      || store.state.ItemProfile.item?.salesStatus === ItemSalesStatus.OnEnglishAuction
      || store.state.ItemProfile.item?.salesStatus === ItemSalesStatus.OnDutchAuction
    ));
    const bids = computed(() => store.state.ItemProfile.bids.data);

    const currentUserId = computed(() => store.state.user.id);
    const isMainNet = computed(() => store.state.user.isMainNet);

    function getDate(date: number) {
      const d = new Date(date);
      return d.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZoneName: 'short',
      });
    }

    function transformSlotProps(props: { [s: string]: number }) {
      const formattedProps: { [s: string]: string } = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    }

    function validateAcceptBid(data: ItemEnglishAuction): boolean {
      const curDate = +new Date();
      return (
        curDate >= data.startDate
        && curDate <= data.endDate
        && (data.minBid === data.reservePrice
          ? data.currentPrice >= data.minBid
          : data.currentPrice > data.minBid)
      ) || (
        curDate > data.endDate
        && data.endDate !== 0
        && data.currentPrice >= data.reservePrice
      );
    }

    const loadItem = async (id: string, withGlobalLoading = false) => {
      if (withGlobalLoading) {
        isLoading.value = true;
      } else {
        isPageLoading.value = true;
      }

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.getItem}`, id);

        const titlePostfix = item.value?.title;

        if (titlePostfix) {
          window.document.title = `${window.document.title} - ${titlePostfix}`;
        }

        if (currentUserId.value && item.value
          && item.value.salesStatus === ItemSalesStatus.OnEnglishAuction
          && currentUserId.value !== item.value.ownerId) {
          await store.dispatch(`${Modules.ItemProfile}/${Actions.fetchOffer}`, id);
        }
        if (currentUserId.value && item.value
          && item.value.salesStatus === ItemSalesStatus.OnOffChainOfferAuction
          && currentUserId.value !== item.value.creatorId) {
          await store.dispatch(`${Modules.ItemProfile}/${Actions.fetchItemOfferOnOffChainOfferAuction}`, id);
        }
      } catch (e) {
        console.error(e);

        if (e.isAxiosError
          // Bad Request is present due to item id type - Number.
          // In case item id type is changed to string or backend will handle it in another way,
          // HttpStatusCode.BadRequest may be removed
          && [HttpStatusCode.BadRequest, HttpStatusCode.NotFound].includes(e.response.status)) {
          router.push({ name: 'item-list' });
        }
      } finally {
        if (withGlobalLoading) {
          isLoading.value = false;
        } else {
          isPageLoading.value = false;
        }
      }
    };

    const isAuthenticated = (callback: () => void) => {
      if (currentUserId.value) {
        callback();
      } else {
        isConnectionModalOpen.value = true;
      }
    };

    const callModalOnBuy = (isEnglishAuction: boolean): void => {
      isAuthenticated(() => {
        if (isEnglishAuction) {
          isMakeBidModalOpen.value = true;
        } else {
          isCheckoutModalOpen.value = true;
        }
      });
    };

    const callModalOnOffer = (): void => {
      isAuthenticated(() => {
        isMakeOfferModalOpen.value = true;
      });
    };

    const callModalOnOffChainOffer = (): void => {
      isAuthenticated(() => {
        isMakeOffChainOfferModalOpen.value = true;
      });
    };

    function buildErrorModal(text: string): void {
      error.value = {
        text: text || ERROR_UNKNOWN,
        isOpen: true,
      };
    }

    function buildSuccessModal(text: string): void {
      success.value = {
        isOpen: true,
        text: text || ACTION_SUCCESS_DEFAULT,
      };
    }

    function reloadPage(): void {
      router.go(0);
    }

    async function subscribeOnSale(email: string) {
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.subscribeForSale}`, email);
        buildSuccessModal(ITEM_SALE_SUBSCRIBE_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function buyItem() {
      isCheckoutModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.buyItem}`);
        buildSuccessModal(ITEM_BUY_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function makeOffChainOfferOnItem(data: ItemSaleData) {
      isMakeOffChainOfferModalOpen.value = false;
      errors.value.amount = null;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.setItemOfferOnOffChainOfferAuction}`, {
          price: data.amount,
          cryptoCurrency: data.currency,
        });
        buildSuccessModal(ITEM_OFF_CHAIN_OFFER_SET_SUCCESS);
      } catch (e) {
        if (e.response?.data?.Price) {
          isMakeOffChainOfferModalOpen.value = true;
          errors.value.amount = e.response?.data?.Price[0];
        } else {
          buildErrorModal(ITEM_OFF_CHAIN_OFFER_SET_FAILURE);
        }
      } finally {
        isLoading.value = false;
      }
    }

    async function withdrawOffChainOfferOnItem() {
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.withdrawItemOfferFromOffChainOfferAuction}`);
        buildSuccessModal(ITEM_OFF_CHAIN_OFFER_WITHDRAW_SUCCESS);
      } catch (e) {
        buildErrorModal(ITEM_OFF_CHAIN_OFFER_WITHDRAW_FAILURE);
      } finally {
        isLoading.value = false;
      }
    }

    async function makeOfferOnItem(data: ItemSaleData) {
      isMakeOfferModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.setItemOfferOnEnglishAuction}`, data.amount);
        buildSuccessModal(ITEM_OFFER_SET_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function withdrawOfferOnItem(offerId: number) {
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.withdrawItemOfferFromEnglishAuction}`, offerId);
        buildSuccessModal(ITEM_OFFER_WITHDRAW_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function makeBidOnItem(data: ItemSaleData) {
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.buyItem}`, data.amount);
        buildSuccessModal(ITEM_BID_SET_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function checkBidOnItem(data: ItemSaleData) {
      const { amount } = data;

      isMakeBidModalOpen.value = false;
      hasPriceChanged.value = false;

      await loadItem(route.params.id as string, true);

      if (!!item.value?.englishAuction && amount < item.value.englishAuction.currentMinBid) {
        isMakeBidModalOpen.value = true;
        hasPriceChanged.value = true;
      } else {
        await makeBidOnItem(data);
      }
    }

    async function updateItemPrice(data: ItemSaleData) {
      isSetPriceModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.updateItemPrice}`, data.amount);
        buildSuccessModal(ITEM_PRICE_UPDATE_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function acceptBidOnItem() {
      isAcceptBidModalOpen.value = false;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.finalizeItemOnEnglishAuction}`);
        buildSuccessModal(ITEM_SELLING_FINALIZE_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function acceptOfferOnItem(offerId: number) {
      selectedOffer.value = null;
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.approveItemOfferOnEnglishAuction}`, offerId);
        buildSuccessModal(ITEM_OFFER_APPROVE_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function withdrawItemFromSelling(): Promise<void> {
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.withdrawItemFromSelling}`);
        buildSuccessModal(ITEM_SELLING_WITHDRAW_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    async function openRewardModal(): Promise<void> {
      isLoading.value = true;

      try {
        secretCodeModal.value.secretCode = await store.dispatch(`${Modules.ItemProfile}/${Actions.getSecretCode}`);
        secretCodeModal.value.isOpen = true;
      } catch (e) {
        buildErrorModal(e.response?.data?.itemId?.[0] || e.message);
      } finally {
        isLoading.value = false;
      }
    }

    function copyCode(code: string) {
      if (copy(code)) {
        secretCodeModal.value.isCodeCopied = true;

        setTimeout(() => {
          secretCodeModal.value.isCodeCopied = false;
        }, 1000);
      }
    }

    function copyEmail(email: string) {
      if (copy(email)) {
        secretCodeModal.value.isEmailCopied = true;

        setTimeout(() => {
          secretCodeModal.value.isEmailCopied = false;
        }, 1000);
      }
    }

    async function markAsRedeemed(): Promise<void> {
      isLoading.value = true;

      try {
        await store.dispatch(`${Modules.ItemProfile}/${Actions.setRewardAsRedeemed}`);
        buildSuccessModal(ITEM_SET_REWARD_AS_REDEEMED_SUCCESS);
      } catch (e) {
        buildErrorModal(e.message);
      } finally {
        isLoading.value = false;
      }
    }

    watch(currentUserId, (userId) => {
      if (userId && item.value
        && item.value.salesStatus === ItemSalesStatus.OnEnglishAuction
        && userId !== item.value.ownerId) {
        store.dispatch(`${Modules.ItemProfile}/${Actions.fetchOffer}`, route.params.id as string);
      } else {
        store.commit(`${Modules.ItemProfile}/${Mutations.setItemUserOffer}`);
      }

      if (userId && item.value
        && item.value.salesStatus === ItemSalesStatus.OnOffChainOfferAuction
        && userId !== item.value.creatorId) {
        store.dispatch(`${Modules.ItemProfile}/${Actions.fetchItemOfferOnOffChainOfferAuction}`, route.params.id as string);
      } else {
        store.commit(`${Modules.ItemProfile}/${Mutations.setItemUserOffChainOffer}`);
      }
    });

    watch([item, currentUserId], ([value, userId]) => {
      isOwner.value = !!userId && userId === value?.ownerId;
      isJustOwner.value = isOwner.value && userId !== value?.creatorId;
      isJustCreator.value = !!userId && userId !== value?.ownerId && userId === value?.creatorId;
    });

    // As for now metamask cancel unapproved transactions on network change,
    // but do not send any response to that, so we reload forcefully, though,
    // there may be ongoing transaction, too.
    watch(isMainNet, () => {
      if (isLoading.value) {
        reloadPage();
      } else {
        loadItem(route.params.id as string);
      }
    });

    watch(() => success.value.isOpen, (open) => {
      if (!open) {
        reloadPage();
      }
    });

    onMounted(() => {
      setTimeout(() => {
        loadItem(route.params.id as string);
      }, 500);
    });

    onUnmounted(() => {
      store.dispatch(`${Modules.ItemProfile}/${Actions.clearItem}`);
    });

    return {
      bidsHelperText,
      offersHelperText,
      width,
      RewardStatus,
      isPageLoading,
      isOwner,
      isJustOwner,
      isJustCreator,
      isItemSelling,
      currentUserId,
      isMainNet,
      item,
      userOffChainOffer,
      userOffer,
      bids,
      SaleStatus: ItemSalesStatus,
      CryptoCurrency,
      isVideoInitiallyMuted,
      isCheckoutModalOpen,
      isConnectionModalOpen,
      isMakeOffChainOfferModalOpen,
      isPutOnSaleModalOpen,
      isSetPriceModalOpen,
      isMakeOfferModalOpen,
      isMakeBidModalOpen,
      hasPriceChanged,
      isAcceptBidModalOpen,
      selectedOffer,
      enableEmbedded,
      isLoading,
      secretCodeModal,
      errors,
      error,
      success,
      isExternalType,
      reloadPage,
      getDate,
      transformSlotProps,
      validateAcceptBid,
      callModalOnBuy,
      callModalOnOffer,
      callModalOnOffChainOffer,
      subscribeOnSale,
      buyItem,
      makeOffChainOfferOnItem,
      withdrawOffChainOfferOnItem,
      makeOfferOnItem,
      withdrawOfferOnItem,
      checkBidOnItem,
      updateItemPrice,
      acceptBidOnItem,
      acceptOfferOnItem,
      withdrawItemFromSelling,
      openRewardModal,
      copyCode,
      copyEmail,
      markAsRedeemed,
      itemBids,
    };
  },

  beforeRouteEnter(to, from, next) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next((vm: any) => {
      // Mute video on details only if this page is starting one,
      // otherwise user has interacted with SPA via link
      // and browser should allow to play video with sound
      // eslint-disable-next-line no-param-reassign
      vm.isVideoInitiallyMuted = from === START_LOCATION;
    });
  },
});
