import { InjectionKey } from 'vue';
import Fortmatic from 'fortmatic';
import { WidgetMode } from 'fortmatic/dist/cjs/src/core/sdk.d';

export const fmKey: InjectionKey<WidgetMode> = Symbol('fm-key');

const rpcSettings = {
  rpcUrl: process.env[`VUE_APP_${process.env.VUE_APP_NETWORK_NAME}_FORTMATIC_RPC_URL`] as string,
  chainId: Number(process.env[`VUE_APP_${process.env.VUE_APP_NETWORK_NAME}_CHAIN_ID`] as string),
};

export const fm = new Fortmatic(
  process.env.VUE_APP_FORTMATIC_API_KEY as string,
  rpcSettings,
);
