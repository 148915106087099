<template>
  <span>
    <CryptoPrice :amount="cryptoAmount" :currency="cryptoCurrency" class="crypto-price" />
    <span v-if="usdAmount !== null" class="usd-price">
      ≈ <span>${{ usdAmount }}</span>
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import CryptoPrice from './CryptoPrice.vue';

export default defineComponent({
  name: 'ItemPrice',

  components: {
    CryptoPrice,
  },

  props: {
    cryptoAmount: {
      required: true,
    },
    cryptoCurrency: {
      required: true,
    },
    usdAmount: {
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'src/styles/variables';

.crypto-price {
  color: $neutrals-3;
}

.usd-price {
  color: $neutrals-4;
}
</style>
