import store from '@/store';

function selfMintGuard(): boolean | { name: string } {
  if (store.state.user.personalInfo.isSelfMint) {
    return true;
  }

  return { name: 'home' };
}

export default selfMintGuard;
