import Web3 from 'web3';

import { fm } from './fortmatic';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const eth = (window as any).ethereum;
export const ethWeb3: Web3 | undefined = eth && new Web3(eth);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fmWeb3 = fm && new Web3(fm.getProvider() as any);

export const anyWeb3 = ethWeb3 || fmWeb3;
