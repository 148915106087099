<template>
  <transition name="fade">
    <div class="popup">
      <slot />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Popup',
});
</script>

<style lang="scss" scoped>
@import 'src/styles/mixins';

.popup {
  @include box-shadow-default;

  position: fixed;
  bottom: 1rem;
  left: 50%;
  z-index: 2147483002; // to be higher than intercom bot
  width: 100%;
  max-width: 360px;
  padding: 1rem 2rem;
  background-color: $neutrals-8;
  border-radius: 0.75rem;
  transform: translateX(-50%);

  @include for-xs-sm-width {
    max-width: 320px;
    padding: 1rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
