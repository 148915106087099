
import {
  computed,
  defineComponent,
  onBeforeUpdate,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
  watch,
} from 'vue';
import InlineSvg from 'vue-inline-svg';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import svgIconUrl from '@/shared/helpers/svg-icon-url';

import PaginationOptions from '@/shared/models/pagination-options';

interface IData {
  [key: string]: string | null | boolean | undefined,
}

export default defineComponent({
  name: 'Select',

  components: {
    InlineSvg,
  },

  props: {
    modelValue: {
      default: null,
    },
    initialFullValue: {
      default: [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    idKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    getOptionIconUrl: {
      type: Function,
    },
    data: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object as () => PaginationOptions,
      default: () => ({ page: 1, pageSize: 10 }),
    },
    isLastPage: {
      type: Boolean,
      default: false,
    },
    withDefaultOption: {
      type: Boolean,
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isFormElement: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
    'loadOptions',
  ],

  setup(props, { emit }) {
    const {
      idKey,
      labelKey,
      multiple,
      data,
      pagination,
      isLastPage,
      withSearch,
      readOnly,
    } = toRefs(props);

    const isDropdownOpen = ref(false);

    const getSelectFullValue = (value: string | boolean | string[] | null, prevValue: IData[]) => {
      if (value === null) {
        return [];
      }

      if (!Array.isArray(value)) {
        const option = (data.value as IData[]).find((o) => o[idKey.value] === value);
        return option ? [{ ...option }] : [];
      }

      if (value.length === 0) {
        return [];
      }

      const nextValue: IData[] = [];

      value.forEach((id) => {
        const option = prevValue.find((o) => o[idKey.value] === id)
          ?? (data.value as IData[]).find((o) => o[idKey.value] === id);

        if (option) {
          nextValue.push({ ...option });
        }
      });

      return nextValue;
    };

    const selectFullValue = ref<IData[]>(
      getSelectFullValue(props.modelValue, props.initialFullValue),
    );

    const getOptionLabel = (options: IData[]) => {
      const labels = options.map((o) => (o ? (o[labelKey.value] ?? o[idKey.value] ?? '') : ''));
      return labels.join(', ');
    };

    const selectValue = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit('update:modelValue', value);
      },
    });

    const search = ref<string | boolean | null>(getOptionLabel(selectFullValue.value));

    const searchInput = ref<HTMLInputElement | null>(null);
    const dropdownSelect = ref<HTMLInputElement | null>(null);
    const toggleButton = ref<HTMLButtonElement | null>(null);

    let optionRefs: HTMLOptionElement[] = [];

    const setOptionRef = (el: HTMLOptionElement) => {
      if (el) {
        optionRefs.push(el);
      }
    };

    const checkElementsBounds = () => {
      if (!isLastPage.value && dropdownSelect.value) {
        const element = optionRefs[optionRefs.length - 1];
        if (element && (element.getBoundingClientRect().top
          < dropdownSelect.value.getBoundingClientRect().bottom)
        ) {
          emit('loadOptions', { page: pagination.value.page + 1 });
        }
      }
    };

    const openDropdown = () => {
      isDropdownOpen.value = true;
    };

    const closeDropdown = () => {
      search.value = getOptionLabel(selectFullValue.value);
      isDropdownOpen.value = false;
    };

    const toggleDropdown = () => {
      if (searchInput.value) {
        if (isDropdownOpen.value) {
          closeDropdown();
        } else {
          searchInput.value.focus();
        }
      }
    };

    const onSearchInputFocus = () => {
      if (readOnly.value) {
        return;
      }

      if (withSearch.value) {
        search.value = '';
      }

      emit('loadOptions', { search: search.value });
      openDropdown();
      checkElementsBounds();
    };

    const onSearchInputBlur = (e: FocusEvent) => {
      if (readOnly.value) {
        return;
      }

      if (e.relatedTarget !== dropdownSelect.value && e.relatedTarget !== toggleButton.value) {
        closeDropdown();
      }
    };

    const onSearchInputChange = (e: InputEvent) => {
      search.value = (e.target as HTMLInputElement)?.value;
      emit('loadOptions', { search: search.value });
    };

    const onDropdownSelectClick = () => {
      if (!multiple.value) {
        closeDropdown();
      }
    };

    const onDropdownSelectBlur = (e: FocusEvent) => {
      if (e.relatedTarget !== searchInput.value && e.relatedTarget !== toggleButton.value) {
        closeDropdown();
      }
    };

    watch(selectValue, (value) => {
      selectFullValue.value = getSelectFullValue(value, [...selectFullValue.value]);
      search.value = getOptionLabel(selectFullValue.value);
    });

    onBeforeUpdate(() => {
      optionRefs = [];
    });

    onMounted(() => {
      emit('loadOptions', { search: search.value });

      if (dropdownSelect.value) {
        dropdownSelect.value.addEventListener('scroll', throttle(checkElementsBounds, 800, { leading: false }));
      }
    });

    onUnmounted(() => {
      if (dropdownSelect.value) {
        dropdownSelect.value.removeEventListener('scroll', throttle(checkElementsBounds, 800, { leading: false }));
      }
    });

    return {
      search,
      selectValue,
      isDropdownOpen,
      searchInput,
      dropdownSelect,
      toggleButton,
      svgIconUrl,
      onSearchInputFocus,
      onSearchInputBlur,
      onSearch: debounce(onSearchInputChange, 400),
      onDropdownSelectClick,
      onDropdownSelectBlur,
      openDropdown,
      toggleDropdown,
      setOptionRef,
    };
  },
});
