
import { defineComponent } from 'vue';
import Cookies from 'js-cookie';
import Loading from 'vue-loading-overlay';
import { RouteLocationNormalizedLoaded } from 'vue-router';

// models
import { Actions } from '@/store/props';

// components
import Popup from '@/components/Popup.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import EmailGatheringModal from '@/components/EmailGatheringModal.vue';

// constants
const cookieAgreeKey = 'agreedToMelonCookiePolicy';
const cookieEmailKey = 'emailSent';

export default defineComponent({
  components: {
    EmailGatheringModal,
    MainLayout,
    Loading,
    Popup,
  },

  data: () => ({
    isCookiePopupOpen: false,
    isEmailModalOpen: false,
  }),

  computed: {
    isLoading(): boolean {
      return this.$store.state.isLoading;
    },
  },

  watch: {
    $route(to: RouteLocationNormalizedLoaded): void {
      const { name } = to;
      if (name === 'terms-and-conditions' || name === 'privacy-policy') {
        this.isEmailModalOpen = false;
      } else {
        // this.checkIsEmailSent();
      }
    },
  },

  mounted() {
    if (this.$ethProvider) {
      this.$ethProvider.on('accountsChanged', this.handleAccountChange);
      this.$ethProvider.on('chainChanged', this.handleChainChange);
    }

    if (this.$ethProvider || this.$fmProvider) {
      this.$store.dispatch(Actions.initializeContractAddresses);
      this.$store.dispatch(Actions.updateFromLocalStorage);
    }

    this.$store.dispatch(Actions.fetchBanner);
    this.$store.dispatch(Actions.fetchFeaturedBanner);

    if (!Cookies.get(cookieAgreeKey)) {
      this.isCookiePopupOpen = true;
    }

    // this.checkIsEmailSent();

    if (this.$loadScript) {
      this.$loadScript(`${window.location.protocol}//platform.twitter.com/widgets.js`);
    }
  },

  unmounted() {
    if (this.$ethProvider) {
      this.$ethProvider.removeAllListeners('accountsChanged');
      this.$ethProvider.removeAllListeners('chainChanged');
    }

    if (this.$unloadScript) {
      this.$unloadScript(`${window.location.protocol}//platform.twitter.com/widgets.js`);
    }
  },

  methods: {
    handleAccountChange(users: string[]): void {
      const { isLoading, session: { isMetamask } } = this.$store.state;
      if (!isLoading && isMetamask) {
        this.$store.dispatch(Actions.reqUserAuth, {
          address: users[0],
          forceConnection: false,
          isMetamask: true,
        });
      }
    },
    handleChainChange(): void {
      const { isLoading, session: { isMetamask } } = this.$store.state;
      if (!isLoading && isMetamask) {
        this.$store.dispatch(Actions.reqUserAuth, {
          address: this.$store.state.user.address,
          forceConnection: false,
          isMetamask: true,
        });
      }
    },
    checkIsEmailSent(): void {
      if (!Cookies.get(cookieEmailKey)) {
        this.isEmailModalOpen = true;
      }
    },
    agree(): void {
      Cookies.set(cookieAgreeKey, String(true), { expires: 365 });
      this.isCookiePopupOpen = false;
    },
    emailSent(): void {
      Cookies.set(cookieEmailKey, String(true), { expires: 365 });
      this.isEmailModalOpen = false;
    },
  },
});
