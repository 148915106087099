
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import throttle from 'lodash/throttle';

import useDebouncedRef from '@/shared/hooks/use-debounced-ref';

import { useStore } from '@/store';
import { Modules } from '@/store/props';
import { Actions } from '@/store/modules/user-list/props';
import PaginationOptions from '@/shared/models/pagination-options';

import UserListFilters from '@/store/modules/user-list/models/user-list-filters';
import UserItemFilter from '@/store/modules/user-list/models/user-item-filter.enum';

import ContentLoader from '@/components/ContentLoader.vue';
import Select from '@/components/Select.vue';
import UserImage from '@/components/UserImage.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'UserList',

  components: {
    ContentLoader,
    Select,
    UserImage,
  },

  setup() {
    const store = useStore();
    const route = useRoute();

    const isUsersLoading = ref<boolean>(false);

    const initialSearch = computed(() => route.query.search);

    const search = useDebouncedRef(initialSearch.value as string ?? '', 400);

    const orderBy = ref<UserItemFilter>(store.state.UserList.users.filters.orderBy);

    const isVerified = ref<string | boolean>('');

    const sortOptions = [
      {
        id: UserItemFilter.MaxItemsOnSale,
        label: 'Most items on sale',
      },
      {
        id: UserItemFilter.Collectibles,
        label: 'Collected',
      },
      {
        id: UserItemFilter.Created,
        label: 'Created',
      },
    ];

    const verifiedOptions = [
      {
        id: false,
        label: 'Non-verified',
      },
      {
        id: true,
        label: 'Verified Only',
      },
      {
        id: '',
        label: 'All',
      },
    ];

    const data = computed(() => store.state.UserList.users.data);
    const pagination = computed(() => store.state.UserList.users.pagination);
    const isLastPage = computed(() => store.state.UserList.users.isLastPage);

    const scrollComponent = ref<HTMLElement | null>(null);

    const loadUsers = (options: Partial<PaginationOptions> & Partial<UserListFilters>) => {
      isUsersLoading.value = true;
      store.dispatch(`${Modules.UserList}/${Actions.fetchUsers}`, {
        search: search.value,
        orderBy: orderBy.value,
        isVerified: isVerified.value,
        ...options,
      }).finally(() => {
        isUsersLoading.value = false;
      });
    };

    const clearUsers = () => {
      store.dispatch(`${Modules.UserList}/${Actions.clearUsers}`);
    };

    const handleScroll = () => {
      const element = scrollComponent.value;
      if (element && !isLastPage.value && !isUsersLoading.value
        && element.getBoundingClientRect().bottom < window.innerHeight
      ) {
        loadUsers({ page: pagination.value.page + 1 });
      }
    };

    watch(search, (value) => {
      clearUsers();
      loadUsers({ search: value });
    });

    watch(orderBy, (value) => {
      clearUsers();
      loadUsers({ orderBy: value });
    });

    watch(isVerified, (value) => {
      clearUsers();
      loadUsers({ isVerified: value });
    });

    onMounted(() => {
      loadUsers({ page: 1 });

      window.addEventListener('scroll', throttle(handleScroll, 800, { leading: false }));
    });

    onUnmounted(() => {
      clearUsers();

      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isUsersLoading,
      search,
      orderBy,
      sortOptions,
      isVerified,
      verifiedOptions,
      data,
      pagination,
      scrollComponent,
      UserItemFilter,
    };
  },
});
